import _ from 'lodash/fp';

export function parseQueryString(queryString) {
  const query = queryString.substring(1);
  const queries = query.split('&');
  const params = {};
  queries.forEach(q => {
    const [key, value] = q.split('=');
    params[key] = value;
  });
  return params;
}

export function toQueryString(obj) {
  const params = Object.keys(_.omitBy(el => _.isEmpty(el.toString()))(obj)).map(
    key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`
  );
  return params.length ? params.join('&') : '';
}

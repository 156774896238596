import React from 'react';
import propTypes from 'prop-types';
import _ from 'lodash/fp';
import { FileDrop } from 'react-file-drop';
import { ReactComponent as BigUploadIcon } from '../../../assets/svgs/bigUploadIcon.svg';
import { ReactComponent as TrashIcon } from '../../../assets/svgs/trashIconRed.svg';
import { features, messages } from '../../../core/i18n/text';
import { AlertModal, Alert, Loader } from '../../index';
import './UploadFileModal.scss';

class UploadFileModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showBrowseOption: true,
      showError: false,
      file: null,
      loading: false,
      submitInProgress: false,
    };

    this.closeModal = this.closeModal.bind(this);
    this.openBrowse = this.openBrowse.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.uploadInput = React.createRef();
  }

  onChangeInput = event => {
    let files = this.uploadInput.current.files;
    this.onDrop(files, event);
  };

  openBrowse() {
    this.uploadInput.current.click();
  }

  removeFile() {
    this.setState({
      file: null,
    });
  }

  onDrop(files, event) {
    const { fileType } = this.props;
    this.setState({
      loading: true,
    });
    if (!files[0].name.endsWith(fileType)) {
      this.setState({
        showError: true,
        file: null,
        showBrowseOption: true,
      });
    } else {
      event.preventDefault();
      this.setState({
        showError: false,
        file: files[0],
        showBrowseOption: true,
      });
    }
    this.setState({
      loading: false,
    });
  }

  closeModal() {
    this.setState({
      file: null,
      showError: false,
    });
    this.props.closeModal();
  }

  async submitFile(file) {
    this.setState({
      submitInProgress: true,
    });

    await this.props.submitFile(file);

    this.setState({
      submitInProgress: false,
    });
    this.closeModal();
  }

  renderDragableSection = () => {
    const uploadModal = {
      ...features.fileUpload.uploadModal,
      ..._.get('fileUpload.uploadModal')(features[this.props.featureName]),
    };
    const { showBrowseOption } = this.state;

    return (
      <FileDrop
        onFrameDragEnter={() => this.setState({ showBrowseOption: false })}
        onFrameDragLeave={() => this.setState({ showBrowseOption: true })}
        onFrameDrop={() => {
          this.setState({ showBrowseOption: true });
        }}
        onDrop={this.onDrop}>
        <div className={`Dragable__Section ${showBrowseOption ? '' : 'Dragable__Section-Border'}`}>
          <BigUploadIcon />
          {!showBrowseOption ? (
            <div className="FileUpload_Hint">{uploadModal.dragHint}</div>
          ) : (
            this.state.showBrowseOption && (
              <>
                <div className="FileUpload_Hint">{uploadModal.hint}</div>
                <p>{uploadModal.or}</p>
                <button className="bigButton Browse__Button" onClick={this.openBrowse}>
                  {uploadModal.browse}
                </button>
                <input
                  type="file"
                  ref={this.uploadInput}
                  accept={this.props.fileType}
                  onChange={this.onChangeInput}
                  hidden
                  aria-label="File"
                />
              </>
            )
          )}
        </div>
      </FileDrop>
    );
  };

  render() {
    const { showModal } = this.props;
    const { file, showError } = this.state;
    const uploadModal = {
      ...features.fileUpload.uploadModal,
      ..._.get('fileUpload.uploadModal')(features[this.props.featureName]),
    };

    return (
      <AlertModal
        show={showModal}
        title={uploadModal.title}
        note={uploadModal.description}
        showButton={!!file}
        buttonText={file ? uploadModal.uploadFile : ''}
        buttonAction={() => this.submitFile(file)}
        buttonLoading={this.state.submitInProgress}
        close={this.closeModal}
        className="upload_file_modal">
        {showError && (
          <Alert type="error" message={messages.error.invalidFileFormat} hasFullWidth />
        )}
        {file ? (
          <div className="File__Section">
            <div>
              <div className="File__Name__Label">{uploadModal.fileName}</div>
              <div className="File__Name">{this.state.file.name}</div>
            </div>
            <div className="File__Remove" onClick={this.removeFile} tabIndex="0">
              <div>{uploadModal.remove}</div>
              <TrashIcon />
            </div>
          </div>
        ) : this.state.loading ? (
          <div className="Loader__Section">
            <Loader />
          </div>
        ) : (
          this.renderDragableSection()
        )}
      </AlertModal>
    );
  }
}

UploadFileModal.propTypes = {
  featureName: propTypes.string,
  fileType: propTypes.string,
};
UploadFileModal.defaultProps = {
  logoOnly: false,
  fileType: '.csv',
};

export default UploadFileModal;

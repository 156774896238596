import React from 'react';
import propTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Validator from 'email-validator';
import { UserServices } from '../../../services';
import { common, clickables, messages, features } from '../../../core/i18n/text';
import Email from '../../../components/forms/fields/Input';
import ConfirmationMessage from '../../../components/forms/ConfirmationMessage';
import './ExpiredLink.scss';

const Text = { messages, common, clickables, features };

class ExpiredLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      verificationSent: false,
      email: props.email,
      errors: {
        email: '',
      },
    };
    this.resendVerification = this.resendVerification.bind(this);
  }

  onEmailChange = e => {
    let email = e.target.value.trim();

    this.setState({
      email,
    });
  };

  resendVerification() {
    const isEmailEmpty = this.state.email.trim() === '' ? true : false;
    const isEmailValid = Validator.validate(this.state.email.trim());

    const emailError = isEmailEmpty
      ? Text.messages.error.emailRequired
      : isEmailValid
      ? ''
      : Text.messages.error.emailInvalid;

    this.setState({
      errors: {
        email: emailError,
      },
    });

    if (!emailError) {
      UserServices.resendVerificationCode(this.state.email)
        .then(() => {
          this.setState({
            verificationSent: true,
          });
        })
        .catch(err => {
          if (err.response) {
            this.setState({
              errors: {
                email: err.response.data.Error,
              },
            });
          }
        });
    }
  }

  handleRedirect = () => {
    this.props.history.push('/login');
  };

  render() {
    if (this.state.verificationSent) {
      const msg = [
        <div>
          <div className="description_label">
            {Text.features.register.confirmation.description.email}{' '}
          </div>
          <div className="register_email">
            <strong>{this.state.email}</strong>
          </div>
        </div>,
        Text.features.register.confirmation.description.check,
      ];

      return (
        <ConfirmationMessage
          buttonAction={this.handleRedirect}
          title={Text.features.register.confirmation.title}
          message={msg}
          buttonText={Text.clickables.buttons.continueToLogin}
        />
      );
    }

    const title = Text.messages.error.expiredEmailVerificationLink.title;
    const message = Text.messages.error.expiredEmailVerificationLink.body;
    return (
      <div className="expiredLink__Container">
        <div className="expiredLink__Title">{title}</div>
        <div className="expiredLink__Text">{message}</div>
        <Email
          name={'email'}
          label={Text.common.fields.email}
          value={this.state.email}
          error={this.state.errors.email}
          onChange={this.onEmailChange}
        />
        <button type="button" className="bigButton" onClick={this.resendVerification}>
          {Text.clickables.buttons.confirmEmail}
        </button>
        <div className="loginForm__Footer">
          <div>
            {Text.clickables.links.alreadyHaveAccount} &nbsp;
            <a className="registerForm__Link" href="/login">
              {Text.clickables.links.clickHere}
            </a>
          </div>
        </div>
      </div>
    );
  }
}

ExpiredLink.defaultProps = {
  email: '',
};

ExpiredLink.propTypes = {
  email: propTypes.string,
};

export default withRouter(ExpiredLink);

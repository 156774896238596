import { createSelector } from 'reselect';

const getEntityDef = state => state.rosterReducer.entityDef?.data;
const getMapp = (state, obj) => obj;

export const mapEntityDef = createSelector([getEntityDef, getMapp], (entityDef, mappingObj) => {
  let newEntityDef = {};

  if (entityDef) {
    entityDef.forEach(el => {
      newEntityDef[el.code] = el;
    });
  }

  return entityDef ? newEntityDef : {};
});

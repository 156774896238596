import React, { useCallback, useMemo } from 'react';
import { generatePath } from 'react-router';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import propTypes from 'prop-types';

import './OrgCrumbs.scss';

const OrgCrumbs = ({ crumbs, rootName }) => {
  /**
   *  react-router-dom hooks
   */
  const history = useHistory();
  const match = useRouteMatch();

  /**
   * useMemo
   */
  const newUrl = useMemo(
    () =>
      match.path.includes('students/students-transfer')
        ? match.path.replace('students-transfer', 'studentstable')
        : match.path,
    [match.path]
  );

  /**
   * useCallback
   */
  const onClick = useCallback(
    ({ orgPartId }) => {
      const url = generatePath(newUrl, {
        orgPartId,
        tabName: match.params.tabName,
      });
      history.push(url);
    },
    [history, match.params.tabName, newUrl]
  );

  const onKeyDown = useCallback(
    (e, crumb) => {
      if (e.keyCode !== 13) return;
      onClick(crumb);
    },
    [onClick]
  );

  const divider = (
    <div className="crumbs__Dividor">
      <FontAwesomeIcon icon={faAngleRight} />
    </div>
  );

  return (
    <div className="crumbs__Container">
      <div className="crumbs__Text">{rootName}</div>
      {divider}
      {crumbs.map((crumb, i) => {
        const isLast = i === crumbs.length - 1;
        return (
          <React.Fragment key={crumb.orgPartId}>
            <div
              className={`crumbs__Text crumbs${isLast ? '__Final' : ''}__Item`}
              onClick={() => onClick(crumb)}
              onKeyDown={e => onKeyDown(e, crumb)}
              tabIndex={isLast ? '-1' : '0'}>
              {crumb.orgName}
            </div>
            {!isLast && divider}
          </React.Fragment>
        );
      })}
    </div>
  );
};

OrgCrumbs.defaultProps = {
  crumbs: [],
  rootName: '',
};

OrgCrumbs.propTypes = {
  crumbs: propTypes.arrayOf(
    propTypes.shape({
      adminId: propTypes.number,
      allowChildOrgsFlag: propTypes.bool,
      depth: propTypes.number,
      orgCode: propTypes.string,
      orgId: propTypes.number,
      orgName: propTypes.string,
      orgPartId: propTypes.number,
    })
  ),
  rootName: propTypes.string,
};

export default OrgCrumbs;

import { combineReducers } from 'redux';

import loginReducer from '../features/login/duck';
import createAccountReducer from '../features/register/duck';
import userInfoReducer from '../core/duck';
import notificationReducer from '../features/notifications/duck';
import rosterReducer from '../features/students/duck';
import navigationReducer from '../components/sidebar-nav/duck';
import contractReducer from '../features/contract/duck';
import orgManagementReducer from '../features/administration/duck';
import landingReducer from '../features/landing/duck';
import testManagementReducer from '../features/test-management/duck';

export default combineReducers({
  navigationReducer,
  loginReducer,
  createAccountReducer,
  userInfoReducer,
  rosterReducer,
  contractReducer,
  orgManagementReducer,
  notificationReducer,
  landingReducer,
  testManagementReducer,
});

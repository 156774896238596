import * as types from './types';
import { states } from '../../../services/helpers/stateStatus';

const initialState = {
  login: states.init(),
  logout: states.init(),
  accVerification: states.init(),
  forgotPassword: states.init(),
  resetPassword: states.init(),
  tnc: states.init(),
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.LOGIN:
      return {
        ...state,
        login: action.payload,
      };
    case types.LOGOUT:
      return {
        ...state,
        logout: action.payload,
      };
    case types.ACC_VERIFICATION:
      return {
        ...state,
        accVerification: { ...action.payload, error: action.payload.error },
      };
    case types.FORGOT_PASSWORD:
      return {
        ...state,
        forgotPassword: action.payload,
      };
    case types.RESET_PASSWORD:
      return {
        ...state,
        resetPassword: action.payload,
      };
    case types.GET_TNC:
      return {
        ...state,
        tnc: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;

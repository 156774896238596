import React from 'react';
import './Table.scss';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { DARK_GREY, BACKGROUND_OFF_WHITE, BORDER_GRAY } from '../../styles/base/_settings.scss';
import * as text from '../../core/i18n/text';
import { ReactComponent as SuccessIcon } from '../../assets/svgs/checkmarkGreen.svg';

// const getColumnWidth = (rows, accessor, headerText) => {
//   const maxWidth = 400;
//   const magicSpacing = 10;
//   const cellLength = Math.max(
//     ...rows.map(row => (`${row[accessor]}` || '').length),
//     headerText.length
//   );
//   return Math.min(maxWidth, cellLength * magicSpacing);
// };

const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: BACKGROUND_OFF_WHITE,
    color: DARK_GREY,
    fontSize: '14px',
    boxShadow: `0 0 0.5rem ${BORDER_GRAY}`,
    padding: '1.3rem',
    border: `0.1rem solid ${BORDER_GRAY}`,
    borderRadius: '0.8rem',
  },
  arrow: {
    color: BACKGROUND_OFF_WHITE,
    '&:before': {
      border: `0.1rem solid ${BORDER_GRAY}`,
    },
  },
}))(Tooltip);

const RenderCellContent = ({ cell }) => {
  const [isOverflowed, setIsOverflow] = React.useState(false);
  const [isTooltipVisible, setIsTooltipVisible] = React.useState(false);
  const textElementRef = React.useRef(null);
  const enter = () => setIsTooltipVisible(true);
  const leave = () => setIsTooltipVisible(false);

  React.useEffect(() => {
    if (textElementRef.current?.classList.contains('name-content')) {
      setIsOverflow(
        textElementRef.current.firstChild.scrollWidth >
          textElementRef.current.firstChild.clientWidth
      );
    } else if (textElementRef.current) {
      setIsOverflow(textElementRef.current.scrollWidth > textElementRef.current.clientWidth);
    }
    textElementRef.current && textElementRef.current.addEventListener('mouseenter', enter);
    textElementRef.current && textElementRef.current.addEventListener('mouseleave', leave);
  }, []);

  switch (cell.column.type) {
    case 'status':
      if (cell.render('Cell').props.row.original.status === 'active') {
        return (
          <LightTooltip title={text.features.orgManagement.users.active} arrow>
            <span className="usersTable__text usersTable__status__active">
              <SuccessIcon />
              {text.common.labels.active}
            </span>
          </LightTooltip>
        );
      } else {
        return (
          <LightTooltip title={text.features.orgManagement.users.invited} arrow>
            <span className="usersTable__text usersTable__status__invited">
              {text.common.labels.invited}
            </span>
          </LightTooltip>
        );
      }
    case 'button':
      return cell.render('Cell');
    case 'checkbox':
      return cell.render('Cell');
    case 'actionCell':
      return (
        <LightTooltip
          title={cell.value}
          open={isOverflowed && isTooltipVisible}
          disableHoverListener={!isOverflowed}
          onClick={() => setIsTooltipVisible(false)}
          arrow>
          <div
            className={`cell-content name-content ${isOverflowed ? 'overflow-container' : ''}`}
            ref={textElementRef}>
            {cell.render('Cell')}
          </div>
        </LightTooltip>
      );
    case 'nameActionCell':
      return (
        <LightTooltip
          title={`${cell.row.original.lastName},
          ${cell.row.original.firstName}
          ${cell.row.original.middleName ? ', ' + cell.row.original.middleName : ''}`}
          arrow
          open={isOverflowed && isTooltipVisible}
          disableHoverListener={!isOverflowed}
          onClick={() => setIsTooltipVisible(false)}>
          <div
            className={`cell-content name-content ${isOverflowed ? 'overflow-container' : ''}`}
            ref={textElementRef}>
            {cell.render('Cell')}
          </div>
        </LightTooltip>
      );

    default:
      return (
        <LightTooltip title={cell.render('Cell')} disableHoverListener={!isOverflowed} arrow>
          <span className="cell-text-content" ref={textElementRef}>
            {cell.render('Cell')}
          </span>
        </LightTooltip>
      );
  }
};

export default RenderCellContent;

import React from 'react';
import NoInternetModal from '../modal/NoInternetModal';
import { Offline } from 'react-detect-offline';
import Notification from '../alerts/Notification';
import Config from '../../core/config';

let closeNotificationTimer;

class NoInternet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayNotification: false,
    };
    this.handleNotificationClose = this.handleNotificationClose.bind(this);
  }

  setNotificationTimer() {
    closeNotificationTimer = setTimeout(this.handleNotificationClose, 5000);
  }

  handleNotificationClose() {
    this.setState({
      displayNotification: false,
    });
    clearTimeout(closeNotificationTimer);
  }

  connectionChange = e => {
    if (e) {
      this.setState({ displayNotification: true });
      this.setNotificationTimer();
    }
  };

  render() {
    return (
      <>
        <Offline polling={{ url: Config.checkUrl }} onChange={this.connectionChange}>
          <NoInternetModal />
        </Offline>
        {this.state.displayNotification && (
          <Notification
            text="Internet connection restored"
            handleClose={this.handleNotificationClose}
            type="Success"
          />
        )}
      </>
    );
  }
}

export default NoInternet;

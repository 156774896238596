import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions as contractActions } from '../duck';
import * as text from '../../../core/i18n/text';
import EmptyContractOrg from './EmptyContractOrg';
import { Notification, OrganizationTab } from '../../../components/index';
import { getPermission } from '../../../core/duck/selectors';
import './ContractOrganizations.scss';
import OrganizationDataModal from './OrganizationDataModal';
import { ReactComponent as AddIcon } from '../../../assets/svgs/addIcon.svg';
import _ from 'lodash/fp';
import LeftButtons from '../../../components/data-panel/profileButtons/ProfileButtons';
import { countArray } from '../../../core/constants';

const initialSearch = {
  filter: '',
};

const inputList = [
  {
    placeholder: 'Org name or code',
    maxLength: '30',
    type: 'text',
    name: 'filter',
  },
];

let closeNotificationTimer;

const initialColumns = [
  {
    Header: 'Local org code',
    name: 'Local org code',
    type: 'string',
    accessor: 'code',
    fields: ['code'],
    width: 132,
  },
  {
    Header: 'ACT org code',
    name: 'ACT org code',
    type: 'string',
    accessor: 'mdmCode',
    fields: ['mdmCode'],
    width: 120,
  },
  {
    Header: 'Type',
    name: 'Type',
    accessor: 'orgTypeName',
    fields: ['orgTypeName'],
    width: 123,
  },
  {
    Header: 'Address',
    name: 'Address',
    accessor: 'address1',
    fields: ['address1'],
    width: 368,
  },
  {
    Header: 'City',
    name: 'City',
    accessor: 'city',
    fields: ['city'],
    width: 205,
  },
  {
    Header: 'Parent org',
    name: 'Parent org',
    accessor: 'parentOrgName',
    fields: ['parentOrgName'],
    width: 406,
  },
  {
    Header: 'Parent local code',
    name: 'Parent local code',
    type: 'string',
    accessor: 'parentOrgCode',
    fields: ['parentOrgCode'],
    width: 150,
  },
];

class ContractOrganizations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orgModalOpen: false,
      displayNotification: false,
      notificationText: '',
      currentOrg: null,
      nonStikyColumns: initialColumns,
    };
  }

  componentDidMount() {
    if (_.get('orgHierarchy.data')(this.props) && this.props.orgHierarchy.data.length) {
      this.props.actions.getParentOrgs(this.props.orgHierarchy.data[0].orgId);
      this.props.actions.getOrgEntityDef(this.props.orgHierarchy.data[0].adminId);
      !this.props.organizations?.data &&
        this.props.actions.getData(this.props.orgHierarchy.data[0]);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      _.get('orgHierarchy.data')(this.props) &&
      this.props.orgHierarchy.data.length &&
      !_.isEqual(prevProps.orgHierarchy)(this.props.orgHierarchy)
    ) {
      this.props.actions.getParentOrgs(this.props.orgHierarchy.data[0].orgId);
      this.props.actions.getOrgEntityDef(this.props.orgHierarchy.data[0].adminId);
      this.props.actions.getData({
        ...this.props.orgHierarchy.data[0],
        ...{
          limit: countArray[0],
        },
      });
    }
  }

  getData = params => {
    this.setState({ getDataParams: params });
    this.props.actions.getData(params);
  };

  showNotification = (notificationText, success = true) => {
    this.setState(prevState => ({
      displayNotification: true,
      notificationText: notificationText,
      notificationType: success ? 'Success' : 'Error',
    }));
    this.setNotificationTimer();
  };

  handleNotificationClose = () => {
    this.setState({
      displayNotification: false,
    });
    clearTimeout(closeNotificationTimer);
  };

  setNotificationTimer() {
    closeNotificationTimer = setTimeout(this.handleNotificationClose, 5000);
  }

  handleOpenOrgModal = org => {
    this.setState({
      orgModalOpen: true,
      currentOrg: org,
    });
  };

  handleCloseOrgModal = () => {
    this.getData(this.state.getDataParams);
    this.setState({
      orgModalOpen: false,
      currentOrg: null,
    });
  };
  setNonStikyColumns = newTableColumns => {
    this.setState({ nonStikyColumns: newTableColumns });
  };

  setTableColumns = () => {
    return [
      {
        id: 'leftColumns',
        sticky: 'left',
        columns: [
          {
            Header: 'Org name',
            accessor: 'name',
            length: this.state.nonStikyColumns.length + 1,
            type: 'actionCell',
            Cell: ({ cell }) => {
              return (
                <>
                  <div className="bold-txt">{cell.value}</div>
                  <LeftButtons
                    hasUsersManagePermissions={this.props.hasContractPermission}
                    item={cell.row.original}
                    openEditModal={() =>
                      this.props.hasContractPermission
                        ? this.handleOpenOrgModal(cell.row.original)
                        : {}
                    }
                  />
                </>
              );
            },
            width: 435,
          },
        ],
      },
      {
        id: 'rightColumns',
        columns: this.state.nonStikyColumns,
      },
    ];
  };

  render() {
    return (
      <>
        <OrganizationTab
          currentOrg={this.state.currentOrg}
          {...this.props}
          actions={{ ...this.props.actions, getData: this.getData }}
          inputList={inputList}
          initialSearch={initialSearch}
          organizations={this.props.organizations}
          initialColumns={initialColumns}
          nonStikyColumns={this.state.nonStikyColumns}
          setTableColumns={this.setTableColumns}
          setColumns={this.setNonStikyColumns}
          dataToReload={this.props.updatedOrg}
          usingOrgPartId={false}
          currentOrgPartId={this.state.currentOrg?.orgPartId}
          addNewItemLabel={text.features.contract.organization.addNewOrganization}
          addNewItemIcon={AddIcon}
          handleAddNewItem={() => this.handleOpenOrgModal()}
          emptyStateComponent={
            !this.props.loadingData && (
              <EmptyContractOrg handleAddOrg={() => this.handleOpenOrgModal()} {...this.props} />
            )
          }
          emptyStateMessage={text.features.orgManagement.emptyMessage}
          useRootOrgPartId={true}
          title="Contract Organizations - ACT-Now"
          stickyColumnsLength={0}
        />

        <OrganizationDataModal
          {...this.props}
          showModal={this.state.orgModalOpen}
          handleCloseModal={this.handleCloseOrgModal}
          currentOrg={this.state.currentOrg}
          showNotification={this.showNotification}
        />

        {this.state.displayNotification && (
          <Notification
            text={this.state.notificationText}
            handleClose={this.handleNotificationClose}
            type={this.state.notificationType}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  const { contractReducer, userInfoReducer } = state;
  return {
    orgHierarchy: userInfoReducer.orgHierarchy,
    organizations: contractReducer.organizations,
    hasContractPermission: getPermission(state, 'now_contract_manage'),
    parentOrgs: contractReducer.parentOrgs.data || [],
    updatedOrg: contractReducer.updatedOrg,
    loadingData: contractReducer.loadingOrg,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        getData: contractActions.getOrganizations,
        getParentOrgs: contractActions.getParentOrgs,
        getOrgEntityDef: contractActions.getOrgEntityDef,
      },
      dispatch
    ),
  };
};

export { ContractOrganizations };

export default connect(mapStateToProps, mapDispatchToProps)(ContractOrganizations);

import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Menu, MenuItem, Divider, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import SplitButton from '../../split-button/SplitButton';

import TextContext from '../../../core/providers/TextProvider';

import { ReactComponent as DropdownArrow } from '../../../assets/svgs/blueDropdownArrow.svg';

import './BulkActions.scss';
import {
  BACKGROUND_OFF_WHITE,
  BLUE,
  BORDER_GRAY,
  DARK_GREY,
} from '../../../styles/base/_settings.scss';

const CustomSubmenuItem = withStyles(() => ({
  root: {
    color: BLUE,
    fontSize: '14px',
    fontWeight: '700',
  },
}))(MenuItem);

const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: BACKGROUND_OFF_WHITE,
    color: DARK_GREY,
    fontSize: '14px',
    boxShadow: `0 0 0.5rem ${BORDER_GRAY}`,
    padding: '1.3rem',
    border: `0.1rem solid ${BORDER_GRAY}`,
    borderRadius: '0.8rem',
  },
  arrow: {
    color: BACKGROUND_OFF_WHITE,
    '&:before': {
      border: `0.1rem solid ${BORDER_GRAY}`,
    },
  },
}))(Tooltip);

const BulkActions = ({
  deselectActionHandle,
  options = [],
  selectActionHandle,
  selectionsNumber = 0,
}) => {
  /**
   * useContext()
   */
  const Text = useContext(TextContext);

  /**
   * useState()
   */
  const [anchorEl, setAnchorEl] = useState(null);

  /**
   * useMemo()
   */
  const hasSelections = useMemo(() => Boolean(selectionsNumber), [selectionsNumber]);

  /**
   *  useCallback()
   */
  const handleClick = useCallback(e => setAnchorEl(e.currentTarget), []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    hasSelections ? deselectActionHandle() : selectActionHandle();
  }, [deselectActionHandle, hasSelections, selectActionHandle]);

  const onKeyDown = useCallback(
    e => {
      if (e.keyCode !== 13) return;
      handleClick(e);
    },
    [handleClick]
  );

  return (
    <div className="bulk-actions-container">
      <div className="selected-number-container">
        {hasSelections ? (
          <span className={'selected-number'}>
            {selectionsNumber > 999 ? '999+' : selectionsNumber}
          </span>
        ) : (
          <span className="selected-number zero-number">{selectionsNumber}</span>
        )}
        <span>{Text.features.roster.assignStudents.selected}</span>
        <Divider orientation="vertical" className="divider" />
        <DropdownArrow
          anchorel={anchorEl}
          className="action-menu"
          onClick={handleClick}
          onKeyDown={onKeyDown}
          tabIndex="0"
        />
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          disableScrollLock={true}
          getContentAnchorEl={null}
          id="bulk-actions-menu"
          keepMounted
          onClose={() => setAnchorEl(null)}
          open={Boolean(anchorEl)}
          MenuListProps={{ disablePadding: true }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}>
          <CustomSubmenuItem onClick={handleClose}>
            {hasSelections ? Text.common.labels.deselectAll : Text.common.labels.selectAll}
          </CustomSubmenuItem>
        </Menu>
      </div>
      {options.length === 1 && (
        <LightTooltip
          arrow
          title={hasSelections ? '' : Text.features.roster.assignStudents.disabledTooltip}>
          <div className="bulk-actions-button-container">
            <button
              className={`bulk-actions-button ${hasSelections ? 'active' : ''}`}
              disabled={!hasSelections}
              onClick={options[0].action}>
              {options[0].text}
            </button>
          </div>
        </LightTooltip>
      )}
      {options.length > 1 && (
        <SplitButton color="primary" disabled={!hasSelections} options={options} />
      )}
    </div>
  );
};

export default BulkActions;

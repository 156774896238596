import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash/fp';
import { CSVLink } from 'react-csv';

import {
  FileTile,
  InfiniteScroll,
  Loader,
  NoFiles,
  Notification,
  SimpleBox,
  UploadFileModal,
} from '../index';

import { useStore } from '../../store/hooks';

import TextContext from '../../core/providers/TextProvider';
import { getProgramConfiguration } from '../../core/utils';
import { CommonServices } from '../../services';

import { ReactComponent as fileIcon } from '../../assets/svgs/file.svg';
import { ReactComponent as uploadIcon } from '../../assets/svgs/uploadIcon.svg';

import './FilesBaseComponent.scss';

const FilesBaseComponent = ({ featureName, getUploadedFiles, useRootOrgId }) => {
  /**
   * useContext()
   */
  const Text = useContext(TextContext);

  /**
   * useRef()
   */
  const csvLinkRef = useRef();
  const fileRef = useRef();

  /**
   * useStore()
   */
  const [orgHierarchyResponse] = useStore('orgHierarchy');
  const [programsResponse] = useStore('programs');

  /**
   * useState()
   */
  const [currentPage, setCurrentPage] = useState(0);
  const [displayCSVLink, setDisplayCSVLink] = useState(false);
  const [downloadUrls, setDownloadUrls] = useState({});
  const [files, setFiles] = useState([]);
  const [fileTemplate, setFileTemplate] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState({ show: false });
  const [notification, setNotification] = useState({ show: false });

  /**
   * useMemo()
   */
  const currentOrg = useMemo(() => {
    if (!orgHierarchyResponse?.data) return;
    return useRootOrgId
      ? orgHierarchyResponse.data[0]
      : orgHierarchyResponse.data[orgHierarchyResponse.data.length - 1];
  }, [orgHierarchyResponse, useRootOrgId]);

  const fileConfig = useMemo(
    () => _.merge(Text.features.fileUpload)(_.get('fileUpload')(Text.features[featureName])),
    [featureName, Text.features]
  );

  const program = useMemo(() => {
    if (!programsResponse?.data?.length || !currentOrg?.programId) return;
    return programsResponse.data.find(program => program.programId === currentOrg.programId);
  }, [currentOrg, programsResponse]);

  const jobTypeCode = useMemo(() => {
    if (!featureName || !program) return;
    return getProgramConfiguration(`${featureName}.jobTypeCode`)(program);
  }, [featureName, program]);

  /**
   * useCallback()
   */
  const closeModal = useCallback(() => setModal({ show: false }), []);

  const closeNotification = useCallback(() => setNotification({ show: false }), []);

  const downloadFileTemplate = useCallback(() => {
    setDisplayCSVLink(true);
    CommonServices.getFileTemplate(jobTypeCode).then(response => {
      setFileTemplate(response.data);
    });
  }, [jobTypeCode]);

  const fetchUploadedFiles = useCallback(
    limit => page => {
      if (!currentOrg?.adminId || !currentOrg?.orgId || !jobTypeCode) return;

      setLoading(true);
      setCurrentPage(page);
      return getUploadedFiles({
        adminId: currentOrg.adminId,
        jobTypeCode,
        limit,
        orgId: currentOrg.orgId,
        page,
      })
        .then(response => {
          if (response.data?.length) {
            setFiles(prev => [...prev, ...response.data]);
          } else {
            setHasMore(false);
          }
        })
        .finally(() => setLoading(false));
    },
    [currentOrg, getUploadedFiles, jobTypeCode]
  );

  const handleFileDownload = useCallback(
    urlType => e => {
      if (!currentOrg?.orgId) return;
      const id = e.target.closest('.FileTile__Container').id;
      if (id !== fileRef.current) {
        fileRef.current = id;
        CommonServices.getDownloadUrls({ jobId: id, orgId: currentOrg.orgId }).then(response => {
          setDownloadUrls(response.data);
          window.location.assign(response.data[urlType]);
        });
      } else {
        window.location.assign(downloadUrls[urlType]);
      }
    },
    [currentOrg, downloadUrls]
  );

  const handleLoadData = useCallback(page => fetchUploadedFiles(4)(page + 1), [fetchUploadedFiles]);

  const openModal = useCallback(() => setModal({ show: true }), []);

  const refresh = useCallback(() => {
    setFiles([]);
    fetchUploadedFiles(4 * (currentPage + 1))(0);
  }, [currentPage, fetchUploadedFiles]);

  const submitFile = useCallback(
    file => {
      if (!currentOrg?.adminId || !currentOrg?.orgId) return;

      CommonServices.uploadFile(currentOrg.orgId)({
        adminId: currentOrg.adminId,
        file,
        fileName: file.name,
        jobTypeCode,
      }).then(response => {
        if (_.get('data.message')(response)) {
          setNotification({ show: true, text: Text.messages.success.fileUpload, type: 'Success' });
          setFiles([]);
          fetchUploadedFiles(4)(0);
        } else if (_.get('error')(response)) {
          setNotification({ show: true, text: Text.messages.error.generic, type: 'Error' });
        } else if (_.get('data.status')(response) === 'stopped') {
          setNotification({ show: true, text: Text.messages.error.generic, type: 'Error' });
          setFiles(prev => [{ ...response.data }, ...prev]);
        }
      });
    },
    [currentOrg, fetchUploadedFiles, jobTypeCode, Text.messages]
  );

  /**
   * useEffect()
   */
  useEffect(() => {
    if (!displayCSVLink || !fileTemplate) return;
    csvLinkRef.current.link.click();
    setDisplayCSVLink(false);
    setFileTemplate(null);
  }, [displayCSVLink, fileTemplate]);

  useEffect(() => {
    if (!notification.show) return;
    setTimeout(closeNotification, 5000);
  }, [closeNotification, notification]);

  useEffect(() => {
    setFiles([]);
    setHasMore(true);
    fetchUploadedFiles(4)(0);
  }, [fetchUploadedFiles]);

  /**
   * render
   */
  return (
    <div className="FileRepPage__Container">
      <div className="FileRep__Container">
        <div className="FileRep__TilesContainer">
          {!files.length ? (
            loading ? (
              <Loader />
            ) : (
              <NoFiles fileUpload={fileConfig} />
            )
          ) : (
            <InfiniteScroll onLoadData={handleLoadData} hasMore={hasMore}>
              {files.map(file => {
                return (
                  <FileTile
                    buttonsAction={handleFileDownload}
                    errors={file.errorRecordCount}
                    fileName={file.sourceDisplayFileName}
                    id={file.id}
                    key={file.id}
                    percentage={
                      file.status === 'pending' || file.status === 'processing'
                        ? file.recordCount && file.processedRecordCount
                          ? String(Math.floor((file.processedRecordCount / file.recordCount) * 100))
                          : '0'
                        : ''
                    }
                    records={file.recordCount}
                    refresh={refresh}
                    status={file.status}
                    statusMessage={file.statusMessage}
                    uploadDate={file.requestDt}
                    uploadUser={`${file.requestPersonnel.lastName}, ${file.requestPersonnel.firstName} `}
                  />
                );
              })}
            </InfiniteScroll>
          )}
        </div>
        <SimpleBox
          buttons={[
            {
              action: openModal,
              icon: uploadIcon,
              text: fileConfig.uploadFileBox.butttonText,
            },
            {
              action: downloadFileTemplate,
              icon: fileIcon,
              text: fileConfig.uploadFileBox.secondaryButtonText,
              type: 'open',
            },
          ]}
          description={fileConfig.uploadFileBox.description}
          hyperlink={fileConfig.uploadFileBox.hyperlink}
          title={fileConfig.uploadFileBox.title}
        />
        {displayCSVLink && fileTemplate && (
          <CSVLink
            data={fileTemplate}
            filename={fileConfig.templateName}
            ref={csvLinkRef}
            target="_blank"
          />
        )}
        {notification.show && (
          <Notification
            handleClose={closeNotification}
            text={notification.text}
            type={notification.type}
          />
        )}
        {modal.show && (
          <UploadFileModal
            closeModal={closeModal}
            featureName={featureName}
            showModal={true}
            submitFile={submitFile}
          />
        )}
      </div>
    </div>
  );
};

export default FilesBaseComponent;

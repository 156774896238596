import Axios from 'axios';
import http from '../core/services/Http';
import Config from '../core/config';

const nowOrgUrl = '/now/org';
const nowQuerylistUrl = '/tcm/query';

export default class ContractServices {
  static async uploadFile(data, orgId) {
    const initUpload = await http.post(`${Config.apiUrl}${nowOrgUrl}/${orgId}/initializeJob`, data);
    let uploadFile;
    const { jobId, url } = initUpload.data;
    if (initUpload.status === 200 && jobId && url) {
      try {
        uploadFile = await Axios.put(`${url}`, data.file);
        if (uploadFile.status === 200) {
          return http.post(`${Config.apiUrl}${nowOrgUrl}/${orgId}/executeJob/${jobId}`, [
            data.fileName,
          ]);
        }
      } catch {
        return http.put(`${Config.apiUrl}${nowOrgUrl}/${orgId}/job/${jobId}/stopJob`);
      }
    }
  }

  static getUploadedFiles({ limit, orgId, page }) {
    return http.get(`${Config.apiUrl}${nowOrgUrl}/${orgId}/jobs/org?limit=${limit}&page=${page}`);
  }

  static getDownloadUrls(orgPartId, jobId) {
    return http.get(`${Config.apiUrl}${nowOrgUrl}/${orgPartId}/getDownloadUrls/${jobId}`);
  }

  static getOrganizations(params) {
    let url = `${Config.apiUrl}${nowQuerylistUrl}/orgList?orgId=${params.orgId}`;
    if (params.limit) {
      url += `&limit=${params.limit}`;
    }
    if (params.offset) {
      url += `&offset=${params.offset}`;
    }
    if (params.filter) {
      url += `&filter=${params.filter}`;
    }
    return http.get(url);
  }

  static getParentOrgs(orgId) {
    return http.get(`${Config.apiUrl}${nowQuerylistUrl}/parentOrgs?orgId=${orgId}`);
  }

  static getChildOrgTypes(parentOrgType) {
    return http.get(
      `${Config.apiUrl}${nowQuerylistUrl}/possibleChildOrgTypes?parentOrgType=${parentOrgType}`
    );
  }

  static updateOrg(data) {
    return http.put(`${Config.apiUrl}/now/org`, data);
  }

  static createOrg(data) {
    return http.post(`${Config.apiUrl}/now/org`, data);
  }

  static getOrgEntityDef(adminId) {
    return http.get(`${Config.apiUrl}/now/entitydef/org/admin/${adminId}`);
  }
}

import React from 'react';
import Header from '../../components/header/Header';
import { clickables, features } from '../../core/i18n/text';
import Auth from '../../core/services/AuthToken';
import './NotFoundPage.scss';
import SidebarNav from '../../components/sidebar-nav/SidebarNav';
import { Helmet } from 'react-helmet';

const Text = { clickables, features };

const NotFoundPage = () => {
  let orgPartId = '';
  if (window.sessionStorage.getItem('mainSelector')) {
    orgPartId = JSON.parse(window.sessionStorage.getItem('mainSelector')).orgPartId;
  }
  return (
    <div className="notFound__Page">
      <Helmet>
        <title>Page Not Found - ACT-Now</title>
      </Helmet>
      <Header logoOnly={!Auth.authToken} noAutoRedirect={true} />
      <div className="_outter_">
        {Auth.authToken && <SidebarNav />}
        <div className="notFound__Separator">
          <div className="notFound__Countainer">
            <div className="notFound__Title">{Text.features.notFound.title}</div>
            <p className="notFound__Description">{Text.features.notFound.description}</p>
            <a href={`/landingPage/orgPartId/${orgPartId}`}>
              <button type="button" className="bigButton notFound__Button">
                {Text.clickables.buttons.goBackHome}
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

NotFoundPage.displayName = '404NotFound';

export default NotFoundPage;

import React from 'react';
import './FormHeader.scss';

const FormHeader = ({ title, description }) => (
  <div className="form__Container">
    <div className="form__Title">{title}</div>
    {description &&
      description.map((desc, index) => {
        return (
          <p className={`form__Description ${index === 1 ? 'description_label' : ''} `} key={index}>
            {desc}
          </p>
        );
      })}
  </div>
);

FormHeader.displayName = 'FormHeader';
FormHeader.defaultProps = {
  description: '',
};

export default FormHeader;

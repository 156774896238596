import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Auth from '../../../core/services/AuthToken';
import { actions as loginActions } from '../duck';

class AutoLogout extends React.Component {
  static defaultProps = {
    events: ['mousemove', 'mousedown', 'keydown', 'touchstart', 'scroll'],
    timeout: 60 * 60 * 1000,
  };

  constructor(props) {
    super(props);
    this.countdown = this.countdown.bind(this);
  }

  componentDidMount() {
    this.attachEvents();
    this.countdown();
  }

  componentWillReceiveProps() {
    if (this.timeout < 1000 && Auth.checkTokens() && this.props.isLogoutSuccess) {
      window.sessionStorage.clear();
      Auth.remove();
      this.attachEvents();
      this.countdown();
    }
  }

  componentWillUnmount() {
    this.removeEvents();
    clearTimeout(this.timeout);
  }

  countdown() {
    this.timeout = setTimeout(() => {
      this.props.actions.logout();
      clearTimeout(this.timeout);
    }, this.props.timeout);
  }

  attachEvents() {
    this.props.events.forEach(e => {
      window.addEventListener(e, this.handleEvent.bind(this), true);
    });
  }

  removeEvents() {
    this.props.events.forEach(e => {
      window.removeEventListener(e, this.handleEvent.bind(this), true);
    });
  }

  handleEvent() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    if (Auth.checkTokens()) {
      this.countdown();
    }
  }

  render() {
    return null;
  }
}

const mapStateToProps = state => {
  return {
    isLogoutSuccess: state.loginReducer.logout.data,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ ...loginActions }, dispatch),
  };
};

AutoLogout.displayName = 'AutoLogout';

export default connect(mapStateToProps, mapDispatchToProps)(AutoLogout);

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Validator from 'email-validator';

import { UserServices } from '../../../services';

import { ReactComponent as BackArrow } from '../../../assets/svgs/blueBackArrow.svg';

import Header from '../../../components/forms/FormHeader';
import Email from '../../../components/forms/fields/Input';
import Alert from '../../../components/alerts/Alert';
import { actions as loginActions } from '../duck';
import _ from 'lodash/fp';
import { messages, common, features } from '../../../core/i18n/text';
import ConfirmationMessage from '../../../components/forms/ConfirmationMessage';
import './ForgotPassword.scss';

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      errors: {
        email: '',
        notRegistered: false,
      },
      alert: {
        show: false,
        message: '',
      },
    };
    this.props.actions.clearForgotPasswordFlow();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(this.props)(prevProps)) {
      if (this.props.forgotPasswordSentError) {
        this.setState({
          alert: {
            show: true,
            message: this.props.forgotPasswordSentError.response.data.Error,
          },
        });
      }
    }
  }

  onEmailChange = event => {
    let email = event.target.value.trim();
    this.setState({
      email,
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    if (this.validateInput()) {
      UserServices.verifyUserExists({ email: this.state.email })
        .then(success => {
          if (success.data.result) {
            this.props.actions.forgotPassword(this.state.email);
          } else {
            this.setState(prevState => ({
              ...prevState,
              errors: {
                ...prevState.errors,
                notRegistered: true,
              },
            }));
          }
        })
        .catch(err => {
          // TODO: handle this better
          console.log(err);
        });
    }
  };

  validateInput = e => {
    const isEmailEmpty = this.state.email.trim() === '' ? true : false;
    const isEmailValid = Validator.validate(this.state.email.trim());
    const emailError = isEmailEmpty
      ? messages.error.emailRequired
      : isEmailValid
      ? ''
      : messages.error.emailInvalid;
    this.setState({
      errors: {
        email: emailError,
      },
    });
    return isEmailEmpty || !isEmailValid ? false : true;
  };

  onKeyDown = e => {
    if (e.keyCode === 13) {
      this.props.back();
    }
  };

  renderForm() {
    const headerTitle = (
      <div className="back-container">
        <BackArrow tabIndex="0" onClick={this.props.back} onKeyDown={this.onKeyDown} />
        {features.login.fpTitle}
      </div>
    );
    return (
      <div className="forgotPassword__Container">
        <Header title={headerTitle} description={[features.login.fpDescription]} />
        {this.state.errors.notRegistered && (
          <Alert type="error" message={messages.error.emailNotRegistered} />
        )}
        {this.state.alert.show && <Alert type="error" message={this.state.alert.message} />}
        <form onSubmit={this.handleSubmit}>
          <Email
            name={'email'}
            label={common.fields.email}
            value={this.state.email}
            error={this.state.errors.email}
            onChange={this.onEmailChange}
          />
          <button
            type="submit"
            className="bigButton"
            disabled={this.props.isForgotPasswordSentPending}
            value="Submit">
            Reset password
          </button>
        </form>
      </div>
    );
  }

  renderMessage() {
    const msg = [
      <div>
        <div className="description_label">{features.register.confirmation.description.email}</div>
        <div className="register_email">
          <strong>{this.state.email}</strong>
        </div>
      </div>,
      features.forgotPassword.description,
    ];
    return <ConfirmationMessage title={features.forgotPassword.title} message={msg} />;
  }

  render() {
    const { isForgotPasswordSentSuccess, forgotPasswordSentError } = this.props;
    return (
      <div>
        {isForgotPasswordSentSuccess && !forgotPasswordSentError
          ? this.renderMessage()
          : this.renderForm()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { loginReducer } = state;
  return {
    isForgotPasswordSentSuccess: loginReducer.forgotPassword.data,
    isForgotPasswordSentPending: loginReducer.forgotPassword.loading,
    forgotPasswordSentError: loginReducer.forgotPassword.error,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ ...loginActions }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);

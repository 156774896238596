import React, { useState, useEffect } from 'react';
import AlertModal from '../../../components/modal/AlertModal';
import Alert from '../../../components/alerts/Alert';

import * as Text from '../../../core/i18n/text';

import './TermsAndConditions.scss';
import Checkbox from '../../../components/inputs/Checkbox';
import Notification from '../../../components/alerts/Notification';
import { UserServices } from '../../../services';

const customStyles = {
  overlay: {
    overflow: 'auto',
    alignItems: 'unset',
  },
  content: {
    margin: '3% 0',
    maxHeight: 'none',
  },
};

const newline2br = text => text.replace(/(?:\r\n|\r|\n)/g, '<br>');

const mapTerms = terms => {
  const mapped = [];
  terms.forEach(t => {
    mapped.push({
      ...t,
      checked: false,
    });
  });
  return mapped;
};
const TermsAndConditions = props => {
  const [unacceptedTerms, setUnacceptedTerms] = useState([]);
  const [acceptedTerms, setAcceptedTerms] = useState([]);
  const [currentTerm, setCurrentTerm] = useState(null);
  const [logOutConfirm, setLogOutConfirm] = useState(false);
  const [termsModalOpen, setTermsModalOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [error, setError] = useState('');

  const acceptTerms = async () => {
    if (currentTerm.checked) {
      const result = await UserServices.acceptUserTerms(props.userInfo.id, currentTerm.id);

      if (result.data && result.data.agreementDateTime) {
        const [, ...restTerms] = unacceptedTerms;
        setAcceptedTerms(prevState => [...prevState, currentTerm]);
        setUnacceptedTerms(restTerms);
        if (!restTerms.length) {
          await props.actions.getTerms(props.userInfo.id);
          setShowMessage(true);
          setTimeout(() => {
            setShowMessage(false);
          }, 2000);
          setCurrentTerm(null);
          setAlertMessage(
            `${Text.features.landing.termsAndConditions.successMessage} ${props.userInfo.emailAddress}`
          );
          setTermsModalOpen(false);
          return;
        }
        setCurrentTerm(restTerms[0]);
        setError('');
        setShowMessage(true);
        setTimeout(() => {
          setShowMessage(false);
        }, 2000);
        setAlertMessage(
          `${Text.features.landing.termsAndConditions.successMessage} ${props.userInfo.emailAddress}`
        );
        return;
      }
      setError('error');
      setShowMessage(true);
      setAlertMessage(Text.features.landing.termsAndConditions.errorMessage);
      return;
    }
    setError(
      `${Text.features.landing.termsAndConditions.agreementError} ${currentTerm.name} ${Text.features.landing.termsAndConditions.continue}`
    );
  };

  const handleCheck = () => {
    setCurrentTerm(prevState => ({ ...prevState, checked: !prevState.checked }));
    setError('');
  };

  const logOutUserConfirm = e => {
    if (e) {
      e.preventDefault();
    }
    setLogOutConfirm(prevState => !prevState);
  };

  const logOutUser = async () => {
    await props.actions.logout();
    setLogOutConfirm(false);
  };

  const closeConfirmLogout = () => {
    setLogOutConfirm(false);
  };

  useEffect(() => {
    if (props.terms && props.terms.length > 0) {
      setUnacceptedTerms(mapTerms(props.terms));
      setTermsModalOpen(true);
      setCurrentTerm({
        ...props.terms[0],
        checked: false,
      });
    }
  }, [props.terms]);

  if (!currentTerm) {
    return null;
  }
  return (
    Boolean(props.terms) && (
      <div className="terms-conditions-container">
        <AlertModal
          show={termsModalOpen}
          onRequestClose={() => {}}
          className="modal-info terms-and-conditions-modal"
          shouldCloseOnOverlayClick={false}
          ariaHideApp={false}
          style={customStyles}
          contentLabel="Modal">
          <div className="terms-modal-content">
            <Alert type="info" message={Text.features.landing.termsAndConditions.infoMessage} />
            <div id="terms">
              <h5>{`${acceptedTerms.length + 1} of ${props.terms.length}`}</h5>
              <h4> {currentTerm.name} </h4>
              <div
                dangerouslySetInnerHTML={
                  currentTerm && currentTerm.text ? { __html: newline2br(currentTerm.text) } : ''
                }
              />
              <div className="section-bottom">
                <div className="checkbox_container">
                  <Checkbox checked={currentTerm.checked} handleChange={handleCheck} />
                  <span className="cmb__Label">{`${Text.features.landing.termsAndConditions.agreeLabel} ${currentTerm.name}`}</span>
                </div>
                {Boolean(error) && <Alert type="error" message={error} />}
                <button className="bigButton" type="button" onClick={acceptTerms}>
                  {Text.common.labels.continue}
                </button>
                <div className="logout-container">
                  <button className="linkButton" onClick={logOutUserConfirm}>
                    {Text.features.landing.termsAndConditions.logOut}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </AlertModal>
        <AlertModal
          className="ConfirmationModal__Container terms_and_conditions_logout"
          customStyles={{ overlay: { zIndex: '1000' } }}
          show={logOutConfirm}
          buttonAction={logOutUser}
          close={closeConfirmLogout}
          title=""
          bodyTitle="Are you sure you want to logout?"
          showButton={true}
          buttonText="Yes"
          secondaryButton={{
            text: 'No',
            action: closeConfirmLogout,
            redButton: false,
          }}
        />
        {showMessage && <Notification text={alertMessage} handleClose={() => {}} type="Success" />}
      </div>
    )
  );
};

export default TermsAndConditions;

import React from 'react';
import uploadIcon from '../../../assets/svgs/uploadIcon.svg';
import { ReactComponent as Plus } from '../../../assets/svgs/plus.svg';
import * as Text from '../../../core/i18n/text';

function EmptyState(props) {
  return (
    <div className="OrgManagementUsers__Container">
      <div className="OrgManagementUsers__Title">
        {Text.features.orgManagement.users.emptyState.title}
      </div>
      <div className="OrgManagementUsers__Description">
        {Text.features.orgManagement.users.emptyState.description}
      </div>
      {props.hasPermissions && (
        <span className="OrgManagementUsers__ButtonsGroup">
          <button
            className={'bigButton OrgManagementUsers__Button'}
            onClick={() =>
              props.history.push(
                `/administration/files/staffinvite/orgPartId/${props.match.params.orgPartId}`
              )
            }>
            <img src={uploadIcon} alt=""></img>
            {Text.features.orgManagement.users.emptyState.uploadButton}
          </button>
          <button
            className={'openButton OrgManagementUsers__Button'}
            onClick={props.handleOpenInviteModal}>
            <Plus />
            {Text.features.orgManagement.users.emptyState.inviteButton}
          </button>
        </span>
      )}
    </div>
  );
}

export default EmptyState;

import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './core/App';
import * as serviceWorker from './serviceWorker';
import 'typeface-montserrat';
import axios from 'axios';
import ConfigService from '../src/core/config';

const render = () => {
  ReactDOM.render(<App />, document.getElementById('root'));
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below.  Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

const getConfig = () => axios.get('/src/config.json');

axios.all([getConfig()]).then(response => {
  ConfigService.config = response[0].data;
  render();
});

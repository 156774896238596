import React, { useEffect, useState } from 'react';
import _ from 'lodash/fp';
import CustomReactTable from '../../../components/table/Table';
import Notification from '../../../components/alerts/Notification';
import Alert from '../../../components/alerts/Alert';
import moment from 'moment';
import OrderDetailsModal from './order-details-modal/OrderDetailsModal';
import { ReactComponent as TruckIcon } from '../../../assets/svgs/truckIcon.svg';
import { ReactComponent as MailBoxIcon } from '../../../assets/svgs/mailBoxIcon.svg';
import { ReactComponent as Checkmark } from '../../../assets/svgs/checkmarkGreen.svg';
import { ReactComponent as CloseRedIcon } from '../../../assets/svgs/closeRedIcon.svg';
import { ReactComponent as ListIcon } from '../../../assets/svgs/listIcon.svg';
import { TestManagementServices } from '../../../services';
import * as Text from '../../../core/i18n/text';
import './TestManagementTestEvents.scss';

const shipmentsStatus = [
  {
    label: Text.features.testManagement.testEvents.labels.submitted,
    icon: <MailBoxIcon />,
  },
  {
    label: Text.features.testManagement.testEvents.labels.inTransit,
    icon: <TruckIcon />,
  },
  {
    label: Text.features.testManagement.testEvents.labels.delivered,
    icon: <Checkmark />,
    delivered: true,
  },
  {
    label: Text.features.testManagement.testEvents.labels.approved,
    icon: <ListIcon />,
  },
  {
    label: Text.features.testManagement.testEvents.labels.rejected,
    icon: <CloseRedIcon />,
    rejected: true,
  },
];

const mappingStatusColumn = status => {
  switch (status.toLowerCase()) {
    case shipmentsStatus[0].label.toLowerCase():
      return (
        <div className="shipment-status">
          {shipmentsStatus[0].icon}
          <span>{shipmentsStatus[0].label}</span>
        </div>
      );
    case 'in_transit':
      return (
        <div className="shipment-status">
          {shipmentsStatus[1].icon}
          <span>{shipmentsStatus[1].label}</span>
        </div>
      );
    case shipmentsStatus[2].label.toLowerCase():
      return (
        <div className="shipment-status green">
          {shipmentsStatus[2].icon}
          <span>{shipmentsStatus[2].label}</span>
        </div>
      );
    case shipmentsStatus[3].label.toLowerCase():
      return (
        <div className="shipment-status">
          {shipmentsStatus[3].icon}
          <span>{shipmentsStatus[3].label}</span>
        </div>
      );
    case shipmentsStatus[4].label.toLowerCase():
      return (
        <div className="shipment-status red">
          {shipmentsStatus[4].icon}
          <span>{shipmentsStatus[4].label}</span>
        </div>
      );
    default:
      return (
        <div className="shipment-status">
          <span>{status}</span>
        </div>
      );
  }
};

const TestManagementShipmentsTracking = props => {
  const columns = [
    {
      Header: 'Order',
      accessor: 'orderNumber',
      width: 200,
      Cell: ({ cell }) => (
        <button className="content-link-button" onClick={e => showOrderDetailsHandle(cell.value)}>
          {cell.row.original.orderNumber}
        </button>
      ),
    },
    {
      Header: 'Type',
      accessor: 'type',
      width: 200,
      Cell: ({ cell }) => _.capitalize(cell.row.original.type),
    },
    {
      Header: 'Status',
      accessor: 'status',
      width: 200,
      Cell: ({ cell }) => mappingStatusColumn(cell.row.original.status),
    },
    {
      Header: 'Order created',
      accessor: 'dateOrdered',
      width: 200,
      Cell: ({ cell }) => moment(cell.row.original.dateOrdered).format('MM/DD/YYYY'),
    },
  ];

  const [ordersData, setOrdersData] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [errorNotification, setErrorNotification] = useState(false);
  const [orderId, setOrderId] = useState(0);
  const [orgName, setOrgName] = useState('');
  const [openOrderDetailsModal, setOpenOrderDetailsModal] = useState(false);
  const { orgPartId } = props.currentOrg;
  const currentOrgType = props.currentOrg?.type;

  const handleNotificationClose = () => {
    setErrorNotification(false);
  };
  const showOrderDetailsHandle = value => {
    if (value && ordersData) {
      const selectedArr = ordersData.map(o => o.orders.find(item => item.orderNumber === value));
      const indexSelected = selectedArr.findIndex(obj => obj);
      const selectedOrderData = selectedArr.find(selected => selected);
      setSelectedOrder(selectedOrderData);
      setOrgName(ordersData[indexSelected].orgName);
    }
    setOpenOrderDetailsModal(true);
    setOrderId(value);
  };

  const getsortedData = orders => _.orderBy([obj => new Date(obj.dateOrdered)])(['desc'])(orders);
  const getOrders = async () => {
    try {
      const response = await TestManagementServices.getOrders(orgPartId);
      if (response.data?.length) {
        setOrdersData(response.data);
      }
    } catch (e) {
      setErrorNotification(true);

      setTimeout(() => {
        setErrorNotification(false);
      }, 3000);
    }
  };

  const closeModalHandle = () => {
    setOpenOrderDetailsModal(false);
  };

  useEffect(() => {
    if (orgPartId) {
      getOrders();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgPartId]);

  return ordersData?.length ? (
    <div
      className={`shipments-tracking-tabel-container ${
        ordersData.length > 1 ? 'multiple-table' : ''
      }`}>
      {ordersData.map((item, index) => {
        if (currentOrgType === 'school' && !item.orders.length) {
          return (
            <Alert
              type="info"
              message={Text.features.testManagement.testEvents.messages.noShipments}
            />
          );
        }

        return (
          <CustomReactTable
            data={getsortedData(item.orders)}
            columns={columns}
            title={currentOrgType !== 'school' && item.orgName}
            key={index}
            emptyMessage={
              !getsortedData(item.orders).length &&
              Text.features.testManagement.testEvents.messages.noOrders
            }
          />
        );
      })}
      <OrderDetailsModal
        open={openOrderDetailsModal}
        orderId={orderId}
        status={!_.isEmpty(selectedOrder) && mappingStatusColumn(selectedOrder.status)}
        organizationName={!_.isEmpty(selectedOrder) && orgName}
        closeModalHandle={closeModalHandle}
        orgPartId={orgPartId}
      />
    </div>
  ) : (
    <>
      <Alert type="info" message={Text.features.testManagement.testEvents.messages.noShipments} />
      {errorNotification && (
        <Notification
          text={Text.messages.error.generic}
          handleClose={handleNotificationClose}
          type="Error"
        />
      )}
    </>
  );
};

export default TestManagementShipmentsTracking;

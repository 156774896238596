import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash/fp';
import propTypes from 'prop-types';

import DropDown from '../inputs/DropDown';
import FilterCheckboxGroup from './FilterCheckboxGroup';
import FilterSection from './FilterSection';
import Radio from '../inputs/Radio';

import { ReactComponent as FilterIcon } from '../../assets/svgs/filterIcon.svg';

import TextContext from '../../core/providers/TextProvider';

import './Filters.scss';

const getCheckboxResults = data => ({ name }) =>
  (data[name] || [])
    .filter(option => option.checked)
    .map(option => option.value)
    .join();

const getCheckedOptions = values => ({ callParameter, options = [], type }) =>
  options.map(option => ({
    ...option,
    checked: (values[callParameter] || '')
      .split(',')
      .includes(type === 'checkbox' ? option.value : option.id),
  }));

const getDropdownResults = data => ({ name, value = 'value' }) =>
  (data[name] || [])
    .filter(option => option.selected)
    .map(option => option[value])
    .join();

const getSelectedDropdownOptions = values => ({ callParameter, options = [], value = 'value' }) =>
  options.map(option => ({
    ...option,
    selected: values[callParameter].split(',').includes(option[value].toString()),
  }));

const getFormState = values => (sections = []) =>
  sections.reduce((acc, section) => {
    switch (section.type) {
      case 'checkbox':
      case 'radio':
        return {
          ...acc,
          [section.name]: getCheckedOptions(values)(section),
        };
      case 'dropdown':
        return {
          ...acc,
          [section.name]: getSelectedDropdownOptions(values)(section),
        };
      default:
        return { ...acc };
    }
  }, {});

const getRadioResults = data => ({ name }) =>
  (data[name] || [])
    .filter(option => option.checked)
    .map(option => option.id)
    .join();

const Filters = ({ filter, hide, isHideDisabled = false, sections = [], show = false, values }) => {
  /**
   * useContext()
   */
  const Text = useContext(TextContext);

  /**
   * useRef()
   */
  const sectionsRef = useRef();

  /**
   * useState()
   */
  const [formData, setFormData] = useState({});
  const [initialFilters, setInitialFilters] = useState();
  const [previousParams, setPreviousParams] = useState({});

  /**
   * useMemo()
   */
  const filtersParams = useMemo(() => {
    if (_.isEmpty(formData) || _.isEmpty(sections)) return {};
    return sections.reduce((acc, section) => {
      switch (section.type) {
        case 'checkbox':
          return { ...acc, [section.callParameter]: getCheckboxResults(formData)(section) };
        case 'dropdown':
          return { ...acc, [section.callParameter]: getDropdownResults(formData)(section) };
        case 'radio':
          return { ...acc, [section.callParameter]: getRadioResults(formData)(section) };
        default:
          return { ...acc };
      }
    }, {});
  }, [formData, sections]);

  const noFiltersSet = useMemo(() => initialFilters && _.isEqual(initialFilters)(values), [
    initialFilters,
    values,
  ]);

  /**
   * useCallback()
   */
  const clearFilters = useCallback(() => {
    setFormData(getFormState(initialFilters)(sections));
  }, [initialFilters, sections]);

  const onKeyDownClear = useCallback(
    e => {
      if (e.keyCode !== 13) return;
      clearFilters();
    },
    [clearFilters]
  );

  const onKeyDownHide = useCallback(
    e => {
      if (e.keyCode !== 13) return;
      hide();
    },
    [hide]
  );

  const updateCheckboxOptions = useCallback((name, options) => {
    setFormData(prev => ({ ...prev, [name]: options }));
  }, []);

  const updateDropdownOptions = useCallback(
    ({ name, value = 'value' }) => selectedOptions => {
      setFormData(prev => ({
        ...prev,
        [name]: prev[name].map(option => ({
          ...option,
          selected: (Array.isArray(selectedOptions)
            ? selectedOptions
            : !_.isNil(selectedOptions)
            ? [selectedOptions]
            : []
          )
            .map(o => o[value])
            .includes(option[value]),
        })),
      }));
    },
    []
  );

  const updateRadioOptions = useCallback(
    ({ name }) => e => {
      setFormData(prev => ({
        ...prev,
        [name]: prev[name].map(option => ({
          ...option,
          checked: option.id === e.target.id,
        })),
      }));
    },
    []
  );

  /**
   * useEffect()
   */
  useEffect(() => {
    if (
      _.isEmpty(values) ||
      _.isEmpty(sections) ||
      sections.some(section => _.isEmpty(section.options)) ||
      _.isEqual(sections)(sectionsRef.current)
    )
      return;

    sectionsRef.current = sections;
    setFormData(getFormState(values)(sections));
  }, [sections, values]);

  useEffect(() => {
    if (_.isEmpty(filtersParams) || _.isEqual(filtersParams)(previousParams)) return;
    setPreviousParams(filtersParams);
    filter(filtersParams);
  }, [filter, filtersParams, previousParams]);

  useEffect(() => {
    if (initialFilters || _.isEmpty(values)) return;
    setInitialFilters(values);
  }, [initialFilters, values]);

  /**
   * render
   */
  return (
    <>
      {show && (
        <>
          <div className="roster__Filter__Container">
            <div className="roster__Filter__Header">
              <div className="filter_label">
                <FilterIcon />
                <div>{Text.features.roster.filters.title}</div>
              </div>
              {!noFiltersSet && (
                <span
                  className="link"
                  tabIndex="0"
                  onClick={clearFilters}
                  onKeyDown={onKeyDownClear}>
                  {Text.common.labels.clear}
                </span>
              )}
              {!isHideDisabled && (
                <span className="link" tabIndex="0" onClick={hide} onKeyDown={onKeyDownHide}>
                  {Text.common.labels.hide}
                </span>
              )}
            </div>
            <div className="filter_content_container">
              {sections.map(section => (
                <React.Fragment key={section.name}>
                  {section.type.toLowerCase() === 'checkbox' && !!section.options.length && (
                    <FilterSection title={section.title}>
                      <FilterCheckboxGroup
                        options={formData[section.name]}
                        propName={section.name}
                        setFilterState={updateCheckboxOptions}
                      />
                    </FilterSection>
                  )}
                  {section.type.toLowerCase() === 'dropdown' && (
                    <FilterSection>
                      <DropDown
                        customLabel={section.setCustomLabel}
                        customLabelFunc={section.setCustomLabel}
                        isClearable={section.isClearable}
                        label={section.title}
                        multiSelect={!section.singleSelect}
                        onChange={updateDropdownOptions(section)}
                        optionLabelKey={section.label}
                        options={formData[section.name]}
                        optionValueKey={section.value}
                        placeholder={section.placeholder}
                        searchable
                        value={formData[section.name]?.filter(option => option.selected)}
                      />
                    </FilterSection>
                  )}
                  {section.type.toLowerCase() === 'radio' && !!section.options.length && (
                    <FilterSection title={section.title}>
                      <Radio
                        defaultOption={formData[section.name]?.find(option => option.checked)?.id}
                        handleDateSelection={updateRadioOptions(section)}
                        items={formData[section.name]}
                      />
                    </FilterSection>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
};

Filters.propTypes = {
  filter: propTypes.func,
  filterFirst: propTypes.bool,
  hide: propTypes.func,
  isHideDisabled: propTypes.bool,
  sections: propTypes.arrayOf(
    propTypes.shape({
      callParameter: propTypes.string.isRequired,
      label: propTypes.string,
      name: propTypes.string.isRequired,
      options: propTypes.arrayOf(
        propTypes.shape({
          checkedByDefault: propTypes.bool,
          label: propTypes.string,
          value: propTypes.string,
        })
      ),
      placeholder: propTypes.string,
      type: propTypes.string.isRequired,
      value: propTypes.string,
    })
  ),
  show: propTypes.bool,
  values: propTypes.shape({
    [propTypes.string]: propTypes.string,
  }),
};

export default Filters;

import React from 'react';
import propTypes from 'prop-types';
import { Line } from 'rc-progress';

const ProgressBar = ({ completed, total }) => {
  const text = `${completed}/${total} tasks completed`;
  return (
    <div className="progressBar__Container">
      {text}
      <Line
        percent={(completed * 100) / total}
        strokeWidth="1"
        strokeColor="#0077AA"
        trailColor="#E6F2F7"
      />
    </div>
  );
};

ProgressBar.displayName = 'ProgressBar';
ProgressBar.propTypes = {
  completed: propTypes.number.isRequired,
  total: propTypes.number.isRequired,
};

export default ProgressBar;

import _ from 'lodash/fp';
import { createDispatchFunction } from '../../../services/helpers/stateHelper';

import { ContractServices } from '../../../services';
import * as types from './types';

export function uploadAction(payload) {
  return {
    type: types.UPLOAD_FILE,
    payload,
  };
}

export function getFilesAction(payload) {
  return {
    type: types.GET_FILES,
    payload,
  };
}

export function getDownloadUrlsAction(payload) {
  return {
    type: types.GET_DOWNLOAD_URLS,
    payload,
  };
}

export function getOrganizationsAction(payload) {
  return {
    type: types.GET_ORGANIZATIONS,
    payload,
  };
}

export function getParentOrgsAction(payload) {
  return {
    type: types.GET_PARENT_ORGS,
    payload,
  };
}

export function getChildOrgTypesAction(payload) {
  return {
    type: types.GET_CHILD_ORG_TYPES,
    payload,
  };
}

export function updateOrgAction(payload) {
  return {
    type: types.UPDATE_ORG,
    payload,
  };
}

export function createOrgAction(payload) {
  return {
    type: types.CREATE_ORG,
    payload,
  };
}

export function getOrgEntityDefAction(payload) {
  return {
    type: types.ORG_ENTITY_DEF,
    payload,
  };
}

export function uploadFile(data, orgId) {
  return createDispatchFunction(
    _.partial(ContractServices.uploadFile)([data, orgId]),
    uploadAction
  );
}

export function getUploadedFiles(orgPartId, adminId, params) {
  return createDispatchFunction(
    _.partial(ContractServices.getUploadedFiles)([orgPartId, adminId, params]),
    getFilesAction
  );
}

export function getDownloadUrls(orgPartId, jobId) {
  return createDispatchFunction(
    _.partial(ContractServices.getDownloadUrls)([orgPartId, jobId]),
    getDownloadUrlsAction
  );
}

export function getOrganizations(data) {
  return createDispatchFunction(
    _.partial(ContractServices.getOrganizations)([data]),
    getOrganizationsAction
  );
}

export function getParentOrgs(orgId) {
  return createDispatchFunction(
    _.partial(ContractServices.getParentOrgs)([orgId]),
    getParentOrgsAction
  );
}

export function getChildOrgTypes(orgType) {
  return createDispatchFunction(
    _.partial(ContractServices.getChildOrgTypes)([orgType]),
    getChildOrgTypesAction
  );
}

export function updateOrg(data) {
  return createDispatchFunction(_.partial(ContractServices.updateOrg)([data]), updateOrgAction);
}

export function createOrg(data) {
  return createDispatchFunction(_.partial(ContractServices.createOrg)([data]), createOrgAction);
}

export function getOrgEntityDef(adminId) {
  return createDispatchFunction(
    _.partial(ContractServices.getOrgEntityDef)([adminId]),
    getOrgEntityDefAction
  );
}

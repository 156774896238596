import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions as coreActions } from '../../../core/duck';
import * as text from '../../../core/i18n/text';
import OrganizationTab from '../../../components/organization-tab/OrganizationTab';
import Buttons from '../../../components/data-panel/buttons/Buttons';
import { ReactComponent as GoToArrow } from '../../../assets/svgs/goToArrow.svg';
import _ from 'lodash/fp';
import { countArray } from '../../../core/constants';

const initialSearch = {
  filter: '',
};
const inputList = [
  {
    placeholder: 'Org name or code',
    maxLength: '30',
    type: 'text',
    name: 'filter',
  },
];
const columns = [
  {
    Header: 'Organization name',
    accessor: 'orgname',
  },
  {
    Header: '',
    accessor: 'actionCell',
  },
];
const linkButtons = {
  school: [
    {
      text: text.features.testManagement.buttons.seeTestEvents,
      basePath: '/testevents/test-events',
      svg: GoToArrow,
    },
  ],
  district: [
    {
      text: text.features.organization.buttons.seeOrgs,
      basePath: '/testevents/organization',
      svg: GoToArrow,
    },
  ],
};
class TestManagementOrganization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentOrg: null,
    };
  }

  componentDidMount() {
    if (_.get('orgHierarchy.data')(this.props) && this.props.orgHierarchy.data.length) {
      const idx = this.props.orgHierarchy.data.length - 1;
      this.setState({ currentOrg: this.props.orgHierarchy.data[idx] });
      this.props.actions.getData({
        ...this.props.orgHierarchy.data[idx],
        ...{
          status: 'active',
          limit: countArray[0],
        },
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      _.get('orgHierarchy.data')(this.props) &&
      this.props.orgHierarchy.data.length &&
      !_.isEqual(prevProps.orgHierarchy)(this.props.orgHierarchy)
    ) {
      const idx = this.props.orgHierarchy.data.length - 1;
      this.setState({ currentOrg: this.props.orgHierarchy.data[idx] });
      this.props.actions.getData({
        ...this.props.orgHierarchy.data[idx],
        ...{
          status: 'active',
          limit: countArray[0],
        },
      });
    }
  }

  setTableColumns = linkButtons => {
    return [
      {
        id: 'columns',
        columns: [
          {
            Header: 'Org name',
            name: 'Org name',
            accessor: 'orgname',
            fields: ['orgname'],
            length: 2,
            width: 406,
            Cell: ({ cell }) => <span className="bold-txt">{cell.row.original.orgname}</span>,
          },
          {
            Header: '',
            accessor: 'actionCell',
            length: 2,
            type: 'button',
            width: 190,
            Cell: ({ cell }) => <Buttons item={cell.row.original} linkButtons={linkButtons} />,
          },
        ],
      },
    ];
  };
  render() {
    return (
      <div className="test-events-container ">
        <OrganizationTab
          currentOrg={this.state.currentOrg}
          {...this.props}
          inputList={inputList}
          organizations={this.props.organizations}
          linkButtons={linkButtons}
          nonStikyColumns={columns}
          setTableColumns={this.setTableColumns}
          usingOrgPartId={true}
          currentOrgPartId={this.state.currentOrg?.orgPartId}
          initialSearch={initialSearch}
          emptyStateMessage={text.messages.info.testManagementEmptyState}
          callParameters={{
            params: {
              status: 'active',
            },
          }}
          title="Test Events Organizations - ACT-Now"
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { userInfoReducer } = state;
  return {
    orgHierarchy: userInfoReducer.orgHierarchy,
    organizations: userInfoReducer.childOrganizations,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        getData: coreActions.getChildOrgs,
      },
      dispatch
    ),
  };
};

export { TestManagementOrganization };
export default connect(mapStateToProps, mapDispatchToProps)(TestManagementOrganization);

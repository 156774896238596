import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import propTypes from 'prop-types';
import _ from 'lodash/fp';

import Checkbox from '../inputs/Checkbox';

import TextContext from '../../core/providers/TextProvider';

import { ReactComponent as Wheel } from '../../assets/svgs/settings.svg';

import './ColumnManager.scss';

const ColumnManager = ({ headerColumns = [], initialColumns, page, setNewHeaders, tab }) => {
  /**
   * useContext()
   */
  const Text = useContext(TextContext);

  /**
   * useRef()
   */
  const manageColumnsRef = useRef();

  /**
   * useState()
   */
  const [display, setDisplay] = useState(false);
  const [managerList, setManagerList] = useState(headerColumns);
  const [showAllButton, setShowAllButton] = useState(false);

  /**
   * useCallback()
   */
  const handleColumnManagerApply = useCallback(() => {
    setNewHeaders(managerList.filter((column, i) => column.checked));
    setDisplay(false);
  }, [managerList, setNewHeaders]);

  const handleColumnManagerCheckbox = useCallback(
    index =>
      setManagerList(prev =>
        prev.map((item, i) => (i === index ? { ...item, checked: !item.checked } : item))
      ),
    []
  );

  const handleColumnManagerSelectAll = useCallback(
    () => setManagerList(prev => prev.map(column => ({ ...column, checked: true }))),
    []
  );

  const openColumnManager = useCallback(() => {
    setDisplay(prev => !prev);
  }, []);

  const setInitialColumns = useCallback(() => {
    if (_.isEmpty(initialColumns)) return;

    const savedColumns = JSON.parse(
      window.sessionStorage.getItem(`${page}-${tab}-columns`) || '[]'
    );
    if (savedColumns.length) {
      const headers = initialColumns.map(
        column => savedColumns.find(o => column.name === o.name) || { ...column, checked: false }
      );
      setNewHeaders(headers);
      setManagerList(headers);
    } else {
      setManagerList(initialColumns.map(column => ({ ...column, checked: true })));
    }
  }, [initialColumns, page, setNewHeaders, tab]);

  const onKeyDown = useCallback(
    e => {
      if (e.keyCode === 13) handleColumnManagerSelectAll();
    },
    [handleColumnManagerSelectAll]
  );

  /**
   * useEffect()
   */
  useEffect(() => {
    setInitialColumns();
  }, [setInitialColumns]);

  useEffect(() => {
    setShowAllButton(managerList.some(column => !column.checked));
  }, [managerList]);

  /**
   * render
   */
  return (
    <div className="manageColumns__Container">
      <span className="manageColumns__tooltip__Container">
        <button
          aria-expanded={display}
          aria-haspopup="true"
          aria-label="Manage columns"
          className="roster__ManageColumns"
          onClick={openColumnManager}>
          <Wheel />
        </button>
        <span className="manageColumns__tooltip">Manage columns</span>
      </span>
      {display && (
        <div className="manageColumns__ArrowBox manageColumns__Ajusted" ref={manageColumnsRef}>
          <div className="manageColumns__Arrow manageColumns_Moved-arrow"></div>
          <div className="cmb__Container" role="group">
            {managerList.map((column, i) => {
              return (
                <div key={column.name} className="cmb__Row" role="menuitem">
                  <Checkbox
                    checked={column.checked}
                    handleChange={() => handleColumnManagerCheckbox(i)}
                  />
                  <span className="cmb__Label">{column.name}</span>
                </div>
              );
            })}
            <div className="cmb__Controls">
              <button
                type="button"
                className="bigButton apply__Button"
                onClick={() => handleColumnManagerApply()}>
                {Text.clickables.buttons.apply}
              </button>
              {showAllButton && (
                <span
                  tabIndex="0"
                  className="cancel__Link"
                  onClick={handleColumnManagerSelectAll}
                  onKeyDown={e => onKeyDown(e)}>
                  {Text.common.labels.selectAll}
                </span>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

ColumnManager.displayName = 'ColumnManager';

ColumnManager.propTypes = {
  headerColumns: propTypes.arrayOf(
    propTypes.shape({ checked: propTypes.bool, name: propTypes.string })
  ),
};

export default ColumnManager;

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import _ from 'lodash/fp';
import moment from 'moment';

import { withStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import Alert from '../../components/alerts/Alert';
import Filters from '../../components/filters/Filters';
import Header from '../../components/header/Header';
import Loader from '../../components/loader/Loader';
import Notification from '../../components/alerts/Notification';
import SidebarNav from '../../components/sidebar-nav/SidebarNav';

import { ReactComponent as Download } from '../../assets/svgs/download.svg';
import { ReactComponent as DropdownIcon } from '../../assets/svgs/downArrow.svg';
import { ReactComponent as OnlineIcon } from '../../assets/svgs/online.svg';
import { ReactComponent as PaperIcon } from '../../assets/svgs/paper.svg';

import { CommonServices } from '../../services';

import { useStore } from '../../store/hooks';

import TextContext from '../../core/providers/TextProvider';

import { BORDER_GRAY, DARK_GREY, WHITE } from '../../styles/base/_settings.scss';
import './ScheduleOfEvents.scss';

const LightTooltip = withStyles(() => ({
  arrow: {
    color: WHITE,
    '&:before': {
      border: `0.1rem solid ${BORDER_GRAY}`,
    },
  },
  popper: {
    zIndex: '1001 !important',
  },
  tooltip: {
    backgroundColor: WHITE,
    color: DARK_GREY,
    fontSize: '1.4rem',
    boxShadow: `0 0 1rem ${BORDER_GRAY}`,
  },
}))(Tooltip);

const downloadPDF = url => {
  const link = document.createElement('a');
  link.download = '';
  link.href = url;
  link.id = 'pdf';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const ScheduleOfEvents = () => {
  /**
   * react-router-dom hooks
   */
  const { orgPartId } = useParams();

  /**
   * useContext()
   */
  const Text = useContext(TextContext);

  /**
   * useStore()
   */
  const [orgHierarchyResponse] = useStore('orgHierarchy');

  /**
   * useState()
   */
  const [cleanFilters, setCleanFilters] = useState(false);
  const [downloadNotification, setDownloadNotification] = useState({ show: false });
  const [getEventsResponse, setGetEventsResponse] = useState({});
  const [events, setEvents] = useState([]);
  const [filterParams, setFilterParams] = useState({});
  const [filteredResponse, setFilteredResponse] = useState(false);
  const [initialFilters, setInitialFilters] = useState({});
  const [previousParams, setPreviousParams] = useState({});
  const [roles, setRoles] = useState([]);

  /**
   * useMemo()
   */
  const currentOrg = useMemo(() => {
    if (!orgHierarchyResponse?.data) return {};
    return orgHierarchyResponse.data[orgHierarchyResponse.data.length - 1];
  }, [orgHierarchyResponse]);

  const filters = useMemo(() => {
    if (_.isEmpty(roles)) return [];
    return [
      {
        callParameter: 'roles',
        name: 'roles',
        options: roles.map(({ abbr, name }) => ({
          label: `${name} (${abbr})`,
          value: abbr,
        })),
        title: 'Role(s)',
        type: 'checkbox',
      },
      {
        callParameter: 'deadlines',
        name: 'deadlines',
        options: [
          {
            value: 'deadlinesOnly',
            label: Text.features.scheduleOfEvents.filters.deadlines.options.deadlinesOnly,
          },
          {
            value: 'testingDaysOnly',
            label: Text.features.scheduleOfEvents.filters.deadlines.options.testingDaysOnly,
          },
        ],
        title: Text.features.scheduleOfEvents.filters.deadlines.title,
        type: 'checkbox',
      },
      {
        callParameter: 'format',
        name: 'format',
        options: [
          { id: 'all', displayText: Text.features.scheduleOfEvents.filters.format.options.all },
          {
            id: 'onlineOnly',
            displayText: Text.features.scheduleOfEvents.filters.format.options.onlineOnly,
            icon: OnlineIcon,
          },
          {
            id: 'paperOnly',
            displayText: Text.features.scheduleOfEvents.filters.format.options.paperOnly,
            icon: PaperIcon,
          },
        ],
        title: Text.features.scheduleOfEvents.filters.format.title,
        type: 'radio',
      },
    ];
  }, [roles, Text]);

  const { adminId, orgId } = useMemo(() => currentOrg, [currentOrg]);

  /**
   * useCallback()
   */
  const clearFilters = useCallback(() => {
    setFilterParams(initialFilters);
  }, [initialFilters]);

  const filter = useCallback(params => {
    if (
      !Object.values(params).length ||
      Object.values(params).some(param => typeof param === undefined)
    )
      return;
    setFilterParams(params);
  }, []);

  const handleDownload = useCallback(() => {
    CommonServices.getScheduleOfEventsPdf({ adminId, orgId })
      .then(response => {
        downloadPDF(response.data.url);
        setDownloadNotification({
          message: Text.features.scheduleOfEvents.downloadSuccess,
          show: true,
          type: 'Success',
        });
      })
      .catch(() => {
        setDownloadNotification({
          message: Text.messages.error.generic,
          show: true,
          type: 'Error',
        });
      })
      .finally(() => {
        setTimeout(() => {
          setDownloadNotification({ show: false });
        }, 3000);
      });
  }, [adminId, orgId, Text.features.scheduleOfEvents.downloadSuccess, Text.messages.error.generic]);

  const isAllExpanded = useCallback(
    event => event.activityDTOS.every(activity => activity.expanded),
    []
  );

  const onFiltersKeyDown = useCallback(
    e => {
      if (e.keyCode !== 13) return;
      clearFilters();
    },
    [clearFilters]
  );

  const toggleActivity = useCallback((e, a) => {
    setEvents(prev =>
      prev.map((event, i) => {
        if (i !== e) return { ...event };
        return {
          ...event,
          activityDTOS: event.activityDTOS.map((activity, j) => {
            if (j !== a) return { ...activity };
            return {
              ...activity,
              expanded: !activity.expanded,
            };
          }),
        };
      })
    );
  }, []);

  const toggleAllActivities = useCallback(
    index => {
      setEvents(prev =>
        prev.map((event, i) => {
          if (i !== index) return { ...event };
          const expanded = isAllExpanded(event);
          return {
            ...event,
            activityDTOS: event.activityDTOS.map(activity => ({
              ...activity,
              expanded: !expanded,
            })),
            expanded: !expanded || event.expanded,
          };
        })
      );
    },
    [isAllExpanded]
  );

  const toggleEvent = useCallback(index => {
    setEvents(prev =>
      prev.map((event, i) => {
        if (i !== index) return { ...event };
        const expanded = !event.expanded;
        return {
          ...event,
          activityDTOS: event.activityDTOS.map(activity => ({
            ...activity,
            expanded: activity.expanded && expanded,
          })),
          expanded,
        };
      })
    );
  }, []);

  const getDeadline = useCallback(
    activity => {
      const currentDate = moment(new Date()).format('MM/DD/YYYY');
      if (
        !((activity.testDay || activity.testWindow || activity.deadline) && activity.displayDates)
      )
        return;
      if (!activity.isDeadline || !moment(activity.startDate).isAfter(moment(currentDate)))
        return <span>{moment(activity.startDate).format('MM/DD/YYYY')}</span>;
      const difference = moment(activity.startDate).diff(moment(currentDate), 'days');
      if (difference) {
        return (
          <span className="deadline">{`Due in ${difference} day(s), ${moment(
            activity.startDate
          ).format('MM/DD/YYYY')}`}</span>
        );
      }

      return (
        <span className="deadline">{`${Text.features.scheduleOfEvents.dueToday}, ${moment(
          activity.startDate
        ).format('MM/DD/YYYY')}`}</span>
      );
    },
    [Text.features.scheduleOfEvents.dueToday]
  );

  const handleNotificationClose = useCallback(() => {
    setDownloadNotification({ show: false });
  }, []);

  const getLink = useCallback(
    label => {
      switch (label) {
        case 'Administration':
          const type = orgHierarchyResponse?.data
            ? orgHierarchyResponse.data[orgHierarchyResponse.data.length - 1].type
            : '';
          return `/administration/${
            type === 'school' ? 'participation' : 'organization'
          }/orgPartId/${orgPartId}`;
        case 'Contract':
          return `/contract/organization/orgPartId/${orgPartId}`;
        case 'Students':
          return `/students/organization/orgPartId/${orgPartId}`;
        case 'Test events':
          return `/testevents/organization/orgPartId/${orgPartId}`;
        default:
          return '';
      }
    },
    [orgHierarchyResponse, orgPartId]
  );

  /**
   * useEffect()
   */
  useEffect(() => {
    if (!adminId || !orgId || _.isEmpty(filterParams) || _.isEqual(filterParams)(previousParams))
      return;
    setPreviousParams(filterParams);
    setGetEventsResponse({ data: null, error: false, loading: true });
    CommonServices.getScheduleOfEvents({ adminId, orgId, params: filterParams })
      .then(response => {
        setFilteredResponse(!_.isEqual(filterParams)(initialFilters));
        setGetEventsResponse({ data: response.data, error: false, loading: false });
      })
      .catch(() => setGetEventsResponse({ data: null, error: true, loading: false }));
  }, [adminId, filterParams, initialFilters, orgId, previousParams]);

  useEffect(() => {
    if (_.isEmpty(getEventsResponse.data)) return;
    const data = getEventsResponse.data.map(event => {
      const current =
        moment(event.intervalStartDate).isBefore() && moment(event.intervalEndDate).isAfter();
      return {
        ...event,
        activityDTOS: event.activityDTOS.map(activity => {
          return {
            ...activity,
            expanded: false,
            isDeadline: current && !activity.testDay && !activity.testWindow,
          };
        }),
        current,
        expanded: true,
      };
    });
    setEvents(data);
  }, [getEventsResponse]);

  useEffect(() => {
    CommonServices.getScheduleOfEventsRoles().then(response => {
      if (!response.data) return;
      setRoles(response.data);
    });
  }, []);

  useEffect(() => {
    if (_.isEmpty(filters)) return;
    setInitialFilters(
      filters.reduce(
        (acc, { callParameter }) => ({
          ...acc,
          [callParameter]: callParameter === 'format' ? 'all' : '',
        }),
        {}
      )
    );
  }, [filters]);

  useEffect(() => {
    if (_.isEmpty(initialFilters) || !_.isEmpty(filterParams)) return;
    setFilterParams(initialFilters);
  }, [filterParams, initialFilters]);

  /**
   * render
   */
  return (
    <div className="schedule-of-events">
      {downloadNotification.show && (
        <Notification
          handleClose={handleNotificationClose}
          text={downloadNotification.message}
          type={downloadNotification.type}
        />
      )}
      <Header />
      <div className="_outter_">
        <SidebarNav active="scheduleOfEvents" />
        <div className="sidebar__Separator sidebar_fit_table">
          <div className="title-container">
            <h1 className="schedule-title">{Text.features.scheduleOfEvents.title}</h1>
            <button className="openButton" onClick={handleDownload}>
              <Download />
              {Text.features.scheduleOfEvents.downloadPdf}
            </button>
          </div>
          <div className="schedule-container">
            <div>
              <Filters
                clean={cleanFilters}
                clear={clearFilters}
                filter={filter}
                filterFirst={false}
                isHideDisabled
                sections={filters}
                setCleanFilters={setCleanFilters}
                show
                values={filterParams}
              />
            </div>
            {!!getEventsResponse.error && (
              <div>
                <Alert type="error" message={Text.messages.error.generic} />
              </div>
            )}
            {!getEventsResponse.error && getEventsResponse.loading && !getEventsResponse.data && (
              <Loader />
            )}
            {!getEventsResponse.error &&
              !getEventsResponse.loading &&
              getEventsResponse.data &&
              getEventsResponse.data?.length === 0 &&
              filteredResponse && (
                <div className="no-results">
                  <div>
                    <div className="no-results-description">
                      {Text.features.scheduleOfEvents.noResults1} <strong>0</strong>{' '}
                      {Text.features.scheduleOfEvents.noResults2}
                    </div>
                    <span
                      onClick={clearFilters}
                      onKeyDown={onFiltersKeyDown}
                      className="link"
                      tabIndex="0">
                      {Text.features.scheduleOfEvents.clearFilters}
                    </span>
                  </div>
                </div>
              )}
            {!getEventsResponse.error &&
              !getEventsResponse.loading &&
              getEventsResponse.data &&
              getEventsResponse.data?.length === 0 &&
              !filteredResponse && (
                <div>
                  <Alert type="info" message={Text.features.scheduleOfEvents.noEventsMessage} />
                </div>
              )}
            {!getEventsResponse.error &&
              !getEventsResponse.loading &&
              getEventsResponse.data &&
              !!getEventsResponse.data.length && (
                <div>
                  <div className="schedule-list-header">
                    <div>{Text.features.scheduleOfEvents.tableHeader.tasks}</div>
                    <div>{Text.features.scheduleOfEvents.tableHeader.deadline}</div>
                    <div className="roles">{Text.features.scheduleOfEvents.tableHeader.roles}</div>
                  </div>
                  {events.map((event, i) => {
                    return (
                      <Accordion
                        className="main-panel-container"
                        expanded={event.expanded}
                        key={`${event.intervalStartDate}-${event.intervalEndDate}`}
                        onClick={() => toggleEvent(i)}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          className="main-panel">
                          <Typography className="event-title">{`${moment(
                            event.intervalStartDate
                          ).format('MMMM DD, YYYY')} - ${moment(event.intervalEndDate).format(
                            'MMMM DD, YYYY'
                          )}`}</Typography>
                          <button
                            onClick={e => {
                              e.stopPropagation();
                              toggleAllActivities(i);
                            }}
                            className={`${
                              isAllExpanded(event) ? 'expanded' : 'collapsed'
                            }  expand-collapse`}>
                            <DropdownIcon />
                            {isAllExpanded(event) ? 'collapse all' : 'expand all'}
                          </button>
                        </AccordionSummary>
                        {event.activityDTOS.map((activity, j) => {
                          return (
                            <Accordion
                              expanded={activity.expanded}
                              onClick={e => {
                                e.stopPropagation();
                                toggleActivity(i, j);
                              }}
                              key={activity.name}>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className="sub-panel-container">
                                <div className="sub-panel">
                                  {activity.name}{' '}
                                  {(activity.testDay || activity.testWindow) && (
                                    <div className="test-event-info">
                                      <span>
                                        {activity.testDay
                                          ? Text.features.scheduleOfEvents.testDay
                                          : Text.features.scheduleOfEvents.testWindow}
                                      </span>
                                    </div>
                                  )}
                                </div>
                                <div className="test-dates">
                                  {getDeadline(activity)}
                                  {!activity.deadline &&
                                    activity.displayDates &&
                                    activity.endDate &&
                                    activity.testWindow && (
                                      <span className={activity.isDeadline ? 'deadline' : ''}>
                                        {` - ${moment(activity.endDate).format('MM/DD/YYYY')}`}
                                      </span>
                                    )}
                                </div>
                                <div className="icons">
                                  {activity.online && (
                                    <LightTooltip
                                      title={Text.features.scheduleOfEvents.onlineDelivery}
                                      arrow>
                                      <OnlineIcon />
                                    </LightTooltip>
                                  )}
                                  {activity.paper && (
                                    <LightTooltip
                                      title={Text.features.scheduleOfEvents.paperDelivery}
                                      arrow>
                                      <PaperIcon />
                                    </LightTooltip>
                                  )}
                                </div>
                                <div className="roles">{activity.roles}</div>
                              </AccordionSummary>
                              <AccordionDetails className="schedule-description">
                                <div className="description-label">
                                  <Typography>
                                    {activity.description
                                      ? activity.description.split('\n').map(str => <p>{str}</p>)
                                      : Text.features.scheduleOfEvents.noDetails}
                                  </Typography>
                                  <div></div>
                                </div>
                                {activity.linkLabel &&
                                  (activity.link.toLowerCase().includes('tcm') ? (
                                    <div className="tcm-link">
                                      {Text.features.scheduleOfEvents.goToTcm}
                                    </div>
                                  ) : (
                                    <Link
                                      className="link"
                                      to={getLink(activity.linkLabel)}
                                      onClick={() => {}}>
                                      {Text.features.scheduleOfEvents.goTo} {activity.linkLabel}
                                    </Link>
                                  ))}
                              </AccordionDetails>
                            </Accordion>
                          );
                        })}
                      </Accordion>
                    );
                  })}
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleOfEvents;

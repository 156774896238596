import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import T from 'i18n-react';
import { merge } from 'lodash';
import { ReactComponent as CloseModal } from '../../assets/svgs/close.svg';
import ArrowLeftBack from '../../assets/svgs/arrowLeftBack.svg';
import '../../components/modal/Modal.scss';
import './ModalInfo.scss';

const fullHeightStyles = {
  overlay: {
    overflow: 'auto',
    alignItems: 'unset',
  },
  content: {
    margin: '3% 0',
    maxHeight: 'none',
    height: 'max-content',
  },
};

const ModalInfo = props => {
  const {
    modalIsOpen,
    closeModal,
    className,
    headerTitle,
    cancelLabel,
    saveLabel,
    onSubmit,
    onCancel,
    children,
    subHeaderContent,
    showCancel,
    loadingButton,
    scrollableContentId,
    disabledSubmit,
    disableClose,
    tooltipContent,
    containerClass,
    headerSticky,
    fullHeightModal,
    headerBack,
    customContainerStyles,
    wideModal,
    iconActionButton = null,
    hasRemoveButton = false,
    bottomButtonLabel = false,
    showBottomButton = false,
  } = props;

  let modalContentRef = null;
  let scrollDownRef = null;
  let scrollHeader = null;
  const classHeader = headerSticky || fullHeightModal ? 'modal-header-sticky' : '';

  const setFocus = () => {
    ReactDOM.findDOMNode(modalContentRef).focus();
  };

  const scrollDown = () => {
    const element = ReactDOM.findDOMNode(modalContentRef);
    element.scrollTop = element.scrollHeight - element.clientHeight;
  };

  const checkScroll = () => {
    const element = ReactDOM.findDOMNode(modalContentRef);
    if (element.scrollHeight > element.clientHeight + element.scrollTop + 20) {
      ReactDOM.findDOMNode(scrollDownRef).style.display = 'block';
    } else {
      ReactDOM.findDOMNode(scrollDownRef).style.display = 'none';
    }

    if (headerTitle) {
      const headerElement = ReactDOM.findDOMNode(modalContentRef);
      if (headerElement.scrollTop > 0) {
        ReactDOM.findDOMNode(scrollHeader).style.boxShadow = '0 0 15px 0 rgb(0 0 0 / 12%)';
      } else {
        ReactDOM.findDOMNode(scrollHeader).style.boxShadow = 'none';
      }
    }
  };

  const addActions = () => {
    setFocus();
    checkScroll();

    if (fullHeightModal) {
      const element = document.getElementsByClassName('ReactModal__Overlay');
      element[0].scroll(0, 0);
    }
  };

  const setCustomStyles = () => {
    const customStyles = {};
    merge(customStyles, customContainerStyles, fullHeightModal ? fullHeightStyles : {});

    return customStyles;
  };

  const modalWidth = wideModal ? 'wide-modal' : '';

  return (
    <Modal
      isOpen={modalIsOpen}
      style={setCustomStyles()}
      onRequestClose={closeModal}
      className={`modal-info ${modalWidth} ${
        fullHeightModal ? 'full-height-modal' : ''
      } ${containerClass}`}
      shouldCloseOnOverlayClick={false}
      contentLabel="Modal"
      onAfterOpen={addActions}
      ariaHideApp={false}>
      <div className={`${className} modal-info-container`}>
        {headerTitle && (
          <div
            className={`modal-header ${classHeader} ${headerBack ? 'header-with-back' : ''}`}
            ref={ref => (scrollHeader = ref)}>
            {headerBack && (
              <button className="btn-link btn-back" onClick={onCancel ? onCancel : closeModal}>
                <ArrowLeftBack /> {T.translate('common.labels.back')}
              </button>
            )}
            <p className="fw-bold align-text-bottom d-inline-block mb-0">
              {T.translate(headerTitle)}
            </p>
            <button
              className={`close-blue text-navy btn-light ${disableClose ? 'disabled' : ''}`}
              aria-label="Close"
              onClick={closeModal}
              disabled={disableClose}>
              <CloseModal />
            </button>
          </div>
        )}
        <div
          className="modal-content"
          tabIndex="0"
          ref={ref => (modalContentRef = ref)}
          onScroll={checkScroll}
          onClick={checkScroll}
          id={scrollableContentId}>
          {!!subHeaderContent && subHeaderContent}
          <div className="modal-content-inner">{children}</div>
        </div>
        {props.hideButtons || showBottomButton ? null : showCancel ? (
          <div className="button-section">
            <button
              className="btn btn-outline btn-cancel"
              onClick={onCancel ? onCancel : closeModal}>
              {T.translate(cancelLabel)}
            </button>
            <span
              data-bs-toggle="popover"
              data-bs-placement="top"
              data-bs-trigger="hover"
              data-bs-content={tooltipContent}
              className="btn-submit-container">
              <button
                disabled={loadingButton || disabledSubmit}
                className={`btn ${hasRemoveButton ? 'btn-remove' : 'btn-action'}`}
                onClick={onSubmit}>
                {iconActionButton}
                {loadingButton ? <i className="fa fa-spinner fa-spin" /> : T.translate(saveLabel)}
              </button>
            </span>
          </div>
        ) : (
          <div className="button-section">
            <span
              data-bs-toggle="popover"
              data-bs-placement="top"
              data-bs-trigger="hover"
              data-bs-content={tooltipContent}
              className="btn-submit-container">
              <button
                disabled={loadingButton || disabledSubmit}
                className={`btn ${hasRemoveButton ? 'btn-remove' : 'btn-action'}`}
                onClick={onSubmit}>
                {iconActionButton}
                {loadingButton ? <i className="fa fa-spinner fa-spin" /> : T.translate(saveLabel)}
              </button>
            </span>
          </div>
        )}
        {props.showBottomButton && (
          <div className="button-section">
            <button className="btn btn-action" onClick={closeModal}>
              {T.translate(bottomButtonLabel) || T.translate('common.labels.cancel')}
            </button>
          </div>
        )}
        <div className="scroll-down" onClick={scrollDown} ref={ref => (scrollDownRef = ref)}>
          {T.translate('common.labels.scrollDown')}
          <div>
            <i className="fa fa-angle-down" />
          </div>
        </div>
      </div>
    </Modal>
  );
};

ModalInfo.propTypes = {
  closeModal: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  modalIsOpen: PropTypes.bool.isRequired,
  headerTitle: PropTypes.string,
  cancelLabel: PropTypes.string,
  saveLabel: PropTypes.string,
  showCancel: PropTypes.bool,
  className: PropTypes.string,
  containerClass: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.shape({})]),
  loadingButton: PropTypes.bool,
  disabledSubmit: PropTypes.bool,
  hideButtons: PropTypes.bool,
  disableClose: PropTypes.bool,
  headerSticky: PropTypes.bool,
  fullHeightModal: PropTypes.bool,
  headerBack: PropTypes.bool,
  customContainerStyles: PropTypes.shape({}),
  wideModal: PropTypes.bool,
};

ModalInfo.defaultProps = {
  headerTitle: '',
  cancelLabel: 'common.labels.cancel',
  saveLabel: 'common.labels.save',
  className: '',
  children: [],
  loadingButton: false,
  closeModal: () => {},
  disabledSubmit: false,
  hideButtons: false,
  disableClose: false,
  headerSticky: false,
  fullHeightModal: false,
  headerBack: false,
  customContainerStyles: {},
  wideModal: false,
};

export default ModalInfo;

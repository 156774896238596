import { STATUS, states } from './stateStatus';

export function createState(status, data = null) {
  return states[status](data);
}

export function dispatchPromise(dispatch, asyncFn, actionCreator) {
  let state = createState(STATUS.loading);
  dispatch(actionCreator(state));
  return asyncFn()
    .then(response => {
      state = createState(STATUS.resolved, response.data);
      dispatch(actionCreator(state));
      return response;
    })
    .catch(error => {
      state = createState(STATUS.rejected, error);
      dispatch(actionCreator(state));
      return error;
    });
}

export function createDispatchFunction(asyncFn, actionCreator) {
  return dispatch => {
    return dispatchPromise(dispatch, asyncFn, actionCreator);
  };
}

export default STATUS;

import React from 'react';
import propTypes from 'prop-types';

const TextArea = props => {
  const {
    nrCharactes,
    maxLength,
    placeHolder,
    error,
    handleTextAreaChange,
    value,
    onClear,
    showClear,
  } = props;
  const textAreaClass = showClear ? 'textArea clear__textArea' : 'textArea';
  const onKeyDown = e => {
    if (e.keyCode === 13) {
      onClear();
    }
  };
  return (
    <div className="textArea_-Container">
      <textarea
        className={error ? `${textAreaClass} textArea__Error` : textAreaClass}
        placeholder={placeHolder}
        value={value}
        maxLength={maxLength}
        onChange={handleTextAreaChange}
        aria-label="Input text"
      />
      {showClear && (
        <div
          className="textArea__Clear"
          tabIndex="0"
          onClick={onClear}
          onKeyDown={e => onKeyDown(e)}>
          Clear
        </div>
      )}
      <div className={error ? 'textArea__textContainerError' : 'textArea__textContainer'}>
        {error && <span className="textArea__text textArea__textError">{error}</span>}
        <span className="textArea__text">
          {nrCharactes}
          &nbsp; of &nbsp;
          {maxLength}
          &nbsp; characters
        </span>
      </div>
    </div>
  );
};

TextArea.displayName = 'TextArea';
TextArea.propTypes = {
  nrCharactes: propTypes.number.isRequired,
  maxLength: propTypes.string.isRequired,
  placeHolder: propTypes.string,
  error: propTypes.string,
  handleTextAreaChange: propTypes.func.isRequired,
  value: propTypes.string,
};
export default TextArea;

import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash/fp';

import Alert from '../../../components/alerts/Alert';
import AlertModal from '../../../components/modal/AlertModal';
import Checkbox from '../../../components/inputs/Checkbox';
import DropDown from '../../../components/inputs/DropDown';
import FilterCheckboxGroup from '../../../components/filters/FilterCheckboxGroup';
import FilterSection from '../../../components/filters/FilterSection';
import Input from '../../../components/forms/fields/Input';
import RadioControl from '../../../components/inputs/Radio';

import { actions as coreActions } from '../../../core/duck';
import { actions as landingActions } from '../../landing/duck';
import { actions as rosterActions } from '../duck';
import { RosterServices } from '../../../services';

import TextContext from '../../../core/providers/TextProvider';
import { getProgramConfiguration } from '../../../core/utils';

import { useStore } from '../../../store/hooks';

import { getEntityDef } from '../../../services/helpers/dataHelper';
import ValidationHelper from '../../../services/helpers/validationHelper';

import './EditStudentsModal.scss';

const EditStudentsModal = ({
  closeModal,
  currentOrg,
  multipleRegistrations,
  showNotification,
  student = {},
}) => {
  const { adminId, contractId } = currentOrg;

  /**
   * useContext()
   */
  const Text = useContext(TextContext);

  /**
   *  useDispatch()
   */
  const dispatch = useDispatch();

  /**
   *  useRef()
   */
  const keyCode = useRef();

  /**
   *  useState()
   */
  const [accommodationOptions, setAccommodationOptions] = useState([]);
  const [formData, setFormData] = useState({ errors: {} });
  const [ncrOptions, setNcrOptions] = useState([]);
  const [serverErrors, setServerErrors] = useState([]);
  const [showCancelConfirmationModal, setShowCancelConfirmationModal] = useState(false);
  const [showSaveWithoutAccommsModal, setShowSaveWithoutAccommsModal] = useState(false);

  /**
   *  useSelector()
   */
  const accommsOptionsResponse = useSelector(state => state.rosterReducer.accommodationOptions);
  const contractAdminInfoResponse = useSelector(state => state.landingReducer.contractAdminInfo);
  const entityDefResponse = useSelector(state => state.rosterReducer.entityDef);
  const programsResponse = useSelector(state => state.userInfoReducer.programs);
  const regAccomsResponse = useSelector(state => state.rosterReducer.regAccoms);

  /**
   *  useStore()
   */
  const [ncrMaterialKitOptionsResponse, ncrMaterialKitOptionsStore] = useStore(
    'ncrMaterialKitOptions'
  );
  const [orgHierarchyResponse] = useStore('orgHierarchy');
  const [regTypesResponse] = useStore('regTypes');

  /**
   *  useMemo()
   */
  const editFlag = useMemo(() => {
    return !_.isEmpty(student);
  }, [student]);

  // TODO: Update to useContext when available
  const program = useMemo(() => {
    if (!programsResponse?.data?.length || !orgHierarchyResponse?.data?.length) return;
    return programsResponse.data.find(
      program => parseInt(program.programId) === parseInt(orgHierarchyResponse.data[0]?.programId)
    );
  }, [orgHierarchyResponse, programsResponse]);

  const accommsConfig = useMemo(() => getProgramConfiguration('accommodations')(program), [
    program,
  ]);

  const rosterConfig = useMemo(() => getProgramConfiguration('roster')(program), [program]);

  /**
   * Populate form inputs
   */
  useEffect(() => {
    if (accommsConfig?.options !== 'regAccomms' || !accommsOptionsResponse?.data) return;
    setAccommodationOptions(
      accommsOptionsResponse.data.map(({ code, id, name }) => ({
        checked: (formData.regAccoms || []).some(ra => ra.code === code),
        code,
        label: name,
        value: id.toString(),
      }))
    );
  }, [accommsConfig, accommsOptionsResponse, formData.regAccoms]);

  const deliveryOptions = useMemo(() => {
    if (!entityDefResponse?.data || !rosterConfig?.deliveryOptions) return [];
    return (
      getEntityDef('deliveryMode')(entityDefResponse.data)
        ?.options.filter(({ label }) => rosterConfig.deliveryOptions.includes(label.toLowerCase()))
        .map(({ label, value }) => ({ displayText: label, id: value })) || []
    );
  }, [entityDefResponse, rosterConfig]);

  const genderOptions = useMemo(() => {
    if (!entityDefResponse?.data) return [];
    return getEntityDef('gender')(entityDefResponse.data)?.options || [];
  }, [entityDefResponse]);

  const gradeOptions = useMemo(() => {
    if (!contractAdminInfoResponse?.data || !entityDefResponse?.data) return [];
    const gradeLimits = _.get('data[0]')(contractAdminInfoResponse)?.gradesAllowed?.split(',');
    return (
      getEntityDef('grade')(entityDefResponse.data)
        ?.options.filter(option => !gradeLimits || gradeLimits.includes(option.value))
        .sort((a, b) => parseInt(b.value) - parseInt(a.value)) || []
    );
  }, [contractAdminInfoResponse, entityDefResponse]);

  useEffect(() => {
    if (accommsConfig?.options !== 'ncrMaterials' || !ncrMaterialKitOptionsResponse?.data) return;
    setNcrOptions(
      ncrMaterialKitOptionsResponse.data.map(({ code, name }) => ({
        label: name,
        value: code,
      }))
    );
  }, [accommsConfig, ncrMaterialKitOptionsResponse]);

  const regTypeOptions = useMemo(() => {
    if (!regTypesResponse?.data) return [];
    return (
      regTypesResponse.data.map(({ code, id, name }) => ({ id, label: name, value: code })) || []
    );
  }, [regTypesResponse]);

  const accommodationCategories = useMemo(() => {
    if (!accommsConfig) return [];
    return [
      {
        id: 'N',
        displayText: 'None',
      },
      accommsConfig.options === 'ncrMaterials' &&
        !!ncrOptions?.length && {
          id: 'R',
          displayText: 'Non-College Reportable',
        },
      accommsConfig.options === 'regAccomms' &&
        !!accommodationOptions?.length && {
          id: 'R',
          displayText: 'Accommodations',
        },
      accommsConfig.taaPin && {
        id: 'T',
        displayText: 'TAA',
      },
    ].filter(Boolean);
  }, [accommodationOptions, accommsConfig, ncrOptions]);

  /**
   *  useCallback()
   */
  const getAccommodation = useCallback(() => {
    if (student.taaPin) return 'T';
    if (
      (accommsConfig.options === 'ncrMaterials' && student.nonCollegeReportableFlag) ||
      (accommsConfig.options === 'regAccomms' && student.accomRosterFlag)
    )
      return 'R';
    return 'N';
  }, [accommsConfig.options, student]);

  const resetFormData = useCallback(() => {
    setFormData({ errors: {} });
    setServerErrors([]);
  }, []);

  const saveFormData = useCallback(() => {
    const body = {
      accomKit:
        accommsConfig.options === 'ncrMaterials' && formData.accommodation === 'R'
          ? formData.ncrValue.value
          : undefined,
      accommOptOut: formData.accommOptOut,
      capacityType: formData.deliveryMode,
      dateOfBirth: formData.dateOfBirth,
      externalStudentId: formData.externalStudentCode,
      firstName: formData.firstName,
      gender: formData.gender?.value,
      grade: formData.grade.value,
      lastName: formData.lastName,
      middleName: formData.middleName,
      nonCollegeReportableFlag:
        accommsConfig.options === 'ncrMaterials' && formData.accommodation === 'R'
          ? !!formData.ncrValue.value
          : undefined,
      regAccoms:
        accommsConfig.options === 'regAccomms' && formData.accommodation === 'R'
          ? formData.regAccoms
          : undefined,
      regType: formData.regType,
      reportingHighSchoolCode: formData.reportingHighSchoolCode,
      taaPin:
        !formData.accommOptOut && (formData.accommodation === 'T' || formData.deliveryMode === 'O')
          ? formData.taaPin
          : '',
    };

    const promise = editFlag
      ? RosterServices.updateStudent({
          ...body,
          id: student.regId,
          orgPartId: student.orgPartId,
        })
      : RosterServices.addStudent({
          ...body,
          orgPartId: currentOrg.orgPartId,
        });
    promise
      .then(() => {
        showNotification();
        resetFormData();
        return closeModal();
      })
      .catch(error => {
        const messages = error.response?.data?.fieldErrorMessageList?.map(
          ({ message }) => message
        ) || [error];
        setServerErrors(messages);
      });
  }, [
    accommsConfig,
    closeModal,
    currentOrg.orgPartId,
    editFlag,
    formData,
    resetFormData,
    showNotification,
    student,
  ]);

  const validateFormData = useCallback(() => {
    if (!entityDefResponse.data) return;
    const {
      dateOfBirth,
      deliveryMode,
      externalStudentCode,
      firstName,
      gender,
      grade,
      lastName,
      middleName,
      ncrValue,
      regType,
      reportingHighSchoolCode,
      taaPin,
    } = formData;

    const data = {
      dateOfBirth,
      deliveryMode,
      externalStudentCode,
      firstName,
      gender,
      grade,
      lastName,
      middleName,
      ncrValue,
      regType,
      reportingHighSchoolCode,
      taaPin,
    };

    const rules = entityDefResponse.data.reduce((acc, o) => ({ ...acc, [o.code]: o }), {});

    const errors = ValidationHelper.validate(data, rules);

    if (!errors.reportingHighSchoolCode && data.reportingHighSchoolCode === '000000') {
      errors.reportingHighSchoolCode = Text.messages.error.reportingHighSchoolCodeError;
    }

    if (
      formData.accommodation === 'R' &&
      !formData.ncrValue?.value &&
      !accommodationOptions.some(({ checked }) => checked)
    ) {
      errors.ncrValue = true;
    }

    return errors;
  }, [accommodationOptions, entityDefResponse, formData, Text]);

  // Handle user interactions
  const closeCancelConfirmationModal = useCallback(
    close => _e => {
      setShowCancelConfirmationModal(false);
      if (!close) return;

      resetFormData();
      closeModal();
    },
    [closeModal, resetFormData]
  );

  const closeSaveWithoutAccommsModal = useCallback(
    save => _e => {
      setShowSaveWithoutAccommsModal(false);
      save && saveFormData();
    },
    [saveFormData]
  );

  const handleAccommodationsChange = useCallback((_propName, options) => {
    setFormData(prev => ({
      ...prev,
      regAccoms: options
        .filter(({ checked }) => checked)
        .map(({ code, label }) => ({ code, name: label })),
    }));
  }, []);

  const handleCheckboxChange = useCallback(
    inputName => e => {
      const inputValue = e.target.checked;

      // If we are opting out of TAA, clear the taaPin value
      setFormData(prev => ({
        ...prev,
        [inputName]: inputValue,
        taaPin: inputName === 'accommOptOut' && inputValue ? '' : prev.taaPin,
        errors: {
          ...prev.errors,
          [inputName]: undefined,
        },
      }));
    },
    []
  );

  const handleDateChange = useCallback(e => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      errors: {
        ...prev.errors,
        [name]: undefined,
      },
    }));
    if (/^[0-9/]+$/.test(value) || !value.length) {
      const formattedDate = ValidationHelper.dateFormatter(name, value, keyCode.current);
      setFormData(prev => ({ ...prev, [name]: formattedDate }));
    }
  }, []);

  const handleDropDownChange = useCallback(
    key => option => {
      setFormData(prev => ({
        ...prev,
        [key]: key === 'regType' ? option.value : option,
        errors: { ...prev.errors, [key]: undefined },
      }));
    },
    []
  );

  const handleInputChange = useCallback(e => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value,
      errors: {
        ...prev.errors,
        [name]: undefined,
      },
    }));
  }, []);

  const handleKeyDown = useCallback(e => (keyCode.current = e.keyCode), []);

  const handleRadioButtonChange = useCallback(
    key => e => {
      const { id } = e.target;
      setFormData(prev => ({ ...prev, [key]: id, errors: { ...prev.errors, [key]: undefined } }));
    },
    []
  );

  const handleRegTypeChange = useCallback((key, options) => {
    const value = options
      .filter(({ checked }) => checked)
      .map(({ value }) => value)
      .join(':');
    setFormData(prev => ({ ...prev, [key]: value, errors: { ...prev.errors, [key]: undefined } }));
  }, []);

  const handleValidation = useCallback(() => {
    let errors = validateFormData();
    setFormData(prev => ({ ...prev, errors }));

    const fieldsWithErrors = Object.entries(errors)
      .map(([k, v]) => (v !== '' ? k : null))
      .filter(Boolean);

    // If the user selected "Accommodations" but did not select any, display a confirmation modal
    if (accommsConfig.options === 'regAccomms' && _.isEqual(fieldsWithErrors)(['ncrValue'])) {
      setShowSaveWithoutAccommsModal(true);
      return;
    }

    // If there are any other field errors, do not save
    if (fieldsWithErrors.length) return;

    // If there are no fields with errors, save
    saveFormData();
  }, [accommsConfig, saveFormData, validateFormData]);

  const openCancelConfirmationModal = useCallback(() => setShowCancelConfirmationModal(true), []);

  /**
   *  useEffect()
   */
  // Dispatch actions to populate state
  useEffect(() => {
    if (accommsConfig?.options !== 'regAccomms' || !adminId || !formData.deliveryMode) return;
    dispatch(
      rosterActions.getAccommodationOptions({ adminId, deliveryMode: formData.deliveryMode })
    );
  }, [accommsConfig, adminId, dispatch, formData.deliveryMode]);

  useEffect(() => {
    if (!adminId || !contractId) return;
    dispatch(landingActions.getContractAdminInfo({ adminId, contractId }));
  }, [adminId, contractId, dispatch]);

  useEffect(() => {
    if (!currentOrg?.adminId) return;
    dispatch(rosterActions.getEntityDef({ adminId: currentOrg.adminId }));
  }, [currentOrg, dispatch]);

  useEffect(() => {
    if (
      accommsConfig?.options !== 'ncrMaterials' ||
      !adminId ||
      !formData.regType ||
      !regTypeOptions?.length
    )
      return;
    const option = regTypeOptions.find(o => formData.regType?.split(':').includes(o.value));
    ncrMaterialKitOptionsStore.fetch({
      adminId,
      nonCollegeReportableFlag: 1,
      regTypeId: option.id,
    });
  }, [accommsConfig, adminId, formData.regType, ncrMaterialKitOptionsStore, regTypeOptions]);

  useEffect(() => {
    if (accommsConfig?.options !== 'regAccomms' || !student?.regId) return;
    dispatch(rosterActions.getRegAccoms(student.regId));
  }, [accommsConfig, dispatch, student]);

  useEffect(() => {
    if (programsResponse) return;
    dispatch(coreActions.getUserProgramsAction());
  }, [dispatch, programsResponse]);

  useEffect(() => {
    if (accommodationCategories?.length !== 1) return;
    setFormData(prev => ({ ...prev, accommodation: accommodationCategories[0].id }));
  }, [accommodationCategories]);

  // Populate initial form data
  useEffect(() => {
    if (!deliveryOptions?.length) return;
    setFormData(prev => ({
      ...prev,
      deliveryMode:
        typeof student.onlineFlag !== 'undefined'
          ? student.onlineFlag
            ? 'O'
            : 'P'
          : deliveryOptions[0].id,
    }));
  }, [deliveryOptions, student.onlineFlag]);

  useEffect(() => {
    if (!genderOptions?.length) return;
    setFormData(prev => ({
      ...prev,
      gender: genderOptions.find(o => o.label === student.gender) || null,
    }));
  }, [genderOptions, student.gender]);

  useEffect(() => {
    if (!gradeOptions?.length) return;
    setFormData(prev => ({
      ...prev,
      grade: gradeOptions.find(o => o.label === student.grade) || null,
    }));
  }, [gradeOptions, student.grade]);

  useEffect(() => {
    if (!ncrOptions?.length) return;
    setFormData(prev => ({
      ...prev,
      ncrValue: ncrOptions.find(({ value }) => student.accomKit === value),
    }));
  }, [ncrOptions, student.accomKit]);

  useEffect(() => {
    setFormData(prev => ({
      ...prev,
      regAccoms: editFlag ? regAccomsResponse?.data : [],
    }));
  }, [editFlag, regAccomsResponse]);

  useEffect(() => {
    if (!regTypeOptions?.length) return;
    setFormData(prev => ({
      ...prev,
      regType: regTypeOptions.some(o => o.value === student.regTypeCode)
        ? student.regTypeCode
        : regTypeOptions.length === 1
        ? regTypeOptions[0].value
        : '',
    }));
  }, [regTypeOptions, student.regTypeCode]);

  useEffect(() => {
    setFormData(prev => ({
      ...prev,
      accommodation: getAccommodation(),
    }));
  }, [getAccommodation]);

  useEffect(() => {
    setFormData(prev => ({
      ...prev,
      accommOptOut: !!student.accommOptOut,
      dateOfBirth: student.dateOfBirth || '',
      externalStudentCode: student.externalStudentId || '',
      firstName: student.firstName || '',
      lastName: student.lastName || '',
      middleName: student.middleName || '',
      reportingHighSchoolCode: student.reportingHighSchoolCode || '',
      taaPin: student.taaPin || '',
      errors: {},
    }));
  }, [student]);

  /**
   *  Render
   */
  return (
    <>
      <AlertModal
        className="editStudent__ModalContainer"
        customStyles={{ overlay: { zIndex: '1000' } }}
        show={true}
        title={
          editFlag ? Text.features.roster.modal.edit : Text.features.roster.modal.addStudentTitle
        }
        body={[
          serverErrors.length ? (
            serverErrors.map(message => <Alert type="error" message={message} key={message} />)
          ) : (
            <Alert
              type="info"
              message={
                editFlag
                  ? Text.messages.info.editStudentModal
                  : Text.features.roster.modal.addInfoMessage
              }
            />
          ),
          rosterConfig.allowMultipleRegistrations && editFlag && multipleRegistrations ? (
            <Alert type="warning" message={Text.messages.warning.multipleRegistrationsUpdate} />
          ) : null,
          <div className="editStudent__NameContainer">
            <Input
              label={Text.common.fields.lastName}
              error={formData.errors.lastName}
              onChange={handleInputChange}
              name={'lastName'}
              value={formData.lastName}
              maxLength="50"
            />
            <Input
              label={Text.common.fields.firstName}
              error={formData.errors.firstName}
              onChange={handleInputChange}
              name={'firstName'}
              value={formData.firstName}
              maxLength="50"
            />
            <Input
              label={Text.common.fields.middleInitial}
              error={formData.errors.middleName}
              onChange={handleInputChange}
              name={'middleName'}
              value={formData.middleName}
              maxLength="1"
            />
          </div>,
          <Input
            label={Text.features.roster.modal.details.externalStudentId}
            error={formData.errors.externalStudentCode}
            onChange={handleInputChange}
            name={'externalStudentCode'}
            value={formData.externalStudentCode}
            maxLength="20"
          />,
          <DropDown
            label={Text.features.roster.modal.details.grade}
            options={gradeOptions}
            value={formData.grade}
            placeholder={formData.grade?.label || ''}
            onChange={handleDropDownChange('grade')}
            error={!!formData.errors.grade}
            disabled={false}
            searchable={false}
          />,
          <DropDown
            label={Text.features.roster.modal.details.gender}
            options={genderOptions}
            value={formData.gender}
            placeholder={formData.gender?.label || ''}
            onChange={handleDropDownChange('gender')}
            error={!!formData.errors.gender}
            disabled={false}
            searchable={false}
          />,
          <Input
            label={Text.features.roster.modal.details.dateOfBirth}
            error={formData.errors.dateOfBirth}
            onChange={handleDateChange}
            name={'dateOfBirth'}
            value={formData.dateOfBirth}
            maxLength="10"
            onKeyDown={handleKeyDown}
            placeholder="MM/DD/YYYY"
          />,
          <div>
            {!editFlag && rosterConfig.allowMultipleRegistrations ? (
              <FilterSection name="regType" title={Text.features.roster.modal.details.regTypeName}>
                <FilterCheckboxGroup
                  propName="regType"
                  options={regTypeOptions.map(option => ({
                    ...option,
                    checked: formData.regType?.split(':').includes(option.value),
                  }))}
                  setFilterState={handleRegTypeChange}
                />
              </FilterSection>
            ) : (
              <DropDown
                label={Text.features.roster.modal.details.regTypeName}
                options={regTypeOptions}
                value={regTypeOptions.find(o => o.value === formData.regType)?.label || ''}
                placeholder={regTypeOptions.find(o => o.value === formData.regType)?.label || ''}
                onChange={handleDropDownChange('regType')}
                error={!!formData.errors.regType}
                disabled={regTypeOptions.length <= 1 || rosterConfig.allowMultipleRegistrations}
                searchable={false}
                className="regType-dropdown"
              />
            )}
          </div>,
          <div>
            {formData.regType && deliveryOptions.length > 1 && (
              <div className="editStudent__delivery">
                <span
                  className={`field__Label ${
                    formData.errors.deliveryMode ? 'field__Label-error' : ''
                  }`}>
                  {Text.features.roster.modal.format}
                </span>
                {formData.errors.deliveryMode && (
                  <div className="field__ErrorMessage">
                    {Text.features.roster.modal.errors.deliveryMode}
                  </div>
                )}
                <form>
                  <RadioControl
                    defaultOption={formData.deliveryMode}
                    disableOption=""
                    error={formData.errors.deliveryMode}
                    handleDateSelection={handleRadioButtonChange('deliveryMode')}
                    items={deliveryOptions}
                  />
                </form>
              </div>
            )}
          </div>,
          <div>
            {formData.regType && accommodationCategories.length > 1 && (
              <div className="editStudent__accommodations">
                {['ncrMaterials', 'regAccomms'].includes(accommsConfig.options) && (
                  <>
                    <span className={`field__Label`}>
                      {Text.features.roster.modal.accommodationOption}
                    </span>
                    <form>
                      <RadioControl
                        defaultOption={formData.accommodation}
                        disableOption=""
                        handleDateSelection={handleRadioButtonChange('accommodation')}
                        items={accommodationCategories}
                      />
                    </form>
                    {formData.accommodation === 'R' && (
                      <>
                        {accommsConfig.options === 'regAccomms' && !!accommodationOptions.length && (
                          <>
                            <span className={`field__Label`}>
                              {Text.features.roster.modal.accommodationsNeeded}
                            </span>
                            <FilterCheckboxGroup
                              propName={Text.features.roster.modal.accommodationsNeeded}
                              options={accommodationOptions}
                              setFilterState={handleAccommodationsChange}
                            />
                          </>
                        )}
                        {accommsConfig.options === 'ncrMaterials' && !!ncrOptions.length && (
                          <DropDown
                            label={Text.features.roster.modal.materialsRequired}
                            options={ncrOptions}
                            value={formData.ncrValue || ''}
                            placeholder={
                              formData.ncrValue?.label || Text.features.roster.modal.selectMaterials
                            }
                            onChange={handleDropDownChange('ncrValue')}
                            error={formData.errors.ncrValue}
                            disabled={false}
                            searchable={false}
                          />
                        )}
                      </>
                    )}
                  </>
                )}
                {accommsConfig.taaPin && formData.accommodation === 'T' && (
                  <div className="editStudent__taaPin">
                    <Input
                      disabled={formData.accommOptOut}
                      label={Text.features.roster.modal.details.taaPin}
                      error={formData.errors.taaPin}
                      onChange={handleInputChange}
                      name={'taaPin'}
                      value={formData.taaPin || ''}
                      maxLength="8"
                    />
                    <label className="ml-2">
                      <Checkbox
                        checked={formData.accommOptOut}
                        handleChange={handleCheckboxChange('accommOptOut')}
                      />
                      {Text.features.roster.modal.details.accommOptOut}
                    </label>
                  </div>
                )}
              </div>
            )}
          </div>,
          <Input
            label={Text.features.roster.modal.details.reportingHighSchoolCode}
            error={formData.errors.reportingHighSchoolCode}
            onChange={handleInputChange}
            name={'reportingHighSchoolCode'}
            value={formData.reportingHighSchoolCode || ''}
            maxLength="6"
          />,
        ]}
        buttonText={Text.features.roster.modal.saveButton}
        buttonAction={handleValidation}
        secondaryButton={{
          text: Text.clickables.buttons.cancel,
          redButton: false,
          action: openCancelConfirmationModal,
        }}
        close={e => {
          resetFormData();
          closeModal(e);
        }}
        showButton={true}
      />
      <AlertModal
        className="ConfirmationModal__Container"
        customStyles={{ overlay: { zIndex: '1000' } }}
        show={showCancelConfirmationModal}
        buttonAction={closeCancelConfirmationModal(true)}
        close={closeCancelConfirmationModal(false)}
        title=""
        bodyTitle="Are you sure you want to cancel all the changes?"
        showButton={true}
        buttonText="Cancel changes"
        secondaryButton={{
          text: 'Back',
          action: closeCancelConfirmationModal(false),
          redButton: false,
        }}
      />
      <AlertModal
        className="ConfirmationModal__Container"
        customStyles={{ overlay: { zIndex: '1000' } }}
        show={showSaveWithoutAccommsModal}
        buttonAction={closeSaveWithoutAccommsModal(true)}
        close={closeSaveWithoutAccommsModal(false)}
        title=""
        bodyTitle="Do you want to save without accommodations?"
        showButton={true}
        buttonText="Yes"
        secondaryButton={{
          text: 'Go Back',
          action: closeSaveWithoutAccommsModal(false),
          redButton: false,
        }}
      />
    </>
  );
};

export default EditStudentsModal;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions as rosterActions } from '../duck';
import { Tabs } from '../../../components/index';
import RosterManagement from '../students-management/StudentsManagement';
import RegistrationStudentsTransfer from '../registration-students-transfer/RegistrationStudentsTransfer';
import _ from 'lodash/fp';
import './RegistrationStudentsRouter.scss';

let tabs = [
  {
    title: 'Students roster',
    url: 'studentstable',
    avail: true,
    component: RosterManagement,
  },
  {
    title: 'Student transfer',
    url: 'students-transfer',
    avail: true,
    component: RegistrationStudentsTransfer,
    pending: false,
  },
];

class RegistrationStudentsRouter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '',
      TabComponent: null,
      tabs,
    };
  }

  componentDidMount() {
    this.props.actions.getReceivedTransferRequests({
      orgPartId: this.props.match.params.orgPartId,
    });

    const url = this.props.match.url;
    for (let i = 0; i < this.state.tabs.length; i++) {
      if (url.includes(this.state.tabs[i].url)) {
        const CurrentComponent = this.state.tabs[i].component;
        this.setState({
          activeTab: this.state.tabs[i].url,
          TabComponent: CurrentComponent,
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.match)(this.props.match)) {
      const url = this.props.match.url;
      for (let i = 0; i < this.state.tabs.length; i++) {
        if (url.includes(this.state.tabs[i].url)) {
          const CurrentComponent = this.state.tabs[i].component;
          this.setState({
            activeTab: this.state.tabs[i].url,
            TabComponent: CurrentComponent,
          });
        }
      }
    }

    if (
      _.get('data')(this.props.receivedTransferRequests) &&
      !_.isEqual(prevProps.receivedTransferRequests)(this.props.receivedTransferRequests)
    ) {
      const cloneTabs = [].concat(this.state.tabs);
      if (
        _.get('data')(this.props.receivedTransferRequests) &&
        this.props.receivedTransferRequests.data.length > 0
      ) {
        cloneTabs[1].pending = true;
      } else {
        cloneTabs[1].pending = false;
      }
      this.setState({
        tabs: cloneTabs,
      });
    }
  }

  handleTabChange = tab => {
    this.setState({
      activeTab: tab,
    });
  };

  render() {
    const { activeTab, tabs, TabComponent } = this.state;
    const { currentOrg, hasFilePermissions } = this.props;
    return (
      <div className="RegistrationStudentsRouter__Container">
        {currentOrg && !currentOrg.allowChildOrgsFlag && hasFilePermissions && (
          <Tabs
            activeTab={activeTab}
            basePath="/students"
            currentOrgPartId={this.props.match.params.orgPartId}
            handleTabChange={this.handleTabChange}
            tabs={tabs}
            {...this.props}
          />
        )}
        {TabComponent ? <TabComponent {...this.props} currentOrg={currentOrg} /> : null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { rosterReducer } = state;
  return {
    receivedTransferRequests: rosterReducer.receivedTransferRequests,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ ...rosterActions }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationStudentsRouter);
export { RegistrationStudentsRouter };

import * as types from './types';

const initialState = {
  contractInfo: null,
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.GET_CONTRACT_ADMIN_DETAIL:
      return {
        ...state,
        contractAdminInfo: action.payload,
      };
    case types.GET_CONTRACT_INFO:
      return {
        ...state,
        contractInfo: action.payload,
      };
    case types.GET_PROGRAM_ADMIN_STATIC_INFO:
      return {
        ...state,
        programAdminStaticInfo: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;

import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { MenuItem, Menu, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';

import TextContext from '../../../core/providers/TextProvider';

import { ReactComponent as PlusIcon } from '../../../assets/svgs/plusSmall.svg';
import { ReactComponent as TitleDropdownArrow } from '../../../assets/svgs/blueDropdownArrow.svg';

import { BORDER_GRAY } from '../../../styles/base/_settings.scss';
import './Buttons.scss';

const StyledMenu = withStyles({
  paper: {
    border: `0.1rem solid ${BORDER_GRAY}`,
    boxShadow: `0 0 0.5rem ${BORDER_GRAY}`,
  },
})(props => (
  <Menu
    anchorOrigin={{
      horizontal: 'right',
      vertical: 'bottom',
    }}
    elevation={0}
    getContentAnchorEl={null}
    transformOrigin={{
      horizontal: 'right',
      vertical: 'top',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    fontSize: '1.3rem',
  },
}))(MenuItem);

const Buttons = ({
  approveHandle,
  cancelHandle,
  declineHandle,
  getInviteData,
  hasEndDate,
  hasReceivedRequestTransfer,
  hasSentRequestTransfer,
  inviteButton,
  isAdministration,
  isExpanded,
  item,
  linkButtons,
  parentStatus,
  serverDateTimeValue,
}) => {
  /**
   * useContext()
   */
  const Text = useContext(TextContext);

  /**
   * useState()
   */
  const [anchorEl, setAnchorEl] = useState(null);

  /**
   * useMemo()
   */
  const currentDate = useMemo(
    () => serverDateTimeValue || moment(new Date()).format('MM.DD.YYYY'),
    [serverDateTimeValue]
  );

  const endDate = useMemo(() => moment(item.orgpartaddenddate).format('MM.DD.YYYY'), [
    item.orgpartaddenddate,
  ]);

  const isDeadlineExpired = useMemo(
    () => moment(currentDate, 'MM.DD.YYYY').isAfter(moment(endDate, 'MM.DD.YYYY')),
    [currentDate, endDate]
  );

  /**
   * useCallback()
   */
  const handleClick = useCallback(
    e => {
      if (anchorEl === e.currentTarget) return;
      setAnchorEl(e.currentTarget);
    },
    [anchorEl]
  );

  const handleClose = useCallback(e => {
    if (e.keyCode === 9) return;
    setAnchorEl(null);
  }, []);

  const inviteAction = useCallback(
    data => {
      inviteButton.action(data).then(() => getInviteData());
    },
    [getInviteData, inviteButton]
  );

  const onKeyDown = useCallback(
    e => {
      if (e.keyCode !== 13) return;
      handleClick(e);
    },
    [handleClick]
  );

  /**
   * render
   */
  if (item.orgtype === 'school' && parentStatus !== 'active' && isAdministration) {
    return null;
  }

  if (hasReceivedRequestTransfer && declineHandle && approveHandle) {
    return (
      <div className="link icon">
        <div className="two-section-button">
          <div
            className="button-first-section"
            onClick={() => approveHandle(item)}
            tabIndex={isExpanded ? '0' : '-1'}>
            Approve
          </div>
          <div
            className="button-second-section"
            onClick={e => handleClick(e)}
            onKeyDown={e => onKeyDown(e)}
            tabIndex={isExpanded ? '0' : '-1'}>
            <TitleDropdownArrow className="last-icon" />
          </div>
        </div>

        <StyledMenu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={e => handleClose(e)}
          disableScrollLock={true}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}>
          <StyledMenuItem>
            <div
              onClick={e => {
                approveHandle(item);
                handleClose(e);
              }}>
              <span className="link" style={{ marginLeft: '0.5rem' }}>
                {Text.features.roster.studentTransfer.approve}
              </span>
            </div>
          </StyledMenuItem>
          <StyledMenuItem>
            <div
              onClick={e => {
                declineHandle(item);
                handleClose(e);
              }}>
              <span className="link" style={{ marginLeft: '0.5rem' }}>
                {Text.features.roster.studentTransfer.decline}
              </span>
            </div>
          </StyledMenuItem>
        </StyledMenu>
      </div>
    );
  }

  if (hasSentRequestTransfer && !hasReceivedRequestTransfer) {
    return (
      <Button onClick={() => cancelHandle(item)} className="blue-rounded-button">
        Cancel
      </Button>
    );
  }

  if (inviteButton?.action && item.status === 'notInvited' && (!hasEndDate || !isDeadlineExpired)) {
    return (
      <div className="table__Cell table__Buttons">
        <div className="table__Buttons__content">
          <button
            className="linkButton blue-rounded-button admin-button"
            onClick={() => inviteAction(item)}>
            <PlusIcon />
            {Text.features.organization.buttons.addToAdmin}
          </button>
        </div>
      </div>
    );
  }

  if (
    item.status === 'pending' &&
    !item.allowChildOrgsFlag &&
    (!hasEndDate || !isDeadlineExpired)
  ) {
    return (
      <Link to={`/administration/participation/orgPartId/${item.orgPartId}`}>
        <div className=" linkButton blue-rounded-button admin-button">
          {Text.features.organization.buttons.confirm}
        </div>
      </Link>
    );
  }

  if (item.allowChildOrgsFlag) {
    if (!hasEndDate || !isDeadlineExpired) {
      if (linkButtons?.district?.length > 1) {
        if (!item.orgPartId) {
          return (
            <button
              className="linkButton blue-rounded-button admin-button"
              onClick={() => inviteAction(item)}>
              <PlusIcon />
              {Text.features.organization.buttons.addToAdmin}
            </button>
          );
        }

        if (item.status !== 'active') {
          const filteredButton = linkButtons.district.find(
            button => button.text !== Text.features.organization.buttons.seeOrgs
          );

          const Svg = filteredButton.svg;
          return (
            <Link to={`${filteredButton.basePath}/orgPartId/${item.orgPartId}`}>
              <div className="linkButton blue-rounded-button">
                {Svg && <Svg />}
                {filteredButton.text}
              </div>
            </Link>
          );
        }

        const Svg = linkButtons.district[0].svg;
        const path = linkButtons.district[0].basePath;
        return (
          <>
            <div className="two-section-button">
              <Link to={`${path}/orgPartId/${item.orgPartId}`} className="button-first-section">
                {Svg && <Svg />} {linkButtons.district[0].text}
              </Link>
              <div
                className="button-second-section"
                onClick={handleClick}
                onKeyDown={e => onKeyDown(e)}
                tabIndex="0">
                <TitleDropdownArrow className="last-icon" />
              </div>
            </div>
            <StyledMenu
              id="customized-menu"
              anchorEl={anchorEl}
              keepMounted
              disableScrollLock={true}
              className="organization-dropdown"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={e => handleClose(e)}>
              {linkButtons.district.map((button, index) => {
                const SvgMap = button.svg;
                return (
                  <StyledMenuItem key={button.text}>
                    <Link to={`${button.basePath}/orgPartId/${item.orgPartId}`}>
                      <div className="linkButton">
                        {SvgMap && <SvgMap />}
                        {button.text}
                      </div>
                    </Link>
                  </StyledMenuItem>
                );
              })}
            </StyledMenu>
          </>
        );
      } else if (linkButtons) {
        const Svg = linkButtons.district[0] ? linkButtons.district[0].svg : null;
        return (
          <>
            <Link to={`${linkButtons.district[0].basePath}/orgPartId/${item.orgPartId}`}>
              <div className="linkButton blue-rounded-button admin-button">
                {Svg && <Svg />}
                {linkButtons.district[0].text}
              </div>
            </Link>
          </>
        );
      }
    } else {
      if (['active', 'inactive'].includes(item.status)) {
        const Svg = linkButtons.district[0].svg;
        const path = linkButtons.district[0].basePath;
        return (
          <>
            <div className="two-section-button">
              <Link to={`${path}/orgPartId/${item.orgPartId}`} className="button-first-section">
                {Svg && <Svg />} {linkButtons.district[0].text}
              </Link>
              <div
                className="button-second-section"
                onClick={handleClick}
                onKeyDown={e => onKeyDown(e)}
                tabIndex="0">
                <TitleDropdownArrow className="last-icon" />
              </div>
            </div>
            <StyledMenu
              id="customized-menu"
              anchorEl={anchorEl}
              keepMounted
              disableScrollLock={true}
              className="organization-dropdown"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={e => handleClose(e)}>
              {linkButtons.district.map(button => {
                const SvgMap = button.svg;
                return (
                  <StyledMenuItem key={button.text}>
                    <Link to={`${button.basePath}/orgPartId/${item.orgPartId}`}>
                      <div className="linkButton">
                        {SvgMap && <SvgMap />}
                        {button.text}
                      </div>
                    </Link>
                  </StyledMenuItem>
                );
              })}
            </StyledMenu>
          </>
        );
      } else {
        const Svg = linkButtons.district[0] ? linkButtons.district[0].svg : null;
        return (
          <>
            <Link to={`${linkButtons.district[0].basePath}/orgPartId/${item.orgPartId}`}>
              <div className="linkButton blue-rounded-button admin-button">
                {Svg && <Svg />}
                {linkButtons.district[0].text}
              </div>
            </Link>
          </>
        );
      }
    }
  }
  if (
    item.orgtype !== 'district' &&
    linkButtons?.school &&
    (!hasEndDate || !isDeadlineExpired || ['active', 'inactive'].includes(item.status))
  ) {
    return linkButtons.school.map((button, index) => {
      const Svg = button.svg;
      return (
        <React.Fragment key={button.text}>
          <Link to={`${button.basePath}/orgPartId/${item.orgPartId}`}>
            <div className=" linkButton blue-rounded-button admin-button">
              {Svg && <Svg />}
              {button.text}
            </div>
          </Link>
        </React.Fragment>
      );
    });
  }

  if (
    item.allowChildOrgsFlag &&
    item.orgtype === 'school' &&
    item.orgPartStatus === 'active' &&
    (!hasEndDate || !isDeadlineExpired || ['active', 'inactive'].includes(item.status))
  ) {
    return (
      <div className={'table__Cell table__Buttons orgTable__notExtended'}>
        <div className="table__Buttons__content">
          <Link to={`/students/organization/orgPartId/${item.orgPartId}`}>
            <button className="blue-rounded-button" tabIndex="-1">
              {Text.features.organization.buttons.orgs}
            </button>
          </Link>
        </div>
      </div>
    );
  }

  if (
    item.allowChildOrgsFlag &&
    (!hasEndDate || !isDeadlineExpired || ['active', 'inactive'].includes(item.status))
  ) {
    return (
      <div className={'table__Cell table__Buttons orgTable__notExtended'}>
        <div className="table__Buttons__content">
          <Link to={`/students/organization/orgPartId/${item.orgPartId}`}>
            <button className="blue-rounded-button" tabIndex="-1">
              {Text.features.organization.buttons.orgs}
            </button>
          </Link>
        </div>
      </div>
    );
  }

  if (!item.allowChildOrgsFlag && item.orgPartStatus === 'active') {
    return (
      <>
        <div className="two-section-button">
          <Link
            to={`/students/studentstable/orgPartId/${item.orgPartId}`}
            className="button-first-section">
            {Text.features.organization.buttons.stud}
          </Link>
          <div
            className="button-second-section"
            onClick={handleClick}
            onKeyDown={e => onKeyDown(e)}
            tabIndex="0">
            <TitleDropdownArrow className="last-icon" />
          </div>
        </div>
        <StyledMenu
          id="customized-menu"
          className="students-dropdown"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={e => handleClose(e)}
          disableScrollLock={true}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}>
          <StyledMenuItem>
            <Link to={`/students/studentstable/orgPartId/${item.orgPartId}`}>
              <span className="link">{Text.features.organization.buttons.stud}</span>
            </Link>
          </StyledMenuItem>
          <StyledMenuItem>
            <Link to={`/students/overview/orgPartId/${item.orgPartId}`}>
              <span className="link">{Text.features.organization.buttons.overview}</span>
            </Link>
          </StyledMenuItem>
        </StyledMenu>
      </>
    );
  }
  return <div></div>;
};

export default Buttons;

import './OverviewTile.scss';
import propTypes from 'prop-types';
import React from 'react';
import Chart from 'react-google-charts';
import _ from 'lodash/fp';

const OverviewTile = props => {
  const { title, data, chartUnits, id } = props;
  const options = {
    title: title,
    pieHole: 0.6,
    is3D: false,
    hAxis: {
      title: 'Date',
    },
    legend: {
      position: 'left',
      labeledValueText: 'both',
      alignment: 'start',
      textStyle: { fontName: 'Montserrat', fontSize: 14, bold: false },
    },
    backgroundColor: { fill: 'transparent' },
    titleTextStyle: {
      fontName: 'Montserrat',
      fontSize: 16,
      bold: true,
      italic: false,
    },
    colors: [
      '#DD4477',
      '#16D620',
      '#FFD500',
      '#0099C6',
      '#DC3912',
      '#990099',
      '#329262',
      '#FF9900',
      '#AAAA11',
      '#22AA99',
      '#8B0707',
      '#5574A6',
      '#994499',
      '#E67300',
      '#5574A6',
      '#3366CC',
      '#109618',
    ],
    fontName: 'Montserrat',
    fontSize: 14,
    tooltip: {
      textStyle: {
        color: 'black',
      },
      isHtml: true,
    },
    pieSliceTextStyle: {
      color: '#FAFAFA',
      fontSize: 14,
      bold: true,
      position: 'start',
    },
    chartArea: { width: '82%', height: '76%' },
    focusTarget: 'Category',
  };

  let transformedData = [];
  let totalNrOfStudents = _.sumBy(entry => parseInt(entry.value))(data);

  if (data) {
    data.map(entry => {
      let value = parseInt(entry.value);
      transformedData.push([
        `${entry.label} (${value})`,
        value,
        `${entry.label}: ${parseFloat(((100 * value) / totalNrOfStudents).toFixed(1))}% (${
          entry.value
        } ${chartUnits})`,
      ]);
      return true;
    });
  }

  return (
    <div className="OverviewTile__Container">
      <Chart
        chartType="PieChart"
        width="106%"
        height="100%"
        rows={transformedData}
        columns={[
          {
            type: 'string',
            label: 'Category',
          },
          {
            type: 'number',
            label: 'Number of students',
          },
          {
            type: 'string',
            role: 'tooltip',
            label: 'tooltip',
          },
        ]}
        options={options}
        graphID={id}
      />
    </div>
  );
};

OverviewTile.propTypes = {
  title: propTypes.string,
  chartUnits: propTypes.string,
  data: propTypes.array,
  id: propTypes.string,
};

OverviewTile.defaultProps = {
  chartUnits: 'students',
};

export default OverviewTile;

const feat = 'notifications';

const GET_NOTIFICATIONS = `${feat}/GET_NOTIFICATIONS`;
const GET_NOTIFICATIONS_BY_STATUS = `${feat}/GET_NOTIFICATIONS_BY_STATUS`;
const READ_NOTIFICATION = `${feat}/READ_NOTIFICATION`;
const GET_NOT_VIEWED_NOTIFICATIONS_COUNT = `${feat}/GET_NOT_VIEWED_NOTIFICATIONS_COUNT`;
const GET_VIEWED_NOTIFICATIONS_COUNT = `${feat}/GET_VIEWED_NOTIFICATIONS_COUNT`;

export {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_BY_STATUS,
  READ_NOTIFICATION,
  GET_NOT_VIEWED_NOTIFICATIONS_COUNT,
  GET_VIEWED_NOTIFICATIONS_COUNT,
};

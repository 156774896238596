import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import '../styles/styles.scss';

import Auth from './services/AuthToken';
import NotFoundPage from '../features/not-found/NotFoundPage';
import LandingPage from '../features/landing/LandingPage';
import { LoginPage, ResetPassword } from '../features/login';
import ScheduleOfEvents from '../features/schedule-of-events/ScheduleOfEvents';
import { RegisterPage } from '../features/register';
import { PersonalInformation as PersonalInformationPage } from '../features/personal-info';
import { Notifications as NotificationsPage } from '../features/notifications';
import StudentsRouter from '../features/students/students-router/StudentsRouter';
import { OrgManagementRouter } from '../features/administration';
import { ContractRouter } from '../features/contract/index';
import TestManagementRouter from '../features/test-management/test-management-router/TestManagementRouter';
import PDFRender from '../features/pdf/pdf';
import NoInternet from '../components/noInternet/NoInternet';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      Auth.authToken ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
          }}
        />
      )
    }
  />
);

const AppRouter = () => (
  <div className="page__Container">
    <BrowserRouter>
      <div className="appRouter">
        <Switch>
          <Route path="/login" exact component={LoginPage} />
          <Route path="/register/:step?" component={RegisterPage} />
          <Route path="/forgot-password" component={ResetPassword} />
          <PrivateRoute path="/personalInformation" exact component={PersonalInformationPage} />
          <PrivateRoute path="/pdfs/:pdf" exact component={PDFRender} />
          <PrivateRoute
            path="/contract/files/orgPartId/:orgPartId"
            component={ContractRouter}
            exact
          />
          <PrivateRoute
            path="/contract/organization/orgPartId/:orgPartId"
            component={ContractRouter}
            exact
          />
          <PrivateRoute path="/schedule/orgPartId/:orgPartId" component={ScheduleOfEvents} exact />
          <PrivateRoute
            path="/administration/organization/orgPartId/:orgPartId"
            component={OrgManagementRouter}
            exact
          />
          <PrivateRoute
            path="/administration/participation/orgPartId/:orgPartId"
            exact
            component={OrgManagementRouter}
          />
          <PrivateRoute
            path="/administration/participation/orgPartId/:orgPartId"
            exact
            component={OrgManagementRouter}
          />
          <PrivateRoute
            path="/administration/overview/orgPartId/:orgPartId"
            exact
            component={OrgManagementRouter}
          />
          <PrivateRoute
            path="/administration/users/orgPartId/:orgPartId"
            exact
            component={OrgManagementRouter}
          />
          <PrivateRoute
            path="/administration/files/orgPartId/:orgPartId"
            exact
            component={OrgManagementRouter}
          />
          <PrivateRoute
            path="/administration/reports/orgPartId/:orgPartId"
            exact
            component={OrgManagementRouter}
          />
          <PrivateRoute
            path="/administration/files/participate/orgPartId/:orgPartId"
            exact
            component={OrgManagementRouter}
          />
          <PrivateRoute
            path="/administration/files/staffinvite/orgPartId/:orgPartId"
            exact
            component={OrgManagementRouter}
          />
          <PrivateRoute
            path="/personalInformation/orgPartId/:orgPartId"
            exact
            component={PersonalInformationPage}
          />
          <PrivateRoute path="/" exact component={LandingPage} />
          <PrivateRoute path="/landingPage" exact component={LandingPage} />
          <PrivateRoute path="/landingPage/orgPartId/:orgPartId" exact component={LandingPage} />
          <PrivateRoute
            path={'/students/organization/orgPartId/:orgPartId'}
            exact
            component={StudentsRouter}
          />
          <PrivateRoute
            path={'/students/overview/orgPartId/:orgPartId'}
            exact
            component={StudentsRouter}
          />
          <PrivateRoute
            path={'/students/studentstable/orgPartId/:orgPartId'}
            exact
            component={StudentsRouter}
          />
          <PrivateRoute
            path={'/students/students-transfer/orgPartId/:orgPartId'}
            exact
            component={StudentsRouter}
          />
          <PrivateRoute
            path={'/students/files/orgPartId/:orgPartId'}
            exact
            component={StudentsRouter}
          />
          <PrivateRoute
            path={'/students/reports/orgPartId/:orgPartId'}
            exact
            component={StudentsRouter}
          />
          <PrivateRoute
            path={'/students/operationalreports/orgPartId/:orgPartId'}
            exact
            component={StudentsRouter}
          />
          <PrivateRoute
            path={'/testevents/organization/orgPartId/:orgPartId'}
            exact
            component={TestManagementRouter}
          />
          <PrivateRoute
            path={'/testevents/reports/orgPartId/:orgPartId'}
            exact
            component={TestManagementRouter}
          />
          <PrivateRoute
            path={'/testevents/shipments-tracking/orgPartId/:orgPartId'}
            exact
            component={TestManagementRouter}
          />
          <PrivateRoute
            path={'/testevents/test-events/orgPartId/:orgPartId'}
            exact
            component={TestManagementRouter}
          />
          <PrivateRoute
            path="/notifications/orgPartId/:orgPartId"
            exact
            component={NotificationsPage}
          />
          <Route component={NotFoundPage} />
        </Switch>
      </div>
    </BrowserRouter>
    <NoInternet />
  </div>
);

export default AppRouter;

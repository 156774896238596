import React from 'react';
import propTypes from 'prop-types';

import './InfoBox.scss';

const InfoBox = ({ items, title }) => {
  /**
   * render
   */
  return (
    <div className="InfoBox__Container">
      <div className="InfoBox__Title">{title}</div>
      <div className="InfoBox__ItemsContainer">
        {items.map((item, index) => {
          return (
            <div key={index} className={`InfoBox__Item ${index === 0 ? '' : 'border'}`}>
              <div className="InfoBox__Description">{item.key}</div>
              <div className="InfoBox__Value">{item.value}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

InfoBox.displayName = 'InfoBox';

InfoBox.propTypes = {
  items: propTypes.array.isRequired,
  title: propTypes.string.isRequired,
};

export default InfoBox;

import './FileTile.scss';
import React from 'react';
import Calendar from '../../../assets/svgs/calendar.svg';
import Profile from '../../../assets/svgs/profile-grey.svg';
import List from '../../../assets/svgs/list.svg';
import { ReactComponent as Download } from '../../../assets/svgs/download.svg';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import WarningIcon from '@material-ui/icons/ReportProblemOutlined';
import SuccessIcon from '@material-ui/icons/CheckCircleOutline';
import { features } from '../../../core/i18n/text';
import moment from 'moment';

const urlTypes = {
  downloadFile: 'sourceFileURL',
  downloadFileWithErrors: 'errorRecordURL',
  downloadErrors: 'errorMessageURL',
};

const generateLabel = status => {
  switch (status) {
    case 'complete': {
      return (
        <div className="FileTile__Status complete">
          <SuccessIcon />
          {features.fileUpload.fileTiles.labels.complete}
        </div>
      );
    }
    case 'complete_with_errors': {
      return (
        <div className="FileTile__Status error">
          <WarningIcon />
          {features.fileUpload.fileTiles.labels.completeErrors}
        </div>
      );
    }
    case 'failed': {
      return (
        <div className="FileTile__Status fail">
          <ErrorIcon />
          {features.fileUpload.fileTiles.labels.fail}
        </div>
      );
    }
    case 'stopped': {
      return (
        <div className="FileTile__Status fail">
          <ErrorIcon />
          {features.fileUpload.fileTiles.labels.fail}
        </div>
      );
    }
    default:
      return;
  }
};

const generateRecords = (status, records, errors, percentage, refresh, statusMessage) => {
  switch (status) {
    case 'complete': {
      return (
        <div className="FileTile__Details">
          <span>
            {features.fileUpload.fileTiles.general.records} <strong>{records}</strong>
          </span>
        </div>
      );
    }
    case 'complete_with_errors': {
      return (
        <div className="FileTile__Details CompleteWithErrrsContainer">
          <div className="FileTile__RecordsWrapper">
            <div>
              {features.fileUpload.fileTiles.general.records} <strong>{records - errors}</strong>
            </div>
            <div className="FileTile__Error">
              {features.fileUpload.fileTiles.general.errors} {errors}
            </div>
          </div>
          {statusMessage && <div className="FileTile__StatusMessage">{statusMessage}</div>}
        </div>
      );
    }
    case 'failed': {
      return <div className="FileTile__Details FileTile__StatusMessage">{statusMessage}</div>;
    }
    case 'stopped': {
      return <div className="FileTile__Details FileTile__StatusMessage">{statusMessage}</div>;
    }
    default:
      return;
  }
};

const generateButtons = (status, buttonsAction) => {
  switch (status) {
    case 'complete': {
      return (
        <div className="FileTile__ButtonContainer">
          <div>
            <button
              className="linkButton FileTile__Button_Link"
              onClick={buttonsAction(urlTypes.downloadFile)}>
              <Download />
              {features.fileUpload.fileTiles.buttons.downloadFile}
            </button>
          </div>
        </div>
      );
    }
    case 'complete_with_errors': {
      return (
        <div className="FileTile__ButtonContainer">
          <div className="DownloadFile__ButtonContainer">
            <button
              className="linkButton FileTile__Button_Link"
              onClick={buttonsAction(urlTypes.downloadFile)}>
              {features.fileUpload.fileTiles.buttons.downloadFile}
            </button>
          </div>
          <div>
            <button
              className="openButton FileTile__Button"
              onClick={buttonsAction(urlTypes.downloadFileWithErrors)}>
              {features.fileUpload.fileTiles.buttons.downloadFileErrors}
            </button>
            <button
              type="primary"
              className="bigButton FileTile__Button"
              onClick={buttonsAction(urlTypes.downloadErrors)}>
              {features.fileUpload.fileTiles.buttons.downloadError}
            </button>
          </div>
        </div>
      );
    }
    case 'failed': {
      return (
        <div className="FileTile__ButtonContainer_Failed">
          <div className="FileTile__ButtonContainer">
            <button
              className="linkButton FileTile__Button_Link"
              onClick={buttonsAction(urlTypes.downloadFile)}>
              <Download />
              {features.fileUpload.fileTiles.buttons.downloadFile}
            </button>
          </div>
        </div>
      );
    }
    default:
      return;
  }
};

const FileTile = props => {
  const {
    id,
    fileName,
    uploadDate,
    uploadUser,
    status,
    errors,
    records,
    percentage,
    statusMessage,
    refresh,
    buttonsAction,
  } = props;

  const onKeyDown = e => {
    if (e.keyCode === 13) {
      refresh();
    }
  };

  return status === 'processing' || status === 'pending' ? (
    <div>
      <div id={id} className="FileTile__Container">
        <div className="FileTile__Row">
          <div className="FileTile__Title">{fileName}</div>

          <div className="FileTile__DetailsContainer_Processing">
            <div className="FileTile__Details">
              {features.fileUpload.fileTiles.general.uploaded}{' '}
              {moment(new Date(uploadDate).getTime()).format('MM.DD.YYYY')}
            </div>
            <img src={Calendar} alt="Calendar" />
          </div>
        </div>
        <div className="FileTile__Row">
          <div className="FileTile__Details">{features.fileUpload.fileTiles.labels.pending}</div>
          <div className="FileTile__DetailsContainer_Processing">
            <div className="FileTile__Details">{uploadUser}</div>
            <img src={Profile} alt="Profile" />
          </div>
        </div>
        <div className="FileTile__Records">
          <div className="FileTile__Progress">
            {`${percentage}% ${features.fileUpload.fileTiles.general.done} - `}
            <span className="link" onClick={refresh} onKeyDown={onKeyDown} tabIndex="0">
              {features.fileUpload.fileTiles.general.refresh}
            </span>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div id={id} className="FileTile__Container">
      <div className="FileTile__Row">
        <div className="FileTile__Title">{fileName}</div>
        {generateLabel(status)}
      </div>
      <div className="FileTile__Row">
        <div className="FileTile__DetailsContainer">
          <div className="FileTile__Details">
            <div className="FileTile__Details_Image">
              <img src={Calendar} alt="Calendar" />
            </div>
            <div>
              <span className="FileTile__LightText">
                {features.fileUpload.fileTiles.general.processed}
              </span>{' '}
              <strong>{moment(new Date(uploadDate).getTime()).format('MM/DD/YYYY')} </strong>
              <span className="FileTile__LightText">
                {features.fileUpload.fileTiles.general.at}
              </span>{' '}
              <strong>{moment(new Date(uploadDate).getTime()).format('hh:mm A')}</strong>
            </div>
          </div>
          <div className="FileTile__Details">
            <div className="FileTile__Details_Image">
              <img src={Profile} alt="Profile" />
            </div>
            <span>
              <span className="FileTile__LightText">
                {features.fileUpload.fileTiles.general.by}
              </span>
              <span>
                <strong> {uploadUser}</strong>
              </span>
            </span>
          </div>
          {status !== 'stopped' && (
            <div className="FileTile__Details">
              <div className="FileTile__Details_Image">
                <img src={List} alt="List" />
              </div>
              <span>
                <span className="FileTile__LightText">{records || 0}</span>
                <span>
                  <strong> {features.fileUpload.fileTiles.general.rows}</strong>
                </span>
              </span>
            </div>
          )}
        </div>
        <div className="FileTile__DetailsContainer"></div>
        <div className="FileTile__Records">
          {generateRecords(status, records, errors, percentage, refresh, statusMessage)}
        </div>
      </div>
      {generateButtons(status, buttonsAction)}
    </div>
  );
};

export default FileTile;

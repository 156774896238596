import React from 'react';
import propTypes from 'prop-types';
import './FilterCheckboxGroup.scss';

const FilterCheckboxGroup = ({ title, children, error }) => {
  return (
    <div className="roster__Filter__Section">
      <div className={`roster__Filter__Title ${error ? 'error' : ''}`} aria-label={title}>
        {title}
      </div>
      {children}
    </div>
  );
};

FilterCheckboxGroup.propTypes = {
  title: propTypes.string,
  error: propTypes.string,
};

FilterCheckboxGroup.defaultProps = {};

export default FilterCheckboxGroup;

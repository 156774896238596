import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions as rosterActions } from '../duck';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import SimpleBox from '../../../components/box/SimpleBox';
import * as text from '../../../core/i18n/text';
import './RegistrationStudentsTransfer.scss';
import RequestTransferModal from './RequestTransferModal';
import ApproveDeclineTransferRequestModal from './ApproveDeclineTransferRequestModal';
import Notification from '../../../components/alerts/Notification';
import AlertModal from '../../../components/modal/AlertModal';
import CustomReactTable from '../../../components/table/Table';
import Buttons from '../../../components/data-panel/buttons/Buttons';
import _ from 'lodash/fp';

let closeNotificationTimer;

const nonStikyColumns = [
  {
    Header: 'Requesting school',
    name: 'Requesting school',
    accessor: 'currentSchoolName',
    fields: ['currentSchoolName'],
    width: 250,
  },
  {
    Header: 'District',
    name: 'District',
    accessor: 'currentDistrictName',
    fields: ['currentDistrictName'],
    width: 250,
  },
  {
    Header: 'Request date',
    name: 'Request date',
    accessor: 'submitDt',
    fields: ['submitDt'],
    type: 'number',
    width: 250,
  },
  {
    Header: 'State Student ID',
    name: 'State Student ID',
    accessor: 'externalStudentId',
    fields: ['externalStudentId'],
    type: 'number',
    width: 200,
  },
  {
    Header: 'Notes',
    name: 'Notes',
    accessor: 'notes',
    fields: ['notes'],
    width: 200,
  },
];

class RegistrationStudentsTransfer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nonStikyColumns: nonStikyColumns,
      showTransferModal: false,
      displayNotification: false,
      receivedTransferRequests: [],
      sentTransferRequests: [],
      showReceivedTransferRequests: false,
      showSentTransferRequests: false,
      showConfirmCancelModal: false,
      showDeclineModal: false,
      requestResponse: {
        request: null,
        approve: null,
      },
      requestToBeCancelled: null,
      notificationText: '',
    };
    this.refreshReceivedList = this.refreshReceivedList.bind(this);
    this.handleNotificationClose = this.handleNotificationClose.bind(this);
    this.handleHideShowSentRequests = this.handleHideShowSentRequests.bind(this);
    this.handleHideShowReceivedRequests = this.handleHideShowReceivedRequests.bind(this);
    this.refreshSentList = this.refreshSentList.bind(this);
    this.handleCancelRequest = this.handleCancelRequest.bind(this);
  }

  componentDidMount() {
    this.props.actions.getSentTransferRequests({
      orgPartId: this.props.match.params.orgPartId,
    });
    this.props.actions.getReceivedTransferRequests({
      orgPartId: this.props.match.params.orgPartId,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentOrgPartId !== this.props.currentOrgPartId) {
      this.props.actions.getSentTransferRequests({
        orgPartId: this.props.match.params.orgPartId,
      });
      this.props.actions.getReceivedTransferRequests({
        orgPartId: this.props.match.params.orgPartId,
      });
    }
    if (
      !_.isEqual(prevProps.receivedTransferRequests)(this.props.receivedTransferRequests) &&
      _.get('receivedTransferRequests.data')(this.props)
    ) {
      this.setState({
        receivedTransferRequests: this.props.receivedTransferRequests.data,
      });
    }
    if (
      !_.isEqual(prevProps.sentTransferRequests)(this.props.sentTransferRequests) &&
      _.get('sentTransferRequests.data')(this.props)
    ) {
      this.setState({
        sentTransferRequests: this.props.sentTransferRequests.data,
      });
    }
  }

  refreshReceivedList() {
    this.props.actions.getReceivedTransferRequests({
      orgPartId: this.props.match.params.orgPartId,
    });
  }

  refreshSentList() {
    this.props.actions.getSentTransferRequests({
      orgPartId: this.props.match.params.orgPartId,
    });
  }

  handleTransferModalState = state => {
    this.setState({ showTransferModal: state });
    if (state === false) {
      this.refreshSentList();
      this.setState({
        notificationText: text.features.roster.studentTransfer.notification,
      });
    }
  };

  showNotification = notificationText => {
    this.setState(prevState => ({
      displayNotification: true,
      notificationText: notificationText || prevState.notificationText,
    }));
    this.setNotificationTimer();
  };

  setNotificationTimer() {
    closeNotificationTimer = setTimeout(this.handleNotificationClose, 5000);
  }

  handleNotificationClose() {
    this.setState({
      displayNotification: false,
    });
    clearTimeout(closeNotificationTimer);
  }

  handleHideShowSentRequests() {
    this.setState({
      showSentTransferRequests: !this.state.showSentTransferRequests,
    });
  }

  handleHideShowReceivedRequests() {
    this.setState({
      showReceivedTransferRequests: !this.state.showReceivedTransferRequests,
    });
  }

  onKeyDown = e => {
    if (e.keyCode === 13) {
      this.handleHideShowReceivedRequests();
    }
  };

  handleConfirmCancelModal = state => {
    this.setState({
      showConfirmCancelModal: state,
    });
  };

  handleDeclineModalState = state => {
    this.setState({
      showDeclineModal: state,
    });
  };

  handleApprove = request => {
    this.setState(
      {
        requestResponse: {
          request,
          approve: true,
        },
      },
      () => this.handleDeclineModalState(true)
    );
  };
  handleDecline = request => {
    this.setState(
      {
        requestResponse: {
          request,
          approve: false,
        },
      },
      () => this.handleDeclineModalState(true)
    );
  };
  handleCancel = request => {
    this.setState(
      {
        requestToBeCancelled: request,
      },
      () => this.handleConfirmCancelModal(true)
    );
  };

  setTableColumns = (sentTransferRequests, receivedTransferRequests) => {
    return [
      {
        id: 'leftColumns',
        sticky: 'left',
        columns: [
          {
            Header: 'Student name',
            name: 'Student name',
            accessor: 'lastName',
            fields: ['lastName'],
            length: 2 + this.state.nonStikyColumns.length,
            Cell: ({ cell }) => {
              return (
                <div className="name">
                  {cell.row.original.lastName}, {cell.row.original.firstName}{' '}
                  {cell.row.original.middleName}
                </div>
              );
            },
            width: 300,
          },
        ],
      },

      {
        id: 'middleColumns',
        columns: this.state.nonStikyColumns,
      },
      {
        id: 'rightColumns',
        sticky: 'right',
        columns: [
          {
            Header: '',
            name: 'actions',
            accessor: 'actions',
            fields: ['actions'],
            width: 200,
            length: 2 + this.state.nonStikyColumns.length,
            type: 'button',
            Cell: ({ cell }) => {
              return (
                <Buttons
                  item={cell.row.original}
                  approveHandle={this.handleApprove}
                  declineHandle={this.handleDecline}
                  cancelHandle={this.handleCancel}
                  hasSentRequestTransfer={sentTransferRequests}
                  hasReceivedRequestTransfer={receivedTransferRequests}
                  isExpanded={this.state.showReceivedTransferRequests}
                />
              );
            },
          },
        ],
      },
    ];
  };

  async handleCancelRequest() {
    const {
      regTransferId,
      regId,
      sourceOrgPartId,
      destinationOrgPartId,
      notes,
    } = this.state.requestToBeCancelled;
    await this.props.actions.updateStudentTransferRequest({
      id: regTransferId,
      regId,
      sourceOrgPartId,
      destinationOrgPartId,
      status: 'cancelled',
      declineReason: '',
      notes: notes,
    });
    this.refreshSentList();
    this.setState({
      showConfirmCancelModal: false,
      notificationText: text.features.roster.studentTransfer.cancelNotification,
    });
    this.showNotification();
  }

  render() {
    const emptyState =
      !this.state.receivedTransferRequests.length && !this.state.sentTransferRequests.length;

    return (
      <div className={`RegistrationStudentsTransfer__Container ${emptyState ? 'emptyState' : ''}`}>
        {!emptyState ? (
          <div className="RegistrationStudentsTransfer__Tables">
            {this.state.receivedTransferRequests.length > 0 && (
              <div className={'RegistrationStudentsTransfer__Table'}>
                <div className={'RegistrationStudentsTransfer__ExpandableElement '}>
                  {this.state.showReceivedTransferRequests ? (
                    <FontAwesomeIcon
                      className="OrganizationTile__Icon"
                      icon={faMinus}
                      onClick={() => this.handleHideShowReceivedRequests()}
                      onKeyDown={this.onKeyDown}
                      tabIndex="0"
                    />
                  ) : (
                    <FontAwesomeIcon
                      className="OrganizationTile__Icon"
                      icon={faPlus}
                      onClick={() => this.handleHideShowReceivedRequests()}
                      onKeyDown={this.onKeyDown}
                      tabIndex="0"
                    />
                  )}
                  {`${text.features.roster.studentTransfer.requestsReceived} (${this.state.receivedTransferRequests.length})`}
                </div>
                <CustomReactTable
                  data={this.state.receivedTransferRequests}
                  visible={this.state.showReceivedTransferRequests}
                  columns={this.setTableColumns(
                    false,
                    this.state.receivedTransferRequests.length > 0
                  )}
                />
              </div>
            )}
            {this.state.sentTransferRequests.length > 0 && (
              <div className={'RegistrationStudentsTransfer__Table'}>
                <div className={'RegistrationStudentsTransfer__ExpandableElement '}>
                  {this.state.showSentTransferRequests ? (
                    <FontAwesomeIcon
                      className="OrganizationTile__Icon"
                      icon={faMinus}
                      onClick={() => this.handleHideShowSentRequests()}
                    />
                  ) : (
                    <FontAwesomeIcon
                      className="OrganizationTile__Icon"
                      icon={faPlus}
                      onClick={() => this.handleHideShowSentRequests()}
                    />
                  )}
                  {`${text.features.roster.studentTransfer.requestsSent} (${this.state.sentTransferRequests.length})`}
                </div>
                <CustomReactTable
                  data={this.state.sentTransferRequests}
                  visible={this.state.showSentTransferRequests}
                  columns={this.setTableColumns(this.state.sentTransferRequests.length > 0, false)}
                />
              </div>
            )}
          </div>
        ) : (
          ''
        )}
        <SimpleBox
          title={text.features.roster.studentTransfer.requestBox.title}
          description={text.features.roster.studentTransfer.requestBox.desc}
          buttons={[
            {
              text: text.features.roster.studentTransfer.requestBox.button,
              action: () => {
                this.handleTransferModalState(true);
              },
            },
          ]}
        />
        {this.state.showTransferModal && (
          <RequestTransferModal
            showModal={this.state.showTransferModal}
            closeModal={() => {
              this.handleTransferModalState(false);
            }}
            showNotification={this.showNotification}
          />
        )}
        {this.state.showDeclineModal && (
          <ApproveDeclineTransferRequestModal
            showModal={this.state.showDeclineModal}
            closeModal={() => {
              this.handleDeclineModalState(false);
            }}
            request={this.state.requestResponse.request}
            approve={this.state.requestResponse.approve}
            showNotification={this.showNotification}
            refresh={this.refreshReceivedList}
          />
        )}
        {this.state.displayNotification && (
          <Notification
            text={this.state.notificationText}
            handleClose={this.handleNotificationClose}
            type="Success"
          />
        )}
        <AlertModal
          className="ConfirmationCancelModal__Container"
          show={this.state.showConfirmCancelModal}
          buttonAction={() => this.handleCancelRequest()}
          close={() => this.handleConfirmCancelModal(false)}
          title=""
          bodyTitle="Are you sure you want to cancel your transfer request?"
          showButton={true}
          buttonText="Cancel request"
          secondaryButton={{
            text: 'Back',
            action: () => this.handleConfirmCancelModal(false),
            redButton: false,
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { rosterReducer } = state;
  return {
    sentTransferRequests: rosterReducer.sentTransferRequests,
    receivedTransferRequests: rosterReducer.receivedTransferRequests,
    updateStudentTransferRequest: rosterReducer.updateStudentTransferRequest,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ ...rosterActions }, dispatch),
  };
};

export { RegistrationStudentsTransfer };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RegistrationStudentsTransfer));

import React from 'react';
import { parseQueryString } from '../../../services/helpers/urlHelper';
import { RegisterForm, EmailVerification } from '..';
import * as Text from '../../../core/i18n/text';
import ACTNowLogo from '../../../assets/svgs/actNowLogoWhite.svg';
import { Helmet } from 'react-helmet';

class RegisterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.getStep = this.getStep.bind(this);
  }

  getStep() {
    const { step } = this.props.match.params;

    switch (step) {
      case 'email-verification':
        return this.emailVerification();
      default:
        return <RegisterForm />;
    }
  }

  emailVerification() {
    const { location, history } = this.props;
    const params = parseQueryString(location.search);
    if (!params.confirmationCode || !params.username) {
      history.push('/');
      return null;
    }
    return (
      <EmailVerification verificationCode={params.confirmationCode} username={params.username} />
    );
  }

  render() {
    return (
      <div className="loginPage">
        <Helmet>
          <title>Registration - ACT-Now</title>
        </Helmet>
        <div style={{ display: 'contents' }}>
          <div className="loginPageLogoText__Container">
            <img src={ACTNowLogo} alt="ACTNow-Logo" />
            <p className="loginPage__Text">{Text.features.login.bgImgText}</p>
          </div>
          {this.getStep()}
        </div>
      </div>
    );
  }
}

RegisterPage.defaultProps = {};
RegisterPage.propTypes = {};

export default RegisterPage;

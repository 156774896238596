import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import Modal from '../../../components/modal/AlertModal';
import Notification from '../../../components/alerts/Notification';
import Radio from '../../../components/inputs/Radio';
import ResponseSummary from './ResponseSummary';
import ShippingDetailsModal from '../shipping-details-modal/ShippingDetailsModal';
import TextArea from '../../../components/forms/TextArea';

import { ReactComponent as penIcon } from '../../../assets/svgs/pen.svg';

import { actions as coreActions } from '../../../core/duck';

import { OrgManagementServices } from '../../../services';

import { usePermission, useStore } from '../../../store/hooks';

import TextContext from '../../../core/providers/TextProvider';

import './AdministrationParticipation.scss';

const STATUS = {
  active: {
    id: 1,
  },
  inactive: {
    id: 0,
  },
  pending: {
    id: null,
  },
};

const findOption = status => STATUS[status]?.id ?? null;

const AdministrationParticipation = ({ currentOrg }) => {
  /**
   * useContext()
   */
  const Text = useContext(TextContext);

  /**
   * useDispatch()
   */
  const dispatch = useDispatch();

  /**
   * useSelector()
   */
  const serverDateTime = useSelector(state => state.userInfoReducer.serverDateTime);

  /**
   * useStore()
   */
  const [invitationStatusResponse, invitationStatusStore] = useStore('invitationStatus');
  const [orgHierarchyResponse] = useStore('orgHierarchy');

  /**
   * useState()
   */
  const [formData, setFormData] = useState({ errors: {} });
  const [notificationProps, setNotificationProps] = useState({ show: false });
  const [participationModalProps, setParticipationModalProps] = useState({ show: false });
  const [shippingDetailsModalProps, setShippingDetailsModalProps] = useState({ show: false });
  const [testDates, setTestDates] = useState([]);

  /**
   * useMemo()
   */
  const hasInvitationPermissions = usePermission('now_org_part_manage');

  const invitation = useMemo(() => {
    if (!invitationStatusResponse?.data) return null;
    const currentDate = serverDateTime?.data
      ? moment(serverDateTime.data).format('MM.DD.YYYY')
      : moment(new Date()).format('MM.DD.YYYY');
    const endDate = moment(invitationStatusResponse.data.orgPartAddEndDate).format('MM.DD.YYYY');
    return {
      adminId: invitationStatusResponse.data.adminId,
      adminName: invitationStatusResponse.data.adminname,
      inactiveReason: invitationStatusResponse.data.inactiveReason,
      initialReason: invitationStatusResponse.data.inactiveReason,
      isDeadlineExpired: moment(currentDate, 'MM.DD.YYYY').isAfter(moment(endDate, 'MM.DD.YYYY')),
      orgId: invitationStatusResponse.data.orgId,
      parentName: invitationStatusResponse.data.parentname,
      status: invitationStatusResponse.data.status,
    };
  }, [invitationStatusResponse, serverDateTime]);

  /**
   * useCallback()
   */
  const closeParticipationModal = useCallback(() => {
    setParticipationModalProps({ show: false });
  }, []);

  const closeShippingDetailsModal = useCallback(() => {
    setShippingDetailsModalProps({ show: false });
  }, []);

  const getInvitationStatus = useCallback(() => {
    invitationStatusStore.clear();
    invitationStatusStore.fetch({ orgPartId: currentOrg.orgPartId });
  }, [currentOrg.orgPartId, invitationStatusStore]);

  const handleNotification = useCallback(show => {
    if (show)
      setNotificationProps({ show, text: 'Your response has been submitted.', type: 'Success' });
    if (!show) setNotificationProps({ show });
  }, []);

  const handleRadioChange = useCallback(
    name => e => {
      e.persist();
      setFormData(prev => ({
        ...prev,
        [name]: +e.target.id,
        errors: { ...prev.errors, [name]: '' },
      }));
    },
    []
  );

  const handleResponse = useCallback(() => {
    const { inactiveReason, participation } = formData;

    switch (participation) {
      case STATUS.active.id:
        setShippingDetailsModalProps({ show: true });
        closeParticipationModal();
        return;
      case STATUS.inactive.id:
        if (!inactiveReason) {
          setFormData(prev => ({
            ...prev,
            errors: { ...prev.errors, inactiveReason: 'Field is required' },
          }));
          return;
        }
        const data = {
          adminId: invitation.adminId,
          id: currentOrg.orgPartId,
          inactiveReason,
          status: 'inactive',
          orgId: invitation.orgId,
        };
        return OrgManagementServices.respondToInvite(data)
          .then(() => {
            getInvitationStatus();
            setNotificationProps({
              show: true,
              text: 'Your response has been submitted.',
              type: 'Success',
            });
            setTimeout(() => setNotificationProps({ show: false }), 3000);
          })
          .catch(error => {
            console.error('Error while invalidate date : ', error.toJSON());
            setNotificationProps({
              show: true,
              text: 'Error while submitting the request.',
              type: 'Error',
            });
          })
          .finally(() => {
            closeParticipationModal();
          });
      default:
        setFormData(prev => ({
          ...prev,
          errors: { ...prev.errors, participation: 'Response is required' },
        }));
        return;
    }
  }, [closeParticipationModal, currentOrg.orgPartId, formData, getInvitationStatus, invitation]);

  const handleTextareaChange = useCallback(
    name => e => {
      e.persist();
      setFormData(prev => ({
        ...prev,
        [name]: e.target.value,
        errors: { ...prev.errors, [name]: '' },
      }));
    },
    []
  );

  const openParticipationModal = useCallback(() => {
    if (invitation.isDeadlineExpired) {
      setShippingDetailsModalProps({
        isEdit: invitation.status !== 'pending',
        show: true,
      });
    } else {
      setFormData({
        inactiveReason: invitation.status === 'inactive' ? invitation.inactiveReason : '',
        participation: findOption(invitation.status),
        errors: {},
      });
      setParticipationModalProps({
        show: true,
      });
    }
  }, [invitation]);

  /**
   * useEffect()
   */
  useEffect(() => {
    dispatch(coreActions.getServerDateTime());
  }, [dispatch]);

  useEffect(() => {
    if (!invitation?.adminId) return;
    OrgManagementServices.getTestingEventReceiptWindows(invitation.adminId)
      .then(response => {
        setTestDates(response.data?.data || []);
      })
      .catch(() => setTestDates([]));
  }, [invitation]);

  /**
   * render
   */
  if (!invitation) return null;

  return (
    <div className="orgParticipation__Container">
      <Helmet>
        <title>Participation - ACT-Now</title>
      </Helmet>
      <ResponseSummary
        adminName={invitation.adminName}
        buttons={[
          hasInvitationPermissions
            ? invitation.status === 'pending'
              ? {
                  action: openParticipationModal,
                  text: Text.features.orgManagement.participation.respond,
                }
              : {
                  action: openParticipationModal,
                  icon: penIcon,
                  text: Text.features.organization.buttons.edit,
                  type: 'open',
                }
            : {},
        ]}
        initialReason={invitation.initialReason}
        isDeadlineExpired={invitation.isDeadlineExpired}
        orgPart={invitationStatusResponse.data}
        reason={invitation.inactiveReason}
        status={invitation.status}
        testDates={testDates}
      />
      {participationModalProps.show && (
        <Modal
          buttonAction={handleResponse}
          buttonText="Continue"
          className={`accept-decline-invitation ${shippingDetailsModalProps.show && 'hidden'}`}
          close={() => closeParticipationModal()}
          note={`Select one to indicate whether your school will participate in the ${invitation.adminName} administration.`}
          secondaryButton={{
            text: 'Back',
            action: () => closeParticipationModal(),
            redButton: false,
          }}
          show={true}
          showButton
          title={Text.features.orgManagement.participation.participationResponse}
          validationMessage={formData.errors.participation || ''}>
          <Radio
            defaultOption={formData.participation}
            disableOption="111"
            error={!!formData.errors.participation}
            handleDateSelection={handleRadioChange('participation')}
            items={[
              {
                id: STATUS.active.id,
                displayText: Text.features.orgManagement.participation.acceptInvite,
              },
              {
                id: STATUS.inactive.id,
                displayText: Text.features.orgManagement.participation.declineInvite,
              },
            ]}
          />
          {formData.participation === STATUS.inactive.id && (
            <>
              <div className="orgPatricipation__ReasonTitle">
                {Text.features.orgManagement.participation.declineReason}
              </div>
              <TextArea
                error={formData.errors.inactiveReason}
                handleTextAreaChange={handleTextareaChange('inactiveReason')}
                maxLength="360"
                nrCharactes={formData.inactiveReason?.length || 0}
                placeHolder=""
                value={formData.inactiveReason}
              />
            </>
          )}
        </Modal>
      )}
      {shippingDetailsModalProps.show && (
        <ShippingDetailsModal
          adminId={invitation.adminId}
          buttonAction={handleResponse}
          buttonText="Confirm"
          closeModal={closeShippingDetailsModal}
          getInvitationStatus={getInvitationStatus}
          goBack={closeShippingDetailsModal}
          handleNotificationModal={handleNotification}
          isDeadlineExpired={invitation.isDeadlineExpired}
          isEdit={shippingDetailsModalProps.isEdit}
          note={`Select one to indicate whether your school will participate in the ${invitation.adminName} administration.`}
          orgHierarchy={orgHierarchyResponse?.data}
          orgPart={invitationStatusResponse.data}
          showButton
          showModal={true}
          testDates={testDates}
          title={Text.features.orgManagement.participation.respond}
          validationMessage={formData.errors.participation ? 'Answer is required' : ''}
        />
      )}
      {notificationProps.show && (
        <Notification
          handleClose={() => handleNotification(false)}
          text={notificationProps.text}
          type={notificationProps.type}
        />
      )}
    </div>
  );
};

export default AdministrationParticipation;

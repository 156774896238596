import React from 'react';
import { connect } from 'react-redux';

import { messages, clickables, features } from '../../../core/i18n/text';
import ACTNowLogo from '../../../assets/svgs/actNowLogoWhite.svg';

import FormComplete from '../../../components/forms/FormComplete';
import { LoginForm, ForgotPassword } from '..';
import { Helmet } from 'react-helmet';

const Text = { messages, clickables, features };

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showForgotPasswordForm: false,
    };
    this.togglePasswordFormOn = this.togglePasswordFormOn.bind(this);
    this.togglePasswordFormOff = this.togglePasswordFormOff.bind(this);
  }

  componentDidMount() {
    window.sessionStorage.clear();
  }

  togglePasswordFormOn() {
    this.setState({
      showForgotPasswordForm: true,
    });
  }

  togglePasswordFormOff() {
    this.setState({
      showForgotPasswordForm: false,
    });
  }

  render() {
    const { isAccountVerificationSuccess } = this.props;
    const { showForgotPasswordForm } = this.state;
    return (
      <div className="loginPage">
        <Helmet>
          <title>Login - ACT-Now</title>
        </Helmet>
        {isAccountVerificationSuccess ? (
          <FormComplete link="/login" message={Text.messages.success.registerComplete} />
        ) : (
          <div style={{ display: 'contents' }}>
            <div className="loginPageLogoText__Container">
              <img src={ACTNowLogo} alt="ACTNow-Logo" />
              <p className="loginPage__Text">{Text.features.login.bgImgText}</p>
            </div>
            {showForgotPasswordForm ? (
              <ForgotPassword back={this.togglePasswordFormOff} />
            ) : (
              <LoginForm forgotPassword={this.togglePasswordFormOn} />
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAccountVerificationSuccess: state.loginReducer.accVerification.data,
  };
};

export { LoginPage };
export default connect(mapStateToProps, {})(LoginPage);

import React from 'react';
import proptypes from 'prop-types';

import './SimpleBox.scss';

const SimpleBox = ({ buttons, content, description, hyperlink, title }) => {
  /**
   * render
   */
  return (
    <div className="simpleBox__Container">
      <div className="simpleBox__Title">{title}</div>
      <div className="simpleBox__Description">
        {description}
        {hyperlink && (
          <a href={hyperlink.link} target="blank" className="link">
            {hyperlink.text}
          </a>
        )}
      </div>
      {content}
      <div className={`${buttons.length > 1 ? 'simpleBox__ButtonContainer' : ''}`}>
        {buttons.map((button, index) => {
          return (
            button.action && (
              <button
                key={index}
                className={`${
                  button.type && button.type === 'open' ? 'openButton' : 'bigButton'
                } simpleBox__Button`}
                onClick={button.action}>
                {button.icon && <button.icon />}
                {button.text}
              </button>
            )
          );
        })}
      </div>
    </div>
  );
};

SimpleBox.displayName = 'SimpleBox';

SimpleBox.propTypes = {
  buttons: proptypes.array.isRequired,
  content: proptypes.element,
  description: proptypes.string.isRequired,
  hyperlink: proptypes.shape({
    link: proptypes.string.isRequired,
    text: proptypes.string.isRequired,
  }),
  title: proptypes.string.isRequired,
};

export default SimpleBox;

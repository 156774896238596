import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash/fp';
import moment from 'moment';

import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

import { ReactComponent as AccommodationIcon } from '../../../assets/svgs/accommodationIcon.svg';
import { ReactComponent as InfoIcon } from '../../../assets/svgs/info-blue.svg';
import { ReactComponent as Pen } from '../../../assets/svgs/pen.svg';

import AlertModal from '../../../components/modal/AlertModal';

import { RosterServices } from '../../../services';

import { useStore } from '../../../store/hooks';

import { getPermission } from '../../../core/duck/selectors';

import TextContext from '../../../core/providers/TextProvider';

import { DARK_GREY, WHITE, BORDER_GRAY } from '../../../styles/base/_settings.scss';
import './StudentsInfoModal.scss';

const LightTooltip = withStyles(() => ({
  arrow: {
    color: WHITE,
    '&:before': {
      border: `0.1rem solid ${BORDER_GRAY}`,
    },
  },
  popper: {
    zIndex: '1001 !important',
  },
  tooltip: {
    backgroundColor: WHITE,
    boxShadow: `0 0 1rem ${BORDER_GRAY}`,
    color: DARK_GREY,
    fontSize: '14px',
  },
}))(Tooltip);

const StudentsInfoModal = ({
  closeModal,
  currentOrg,
  openEditModal,
  openUnenrollModal,
  student,
}) => {
  /**
   * useContext()
   */
  const Text = useContext(TextContext);

  /**
   * useStore()
   */
  const [ncrMaterialKitOptionsResponse, ncrMaterialKitOptionsStore] = useStore(
    'ncrMaterialKitOptions'
  );

  /**
   * useState()
   */
  const [accommodations, setAccommodations] = useState([]);

  /**
   *  useSelector()
   */
  const hasStudentFilesPermission = useSelector(state => getPermission(state, 'now_reg_files'));

  /**
   * useCallback()
   */
  const formatDetails = useCallback(value => ([null, ''].includes(value) ? '-' : value), []);

  /**
   * useMemo()
   */
  const canEdit = useMemo(() => hasStudentFilesPermission, [hasStudentFilesPermission]);

  const canUnenroll = useMemo(() => !currentOrg.allowChildOrgsFlag && hasStudentFilesPermission, [
    currentOrg.allowChildOrgsFlag,
    hasStudentFilesPermission,
  ]);

  const details = useMemo(() => {
    const accom = ncrMaterialKitOptionsResponse?.data?.find(item => student.accomKit === item.code);
    const accomKitValue = accom ? accom.name : Text.features.roster.modal.standard;
    return [
      'notTestingFlag',
      'externalStudentId',
      'onlineFlag',
      'nonCollegeReportableFlag',
      'timing',
      'regTypeName',
      'dateOfBirth',
      'grade',
      'gender',
      'reportingHighSchoolCode',
      'claimCode',
      'orgName',
      'taaPin',
      'accommOptOut',
    ].reduce((acc, key) => {
      switch (key) {
        case 'accommOptOut':
          return { ...acc, [key]: student[key] ? 'Y' : 'N' };
        case 'dateOfBirth':
          return { ...acc, [key]: formatDetails(moment(student[key]).format('L')) };
        case 'nonCollegeReportableFlag':
          if (!student.onlineFlag && student[key]) {
            return { ...acc, [key]: accomKitValue };
          } else {
            return { ...acc };
          }
        case 'notTestingFlag':
          return {
            ...acc,
            [key]: student[key]
              ? Text.features.roster.modal.willNotTest
              : formatDetails(student.testingEvent),
          };
        case 'onlineFlag':
          return {
            ...acc,
            [key]: student[key]
              ? Text.features.roster.modal.online
              : Text.features.roster.modal.paper,
          };
        case 'timing':
          return { ...acc, [key]: formatDetails(_.capitalize(student[key])) };
        default:
          return { ...acc, [key]: formatDetails(student[key]) };
      }
    }, {});
  }, [
    formatDetails,
    ncrMaterialKitOptionsResponse,
    student,
    Text.features.roster.modal.online,
    Text.features.roster.modal.paper,
    Text.features.roster.modal.standard,
    Text.features.roster.modal.willNotTest,
  ]);

  const labels = useMemo(
    () =>
      Object.entries(Text.features.roster.modal.details).reduce(
        (acc, [key, value]) =>
          key !== 'nonCollegeReportable' || student.nonCollegeReportableFlag
            ? { ...acc, [key]: value }
            : { ...acc },
        {}
      ),
    [student.nonCollegeReportableFlag, Text.features.roster.modal.details]
  );

  /**
   * useEffect()
   */
  useEffect(() => {
    ncrMaterialKitOptionsStore.fetch({
      adminId: currentOrg.adminId,
      nonCollegeReportableFlag: 1,
      regTypeId: student.regTypeId,
    });
  }, [currentOrg.adminId, ncrMaterialKitOptionsStore, student.regTypeId]);

  useEffect(() => {
    if (!student.regId) return;

    RosterServices.getAccommodations({ regId: student.regId }).then(
      response => response.data?.data && setAccommodations(response.data.data)
    );
  }, [student]);

  return (
    <AlertModal
      body={[
        <>
          <div className="student__Name-container">
            <p className="student__Name">
              {`${student.lastName}, ${student.firstName} ${student.middleName}`}
            </p>
            {student.accomRosterFlag && <AccommodationIcon />}
          </div>

          {Object.entries(labels).map(([key, label]) => {
            return (
              <div className="modal__Container" key={key}>
                <div className="modal__Desc">
                  {label}
                  {key === 'nonCollegeReportable' && (
                    <LightTooltip title={Text.features.roster.modal.tooltipInfo} arrow>
                      <InfoIcon />
                    </LightTooltip>
                  )}
                </div>
                <div>{details[key]}</div>
              </div>
            );
          })}
          <div className="modal__Container">
            <div className="modal__Desc accommodations">Accommodations</div>
            <div>
              {accommodations.length ? (
                accommodations.map(({ code, name }) => <div key={code}>{name}</div>)
              ) : (
                <div>-</div>
              )}
            </div>
          </div>
          <div className="modal_LinksContainer">
            {canEdit && (
              <button
                aria-label={Text.features.roster.modal.edit}
                className="modal__Edit link"
                onClick={() => openEditModal(student)}>
                <Pen />
                {Text.features.roster.modal.edit}
              </button>
            )}
            {canUnenroll && (
              <button
                className="modal__Edit link unenroll"
                onClick={() => openUnenrollModal(student.regId)}>
                <div className="delete-icon"></div>
                {Text.features.roster.modal.unenroll}
              </button>
            )}
          </div>
        </>,
      ]}
      close={closeModal}
      customStyles={{ overlay: { zIndex: '1000' } }}
      show={true}
      showButton={true}
      title={Text.features.roster.modal.title}
    />
  );
};

export default StudentsInfoModal;

import React from 'react';
import './pdf.scss';

class PDFRender extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pdf: null,
    };
  }

  componentDidMount() {
    const fileName = this.props.match.params.pdf;
    if (fileName) {
      let pdf = require(`../../assets/pdfs/${fileName}`);
      this.setState({
        pdf,
      });
    }
  }

  render() {
    return (
      <div className="pdf__Container">{this.state.pdf && <embed src={this.state.pdf}></embed>}</div>
    );
  }
}

export default PDFRender;

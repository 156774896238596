import React from 'react';
import './AdministrationParticipation.scss';
import { features } from '../../../core/i18n/text';
import Alert from '../../../components/alerts/Alert';
import moment from 'moment';

const InvitedSummary = ({ fields }) => {
  return (
    <>
      <div className="response__details">
        {fields.map(item => (
          <div className="response__item">
            <div className="details__title">{item.label}</div>
            <div className="details__value">{item.row1}</div>
            <div className="details__value">{item.row2}</div>
            <div className="details__value">{item.row3}</div>
          </div>
        ))}
      </div>
    </>
  );
};

const ResponseSummary = ({
  orgPart,
  testDates,
  buttons,
  status,
  adminName,
  reason,
  isDeadlineExpired,
  initialReason,
}) => {
  const selectedTestEvent = testDates.find(item => item.id === orgPart.testingEventReceiptWindowId);
  const fields = [
    {
      label: 'Test window',
      row1: selectedTestEvent?.testingEventName || '',
    },
    {
      label: 'Receipt week',
      row1: selectedTestEvent?.name || '',
    },
    {
      label: 'Primary Test Coordinator',
      row1: ` ${orgPart.tcLastName}, ${orgPart.tcFirstName}`,
      row2: orgPart.tcPhone,
      row3: orgPart.tcEmailAddress,
    },
    {
      label: 'Shipping contact',
      row1: `${orgPart.shipLastName}, ${orgPart.shipFirstName}`,
      row2: orgPart.shipPhone,
      row3: orgPart.shipEmailAddress,
    },
    {
      label: 'Shipping address',
      row1: `${orgPart.shipAddress1} ${orgPart.shipAddress2 ? orgPart.shipAddress2 : ''}`,
      row2: `${orgPart.shipCity}, ${orgPart.shipStateCode}`,
      row3: orgPart.shipPostalCode,
    },
  ];

  const alertStatus = {
    pending: `You have until ${moment(orgPart.orgPartAddEndDate).format('MM.DD.YYYY')} to respond`,
    active: 'Participation confirmed.',
    inactive: 'Participation declined.',
  };

  return (
    <div className={`response__container ${status === 'pending' && 'pending'}`}>
      <h4 className="response__title">
        {status === 'pending'
          ? `You have been added to ${adminName} administration`
          : 'Response summary'}
      </h4>
      {status === 'pending' && (
        <div className="response__description">
          {features.orgManagement.participation.clickToRespond}
        </div>
      )}
      <Alert type={status === 'active' ? 'success' : 'info'} message={alertStatus[status]} />
      {status === 'active' && orgPart.testingEventReceiptWindowId && (
        <InvitedSummary fields={fields} buttons={buttons} />
      )}
      {status === 'inactive' && (
        <div>
          <h6 className="decline__reason__title">Reason</h6>
          <div className="decline__reason">
            {initialReason ? initialReason : features.orgManagement.participation.autoDecline}
          </div>
        </div>
      )}
      <div className={`${buttons.length > 1 ? 'simpleBox__ButtonContainer' : ''}`}>
        {buttons.map((button, index) => {
          return button.action ? (
            <button
              key={index}
              className={`${
                button.type && button.type === 'open' ? 'openButton' : 'bigButton'
              } simpleBox__Button ${status === 'inactive' && isDeadlineExpired && 'disabled'}`}
              onClick={button.action}
              disabled={status === 'inactive' && isDeadlineExpired}>
              {button.icon && <button.icon />}
              {button.text}
            </button>
          ) : (
            ''
          );
        })}
      </div>
    </div>
  );
};
export default ResponseSummary;

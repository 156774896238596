import { createSelector } from 'reselect';

const getEntityDef = state => state.contractReducer.orgEntityDef;
const getMapp = (state, obj) => obj;

export const mapEntityDef = createSelector([getEntityDef, getMapp], (entityDef, mappingObj) => {
  let newEntityDef = {};
  Object.entries(mappingObj).forEach(([key, value]) => {
    const def = entityDef.find(el => el.code === value);
    newEntityDef[key] = def;
  });
  return entityDef.length ? newEntityDef : mappingObj;
});

import React from 'react';
import './NotificationBox.scss';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash/fp';
import moment from 'moment';

import { common, features } from '../../../core/i18n/text';
import InfoIcon from '../../../assets/svgs/infoSmall.svg';
import AlertModal from '../../../components/modal/AlertModal';
import Loader from '../../../components/loader/Loader';

import { actions as notifActions } from '../duck';

const Text = { common, features };

const NUMBER_OF_NOTIFICATIONS = 10;

class NotificationBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: [],
      notification: null,
      showModal: false,
    };
    this.openNotification = this.openNotification.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.getNotifications = this.getNotifications.bind(this);
    this.renderNotifications = this.renderNotifications.bind(this);
  }

  getNotifications() {
    this.props.actions.getNotificationsByStatus({
      userId: this.props.userInfo.data.id,
      skip: 0,
      limit: NUMBER_OF_NOTIFICATIONS,
      status: 'NOT_VIEWED',
    });
  }

  componentDidMount() {
    if (this.props.userInfo && this.props.userInfo.data) {
      this.getNotifications();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !_.isEqual(this.props.userInfo)(prevProps.userInfo) &&
      this.props.userInfo &&
      this.props.userInfo.data
    ) {
      this.getNotifications();
    }

    if (
      !_.isEqual(this.props.notifications)(prevProps.notifications) &&
      this.props.notifications &&
      this.props.notifications.data
    ) {
      this.setState({
        notifications: this.props.notifications.data.sort(function(a, b) {
          return new Date(b.created) - new Date(a.created);
        }),
      });
    }
  }

  getTime(date) {
    return moment(date).fromNow();
  }

  openNotification = notification => {
    this.setState(
      {
        notification,
        showModal: !this.state.showModal,
      },
      () => {
        this.props.actions.readNotification({
          userId: this.props.userInfo.data.id,
          notification: {
            notificationId: notification.id,
          },
        });
      }
    );
  };

  closeNotification() {
    this.setState(
      {
        showModal: false,
      },
      () => {
        this.getNotifications();
        this.props.onClose();
      }
    );
  }

  renderNotifications() {
    let notificationList = (
      <li className="notifications__EmptyList">{Text.features.notifications.noUnread}</li>
    );

    if (this.state.notifications && this.state.notifications.length > 0) {
      notificationList = this.state.notifications.map(notification => {
        return (
          <li
            className="notifications__Card"
            key={notification.id}
            onClick={() => this.openNotification(notification)}>
            <div className="bullet"></div>
            <div className="notifications__Text">
              <span>{notification.title}</span>
              <div className="notifications__Time">
                {this.getTime(notification.created)} -{' '}
                <span className="notifications__Status">
                  {notification.status === 'NOT_VIEWED' ? 'Unread' : ''}
                </span>
              </div>
            </div>
          </li>
        );
      });
    }

    return notificationList;
  }

  render() {
    return (
      <div className={`notifications__Container ${this.props.show ? '' : 'hidden'}`}>
        <div className="arrow"></div>
        {this.props.notifications && this.props.notifications.loading ? (
          <div className="notifications__Box">
            <span className="notifications__Loader">
              <Loader />
            </span>
          </div>
        ) : (
          <div className="notifications__Box">
            {this.state.notifications && this.state.notifications.length > 0 ? (
              <div className="notifications__Hint">
                <span>{Text.features.notifications.clickToRead}</span>
                <img src={InfoIcon} alt="Info" />
              </div>
            ) : (
              ''
            )}
            <ul className="notifications__List">{this.renderNotifications()}</ul>
            <a
              href={`/notifications/orgPartId/${parseInt(this.props.match.params.orgPartId)}`}
              className="link">
              {Text.common.labels.seeAll}
            </a>
          </div>
        )}
        {this.state.notification && this.state.showModal && (
          <AlertModal
            show={this.state.showModal}
            title={features.notifications.modalTitle}
            bodyTitle={this.state.notification.title ? this.state.notification.title : ''}
            body={[this.state.notification.body]}
            note={this.getTime(this.state.notification.created)}
            close={this.closeNotification}
            showButton={true}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userInfo: state.userInfoReducer.userInfo,
    notifications: state.notificationReducer.statusNotifications,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ ...notifActions }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NotificationBox));

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Header from '../../../components/header/Header';
import SidebarNav from '../../../components/sidebar-nav/SidebarNav';
import FileReports from '../file-upload/FileReports';
import RosterOverview from '../students-overview/StudentsOverview';
import StudentsOrganizationTab from '../students-organization-tab/StudentsOrganizationTab';
import Reports from '../../../components/reports/Reports';
import { actions as coreActions } from '../../../core/duck';
import { actions as rosterActions } from '../duck';
import { actions as orgManagementActions } from '../../administration/duck';
import { Tabs, OrgCrumbs, OrgSelect } from '../../../components/index';
import { features as text } from '../../../core/i18n/text';
import { getPermission } from '../../../core/duck/selectors';
import _ from 'lodash/fp';
import './StudentsRouter.scss';
import { getProgramConfiguration } from '../../../core/utils';
import RegistrationStudentsRouter from '../registration-students-router/RegistrationStudentsRouter';

let currentOrgPartId = 0;
let tabs = [
  {
    title: 'Organization',
    url: 'organization',
    avail: true,
    component: StudentsOrganizationTab,
  },
  {
    title: 'Students',
    url: 'studentstable',
    avail: true,
    component: RegistrationStudentsRouter,
  },
  {
    title: 'Reports',
    url: 'reports',
    permissions: ['hasReportsPermissions'],
    avail: true,
    component: Reports,
  },
  {
    title: 'Overview',
    url: 'overview',
    avail: true,
    component: RosterOverview,
  },
  {
    title: 'Files',
    url: 'files',
    avail: true,
    component: FileReports,
  },
  {
    title: 'Operational reports',
    url: 'operationalreports',
    avail: false,
    component: null,
  },
];

let invitationStatus = '';
let currentOrgType = null;
let currentOrg = null;

class StudentsRouter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '',
      TabComponent: null,
      typeOfSelectedOrg: null,
      orgTree: [],
      tabs,
    };
    this.initTabs = this.initTabs.bind(this);
  }

  componentDidMount() {
    currentOrgPartId = parseInt(this.props.match.params.orgPartId);
    invitationStatus = null;
    this.props.actions.getInvitationStatus({ orgPartId: currentOrgPartId });
    this.props.actions.getUserPrograms();
    this.retrieveOrgHierachy(currentOrgPartId);
    this.initTabs();
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.match)(this.props.match)) {
      currentOrgPartId = parseInt(this.props.match.params.orgPartId);
      this.retrieveOrgHierachy(currentOrgPartId);
      this.initTabs();
      this.props.actions.getInvitationStatus({ orgPartId: currentOrgPartId });
    }
    if (
      this.props.hasFilePermissions !== prevProps.hasFilePermissions ||
      this.props.hasStudentsPermissions !== prevProps.hasStudentsPermissions
    ) {
      this.toggleVisibility();
    }
    if (
      !_.isEqual(prevProps.inviteStatus)(this.props.inviteStatus) &&
      _.get('inviteStatus.data')(this.props)
    ) {
      currentOrgPartId = parseInt(this.props.match.params.orgPartId);
      invitationStatus = this.props.inviteStatus.data.status;
      this.toggleVisibility();
    }
    if (!_.isEqual(prevProps.programs)(this.props.programs)) {
      this.toggleVisibility();
    }
  }

  initTabs() {
    const path = this.props.match.path;
    if (path.includes('students-transfer')) {
      this.setState({
        activeTab: 'studentstable',
        TabComponent: RegistrationStudentsRouter,
      });
    } else {
      for (let i = 0; i < this.state.tabs.length; i++) {
        if (path.includes(this.state.tabs[i].url)) {
          const CurrentComponent = this.state.tabs[i].component;
          this.setState({
            activeTab: this.state.tabs[i].url,
            TabComponent: CurrentComponent,
          });
        }
      }
    }
  }

  handleTabChange = tab => {
    this.setState({
      activeTab: tab,
    });
  };

  toggleVisibility = () => {
    if (currentOrg) {
      let cloneTabs = _.cloneDeep(this.state.tabs);
      const program = this.props.programs?.data?.find(
        ({ programId }) => programId === currentOrg.programId
      );
      cloneTabs[0].avail = currentOrg.allowChildOrgsFlag;
      cloneTabs[1].avail =
        this.props.hasStudentsPermissions &&
        (!currentOrg.allowChildOrgsFlag ? invitationStatus === 'active' : true);
      cloneTabs[2].avail = this.props.hasReportsPermissions;
      cloneTabs[3].avail =
        this.props.hasUsersManagePermissions &&
        (!currentOrg.allowChildOrgsFlag ? invitationStatus === 'active' : true);
      cloneTabs[4].avail =
        ((program &&
          getProgramConfiguration('roster.allowFileUpload')(program) &&
          this.props.hasFilePermissions) ||
          (program &&
            getProgramConfiguration('accommodations.allowFileUpload')(program) &&
            this.props.hasAccomFilePermissions)) &&
        (!currentOrg.allowChildOrgsFlag ? invitationStatus === 'active' : true);
      this.setState({ tabs: cloneTabs });
      if (!currentOrg.allowChildOrgsFlag) {
        if (invitationStatus && invitationStatus !== 'active') {
          this.props.history.replace(
            `/students/overview/orgPartId/${parseInt(this.props.match.params.orgPartId)}`
          );
        } else {
          this.props.history.location.pathname.includes('organization') &&
            this.props.history.replace(
              `/students/studentstable/orgPartId/${parseInt(this.props.match.params.orgPartId)}`
            );
        }
      }
    }
  };

  /* API call */
  retrieveOrgHierachy(orgPartId) {
    this.props.actions.getOrgHierarchy({ orgPartId }).then(response => {
      const data = response.data?.data;
      this.setState({ orgTree: data });
      currentOrg = data[data.length - 1];
      currentOrgType = data[data.length - 1].type;
      this.toggleVisibility();
    });
  }

  render() {
    const { orgTree, activeTab, tabs, TabComponent } = this.state;
    const currentOrgPartIdValue = currentOrg?.orgPartId || this.props.match.params.orgPartId;

    return (
      <div className="roster__Page">
        <Header />
        <div className="_outter_">
          <SidebarNav active="students" />
          <div
            className={`sidebar__Separator ${
              this.props.location.pathname.includes('students') ||
              this.props.location.pathname.includes('organization')
                ? 'sidebar_fit_table'
                : ''
            }`}>
            <OrgCrumbs {...this.props} crumbs={orgTree} rootName={text.roster.breadcrumbsBase} />
            <OrgSelect
              currentOrg={orgTree[orgTree.length - 1]}
              parentOrg={orgTree.length === 1 ? orgTree[0] : orgTree[orgTree.length - 2]}
            />
            <Tabs
              tabs={tabs}
              basePath="/students"
              currentOrgPartId={currentOrgPartIdValue}
              handleTabChange={this.handleTabChange}
              activeTab={activeTab}
              {...this.props}
            />
            {TabComponent && currentOrg ? (
              <TabComponent
                {...this.props}
                currentOrgPartId={currentOrgPartIdValue}
                currentOrg={currentOrg}
                currentOrgType={currentOrgType}
                category="now_reg"
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { userInfoReducer } = state;
  return {
    hasAccomFilePermissions: getPermission(state, 'now_reg_accom_files'),
    hasFilePermissions: getPermission(state, 'now_reg_files'),
    hasReportsPermissions: getPermission(state, 'now_reg_reports'),
    hasStudentsPermissions: getPermission(state, 'now_reg_view'),
    hasUsersManagePermissions: getPermission(state, 'now_user_manage'),
    inviteStatus: state.orgManagementReducer.inviteStatus,
    orgHierarchy: userInfoReducer.orgHierarchy,
    programs: userInfoReducer.programs,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      { ...coreActions, ...rosterActions, ...orgManagementActions },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentsRouter);
export { StudentsRouter };

import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import placeholderLogo from './../../assets/svgs/nowlogo.svg';
import { common, features } from '../../core/i18n/text';
import { LandingServices } from '../../services';

const Text = { common, features };

const ContractInfo = ({ logo, name, code, url, adminId }) => {
  const [adminContent, setAdminContent] = useState('');
  useEffect(() => {
    if (adminId) {
      (async () => {
        const response = await LandingServices.getAdminContent(adminId);
        if (response.data) {
          setAdminContent(response.data.homepageHtml);
        }
      })();
    }
  }, [adminId]);

  return (
    <div className="contract__Container">
      <img src={logo} alt="Logo" className="contract__Logo"></img>
      <div className="contract__Info">
        <div className="contract__Text contract__Name">{name}</div>
        {code && (
          <div className="contract__Text contract__Id">
            {Text.features.landing.contractInfoId}
            <span>{code}</span>
          </div>
        )}
        <a className="contract__Text link" href={url} target="blank">
          {url.replace('https://', '').replace('/', '')}
        </a>
        <div className="contract__Products__Container">
          {adminContent && <div dangerouslySetInnerHTML={{ __html: adminContent }} />}
        </div>
      </div>
    </div>
  );
};

export default ContractInfo;

ContractInfo.displayName = 'ContractInfo';

ContractInfo.propTypes = {
  logo: propTypes.string,
  name: propTypes.string,
  code: propTypes.string,
  url: propTypes.string,
  products: propTypes.array,
};

ContractInfo.defaultProps = {
  logo: placeholderLogo,
  name: '',
  code: '',
  url: '',
  programs: [],
};

const feat = 'core';

const GET_USERINFO = `${feat}/GET_USERINFO`;
const GET_USER_ORGANIZATION = `${feat}/GET_USER_ORGANIZATION`;
const GET_USER_PROGRAMS = `${feat}/GET_USER_PROGRAMS`;
const GET_USER_ADMINS = `${feat}/GET_USER_ADMINS`;
const GET_USER_ORGS = `${feat}/GET_USER_ORGS`;
const GET_ORG_HIERARCHY = `${feat}/GET_ORG_HIERARCHY`;
const GET_CHILD_ORGS = `${feat}/GET_CHILD_ORGS`;
const GET_USER_PERMISSIONS = `${feat}/GET_USER_PERMISSIONS`;
const GET_ALL_ADMINS_BY_PROGRAM = `${feat}/GET_ALL_ADMINS_BY_PROGRAM`;
const GET_APP_URL = `${feat}/GET_APP_URL`;
const GET_FILE_TEMPLATE = `${feat}/GET_FILE_TEMPLATE`;
const GET_SERVER_DATE_TIME = `${feat}/GET_SEVER_DATE_TIME`;
const GET_TERMS = `${feat}/GET_TERMS`;

export {
  GET_USERINFO,
  GET_USER_ORGANIZATION,
  GET_USER_PROGRAMS,
  GET_USER_ADMINS,
  GET_USER_ORGS,
  GET_ORG_HIERARCHY,
  GET_CHILD_ORGS,
  GET_USER_PERMISSIONS,
  GET_ALL_ADMINS_BY_PROGRAM,
  GET_APP_URL,
  GET_FILE_TEMPLATE,
  GET_SERVER_DATE_TIME,
  GET_TERMS,
};

const Config = {
  set config(config) {
    this.conf = config;
  },
  get apiUrl() {
    return this.conf.tcm.apiUrl;
  },
  get cognitoUrl() {
    return this.conf.iam.apiUrl;
  },
  get APP_CLIENT() {
    return this.conf.iam.APP_CLIENT;
  },
  get USER_POOL() {
    return this.conf.iam.USER_POOL;
  },
  get version() {
    return this.conf.version;
  },
  get checkUrl() {
    return this.conf.networkCheckUrl;
  },
};

export default Config;

import http from '../core/services/Http';
import Auth from '../core/services/AuthToken';
import Config from '../core/config';
import cookie from 'react-cookies';
import axios from 'axios';

const personalInformationUrl = '/tcm/personnel';
const cognitoUsersUrl = '/users';
const nowQueryUrl = '/tcm/query';

export default class UserServices {
  static createPersonalInfo(data) {
    return http.post(`${Config.apiUrl}${personalInformationUrl}`, data);
  }

  static updatePersonalInfo(data) {
    return http.put(`${Config.apiUrl}${personalInformationUrl}`, data);
  }

  static loginUser(data) {
    const user = {
      username: data.email,
      password: data.password,
      tncVersion: data.tnc,
      userPool: Config.USER_POOL,
      appClient: Config.APP_CLIENT,
    };
    return http.post(`${Config.cognitoUrl}${cognitoUsersUrl}/login`, user);
  }

  static logoutUser() {
    return http.get(`${Config.cognitoUrl}${cognitoUsersUrl}/logout`);
  }

  static refreshTokens(rToken) {
    const appClient = cookie.load('client');
    return http.post(`${Config.cognitoUrl}${cognitoUsersUrl}/refreshTokens`, {
      userPool: Config.USER_POOL,
      refreshToken: rToken,
      appClient: appClient ? appClient : Config.APP_CLIENT,
    });
  }

  static getUserInfo() {
    return http.get(`${Config.apiUrl}/tcm/profile`);
  }

  static getUserTerms(personnelId) {
    return http.get(`${Config.apiUrl}/tcm/personnel/${personnelId}/availableterms/active`);
  }

  static userOrgPartId(personnelId, orgPartId) {
    return http.put(`${Config.apiUrl}/now/personnel/${personnelId}/orgpart/${orgPartId}`);
  }

  static acceptUserTerms(personnelId, termID) {
    return http.post(`${Config.apiUrl}/tcm/personnel/${personnelId}/terms/${termID}/accept`);
  }

  static getUserOrganization(programId) {
    return http.get(`${Config.apiUrl}/tcm/query/orgPart?programId=${programId}`);
  }

  static setLoginToken(data) {
    Auth.setAuthTokens(data);
  }

  static verifyUserExists(data) {
    return http.get(
      `${Config.cognitoUrl}${cognitoUsersUrl}/exists?username=${data.email}&userPool=${Config.USER_POOL}`
    );
  }

  static registerUser(data) {
    const user = {
      username: data.email,
      password: data.password,
      userPool: Config.USER_POOL,
      appClient: Config.APP_CLIENT,
      tncVersion: data.tncVersion,
      birthDate: '01-01-2000', // hard-coded to be over 13 years
      commPref: 'email',
      ...(data.recaptcha && { recaptchaCode: data.recaptcha }),
    };

    return http.post(`${Config.cognitoUrl}${cognitoUsersUrl}/register`, user);
  }

  static getTnc() {
    return http.get(`${Config.cognitoUrl}/config/terms?userPool=${Config.USER_POOL}`);
  }

  static resendVerificationCode(username) {
    const payload = {
      username,
      userPool: Config.USER_POOL,
      appClient: Config.APP_CLIENT,
      option: 'OVERRIDE',
    };

    return http.post(`${Config.cognitoUrl}${cognitoUsersUrl}/resendVerificationCode`, payload);
  }

  static verifyUser(data) {
    const payload = { ...data };
    payload.userPool = Config.USER_POOL;

    return http.post(`${Config.cognitoUrl}${cognitoUsersUrl}/verify`, payload);
  }

  static forgotPassword(username) {
    const payload = {
      username,
      userPool: Config.USER_POOL,
      appClient: Config.APP_CLIENT,
    };

    return http.post(`${Config.cognitoUrl}${cognitoUsersUrl}/forgotPassword`, payload);
  }

  static resetPassword(data) {
    const payload = {
      username: data.username,
      verificationCode: data.validationCode,
      newPassword: data.password,
      userPool: Config.USER_POOL,
    };

    return http.post(`${Config.cognitoUrl}${cognitoUsersUrl}/completeForgotPassword`, payload);
  }

  static getUserPrograms() {
    return http.get(`${Config.apiUrl}${nowQueryUrl}/programSelector`);
  }

  static getUserAdmins(programId) {
    return http.get(
      `${Config.apiUrl}${nowQueryUrl}/adminSelector?programId=${programId}&activeAdmin=true`
    );
  }

  static getAllAdminsByProgram(programs) {
    return axios
      .all(
        programs.map(program =>
          UserServices.getUserAdmins(program.programId).then(result => ({
            programId: program.programId,
            admins: result.data.data,
          }))
        )
      )
      .then(result => ({ data: result }));
  }

  static getUserOrgs(adminId) {
    return http.get(`${Config.apiUrl}${nowQueryUrl}/orgSelector?adminId=${adminId}`);
  }
}

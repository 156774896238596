import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions as orgManagementActions } from '../duck';
import OverviewTile from '../../students/students-overview/OverviewTile';
import Alert from '../../../components/alerts/Alert';
import * as Text from '../../../core/i18n/text';
import _ from 'lodash/fp';
import './AdministrationOverview.scss';
import { Helmet } from 'react-helmet';

class AdministrationOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      participation: [],
    };
  }

  componentDidMount() {
    if (this.props.currentOrg && _.get('currentOrg.orgPartId')(this.props)) {
      this.props.actions.getParticipationStatus({
        orgPartId: this.props.currentOrg.orgPartId,
        adminId: this.props.currentOrg.adminId,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !_.isEqual(this.props.participationStatus)(prevProps.participationStatus) &&
      _.get('participationStatus.data')(this.props)
    ) {
      this.setState({
        participation: this.getParticipation(this.props.participationStatus.data[0]),
      });
    }
    if (
      !_.isEqual(this.props.currentOrg)(prevProps.currentOrg) &&
      _.get('currentOrg.orgPartId')(this.props)
    ) {
      this.props.actions.getParticipationStatus({
        orgPartId: this.props.currentOrg.orgPartId,
        adminId: this.props.currentOrg.adminId,
      });
    }
  }

  getParticipation = entry => {
    if (
      Number(entry.activeCount) !== 0 ||
      Number(entry.inactiveCount) !== 0 ||
      Number(entry.pendingCount) !== 0
    ) {
      return [
        {
          label: Text.features.orgManagement.overview.participationStatus.participating,
          value: parseInt(entry.activeCount),
        },
        {
          label: Text.features.orgManagement.overview.participationStatus.notParticipating,
          value: parseInt(entry.inactiveCount),
        },
        {
          label: Text.features.orgManagement.overview.participationStatus.pending,
          value: parseInt(entry.pendingCount),
        },
      ];
    }
    return [];
  };

  render() {
    return (
      <div className="OrgManagementOverview__Container">
        <Helmet>
          <title>Participation Overview - ACT-Now</title>
        </Helmet>
        {this.state.participation.length ? (
          <>
            {this.state.participation && (
              <OverviewTile
                title={Text.features.orgManagement.overview.participationStatus.title}
                data={this.state.participation}
                chartUnits={'schools'}
                id={'participationChart'}
              />
            )}
          </>
        ) : (
          <Alert type="info" message={Text.features.orgManagement.overview.emptyState} />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    participationStatus: state.orgManagementReducer.participationStatus,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ ...orgManagementActions }, dispatch),
  };
};

export { AdministrationOverview };

export default connect(mapStateToProps, mapDispatchToProps)(AdministrationOverview);

const countArray = [25, 50, 75, 100];

const jobTypeCode = {
  contract: 'org',
  orgManagement: 'org_part',
  staffInvite: 'org_part_personnel',
};

const organizations = {
  districtColumnsList: [
    {
      checked: true,
      name: 'Code',
    },
    {
      checked: true,
      name: 'Type',
    },
    {
      checked: true,
      name: 'Status',
    },
  ],
  stateColumnsList: [
    {
      checked: true,
      name: 'Code',
    },
    {
      checked: true,
      name: 'Type',
    },
    {
      checked: true,
      name: 'Status',
    },
  ],
};

const personalInformation = {
  createPayload: {
    status: { status: 'active' },
    firstName: '',
    middleName: '',
    lastName: '',
    emailAddress: '',
    address1: '',
    address2: '',
    address3: '',
    city: '',
    stateCode: '',
    postalCode: '',
    cellPhone: '',
    workPhone: '',
    workPhoneExt: '',
    usCitizenFlag: false,
    teachingFlag: false,
    coachingFlag: false,
    counselingFlag: false,
    testPrepFlag: false,
    academicAdminFlag: false,
    clericalSupportFlag: false,
    standardTestFlag: false,
    otherJobFlag: false,
    otherJobDesc: '',
    educationLevel: null,
    expPrimaryAssessmentFlag: false,
    expCollegeAssessmentFlag: false,
    expGradAdmissionFlag: false,
    expCertLicenseFlag: false,
    expCbtFlag: false,
    gender: null,
    hispanicOrLatino: null,
    taAdminCount: 0,
    totalTestingExp: 0,
    firstActYear: 0,
    createdDate: '',
    modifiedDate: '',
    previouslyAdministeredTests: '',
    personnelRaces: [],
    testCenterCyclePersonnels: [],
    testCenterEventPersonnels: [],
    inineFlag: false,
  },
};

const roster = {
  districtAndStateTypeList: [
    {
      checked: true,
      name: 'Assigned Event',
    },
    {
      checked: true,
      name: 'ACT Student Code',
    },
    {
      checked: true,
      name: 'Reporting High School code',
    },
    {
      checked: true,
      name: 'State Student ID',
    },
    {
      checked: true,
      name: 'Timing Category',
    },
    {
      checked: true,
      name: 'Delivery Method',
    },
    {
      checked: true,
      name: 'Test Option',
    },
    {
      checked: true,
      name: 'Grade',
    },
    {
      checked: true,
      name: 'Date of Birth',
    },
    {
      checked: true,
      name: 'Gender',
    },
    {
      checked: true,
      name: 'School Name',
    },
  ],
  schoolTypeList: [
    {
      checked: true,
      name: 'Assigned Event',
    },
    {
      checked: true,
      name: 'ACT Student Code',
    },
    {
      checked: true,
      name: 'Reporting High School code',
    },
    {
      checked: true,
      name: 'State Student ID',
    },
    {
      checked: true,
      name: 'Timing Category',
    },
    {
      checked: true,
      name: 'Delivery Method',
    },
    {
      checked: true,
      name: 'Test Option',
    },
    {
      checked: true,
      name: 'Grade',
    },
    {
      checked: true,
      name: 'Date of Birth',
    },
    {
      checked: true,
      name: 'Gender',
    },
    {
      checked: true,
      name: 'School Name',
    },
  ],
};

const users = {
  manageList: [
    {
      checked: true,
      name: 'Email',
    },
    {
      checked: true,
      name: 'Organization',
    },
    {
      checked: true,
      name: 'Roles',
    },
    {
      checked: true,
      name: 'Status',
    },
  ],
};

export { countArray, jobTypeCode, organizations, personalInformation, roster, users };

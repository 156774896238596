import http from '../core/services/Http';
import Config from '../core/config';

const personalInformationUrl = '/tcm/personnel';

export default class NotificationServices {
  static getNotificationsCount(userId, status) {
    return http.get(
      `${Config.apiUrl}${personalInformationUrl}/${userId}/notificationscount?status=${status}`
    );
  }

  static getNotifications(data) {
    return http.get(
      `${Config.apiUrl}${personalInformationUrl}/${data.userId}/notifications?limit=${data.limit}&skip=${data.skip}`
    );
  }

  static getNotificationsByStatus(data) {
    return http.get(
      `${Config.apiUrl}${personalInformationUrl}/${data.userId}/notifications?limit=${data.limit}&skip=${data.skip}&status=${data.status}`
    );
  }

  static readNotification(data) {
    return http.post(
      `${Config.apiUrl}${personalInformationUrl}/${data.userId}/marknotificationasread`,
      {
        notificationId: data.notification.notificationId,
      }
    );
  }
}

import Axios from 'axios';
import http from '../core/services/Http';
import Config from '../core/config';
import _ from 'lodash/fp';
import { toQueryString } from './helpers/urlHelper';

const nowOrgUrl = '/now/org';
const nowQueryListUrl = '/tcm/query';

export default class OrgManagementServices {
  static async uploadFile(data, orgId) {
    const initUpload = await http.post(`${Config.apiUrl}${nowOrgUrl}/${orgId}/initializeJob`, data);
    let uploadFile;
    const { jobId, url } = initUpload.data;
    if (initUpload.status === 200 && jobId && url) {
      try {
        uploadFile = await Axios.put(`${url}`, data.file);
        if (uploadFile.status === 200) {
          return http.post(`${Config.apiUrl}${nowOrgUrl}/${orgId}/executeJob/${jobId}`, [
            data.fileName,
          ]);
        }
      } catch {
        return http.put(`${Config.apiUrl}${nowOrgUrl}/${orgId}/job/${jobId}/stopJob`);
      }
    }
  }

  static getUploadedFiles({ adminId, limit, orgId, page }) {
    return http.get(
      `${Config.apiUrl}${nowOrgUrl}/${orgId}/jobs/org_part/admin/${adminId}?limit=${limit}&page=${page}`
    );
  }

  static getStaffUploadedFiles({ adminId, limit, orgId, page }) {
    return http.get(
      `${Config.apiUrl}${nowOrgUrl}/${orgId}/jobs/org_part_personnel/admin/${adminId}?limit=${limit}&page=${page}`
    );
  }

  static getDownloadUrls(orgPartId, jobId) {
    return http.get(`${Config.apiUrl}${nowOrgUrl}/${orgPartId}/getDownloadUrls/${jobId}`);
  }

  static getStaffDownloadUrls(orgPartId, jobId) {
    return http.get(`${Config.apiUrl}${nowOrgUrl}/${orgPartId}/getDownloadUrls/${jobId}`);
  }

  static getInvitationStatus({ orgPartId }) {
    return http.get(`${Config.apiUrl}/tcm/query/orgParticipation?orgPartId=${orgPartId}`);
  }

  static respondToInvite(data) {
    return http.put(`${Config.apiUrl}/now/orgpart`, data);
  }

  static getParticipationStatus(params) {
    return http.get(
      `${Config.apiUrl}${nowQueryListUrl}/orgPartOverviewChart?orgPartId=${params.orgPartId}&adminId=${params.adminId}`
    );
  }

  static getStaff(params) {
    const queryString = ['adminId', 'orgPartId']
      .concat(
        ['email', 'filter', 'limit', 'offset', 'orgId', 'roles', 'status'].filter(
          key => params[key]
        )
      )
      .map(key => {
        switch (key) {
          case 'roles':
            return params[key].split(',').length > 1
              ? `roles=${params[key]}`
              : `role=${params[key]}`;
          default:
            return `${key}=${params[key]}`;
        }
      })
      .join('&');

    return http.get(`${Config.apiUrl}${nowQueryListUrl}/orgPartUsers?${queryString}`);
  }

  static getRoles(params) {
    return http.get(
      `${Config.apiUrl}${nowQueryListUrl}/personnelWithRoles?orgPartId=${params.orgPartId}&email=${params.adminId}`
    );
  }

  static verifyUserByEmail(email, orgPartId) {
    return http.get(
      `${Config.apiUrl}${nowQueryListUrl}/verifystaffbyemail?email=${email}&orgPartId=${orgPartId}`
    );
  }

  static getRolesByOrg({ orgPartId }) {
    return http.get(`${Config.apiUrl}${nowQueryListUrl}/delegatableRole?orgPartId=${orgPartId}`);
  }

  static getUserRoles(orgPartId, email) {
    return http.get(
      `${Config.apiUrl}${nowQueryListUrl}/personnelWithRoles?orgPartId=${orgPartId}&email=${email}`
    );
  }

  static getAllUserRoles(userAssignments) {
    return Axios.all(
      userAssignments.map(userAssignment =>
        OrgManagementServices.getUserRoles(
          userAssignment.orgPartId,
          userAssignment.emailAddress
        ).then(res => ({ list: [...res.data.data], orgPartId: userAssignment.orgPartId }))
      )
    ).then(result => ({ data: result }));
  }

  static getAvailableTestingEvents({ orgPartId }) {
    return http.get(
      `${Config.apiUrl}${nowQueryListUrl}/activeTestingEvents?orgPartId=${orgPartId}`
    );
  }

  static initializeTechnicalReadiness(body) {
    return http.post(`${Config.apiUrl}/now/technicalreadiness/testnav`, body, {
      validateStatus: null,
    });
  }

  static inviteUserWithRoles(body) {
    return http.post(`${Config.apiUrl}/now/orgpartinvitation`, body);
  }

  static authorizeUserWithRoles(body) {
    return http.post(`${Config.apiUrl}/now/orgpartpersonnel`, body);
  }

  static saveInactiveUserRoles(body) {
    return http.put(`${Config.apiUrl}/now/orgpartinvitation`, body);
  }

  static saveActiveUserRoles(body) {
    return http.put(`${Config.apiUrl}/now/orgpartpersonnel`, body);
  }

  static getOrgPartDescendants(orgPartId, adminId) {
    return http.get(
      `${Config.apiUrl}${nowQueryListUrl}/orgPartDescendants?orgPartId=${orgPartId}&adminId=${adminId}`
    );
  }

  static inviteOrg(body) {
    return http.post(`${Config.apiUrl}/now/orgpart`, body);
  }

  static getDescendantOrgTypes(parentOrgType) {
    return http.get(
      `${Config.apiUrl}${nowQueryListUrl}/possibleDescendantOrgTypes?parentOrgType=${parentOrgType}`
    );
  }

  static getDescendantOrgs(params) {
    params.status = params.status ? params.status : [];

    const queryString = params && _.isObject(params) ? `?${toQueryString(params)}` : '';
    return http.get(`${Config.apiUrl}${nowQueryListUrl}/rosterDescendantOrgs${queryString}`);
  }

  static getBulkNotification() {
    return http.get(`${Config.apiUrl}/now/bulkNotification/types`);
  }

  static sendBulkNotification(body) {
    return http.post(`${Config.apiUrl}/now/bulkNotification/send`, body);
  }

  static getOperationalReports(orgPartId, category) {
    return http.get(`${Config.apiUrl}/now/orgPart/${orgPartId}/reports?category=${category}`);
  }

  static downloadOperationalReports(orgPartId, reportId) {
    return http.get(`${Config.apiUrl}/now/orgPart/${orgPartId}/report/${reportId}`);
  }

  static generateOperationalReports(body) {
    return http.post(`${Config.apiUrl}/now/report/generate`, body);
  }

  static getTestingEventReceiptWindows(adminId) {
    return http.get(`${Config.apiUrl}/tcm/query/testingEventReceiptWindows?adminId=${adminId}`);
  }

  static validateAddress(body) {
    return http.post(`${Config.apiUrl}/now/address/validation`, body);
  }

  static getIndividualOrgInfo(orgId) {
    return http.get(`${Config.apiUrl}/tcm/query/org?orgId=${orgId}`);
  }

  static getProcessedOrder(orgPartId) {
    return http.get(`${Config.apiUrl}/tcm/query/orgPartOrderCount?orgPartId=${orgPartId}`);
  }

  static getShippingDetails(orgPartId) {
    return http.get(`${Config.apiUrl}/now/orgpart/${orgPartId}/shippingDetails`);
  }
}

import React from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { actions as rosterActions } from '../duck';
import AlertModal from '../../../components/modal/AlertModal';
import * as Text from '../../../core/i18n/text';
import _ from 'lodash/fp';
import TextArea from '../../../components/forms/TextArea';

class ApproveDeclineTransferRequestModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      body: {
        id: '',
        regId: '',
        sourceOrgPartId: '',
        destinationOrgPartId: '',
        status: '',
        declineReason: '',
        notes: '',
      },
      errors: {
        declineReason: '',
      },
      nrCharactes: 0,
      showConfirmationModal: true,
    };
  }

  componentDidMount() {
    const { approve } = this.props;
    const {
      regTransferId,
      regId,
      sourceOrgPartId,
      destinationOrgPartId,
      notes,
    } = this.props.request;
    this.setState({
      body: {
        id: regTransferId,
        regId,
        sourceOrgPartId,
        destinationOrgPartId,
        status: approve ? 'accepted' : 'declined',
        declineReason: '',
        notes: notes,
      },
      showConfirmationModal: false,
      notificationText: '',
    });
  }

  handleClickButton = () => {
    const { body } = this.state;
    if (!this.props.approve && body.declineReason.trim() === '') {
      this.setState(prevState => ({
        errors: {
          ...prevState.errors,
          declineReason: Text.messages.error.fieldIsRequired,
        },
      }));
      return;
    }
    this.props.actions.updateStudentTransferRequest(this.state.body);
  };

  handleConfirmationButton = () => {
    this.props.actions.updateStudentTransferRequest(this.state.body);
    this.setState({
      showConfirmationModal: false,
    });
  };

  componentDidUpdate(prevProps) {
    if (
      _.get('data')(this.props.updateStudentTransferRequest) &&
      !_.isEqual(prevProps.updateStudentTransferRequest)(this.props.updateStudentTransferRequest)
    ) {
      this.props.refresh();
      this.props.closeModal();
      this.props.showNotification(
        this.props.approve
          ? Text.features.roster.studentTransfer.approveNotification
          : Text.features.roster.studentTransfer.declineNotification
      );
    }
  }

  formatDetails = value => {
    return value === '' || value === null ? '-' : value;
  };

  generateRequestDetails = request => {
    const requestDetails = [
      this.formatDetails(request.externalStudentId),
      this.formatDetails(request.grade),
      this.formatDetails(request.dateOfBirth),
      this.formatDetails(request.currentSchoolName),
      this.formatDetails(request.personnelName),
      this.formatDetails(request.currentDistrictName),
      this.formatDetails(request.submitDt),
      this.formatDetails(request.notes),
    ];
    let body = Text.features.roster.studentTransfer.approveDeclineModal.details.map(
      (entry, index) => {
        return (
          <>
            {index < 7 ? (
              <div className="modal__Container" key={index + 1}>
                <div className="modal__Desc">{entry}</div>
                <div>{requestDetails[index]}</div>
              </div>
            ) : (
              <>
                <div className="modal__Desc">{entry}</div>
                <div>{requestDetails[index]}</div>
              </>
            )}
          </>
        );
      }
    );

    body.unshift(
      <div
        className="RequestTransfer___StudentName"
        key="0">{`${request.lastName}, ${request.firstName} ${request.middleName}`}</div>
    );
    return body;
  };

  handleTextAreaChange = event => {
    this.setState({
      nrCharactes: event.target.value.length,
      body: {
        ...this.state.body,
        declineReason: event.target.value,
      },
      errors: {
        ...this.state.errors,
        declineReason: '',
      },
    });
  };

  handleConfirmationModal = state => {
    this.setState({
      showConfirmationModal: state,
    });
  };

  render() {
    const { showModal, closeModal, approve, request } = this.props;

    return (
      <>
        <AlertModal
          className="DeclineTransferRequest__ModalContainer"
          show={showModal}
          title={
            Text.features.roster.studentTransfer.approveDeclineModal.title[
              `${approve ? 'approve' : 'decline'}`
            ]
          }
          body={[
            <div className="DeclineTransferRequest___Inputs">
              {this.generateRequestDetails(request)}
            </div>,
            <>
              {!approve && (
                <>
                  <div
                    className={`${
                      this.state.errors.declineReason ? 'field__Label-error' : ''
                    } RequestTransfer__Note`}>
                    {Text.features.roster.studentTransfer.approveDeclineModal.declineReason}
                  </div>
                  <TextArea
                    nrCharactes={this.state.nrCharactes}
                    maxLength="360"
                    handleTextAreaChange={this.handleTextAreaChange}
                    error={this.state.errors.declineReason}
                  />
                </>
              )}
            </>,
          ]}
          close={closeModal}
          buttonText={
            Text.features.roster.studentTransfer.approveDeclineModal.title[
              `${approve ? 'approve' : 'decline'}`
            ]
          }
          buttonAction={this.handleClickButton}
          showButton={true}
        />
        {this.state.showConfirmationModal && (
          <AlertModal
            className="ConfirmationApproveDeclineModal__Container"
            show={this.state.showConfirmationModal}
            buttonAction={this.handleConfirmationButton}
            close={() => this.handleConfirmationModal(false)}
            title={Text.features.roster.studentTransfer.approveDeclineModal.confirmationModal.title}
            body={[
              <div>
                {Text.features.roster.studentTransfer.approveDeclineModal.confirmationModal.body}
              </div>,
            ]}
            showButton={true}
            buttonText={
              Text.features.roster.studentTransfer.approveDeclineModal.confirmationModal.button
            }
            secondaryButton={{
              text: 'Back',
              action: () => this.handleConfirmationModal(false),
              redButton: false,
            }}
          />
        )}
      </>
    );
  }
}

ApproveDeclineTransferRequestModal.displayName = 'ApproveDeclineTransferRequestModal';
ApproveDeclineTransferRequestModal.defaultProps = {
  noHeader: false,
};
ApproveDeclineTransferRequestModal.propTypes = {
  showModal: propTypes.bool,
  closeModal: propTypes.func,
  approve: propTypes.bool,
};

const mapStateToProps = state => {
  const { rosterReducer } = state;
  return {
    updateStudentTransferRequest: rosterReducer.updateStudentTransferRequest,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ ...rosterActions }, dispatch),
  };
};

export { ApproveDeclineTransferRequestModal };
export default connect(mapStateToProps, mapDispatchToProps)(ApproveDeclineTransferRequestModal);

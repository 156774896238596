import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import Validator from 'email-validator';

import ModalInfo from '../../../components/modal-info/ModalInfo';

import Header from '../../../components/forms/FormHeader';
import Email from '../../../components/forms/fields/Input';
import Password from '../../../components/forms/fields/Password';
import Alert from '../../../components/alerts/Alert';
import { actions } from '../duck';
import * as Text from '../../../core/i18n/text';
import Auth from '../../../core/services/AuthToken';
import Parser from 'html-react-parser';

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      termsModalOpen: false,
      displayRequiredMessage: {
        password: false,
      },
      errors: {
        noInternet: navigator.onLine,
        email: '',
        userNotConfirmed: false,
        accountVerification: null,
      },
    };
    this.resendVerification = this.resendVerification.bind(this);
    this.validateInput = this.validateInput.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.loginError && nextProps.loginError.includes('User account not verified')) {
      this.setState(prevState => ({
        errors: {
          ...prevState.errors,
          userNotConfirmed: true,
        },
      }));
    }
    if (nextProps.loginError === 'INVALID_TERMS_AND_CONDITIONS') {
      this.toggleModal();
      this.setState({
        invalidTermsError: 'errors.invalidTerms',
      });
      return;
    }
    if (
      nextProps.accountVerificationError &&
      nextProps.accountVerificationError !== this.props.accountVerificationError
    ) {
      this.setState(prevState => ({
        errors: {
          ...prevState.errors,
          accountVerification: nextProps.accountVerificationError,
        },
      }));
    }
  }

  onSubmit(e, tncVersion) {
    e.preventDefault();
    if (this.validateInput()) {
      this.props.actions.login(this.state.email, this.state.password, tncVersion);
      this.props.actions.getTnc();
    }
    if (this.state.errors.accountVerification) {
      this.setState({
        errors: {
          ...this.state.errors,
          accountVerification: null,
        },
      });
    }
  }

  validateInput = e => {
    const isEmailEmpty = this.state.email.trim() === '' ? true : false;
    const isPasswordEmpty = this.state.password === '' ? true : false;
    const isEmailValid = Validator.validate(this.state.email.trim());
    const emailError = isEmailEmpty
      ? Text.messages.error.emailRequired
      : isEmailValid
      ? ''
      : Text.messages.error.emailInvalid;
    this.setState({
      displayRequiredMessage: {
        password: isPasswordEmpty,
      },
      errors: {
        noInternet: navigator.onLine,
        email: emailError,
      },
    });
    return isEmailEmpty || isPasswordEmpty || !isEmailValid || !navigator.onLine ? false : true;
  };

  onEmailChange = event => {
    let email = event.target.value.trim();
    this.setState({
      email,
    });
  };

  onPasswordChange = password => {
    this.setState({
      password,
    });
  };

  toggleModal() {
    this.setState(prevState => ({
      termsModalOpen: !prevState.termsModalOpen,
    }));
  }

  resendVerification() {
    this.props.actions.resendVerificationCode(this.state.email);
    if (this.props.accountVerificationError) {
      this.setState({
        errors: {
          ...this.state.errors,
          accountVerification: this.props.accountVerificationError,
        },
      });
    }
  }

  render() {
    if (Auth.authToken) {
      return <Redirect to={{ to: '/landingPage', state: { fromLogin: true } }} />;
    }

    let buttons;
    if (this.state.errors.userNotConfirmed) {
      buttons = (
        <div>
          <button
            onClick={this.resendVerification}
            type="button"
            className="bigButton"
            disabled={this.props.isAccountVerificationPending}>
            {Text.clickables.buttons.resendAccountVerification}
          </button>
          <button
            type="submit"
            className="openButton"
            disabled={this.props.isLoginPending || this.props.isAccountVerificationPending}
            value={Text.clickables.buttons.login}>
            {Text.clickables.buttons.login}
          </button>
        </div>
      );
    } else {
      buttons = (
        <button
          type="submit"
          className="bigButton"
          disabled={this.props.isLoginPending}
          value={Text.clickables.buttons.login}>
          {Text.clickables.buttons.login}
        </button>
      );
    }

    return (
      <div className="loginForm__Container">
        <Header title={Text.features.login.title} description={[Text.features.login.description]} />
        {!this.state.errors.noInternet && (
          <Alert type="error" message={Text.messages.error.connection} />
        )}
        {this.props.loginError && (
          <Alert
            type="error"
            message={this.state.errors.accountVerification ?? this.props.loginError}
          />
        )}
        <form onSubmit={this.onSubmit}>
          <div className="loginForm__fieldsContainer">
            <Email
              name={'email'}
              label={Text.common.fields.email}
              value={this.state.email}
              error={this.state.errors.email}
              onChange={this.onEmailChange}
            />
            <Password
              displayEmpty={this.state.displayRequiredMessage.password}
              onChange={this.onPasswordChange}
            />
          </div>
          {buttons}
        </form>
        <div className="loginForm__Footer">
          <button className="loginForm__Link link" href="#" onClick={this.props.forgotPassword}>
            {Text.clickables.links.fpQuestion}
          </button>
          <div>
            {Text.clickables.links.dontHaveAccount} &nbsp;
            <a className="link" href="/register">
              {Text.clickables.links.createAccount}
            </a>
          </div>
        </div>

        {this.props.tnc && (
          <ModalInfo
            className="irregularity-modal"
            modalIsOpen={this.state.termsModalOpen}
            closeModal={this.toggleModal}
            onSubmit={e => this.onSubmit(e, this.props.tnc.UUID)}
            headerTitle={Text.features.landing.termsAndConditions.termsMessage}
            saveLabel={Text.features.landing.termsAndConditions.acceptTerms}>
            <div className="terms-content">{Parser(this.props.tnc.Terms)}</div>
          </ModalInfo>
        )}

        {this.props.isLoginSuccess && (
          <Redirect to={{ to: '/landingPage', state: { fromLogin: true } }} />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { loginReducer } = state;
  return {
    isLoginPending: loginReducer.login.loading,
    isLoginSuccess: loginReducer.login.data,
    loginError: loginReducer.login.error,
    isAccountVerificationPending: loginReducer.accVerification.loading,
    accountVerificationError: loginReducer.accVerification.error,
    tnc: loginReducer.tnc.data,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
};

export { LoginForm };
export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);

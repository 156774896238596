import React from 'react';
import uploadIcon from '../../../assets/svgs/uploadIcon.svg';
import { ReactComponent as AddIcon } from '../../../assets/svgs/addIcon.svg';
import * as Text from '../../../core/i18n/text';

function EmptyContractOrg(props) {
  return (
    <div className="OrgManagementUsers__Container">
      <div className="OrgManagementUsers__Title">
        {Text.features.contract.organization.emptyState.title}
      </div>
      <div className="OrgManagementUsers__Description">
        {Text.features.contract.organization.emptyState.description}
      </div>
      <span className="OrgManagementUsers__ButtonsGroup">
        <button
          className={'bigButton OrgManagementUsers__Button'}
          onClick={() =>
            props.history.push(`/contract/files/orgPartId/${props.match.params.orgPartId}`)
          }>
          <img src={uploadIcon} alt=""></img>
          {Text.features.contract.organization.emptyState.uploadButtonLabel}
        </button>
        <button className={'openButton OrgManagementUsers__Button'} onClick={props.handleAddOrg}>
          <AddIcon />
          {Text.features.contract.organization.addOrgLabel}
        </button>
      </span>
    </div>
  );
}

export default EmptyContractOrg;

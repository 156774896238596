import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions as rosterActions } from '../duck';
import { withRouter } from 'react-router-dom';
import AlertModal from '../../../components/modal/AlertModal';
import Input from '../../../components/forms/fields/Input';
import * as Text from '../../../core/i18n/text';
import _ from 'lodash/fp';
import MagnifyingGlass from '@material-ui/icons/SearchRounded';
import TextArea from '../../../components/forms/TextArea';
import Alert from '../../../components/alerts/Alert';
import ValidationHelper from '../../../services/helpers/validationHelper';

class RequestTransferModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lastName: '',
      studentID: '',
      dateOfBirth: '',
      keyCode: null,
      errors: {
        lastName: '',
        studentID: '',
        dateOfBirth: '',
      },
      studentDetails: null,
      nrCharactes: 0,
      alreadyAssigned: false,
      transferBody: {
        regId: '',
        sourceOrgPartId: '',
        destinationOrgPartId: '',
        notes: '',
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.searchStudent)(this.props.searchStudent)) {
      if (_.get('data')(this.props.searchStudent)) {
        if (this.props.searchStudent.data.length > 0) {
          if (this.props.searchStudent.data[0].orgPartId === this.props.match.params.orgPartId) {
            this.setState({ alreadyAssigned: true, studentDetails: null });
          } else {
            let student = this.props.searchStudent.data[0];
            this.setState({
              alreadyAssigned: false,
              studentDetails: this.generateStudentDetails(student),
              transferBody: {
                regId: student.id,
                sourceOrgPartId: student.orgPartId,
                destinationOrgPartId: this.props.match.params.orgPartId,
              },
            });
          }
        } else {
          this.setState({ alreadyAssigned: false, studentDetails: {} });
        }
      }
    }

    if (
      !_.isEqual(prevProps.requestStudentTransfer)(this.props.requestStudentTransfer) &&
      _.get('data')(this.props.requestStudentTransfer)
    ) {
      this.props.showNotification();
      this.props.closeModal();
    }
  }

  formatDetails = value => {
    return value === '' || value === null ? '-' : value;
  };

  generateStudentDetails = student => {
    const studentDetails = [
      this.formatDetails(student.externalStudentId),
      this.formatDetails(student.grade),
      this.formatDetails(student.dateOfBirth),
      this.formatDetails(student.currentSchoolName),
      this.formatDetails(student.currentDistrictName),
    ];
    let body = Text.features.roster.studentTransfer.details.map((entry, index) => {
      return (
        <div className="modal__Container" key={index + 1}>
          <div className="modal__Desc">{entry}</div>
          <div>{studentDetails[index]}</div>
        </div>
      );
    });

    body.unshift(
      <div
        className="RequestTransfer___StudentName"
        key="0">{`${student.lastName}, ${student.firstName} ${student.middleName}`}</div>
    );
    return body;
  };

  handleTextAreaChange = event => {
    this.setState({
      nrCharactes: event.target.value.length,
      transferBody: {
        ...this.state.transferBody,
        notes: event.target.value,
      },
    });
  };

  onInputChange = event => {
    const inputValue = event.target.value.trim();
    const inputName = event.target.name;
    this.setState({
      [inputName]: inputValue,
    });
  };

  handleKeyDown = e => {
    this.setState({
      keyCode: e.keyCode,
    });
  };

  handleDateChange = e => {
    const inputValue = e.target.value;
    const inputName = e.target.name;

    this.setState(prevState => ({
      errors: {
        ...prevState.errors,
        [inputName]: '',
      },
    }));
    const keyCode = this.state.keyCode;
    let regex = new RegExp('^[0-9/]+$');
    if (regex.test(inputValue) || inputValue.length === 0) {
      const formattedDate = ValidationHelper.dateFormatter(inputName, inputValue, keyCode);
      this.setState({
        [inputName]: formattedDate,
      });
    }
  };

  stringFormater = nr => {
    return nr > 9 ? nr.toString() : nr.toString().padStart(2, '0');
  };

  getFormatedDate = date => {
    let day = this.stringFormater(date.getDate());
    let month = this.stringFormater(date.getMonth() + 1);
    let year = this.stringFormater(date.getFullYear());
    return `${month}/${day}/${year}`;
  };

  isStringValid = string => {
    const format = /[$<>~*%:\\()=+"^!@#&_{}`;/|?]/;
    return !format.test(string);
  };

  isStringAlphaNumeric = string => {
    const format = /^[a-z0-9]+$/;
    return format.test(string);
  };

  validateInputs = () => {
    const { errors, lastName, studentID, dateOfBirth } = this.state;

    if (lastName.trim() === '') {
      errors['lastName'] = `${Text.messages.error.lastNameRequired}`;
    } else if (!this.isStringValid(lastName)) {
      errors['lastName'] = `${Text.messages.error.nameInvalidNumbers}`;
    } else {
      errors['lastName'] = '';
    }

    if (studentID.trim() === '') {
      errors['studentID'] = `${Text.messages.error.studentIDRequired}`;
    } else if (!this.isStringAlphaNumeric(studentID)) {
      errors['studentID'] = `${Text.messages.error.onlyAlphanumeric}`;
    } else {
      errors['studentID'] = '';
    }

    errors['dateOfBirth'] = dateOfBirth ? '' : `${Text.messages.error.dobRequired}`;

    const isValidFormat = ValidationHelper.validDateFormat(this.state.dateOfBirth);
    const isValidDate = ValidationHelper.validDate(this.state.dateOfBirth);
    if (!isValidFormat && this.state.dateOfBirth) {
      errors.dateOfBirth = `${Text.messages.error.dateFormat}`;
    }

    if (isValidFormat && !isValidDate && this.state.dateOfBirth) {
      errors.dateOfBirth = `${Text.messages.error.invalidDate}`;
    }

    this.setState({
      errors,
    });

    return errors;
  };

  handleSearch = () => {
    let errors = this.validateInputs();
    const noErrors = !Object.values(errors).some(x => x !== '');
    if (noErrors) {
      this.props.actions.searchStudent(
        this.props.match.params.orgPartId,
        this.state.lastName,
        this.state.studentID,
        this.state.dateOfBirth
      );
    }
  };

  sendRequest = () => {
    this.props.actions.requestStudentTransfer(this.state.transferBody);
  };

  render() {
    const { showModal, closeModal } = this.props;
    console.log(this.state.dateOfBirth);
    return (
      <>
        <AlertModal
          className="RequestTransfer__ModalContainer"
          show={showModal}
          title={Text.features.roster.studentTransfer.requestBox.title}
          body={[
            <div className="RequestTransfer___Inputs">
              <Input
                label={Text.features.roster.modal.details.externalStudentId}
                error={this.state.errors.studentID}
                onChange={this.onInputChange}
                name={'studentID'}
                value={this.state.studentID}
                maxLength="20"
              />
              <Input
                label={Text.common.fields.lastName}
                error={this.state.errors.lastName}
                onChange={this.onInputChange}
                name={'lastName'}
                value={this.state.lastName}
                maxLength="50"
              />
              <Input
                label={Text.features.roster.modal.details.dateOfBirth}
                error={this.state.errors.dateOfBirth}
                onChange={this.handleDateChange}
                name={'dateOfBirth'}
                value={this.state.dateOfBirth}
                maxLength="10"
                onKeyDown={this.handleKeyDown}
                placeholder="MM/DD/YYYY"
              />
              <button className="searchIcon__Button" onClick={this.handleSearch}>
                <MagnifyingGlass />
              </button>
            </div>,
            this.state.alreadyAssigned && (
              <Alert type="error" message={Text.features.roster.studentTransfer.error} />
            ),
            this.state.studentDetails &&
              (_.isEmpty(this.state.studentDetails) ? (
                <Alert type="warning" message={Text.features.roster.studentTransfer.warning} />
              ) : (
                <>
                  {this.state.studentDetails}
                  <div className="RequestTransfer__Note">
                    {Text.features.roster.studentTransfer.note}
                  </div>
                  <TextArea
                    nrCharactes={this.state.nrCharactes}
                    maxLength="250"
                    handleTextAreaChange={this.handleTextAreaChange}
                  />
                </>
              )),
          ]}
          close={closeModal}
          buttonText={Text.features.roster.studentTransfer.sendRequest}
          buttonAction={this.sendRequest}
          showButton={!!this.state.studentDetails && !_.isEmpty(this.state.studentDetails)}
          secondaryButton={
            this.state.studentDetails && !_.isEmpty(this.state.studentDetails)
              ? {
                  text: 'Cancel',
                  action: closeModal,
                  redButton: false,
                }
              : null
          }
        />
      </>
    );
  }
}

export { RequestTransferModal };

const mapStateToProps = state => {
  return {
    requestStudentTransfer: state.rosterReducer.requestStudentTransfer,
    searchStudent: state.rosterReducer.searchStudent,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ ...rosterActions }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RequestTransferModal));

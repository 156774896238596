const STATUS = {
  init: 'init',
  loading: 'loading',
  resolved: 'resolved',
  rejected: 'rejected',
};

const states = {
  [STATUS.init]: () => ({
    data: null,
    error: null,
    loading: false,
    meta: null,
  }),
  [STATUS.loading]: () => ({
    data: null,
    error: null,
    loading: true,
    meta: null,
  }),
  [STATUS.resolved]: data => {
    // Handle server responses that include metadata
    if (['data', 'meta'].every(key => Object.hasOwn(data, key))) {
      return {
        error: null,
        loading: false,
        ...data,
      };
    }
    return {
      data,
      error: null,
      loading: false,
      meta: null,
    };
  },
  [STATUS.rejected]: error => ({
    data: null,
    error,
    loading: false,
    meta: null,
  }),
};

export { STATUS, states };

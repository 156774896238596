import React, { useRef, useState } from 'react';
import propTypes from 'prop-types';
import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';
import { ArrowDropDown as ArrowDropDownIcon } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

import { BLUE, BORDER_GRAY, LIGHT_GREY2, WHITE } from '../../styles/base/_settings.scss';

const styles = {
  root: {
    maxHeight: '3.6rem',
    zIndex: 100,
    '& .MuiButtonGroup-groupedContainedPrimary': {
      backgroundColor: BLUE,
      color: WHITE,
    },
    '& .MuiButtonGroup-root': {
      '& button:first-child': {
        borderRadius: '9rem 0 0 9rem',
      },
      '& button:last-child': {
        borderRadius: '0 9rem 9rem 0',
      },
    },
    '& .Mui-disabled': {
      backgroundColor: WHITE,
      border: `0.2rem solid ${BORDER_GRAY}`,
      color: LIGHT_GREY2,
      maxHeight: '3.6rem',
    },
    '& .MuiButton-root': {
      fontFamily: 'Montserrat',
      fontSize: '1.4rem',
      fontWeight: '700',
      lineHeight: '2.4rem',
      '& .MuiButton-label': {
        textTransform: 'none',
      },
    },
    '& .MuiMenuItem-root': {
      fontFamily: 'Montserrat',
      fontSize: '1.4rem',
      fontWeight: '700',
      lineHeight: '2.4rem',
    },
  },
};

const SplitButton = ({ classes, disabled, initialOption, options = [] }) => {
  const anchorRef = useRef(null);

  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    () => options.find(({ label }) => label === initialOption) || options.at(0)
  );

  const handleClick = () => selectedOption.handler();

  const handleClose = e => {
    if (anchorRef.current && anchorRef.current.contains(e.target)) return;

    setOpen(false);
  };

  const handleMenuItemClick = (_e, option) => {
    setSelectedOption(option);
    setOpen(false);
  };

  const handleToggle = () => setOpen(prev => !prev);

  return (
    <Box className={classes.root}>
      <ButtonGroup
        aria-label="split button"
        color="primary"
        disabled={disabled}
        disableElevation
        ref={anchorRef}
        variant="contained">
        <Button onClick={handleClick}>{selectedOption.label}</Button>
        <Button
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="menu"
          aria-label="perform action"
          color="primary"
          onClick={handleToggle}
          size="small">
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper anchorEl={anchorRef.current} disablePortal open={open} role={undefined} transition>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map(option => (
                    <MenuItem
                      key={option.label}
                      selected={option.label === selectedOption.label}
                      onClick={e => handleMenuItemClick(e, option)}>
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

SplitButton.propTypes = {
  color: propTypes.string,
  disabled: propTypes.bool,
  initialOption: propTypes.string,
  options: propTypes.arrayOf(
    propTypes.shape({
      handler: propTypes.func,
      label: propTypes.string.isRequired,
    })
  ).isRequired,
};

SplitButton.defaultProps = {
  color: 'primary',
  disabled: false,
};

export default withStyles(styles)(SplitButton);

import React from 'react';
import propTypes from 'prop-types';
import { PersonalInformationForm } from '../index';
import Header from '../../../components/header/Header';
import SidebarNav from '../../../components/sidebar-nav/SidebarNav';
import { Helmet } from 'react-helmet';

const PersonalInformation = ({ location }) => {
  return (
    <div className="personalInfoPage">
      <Helmet>
        <title>Personal Information - ACT-Now</title>
      </Helmet>
      <Header logoOnly={!(location.state && location.state.edit)} />
      <div className="_outter_">
        {location.state && location.state.edit && <SidebarNav />}
        <div className="sidebar__Separator">
          <div className={`personalInfo__Container ${location.state?.edit ? '' : 'centered'}`}>
            <PersonalInformationForm showCancel={!!(location.state && location.state.edit)} />
          </div>
        </div>
      </div>
    </div>
  );
};

PersonalInformation.displayName = 'PersonalInformation';
PersonalInformation.propTypes = {
  location: propTypes.object.isRequired,
};

export default PersonalInformation;

import React, { useCallback, useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import './Table.scss';
import { useTable, useBlockLayout } from 'react-table';
import { useSticky } from 'react-table-sticky';
import Checkbox from '../inputs/Checkbox';
import { BORDER_GRAY, BACKGROUND_OFF_WHITE } from '../../styles/base/_settings.scss';
import RenderCellContent from './TableCell';

// calculates the whidth of column based on whidthest record

// const getColumnWidth = (rows, accessor, headerText) => {
//   const maxWidth = 400;
//   const magicSpacing = 10;
//   const cellLength = Math.max(
//     ...rows.map(row => (`${row[accessor]}` || '').length),
//     headerText.length
//   );
//   return Math.min(maxWidth, cellLength * magicSpacing);
// };

const Styles = styled.div`
  overflow: hidden;

  .table {
    background-color: white;
    border: 0.1rem solid ${BORDER_GRAY};
    max-height: 68rem;
    overflow-y: auto;
    border-radius: 0.8rem;
    ::-webkit-scrollbar-corner {
      border-radius: inherit;
    }
    .table-header {
      position: sticky;
      z-index: 1;
      top: 0;
    }

    .tr {
      background-color: white;
      border-bottom: 0.1rem solid ${BORDER_GRAY};
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
      &.title,
      &.title .td {
        background-color: ${BACKGROUND_OFF_WHITE};
      }
      &.title {
        border-top: 0.1rem solid ${BORDER_GRAY};
      }
    }
    .td > div {
      width: 100%;
    }
    .th,
    .td {
      .resizer {
        display: inline-block;
        width: 0.5rem;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;
        min-width: 15rem;

        &.isResizing {
          background: red;
        }
      }
      &:last-child {
        border-right: 0;
      }
    }

    &.sticky {
      .header {
        position: sticky;
        z-index: 1;
        top: 0;
        width: fit-content;
      }

      .body {
        position: relative;
        z-index: 0;
      }
      [data-sticky-td] {
        position: sticky;
      }
    }
  }
`;

const RenderHeaderContent = (column, isHeaderCheckboxSelected, handleHeaderCheckboxChange) => {
  switch (column.type) {
    case 'checkbox':
      return (
        <Checkbox handleChange={handleHeaderCheckboxChange} checked={isHeaderCheckboxSelected} />
      );
    default:
      return column.render('Header');
  }
};

const Table = ({
  columns,
  data,
  handleHeaderCheckboxChange,
  isHeaderCheckboxSelected,
  wideTable,
  visible,
  title,
  emptyMessage,
  fullWidth,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useBlockLayout,
    useSticky
  );

  const tableHeaderGroups = headerGroups.slice(-1);
  const tableHeaderGroupsLength = tableHeaderGroups.map(h => h.headers)[0].length;
  const tableRef = useRef(null);
  const tableBodyElement = tableRef?.current || null;
  const [tableClass, setTableClass] = useState('');

  const headerSum = tableHeaderGroups[0].headers
    .map(h => h.width)
    .reduce((partialSum, a) => partialSum + a, 0);

  const handleScrollTable = useCallback(() => {
    if (tableBodyElement) {
      if (tableBodyElement.scrollTop) {
        tableBodyElement.classList.add('scroll-vertical');
      } else {
        tableBodyElement.classList.remove('scroll-vertical');
      }

      if (tableBodyElement.scrollLeft) {
        tableBodyElement.classList.add('scroll-horizontal');
      } else {
        tableBodyElement.classList.remove('scroll-horizontal');
      }
    }
  }, [tableBodyElement]);

  useEffect(() => {
    if (tableBodyElement) {
      tableBodyElement.addEventListener('scroll', handleScrollTable);
      return () => {
        tableBodyElement.removeEventListener('scroll', handleScrollTable);
      };
    }
  }, [handleScrollTable, tableBodyElement]);

  useEffect(() => {
    if (tableRef?.current?.clientWidth > headerSum || fullWidth) {
      setTableClass('extended-row');
    } else {
      setTableClass('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableHeaderGroupsLength, wideTable]);

  return (
    <Styles>
      <div
        {...getTableProps()}
        className={`table sticky tableWrap ${!visible ? 'collapsed-table' : ''}`}
        ref={tableRef}
        tabIndex="-1">
        <div className={`table-header ${tableClass}`}>
          {tableHeaderGroups.map(headerGroup => (
            <div
              {...headerGroup.getHeaderGroupProps()}
              id="header-data-table-row"
              className={`table-header-row ${tableClass}`}>
              {headerGroup.headers.map(column => (
                <div
                  {...column.getHeaderProps()}
                  className={`th table-cell ${
                    column.type === 'number' ? 'content-align-right' : ''
                  }`}
                  aria-label={column.Header}
                  style={{ ...column.getHeaderProps().style, minWidth: column.minWidth || 'auto' }}>
                  <div>
                    {RenderHeaderContent(
                      column,
                      isHeaderCheckboxSelected,
                      handleHeaderCheckboxChange
                    )}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>

        {title ? (
          <div className="title tr table-cell">
            <div className="td table-cell">
              <strong>{title}</strong>
            </div>
          </div>
        ) : null}
        {!rows.length && emptyMessage ? (
          <div className="tr table-cell">
            <div className="td table-cell">{emptyMessage}</div>
          </div>
        ) : null}
        <div {...getTableBodyProps()} className="body">
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <div
                {...row.getRowProps()}
                className={`tr ${tableClass}`}
                aria-label={`row-${i + 1}`}>
                {row.cells.map(cell => {
                  return (
                    <div
                      {...cell.getCellProps()}
                      className={`td table-cell ${
                        cell.column.type === 'number' ? 'content-align-right' : ''
                      }`}
                      aria-label={cell.column.Header}
                      style={{
                        ...cell.getCellProps().style,
                        minWidth: cell.column.minWidth || 'auto',
                      }}>
                      <RenderCellContent cell={cell} />
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </Styles>
  );
};

const CustomReactTable = ({
  columns,
  data = [],
  handleHeaderCheckboxChange,
  isHeaderCheckboxSelected,
  wideTable = false,
  visible = true,
  title,
  emptyMessage = false,
  fullWidth,
}) => {
  return (
    <Table
      columns={columns}
      data={data}
      emptyMessage={emptyMessage}
      fullWidth={fullWidth}
      handleHeaderCheckboxChange={handleHeaderCheckboxChange}
      isHeaderCheckboxSelected={isHeaderCheckboxSelected}
      title={title}
      visible={visible}
      wideTable={wideTable}
    />
  );
};

export default CustomReactTable;

import _ from 'lodash/fp';
import * as Text from '../../core/i18n/text';

const generalErrors = {
  alpha: Text.messages.error.onlyAlpha,
  alphaNumeric: Text.messages.error.onlyAlphanumeric,
  alphaNumericNameWithSpecial: Text.messages.error.nameInvalidNumbers,
  alphaNumericWithSpaces: Text.messages.error.onlyAlphanumeric,
  alphaWithSpaces: Text.messages.error.onlyAlpha,
  numeric: Text.messages.error.onlyNumeric,
  personName: Text.messages.error.onlyAlphanumericAndSymbols,
  required: Text.messages.error.fieldIsRequired,
  upperCase: Text.messages.error.onlyUppercase,
  alphaNumericNameWithAt: Text.messages.error.alphaNumericNameWithAt,
};

export default class ValidationHelper {
  static alphaNumericNameWithSpecial = string => {
    const format = /[$<>~*%:\\=+"^!@{}`|?]/;
    return !format.test(string);
  };

  static alphaNumericNameWithAt = string => {
    const format = /[$<>~*%:\\=+"^!{}`|?]/;
    return !format.test(string);
  };

  static personName = string => {
    const format = /^[a-zA-Z0-9.,' -]*$/;
    return format.test(string);
  };

  static alphaNumericWithSpaces = string => {
    const format = /^[A-Za-z0-9 ]+$/;
    return format.test(string);
  };

  static alphaWithSpaces = string => {
    const format = /^[A-Za-z ]+$/;
    return format.test(string);
  };

  static upperCase = string => {
    const format = /^[A-Z ]+$/;
    return format.test(string);
  };

  static minLength = (string, minLength) => {
    return string.length >= +minLength;
  };

  static numeric = string => {
    const format = /^[0-9]+$/;
    return format.test(string);
  };

  static alpha = string => {
    const format = /^[A-Za-z]+$/;
    return format.test(string);
  };

  static alphaNumeric = string => {
    const format = /^[a-zA-Z0-9]+$/;
    return format.test(string);
  };

  static requiredField = value => {
    value = _.isString(value) ? value.trim() : value;
    return !!value || !_.isEmpty(value);
  };

  static validate = (object, entityDef, errorMessages = generalErrors) => {
    let errors = {};
    let self = this;
    //to be implemented cover also entityDef as array
    Object.entries(object).forEach(([key, value]) => {
      const currentDef = entityDef[key];
      if (!currentDef) {
        return;
      }
      if (currentDef.required && !self.requiredField(value)) {
        errors[key] = errorMessages.required;
      } else if (currentDef.regexClass && value) {
        errors[key] = !self[currentDef.regexClass](value)
          ? errorMessages[currentDef.regexClass]
          : '';
        if (
          errors['name'] === '' &&
          currentDef.regexClass === 'alphaNumericNameWithAt' &&
          value.startsWith('@')
        ) {
          errors[key] = 'Cannot start with @ symbol';
        }
      } else if (currentDef.dateFormat && value) {
        if (!self.validDateFormat(value)) errors[key] = `${Text.messages.error.dateFormat}`;
        if (!self.validDate(value)) errors[key] = `${Text.messages.error.invalidDate}`;
      }
      if (
        currentDef.minLength &&
        currentDef.maxLength &&
        currentDef.minLength === currentDef.maxLength &&
        !errors[key]
      ) {
        errors[key] =
          value && !self.minLength(value, currentDef.minLength) && value.length > 0
            ? `This field must have ${currentDef.minLength} characters`
            : '';
      }
    });
    return _.omitBy(el => el === '' || el === null)(errors);
  };

  static dateFormatter = (inputName, inputValue, keyCode) => {
    let regex = new RegExp('^[0-9/]*$');
    const inputKey = inputValue[inputValue.length - 1];
    const separator = '/';
    let date = '';

    if (regex.test(inputKey) || keyCode === 8) {
      date = inputValue;
      if (keyCode !== 47) {
        if (keyCode !== 8) {
          if (inputValue.length === 2 || inputValue.length === 5) {
            date = inputValue + separator;
          } else if (keyCode === 86 && inputValue.length === 8) {
            date =
              inputValue.substr(0, 2) +
              separator +
              inputValue.substr(2, 2) +
              separator +
              inputValue.substr(4, 4);
          }
        } else {
          if (inputValue.length === 5) {
            date = inputValue.substring(0, 4);
          } else if (inputValue.length === 2) {
            date = inputValue.substring(0, 1);
          }
        }
      } else {
        date = inputValue.substring(0, inputValue.length - 1);
      }
      return date;
    }
  };

  static validDateFormat = string => {
    const format = new RegExp('\\d{1,2}\\/\\d{1,2}\\/\\d{2,4}');
    return format.test(string);
  };

  static validDate = string => {
    const format = new RegExp(
      '^(0[1-9]|1[0,1,2])(\\/|-)(0[1-9]|[12][0-9]|3[01])(\\/|-)(19|20)\\d{2}'
    );
    return format.test(string);
  };

  static validYearRange = (year, minYear, maxYear) => year <= maxYear && year >= minYear;
}

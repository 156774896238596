import _ from 'lodash/fp';
import { createDispatchFunction } from '../../../services/helpers/stateHelper';

import { TestManagementServices } from '../../../services';
import * as types from './types';

export function getTestEventsAction(payload) {
  return {
    type: types.GET_TEST_EVENTS,
    payload,
  };
}

export function getTestEvents(orgPartId) {
  return createDispatchFunction(
    _.partial(TestManagementServices.getTestEvents)([orgPartId]),
    getTestEventsAction
  );
}

const config = {
  act: {
    accommodations: {
      allowFileUpload: false,
      jobTypeCode: 'reg_accoms',
      options: 'ncrMaterials',
      taaPin: true,
    },
    contract: {
      allowFileUpload: true,
      jobTypeCode: 'org',
    },
    orgManagement: {
      allowFileUpload: true,
      jobTypeCode: 'org_part',
    },
    roster: {
      allowFileUpload: true,
      allowMultipleRegistrations: false,
      deliveryOptions: ['online', 'paper'],
      jobTypeCode: 'reg',
    },
    staffInvite: {
      allowFileUpload: true,
      jobTypeCode: 'org_part_personnel',
    },
  },
  preact: {
    accommodations: {
      allowFileUpload: true,
      jobTypeCode: 'preact_reg_accoms',
      options: 'regAccomms',
      taaPin: false,
    },
    contract: {
      allowFileUpload: true,
      jobTypeCode: 'org',
    },
    orgManagement: {
      allowFileUpload: true,
      jobTypeCode: 'org_part',
    },
    roster: {
      allowFileUpload: true,
      allowMultipleRegistrations: false,
      deliveryOptions: ['online', 'paper'],
      jobTypeCode: 'reg',
    },
    staffInvite: {
      allowFileUpload: true,
      jobTypeCode: 'org_part_personnel',
    },
  },
  workkeys: {
    accommodations: {
      allowFileUpload: true,
      jobTypeCode: 'reg_accoms',
      options: 'regAccomms',
      taaPin: false,
    },
    contract: {
      allowFileUpload: true,
      jobTypeCode: 'org',
    },
    orgManagement: {
      allowFileUpload: true,
      jobTypeCode: 'org_part',
    },
    roster: {
      allowFileUpload: true,
      allowMultipleRegistrations: true,
      deliveryOptions: ['online', 'paper'],
      jobTypeCode: 'reg',
    },
    staffInvite: {
      allowFileUpload: true,
      jobTypeCode: 'org_part_personnel',
    },
  },
};

export { config };

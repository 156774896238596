import http from '../core/services/Http';
import Config from '../core/config';

const nowQueryUrl = '/tcm/query';

export default class TestManagementServices {
  static getOrderDetails(orgPartId, orderId) {
    return http.get(`${Config.apiUrl}/now/orgpart/${orgPartId}/order/${orderId}/details`);
  }

  static getOrderTracking(orgPartId, orderId) {
    return http.get(`${Config.apiUrl}/now/orgpart/${orgPartId}/order/${orderId}/tracking`);
  }

  static getOrderMaterials(orgPartId, orderId) {
    return http.get(`${Config.apiUrl}/now/orgpart/${orgPartId}/order/${orderId}/materials`);
  }

  static getTestEvents(orgPartId) {
    return http.get(`${Config.apiUrl}${nowQueryUrl}/testEventsOverview?orgPartId=${orgPartId}`);
  }

  static getOrders(orgPartId) {
    return http.get(`${Config.apiUrl}/now/orgpart/${orgPartId}/orders`);
  }
}

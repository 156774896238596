import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Text from '../../../core/i18n/text';
import { actions as contractActions } from '../duck';
import AlertModal from '../../../components/modal/AlertModal';
import Input from '../../../components/forms/fields/Input';
import DropDown from '../../../components/inputs/DropDown';
import _ from 'lodash/fp';
import { mapEntityDef } from '../duck/selectors';
import { Alert } from '../../../components/index';
import ValidationHelper from '../../../services/helpers/validationHelper';

import './OrganizationDataModal.scss';

const defaultOrg = {
  id: null,
  name: '',
  adminId: null,
  address1: '',
  address2: '',
  code: '',
  orgType: '',
  parentId: null,
  contractId: null,
  city: '',
  stateCode: '',
  postalCode: '',
  mdmCode: '',
};

const entityValidationsCode = {
  name: 'orgName',
  address1: 'address1',
  address2: 'address2',
  city: 'city',
  stateCode: 'state_code',
  postalCode: 'postal_code',
  mdmCode: 'mdm_code',
  code: 'orgCode',
  orgType: 'orgType',
};

class OrganizationDataModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      organization: { ...defaultOrg },
      selectedParentOrg: null,
      selectedOrgType: null,
      isDirty: false,
      showConfirmationModal: false,
      serverError: [],
      loading: false,
    };

    this.initialState = _.cloneDeep(this.state);
  }

  componentDidMount() {
    // this.setState({
    //   organization: currentOrg ? { ...currentOrg } : this.initialState.organization,
    //   selectedParentOrg: this.props.parentOrgs.length === 1 ?
    //     this.props.parentOrgs[0] : null,
    // })
  }

  componentDidUpdate(prevProps) {
    if (
      (!_.isEqual(prevProps.currentOrg)(this.props.currentOrg) ||
        (this.props.showModal && !prevProps.showModal)) &&
      this.props.currentOrg
    ) {
      const { currentOrg, parentOrgs } = this.props;
      const currentParent = parentOrgs.find(opt => +opt.id === +currentOrg.parentId);
      currentParent && this.props.actions.getChildOrgTypes(currentParent.orgTypeCode);
      this.setState({
        organization: currentOrg
          ? { ...currentOrg, orgType: currentOrg.orgTypeCode }
          : this.initialState.organization,
        selectedParentOrg: currentOrg && currentParent ? { ...currentParent } : null,
        selectedOrgType: currentOrg
          ? { orgTypeName: currentOrg.orgTypeName, orgTypeCode: currentOrg.orgTypeCode }
          : null,
      });
    }

    //preselect parent org if only one option is available
    if (
      _.isEmpty(this.props.currentOrg) &&
      this.props.parentOrgs.length === 1 &&
      !this.state.selectedParentOrg
    ) {
      this.setState(prevState => ({
        organization: {
          ...prevState.organization,
          parentId: this.props.parentOrgs[0].id,
        },
        selectedParentOrg: { ...this.props.parentOrgs[0] },
      }));
      this.props.actions.getChildOrgTypes(this.props.parentOrgs[0].orgTypeCode);
    }

    //preselect orgType if only one option is available
    if (
      this.props.childOrgTypes?.data?.length === 1 &&
      !this.state.selectedOrgType &&
      this.state.selectedParentOrg
    ) {
      this.setState(prevState => ({
        organization: {
          ...prevState.organization,
          orgType: this.props.childOrgTypes.data[0].orgTypeCode,
        },
        selectedOrgType: { ...this.props.childOrgTypes.data[0] },
        errors: {
          ...prevState.errors,
          orgType: '',
        },
      }));
    }
  }

  closeModal = (e, omitDirty = false) => {
    const { isDirty } = this.state;
    if (isDirty && !omitDirty) {
      this.setState({
        showConfirmationModal: true,
      });
    } else {
      this.setState({
        ...this.initialState,
      });
      this.props.handleCloseModal();
    }
  };

  onInputChange = e => {
    const { value, name } = e.target;

    this.setState(prevState => ({
      organization: {
        ...prevState.organization,
        [name]: value,
      },
      errors: {
        ...prevState.errors,
        [name]: '',
      },
      isDirty: true,
    }));
  };

  handleSaveOrg = async () => {
    const { organization } = this.state;
    const { rootOrg, entityDef } = this.props;
    let contractInfo = JSON.parse(window.sessionStorage.getItem('contractInfo'));
    const orgToBeSaved = _.pick(Object.keys(defaultOrg))(organization);
    const errors = ValidationHelper.validate(orgToBeSaved, {
      ...entityDef,
      parentId: { required: true },
    });

    if (!_.isEmpty(errors)) {
      this.setState({
        errors,
      });
      return;
    }
    this.setState({
      loading: true,
    });
    if (this.props.currentOrg) {
      await this.props.actions.updateOrg({
        ...orgToBeSaved,
        adminId: +rootOrg.adminId,
        mdmCode: orgToBeSaved.mdmCode || null,
      });
    } else {
      await this.props.actions.createOrg({
        ...this.state.organization,
        adminId: +rootOrg.adminId,
        contractId: +contractInfo?.id || +rootOrg.contractId,
      });
    }
    this.setState({ loading: false });
    if (this.props.updatedOrg.error) {
      const fieldErrorMessageList = _.get('error.response.data.fieldErrorMessageList')(
        this.props.updatedOrg
      );
      if (!fieldErrorMessageList?.length)
        return this.setState({ serverError: [Text.messages.error.generic] });

      const addressFields = ['address1', 'address2'];
      if (
        !fieldErrorMessageList.some(
          ({ field, message }) => addressFields.includes(field) && /^PO Box/.test(message)
        )
      )
        return this.setState({ serverError: fieldErrorMessageList.map(({ message }) => message) });

      return this.setState({
        errors: addressFields.reduce(
          (acc, field) => ({
            ...acc,
            [field]: fieldErrorMessageList.some(error => error.field === field)
              ? Text.messages.error.addressPOBox
              : '',
          }),
          {}
        ),
      });
    }

    this.props.actions.getParentOrgs(this.props.orgHierarchy.data?.[0].orgId);

    const notifMessage = _.isEmpty(this.props.currentOrg)
      ? Text.features.contract.organization.addOrgSuccessMessage
      : Text.features.contract.organization.editOrgSuccessMessage;
    this.closeModal({}, true);
    this.props.showNotification(notifMessage);
  };

  handleParentChange = selectedOption => {
    this.setState(prevState => ({
      selectedParentOrg: selectedOption,
      selectedOrgType: null,
      organization: {
        ...prevState.organization,
        parentId: +selectedOption.id,
      },
      errors: {
        ...prevState.errors,
        parentId: '',
      },
      isDirty: true,
    }));

    this.props.actions.getChildOrgTypes(selectedOption.orgTypeCode);
  };

  handleConfirmationModalClose = (e, closeAll) => {
    this.setState({
      showConfirmationModal: false,
    });

    if (closeAll) {
      this.closeModal(e, true);
    }
  };

  handleOrgTypeChange = selectedOption => {
    this.setState(prevState => ({
      selectedOrgType: selectedOption,
      organization: {
        ...prevState.organization,
        orgType: selectedOption.orgTypeCode,
      },
      errors: {
        ...prevState.errors,
        orgType: '',
      },
      isDirty: true,
    }));
  };

  modalContent = editEnabled => {
    const { organization, serverError, errors, selectedParentOrg } = this.state;
    const { parentOrgs, childOrgTypes, entityDef } = this.props;

    return (
      !_.isEmpty(entityDef) && (
        <div className="">
          <Input
            label={Text.features.contract.organization.orgName}
            error={errors.name}
            onChange={this.onInputChange}
            name="name"
            value={organization.name}
            placeholder={!entityDef.name.required ? Text.common.labels.optional : ''}
            maxLength={entityDef.name.maxLength}
          />

          <DropDown
            label={Text.features.contract.organization.parentOrg}
            options={parentOrgs}
            value={selectedParentOrg}
            name="parentId"
            onChange={this.handleParentChange}
            error={!!this.state.errors.parentId}
            optionLabelKey="orgName"
            optionValueKey="id"
            disabled={editEnabled || (parentOrgs.length === 1 && selectedParentOrg)}
            searchable
            noPlaceholder
          />
          <DropDown
            className="orgType_Dropdown"
            label={Text.features.contract.organization.orgType}
            options={childOrgTypes?.data || []}
            value={this.state.selectedOrgType}
            onChange={this.handleOrgTypeChange}
            error={!!this.state.errors.orgType}
            disabled={
              editEnabled || !this.state.selectedParentOrg || childOrgTypes?.data?.length === 1
            }
            optionLabelKey="orgTypeName"
            optionValueKey="orgTypeCode"
            searchable
            noPlaceholder
          />
          <Input
            className="small__fieldContainer"
            label={Text.features.contract.organization.orgCode}
            error={errors.code}
            onChange={this.onInputChange}
            name="code"
            value={organization.code}
            placeholder={!entityDef.code.required ? Text.common.labels.optional : ''}
            maxLength={entityDef.code.maxLength}
            inputDetails={Text.features.contract.organization.orgCodeTooltip}
          />
          <div className="inline__Content">
            <Input
              className="small__fieldContainer"
              label={Text.features.contract.organization.mdmCode}
              error={errors.mdmCode}
              onChange={this.onInputChange}
              name="mdmCode"
              value={organization.mdmCode}
              maxLength={entityDef.mdmCode.maxLength}
              inputDetails="ACT identifier for the organization"
            />
          </div>

          <Input
            label={Text.features.contract.organization.address}
            error={errors.address1}
            onChange={this.onInputChange}
            name="address1"
            value={organization.address1}
            optionalLabel={!entityDef.address1.required}
            maxLength={entityDef.address1.maxLength}
          />
          <Input
            onChange={this.onInputChange}
            name="address2"
            value={organization.address2}
            maxLength={entityDef.address2.maxLength}
            optionalLabel={!entityDef.address2.required}
            error={errors.address2}
          />
          <div className="city__state__zip__Container">
            <Input
              className="city__field"
              label={Text.features.contract.organization.city}
              error={errors.city}
              onChange={this.onInputChange}
              name="city"
              value={organization.city}
              maxLength={entityDef.city.maxLength}
            />
            <Input
              className="state__field"
              label={Text.features.contract.organization.state}
              error={errors.stateCode}
              onChange={this.onInputChange}
              name="stateCode"
              value={organization.stateCode}
              maxLength={entityDef.stateCode.maxLength}
            />
            <Input
              className="postalCode_field"
              label={Text.features.contract.organization.postalCode}
              error={errors.postalCode}
              onChange={this.onInputChange}
              name="postalCode"
              value={organization.postalCode}
              maxLength={entityDef.postalCode.maxLength}
            />
          </div>
          <div>
            {serverError &&
              !!serverError.length &&
              serverError.map(message => <Alert type="error" message={message} />)}
          </div>
        </div>
      )
    );
  };

  render() {
    const { currentOrg } = this.props;
    const editEnabled = currentOrg && !_.isEmpty(currentOrg);

    return (
      <>
        <AlertModal
          className="Organization__Details__Modal"
          show={this.props.showModal}
          title={
            editEnabled
              ? Text.features.contract.organization.editOrganization
              : Text.features.contract.organization.addNewOrganization
          }
          note={this.state.showModalDescription ? Text.features.orgManagement.users.modal.desc : ''}
          close={this.closeModal}
          showButton
          buttonText={
            editEnabled
              ? Text.features.contract.organization.saveOrgLabel
              : Text.features.contract.organization.addOrgLabel
          }
          buttonAction={this.handleSaveOrg}
          secondaryButton={{
            text: 'Cancel',
            action: e => this.closeModal(e),
            redButton: false,
          }}
          buttonDisabled={this.state.loading}>
          {this.modalContent(editEnabled)}
        </AlertModal>
        <AlertModal
          className="ConfirmationModal__Container"
          show={this.state.showConfirmationModal}
          close={e => this.handleConfirmationModalClose(e, true)}
          title=""
          bodyTitle={Text.features.orgManagement.users.modal.confirmation.cancel}
          showButton
          buttonText="Exit without saving"
          secondaryButton={{
            text: 'Continue editing',
            action: e => this.handleConfirmationModalClose(e, false),
            redButton: false,
          }}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    childOrgTypes: state.contractReducer.childOrgTypes,
    entityDef: mapEntityDef(state, entityValidationsCode),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        ...contractActions,
      },
      dispatch
    ),
  };
};

export { OrganizationDataModal };
export default connect(mapStateToProps, mapDispatchToProps)(OrganizationDataModal);

import React from 'react';
import propTypes from 'prop-types';
import './NoResults.scss';
import * as text from '../../core/i18n/text';

const NoResults = ({ clearInputAndFilters }) => {
  return (
    <div className="no__Results">
      <p>{text.features.table.noResults.zeroResults()}</p>
      <button onClick={clearInputAndFilters} className="clear__Filters__Button">
        {text.features.table.noResults.clear}
      </button>
    </div>
  );
};

NoResults.propTypes = {
  clearInputAndFilters: propTypes.func,
};

export default NoResults;

import React, { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import propTypes from 'prop-types';

const TimerButton = ({ buttonText, onClick, timer = 15 }) => {
  /**
   * useState()
   */
  const [disabled, setDisabled] = useState(false);
  const [time, setTime] = useState(null);

  /**
   * useMemo()
   */
  const content = useMemo(
    () => (disabled ? `${time} ${time > 1 ? 'seconds' : 'second'}` : buttonText),
    [disabled, buttonText, time]
  );

  /**
   * useCallback()
   */
  const setTimer = useCallback(() => {
    const stopTime = moment().add(timer, 's');
    const diff = moment(Math.ceil(stopTime.diff(moment())));
    setDisabled(true);
    setTime(diff.format('ss'));

    const interval = setInterval(() => {
      const diff = moment(Math.ceil(stopTime.diff(moment())));
      setTime(diff.format('ss'));
      if (diff.seconds() !== 0) return;

      clearInterval(interval);
      setDisabled(false);
      setTime(null);
    }, 1000);
  }, [timer]);

  const handleTimerButtonClick = useCallback(() => {
    if (disabled) return;
    onClick();
    setTimer();
  }, [disabled, onClick, setTimer]);

  /**
   * render
   */
  return (
    <button
      className={`timerButton ${disabled ? 'disabled' : 'active'}`}
      onClick={handleTimerButtonClick}
      type="button">
      {content}
    </button>
  );
};

TimerButton.displayName = 'TimerButton';

TimerButton.propTypes = {
  buttonText: propTypes.string.isRequired,
  onClick: propTypes.func.isRequired,
  timer: propTypes.number,
};

export default TimerButton;

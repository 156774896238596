import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Base64 } from 'js-base64';
import _ from 'lodash/fp';

import { actions as coreActions } from '../../../core/duck';
import { actions as personalInfoActions } from '../duck';

import Auth from '../../../core/services/AuthToken';
import { personalInformation } from '../../../core/constants';

import * as Text from '../../../core/i18n/text';
import Header from '../../../components/forms/FormHeader';
import Input from '../../../components/forms/fields/Input';
import Phone from '../../../components/forms/fields/Phone';
import Notification from '../../../components/alerts/Notification';

let userEmail = '';
let oneTime;
let workPhoneCountryCode = 'us',
  cellPhoneCountryCode = '';

class PersonalInformationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      personalInfo: {
        ...personalInformation.createPayload,
      },
      displayRequiredMessage: {
        firstName: false,
        lastName: false,
        firstPhone: false,
      },
      errors: {},
      submitted: false,
      displayNotification: false,
    };
  }

  componentDidMount() {
    oneTime = true;
    if (!this.props.userInfo) {
      this.props.actions.getUserInfo();
    }
    const tokken = Auth.authToken.split(' ')[0].split('.')[1];
    userEmail = JSON.parse(Base64.decode(tokken)).email;
    this.setState({
      personalInfo: {
        ...this.state.personalInfo,
        emailAddress: userEmail,
      },
    });
  }

  static getDerivedStateFromProps(props, state) {
    const userInfo = props.userInfo;
    if (userInfo) {
      if (!userInfo.loading && state.submitted) {
        if (!userInfo.error) {
          props.history.push(props.location.state.from, {
            showSuccessUpdateNotification: true,
            notificationText: Text.messages.success.personalInformationUpdated,
          });
        }
        if (userInfo.error) {
          return {
            displayNotification: true,
          };
        }
      }
      if (userInfo.data && oneTime) {
        oneTime = false;
        return {
          personalInfo: userInfo.data,
        };
      }
    }
    return state;
  }

  handleSubmit = async e => {
    e.preventDefault();
    const errors = this.validateInput();
    if (Object.entries(errors).length === 0 && errors.constructor === Object) {
      if (!_.isEmpty(this.props.userInfo.data)) {
        this.props.actions.updatePersonalInformation(this.state.personalInfo);
      } else {
        this.props.actions.createPersonalInformation(this.state.personalInfo);
      }
      this.setState({
        submitted: true,
      });
    }
  };

  isStringValid = string => {
    const format = /[$<>~*%:!_@#^&*()?;:="+{}|/|]/;
    return !format.test(string);
  };

  validateInput = () => {
    let errors = {};
    const { firstName, lastName, workPhone, workPhoneExt, cellPhone } = this.state.personalInfo;
    if (firstName.trim() === '') {
      errors['firstName'] = `${Text.messages.error.firstNameRequired}`;
    } else if (!this.isStringValid(firstName)) {
      errors['firstName'] = `${Text.messages.error.nameInvalid}`;
    }

    if (lastName.trim() === '') {
      errors['lastName'] = `${Text.messages.error.lastNameRequired}`;
    } else if (!this.isStringValid(lastName)) {
      errors['lastName'] = `${Text.messages.error.nameInvalid}`;
    }
    if (workPhone.trim().replace(/[^0-9]/g, '').length === 0) {
      errors['workPhone'] = `${Text.messages.error.firstPhoneRequired}`;
    } else if (
      workPhoneCountryCode === 'us' &&
      workPhone.trim().replace(/[^0-9]/g, '').length <= 10
    ) {
      errors['workPhone'] = `${Text.messages.error.firstPhoneInvalid}`;
    }
    if (workPhoneExt && !/^\d+$/.test(workPhoneExt)) {
      errors['workPhoneExt'] = `${Text.messages.warning.onlyNumbers}`;
    }
    if (
      cellPhoneCountryCode === 'us' &&
      cellPhone.trim().replace(/[^0-9]/g, '').length > 0 &&
      cellPhone.trim().replace(/[^0-9]/g, '').length <= 10
    ) {
      errors['cellPhone'] = `${Text.messages.error.firstPhoneInvalid}`;
    }
    this.setState({
      errors,
    });
    return errors;
  };

  onInputChange = event => {
    const inputValue = event.target.value.trim();
    const inputName = event.target.name;

    this.setState({
      personalInfo: {
        ...this.state.personalInfo,
        [inputName]: inputValue,
      },
    });
  };

  onFirstPhoneChange = (phone, country) => {
    workPhoneCountryCode = country.countryCode;
    this.setState({
      personalInfo: {
        ...this.state.personalInfo,
        workPhone: phone.replace(/[^0-9]/g, ''),
      },
    });
  };

  onSecondPhoneChange = (phone, country) => {
    cellPhoneCountryCode = country.countryCode;
    this.setState({
      personalInfo: {
        ...this.state.personalInfo,
        cellPhone: phone.replace(/[^0-9]/g, ''),
      },
    });
  };

  onExtensionChange = extension => {
    this.setState({
      personalInfo: {
        ...this.state.personalInfo,
        workPhoneExt: extension.target.value,
      },
    });
  };

  handleNotificationClose = () => {
    this.setState({
      displayNotification: false,
      submitted: false,
    });
  };

  render() {
    const headerTitle =
      this.props.location.state && this.props.location.state.edit ? (
        <div>{Text.features.personalInfo.title}</div>
      ) : (
        Text.features.personalInfo.title
      );
    return (
      <div
        className={
          this.props.location.state && this.props.location.state.edit
            ? ''
            : 'personalInfo__Wrapper__FirstTime'
        }>
        <Header title={headerTitle} />
        <div className="loginForm__Container personalInfo__Container">
          {this.state.displayNotification && (
            <Notification
              text={Text.messages.error.generic}
              handleClose={this.handleNotificationClose}
              type="Error"
            />
          )}
          <form onSubmit={this.handleSubmit}>
            <Input
              label={Text.common.fields.lastName}
              error={this.state.errors.lastName}
              onChange={this.onInputChange}
              name={'lastName'}
              value={this.state.personalInfo.lastName}
              maxLength="16"
            />
            <Input
              label={Text.common.fields.firstName}
              error={this.state.errors.firstName}
              onChange={this.onInputChange}
              name={'firstName'}
              value={this.state.personalInfo.firstName}
              maxLength="12"
            />
            <Input
              label={Text.common.fields.email}
              value={this.state.personalInfo.emailAddress}
              disabled={true}
              valid={true}
            />
            <Phone
              label={Text.common.fields.firstPhone}
              extension={true}
              required={true}
              phoneError={this.state.errors.workPhone}
              extensionError={this.state.errors.workPhoneExt}
              inputProps={{ name: 'workPhone' }}
              phoneValue={this.state.personalInfo.workPhone}
              onPhoneChange={this.onFirstPhoneChange}
              extensionValue={this.state.personalInfo.workPhoneExt}
              onExtensionChange={this.onExtensionChange}
            />
            <Phone
              label={Text.common.fields.secondPhone}
              phoneValue={this.state.personalInfo.cellPhone}
              required={false}
              phoneError={this.state.errors.cellPhone}
              inputProps={{ name: 'cellPhone' }}
              onPhoneChange={this.onSecondPhoneChange}
              extension={false}
            />
            <div className="personalInfo__ButtonContainer">
              <button
                type="submit"
                className="bigButton personalInfo__Button"
                value={Text.clickables.buttons.saveInformation}>
                {Text.clickables.buttons.saveInformation}
              </button>
              {this.props.showCancel && (
                <button
                  type="button"
                  className="openButton personalInfo__Button"
                  onClick={() => {
                    this.props.history.push(this.props.location.state.from);
                  }}>
                  {Text.clickables.buttons.cancel}
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userInfo: state.userInfoReducer.userInfo,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ ...coreActions, ...personalInfoActions }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PersonalInformationForm));

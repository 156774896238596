import { createSelector } from 'reselect';
import _ from 'lodash/fp';

const getAllPermissions = state => state.userInfoReducer.userPermissions;
const getRequiredPermissions = (state, permission) => permission;
const getProgramAdminStaticInfo = state => state.landingReducer.programAdminStaticInfo;

export const getPermission = createSelector(
  [getAllPermissions, getRequiredPermissions],
  (all, required) => {
    return _.get('data')(all) ? all.data.some(element => element.code === required) : false;
  }
);

export const mapAdminsByProgramn = createSelector(
  [getProgramAdminStaticInfo],
  programAdminStaticInfo => {
    if (_.get('data')(programAdminStaticInfo)) {
      let result = {};
      programAdminStaticInfo.data.forEach(program => {
        const { adminId, adminName } = program;
        if (!result[program.programId]) {
          const { programId, programName } = program;
          result[program.programId] = {
            programId,
            programName,
            administration: [
              {
                adminId,
                adminName,
              },
            ],
          };
        } else {
          result[program.programId].administration.push({
            adminId,
            adminName,
          });
        }
      });
      return Object.values(result);
    }
    return [];
  }
);

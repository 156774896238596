import React, { useCallback, useContext, useMemo, useState } from 'react';
import propTypes from 'prop-types';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { RosterServices } from '../../../services/';
import { getEntityDef } from '../../../services/helpers/dataHelper';

import RadioControl from '../../../components/inputs/Radio';

import TextContext from '../../../core/providers/TextProvider';

const fieldNames = ['deliveryMode'];

const UpdateRegistrationOptionsModal = ({
  closeModal,
  currentOrg,
  data,
  selection,
  setNotificationProps,
}) => {
  /**
   * useContext()
   */
  const Text = useContext(TextContext);

  /**
   * useState()
   */
  const [formData, setFormData] = useState({});
  const [formError, setFormError] = useState(false);

  /**
   * useMemo()
   */
  const formState = useMemo(() => {
    return fieldNames.reduce((acc, fieldName) => {
      const entityDef = getEntityDef(fieldName)(data);
      return {
        ...acc,
        [fieldName]: {
          ...entityDef,

          options: entityDef.options.map(({ value, label }) => ({
            id: value,
            displayText: label,
          })),
        },
      };
    }, {});
  }, [data]);

  /**
   * useCallback()
   */
  const handleSaveButtonClick = useCallback(() => {
    if (!Object.values(formData).filter(Boolean).length) return setFormError(true);

    const regList = Object.values(selection)
      .filter(Boolean)
      .map(({ regId }) => regId.toString());
    if (!regList.length) return;

    RosterServices.updateRegistrationOptions(currentOrg.adminId, { data: { ...formData }, regList })
      .then(() => {
        setNotificationProps({
          show: true,
          text: `${regList.length} Students successfully updated`,
          type: 'Success',
        });
        closeModal(true)();
      })
      .catch(() => {
        setNotificationProps({
          show: true,
          text: Text.messages.error.generic,
          type: 'Error',
        });
      });
  }, [
    closeModal,
    currentOrg,
    formData,
    selection,
    setNotificationProps,
    Text.messages.error.generic,
  ]);

  const handleSelection = useCallback(
    code => e => {
      const { id } = e.target;
      setFormData(prev => {
        const data = { ...prev, [code]: id };
        setFormError(!Object.values(data).filter(Boolean).length);
        return data;
      });
    },
    []
  );

  return (
    <Modal
      className={'RegisterStudents__modal'}
      isOpen={true}
      contentLabel="Update Registration Options"
      onRequestClose={closeModal(false)}
      ariaHideApp={false}
      closeTimeoutMS={200}>
      <div className="RegisterStudents__modalWrapper">
        <div className="RegisterStudents__modalHeader">
          <p className="RegisterStudents__modalTitle">Update Registration Options</p>
          <FontAwesomeIcon
            className="RegisterStudents__close"
            icon={faTimes}
            onClick={closeModal(false)}
            tabIndex="0"
          />
        </div>
        <div className="RegisterStudents__modalSubtitle">
          <hr className="RegisterStudents__modalDelimiter" />
          {Object.values(selection).filter(Boolean).length}
          &nbsp; Student(s) selected
        </div>
        {fieldNames.map(code => {
          const { name, options } = formState[code];
          return (
            <React.Fragment key={code}>
              <p className="RegisterStudents__modalTitle RegisterStudents__modalTitle_Small">
                {name}
              </p>
              {formError && (
                <div className="RegisterStudents__modalAlert">
                  You must select an option in order to save.
                </div>
              )}
              <RadioControl
                defaultOption={formData[code]}
                disableOption=""
                handleDateSelection={handleSelection(code)}
                items={options}
              />
            </React.Fragment>
          );
        })}
      </div>
      <div className="RegisterStudents__modalButtonWrapper">
        <hr className="RegisterStudents__modalDelimiter" />
        <button
          type="button"
          className="bigButton RegisterStudents__modalButton"
          onClick={handleSaveButtonClick}>
          Save
        </button>
      </div>
    </Modal>
  );
};

UpdateRegistrationOptionsModal.displayName = 'UpdateRegistrationOptionsModal';

UpdateRegistrationOptionsModal.propTypes = {
  closeModal: propTypes.func.isRequired,
  currentOrg: propTypes.object.isRequired,
  data: propTypes.arrayOf(propTypes.object).isRequired,
  selection: propTypes.object.isRequired,
  setNotificationProps: propTypes.func.isRequired,
};

export default UpdateRegistrationOptionsModal;

import React from 'react';
import { Link } from 'react-router-dom';
import './Tabs.scss';
import { ReactComponent as PendingIcon } from '../../assets/svgs/pendingIcon.svg';

const Tabs = props => {
  const { tabs, basePath, activeTab, activeSubTab, currentOrgPartId } = props;
  return (
    <>
      <div role="tablist" className="tabs__Container">
        {tabs.map((t, idx) => {
          const click = t.avail
            ? () => props.handleTabChange(t.url)
            : event => event.preventDefault();
          return (
            t.avail && (
              <div
                role="tab"
                key={idx}
                className={`tabs__Single tab__Border ${activeTab !== t.url && 'tab__NotActive'}`}>
                <Link
                  to={{
                    pathname: `${basePath}/${t.url}${
                      t.subtabs && activeSubTab ? '/' + activeSubTab : ''
                    }/orgPartId/${currentOrgPartId}`,
                  }}
                  className={`tabs__Single text ${!t.avail ? 'disabled' : ''} ${
                    activeTab === t.url ? 'active' : ''
                  }`}
                  onClick={click}
                  replace>
                  {t.title}
                  {t.pending && <PendingIcon className="tabs__pending" />}
                </Link>
                {activeTab === t.url && <div className="noBorder"></div>}
              </div>
            )
          );
        })}
      </div>
      <hr className="tabs__Divider" />
      {/*<div>*/}
      {/*{*/}
      {/*tabs.map(t => {*/}
      {/*if (t.url === activeTab && t.component) {*/}
      {/*const CurrentComp = t.component;*/}
      {/*return <CurrentComp {...props}/>*/}
      {/*} else return null })*/}
      {/*}*/}
      {/*</div>*/}
    </>
  );
};

export default Tabs;

import React, { useEffect, useState } from 'react';
import { Provider as ReduxProvider /*, useSelector*/ } from 'react-redux';

import AppRouter from './AppRouter';
import Footer from '../components/footer/Footer';
import store from '../store/Store';
import { AutoLogout } from '../features/login';
import TextContext from './providers/TextProvider';
import { getText } from './utils';
import * as Text from './i18n/text';
import { CPCAProvider } from './providers/CPCAContext';

export default () => {
  const [text, setText] = useState(Text);
  const [cpca, setCPCA] = useState();

  const setCPCAWrapper = value => {
    setCPCA(value);
  };

  useEffect(() => {
    if (!cpca) return;
    const program = cpca?.program;
    const parsedText = getText({ program })(Text);
    setText(parsedText);
  }, [cpca]);

  return (
    <ReduxProvider store={store}>
      <CPCAProvider value={cpca} setCPCA={setCPCAWrapper}>
        <TextContext.Provider value={text}>
          <div className="App" role="main">
            <AppRouter />
            <Footer />
            <AutoLogout />
          </div>
        </TextContext.Provider>
      </CPCAProvider>
    </ReduxProvider>
  );
};

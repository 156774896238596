import React, { useContext, useMemo } from 'react';
import _ from 'lodash/fp';

import TextContext from '../../../core/providers/TextProvider';

import prepareIcon from '../../../assets/svgs/prepareFile.svg';
import processicon from '../../../assets/svgs/processFile.svg';
import uploadIcon from '../../../assets/svgs/uploadFile.svg';

import './NoFiles.scss';

const NoFiles = ({ fileUpload }) => {
  /**
   * useContext()
   */
  const Text = useContext(TextContext);

  /**
   * useMemo()
   */
  const hyperlink = useMemo(
    () =>
      _.merge(Text.features.fileUpload.noFiles.steps[1].hyperlink)(
        fileUpload?.uploadFileBox?.hyperlink
      ),
    [fileUpload, Text.features]
  );

  /**
   * render
   */
  return (
    <div className="noFiles__Container">
      <div className="noFiles__Title">
        {fileUpload.noFiles.title || Text.features.fileUpload.noFiles.title}
      </div>
      <div className="noFiles__Tile">
        <img src={prepareIcon} alt="prepare" className="noFiles__Icon"></img>
        <div>
          <div className="noFiles__TileTitle">
            {Text.features.fileUpload.noFiles.steps[1].title}
          </div>
          <div className="noFiles__TileDesc">
            {Text.features.fileUpload.noFiles.steps[1].desc[0]}
            <a href={hyperlink.link} target="blank" className="link">
              {hyperlink.text}
            </a>
            {Text.features.fileUpload.noFiles.steps[1].desc[1]}
          </div>
        </div>
      </div>
      <div className="noFiles__Tile">
        <img src={uploadIcon} alt="upload" className="noFiles__Icon"></img>
        <div>
          <div className="noFiles__TileTitle">
            {Text.features.fileUpload.noFiles.steps[2].title}
          </div>
          <div className="noFiles__TileDesc">{Text.features.fileUpload.noFiles.steps[2].desc}</div>
        </div>
      </div>
      <div className="noFiles__Tile">
        <img src={processicon} alt="process" className="noFiles__Icon"></img>
        <div>
          <div className="noFiles__TileTitle">
            {Text.features.fileUpload.noFiles.steps[3].title}
          </div>
          <div className="noFiles__TileDesc">{Text.features.fileUpload.noFiles.steps[3].desc}</div>
        </div>
      </div>
    </div>
  );
};

export default NoFiles;

import axios from 'axios';
import Auth from './AuthToken';
import Config from '../config';

const config = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

const http = axios.create(config);

http.interceptors.request.use(request => {
  let authHeader = `${Auth.accessToken}`;
  if (request.url.indexOf(Config.cognitoUrl) === -1) {
    request.headers['Cache-Control'] = 'no-cache, no-store, must-revalidate';
    request.headers.Pragma = 'no-cache';
    request.headers.Expires = 0;
    authHeader = Auth.authToken && `Bearer ${Auth.authToken}`;
  }

  if (authHeader) {
    request.headers.Authorization = authHeader;
  }

  return request;
});

export default http;

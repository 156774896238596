import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import _ from 'lodash/fp';
import propTypes from 'prop-types';

import Input from '../../forms/fields/Input';
import TextInput from '../../inputs/TextInput';

import TextContext from '../../../core/providers/TextProvider';

import { ReactComponent as MagnifyingGlass } from '../../../assets/svgs/magnifyingGlass.svg';

import './Search.scss';

const Search = ({
  className,
  cleanInput,
  clearInput,
  focusPlaceholder,
  handleChange,
  handleSearch,
  hasFocus,
  inputList,
  resetSearch,
  searchedValues,
  searchLabel,
  searchMultiInput,
}) => {
  /**
   * useContext()
   */
  const Text = useContext(TextContext);

  /**
   * useRef()
   */
  const inputRef = useRef();

  /**
   * useState()
   */
  const [focus, setFocus] = useState(false);
  const [showClearButton, setShowClearButton] = useState(false);

  /**
   * useMemo()
   */
  const searchName = useMemo(() => inputList[0].name, [inputList]);

  /**
   * useCallback()
   */
  const addFocus = useCallback(ref => ref.current.focus(), []);

  const handleClear = useCallback(() => {
    hasFocus && addFocus(inputRef);
    clearInput();
    resetSearch();
    setShowClearButton(false);
  }, [addFocus, clearInput, hasFocus, resetSearch]);

  const handleClick = useCallback(() => {
    hasFocus && addFocus(inputRef);
    if (!_.isEmpty(_.omitBy(_.isEmpty)(searchedValues))) {
      handleSearch();
    }
    setShowClearButton(true);
  }, [addFocus, handleSearch, hasFocus, searchedValues]);

  const handleKeyPress = useCallback(e => e.key === 'Enter' && handleSearch(), [handleSearch]);

  const handleInputFocus = useCallback(() => setFocus(true), []);

  const handleInputBlur = useCallback(() => setFocus(false), []);

  const preventLoseInputFocus = useCallback(e => e.preventDefault(), []);

  return (
    <div className={`multi__Search__Container ${className}`} onKeyPress={handleKeyPress}>
      {searchLabel && <span className="search__label">{searchLabel}</span>}
      <div className="search__Form">
        <div className="input__Container">
          {searchMultiInput ? (
            inputList.map((inputData, idx) => (
              <Input
                disabled={inputData.disabled}
                key={idx}
                maxLength={inputData.maxLength}
                name={inputData.name}
                onChange={handleChange}
                placeholder={inputData.placeholder}
                setRef={inputRef}
                tooltip={inputData.tooltip}
                type={inputData.type}
                value={searchedValues[inputData.name]}
              />
            ))
          ) : (
            <TextInput
              handleChange={handleChange}
              inputValue={searchedValues[searchName]}
              maxLength={inputList[0].maxLength}
              name={searchName}
              noPlaceholder={!cleanInput}
              onBlur={handleInputBlur}
              onFocus={handleInputFocus}
              placeholder={
                focus && focusPlaceholder ? focusPlaceholder : inputList[0].placeholder || ''
              }
              setRef={inputRef}
            />
          )}
        </div>
      </div>
      <button
        aria-label={Text.common.fields.search}
        className="searchIcon__Button"
        onClick={handleClick}
        onMouseDown={preventLoseInputFocus}>
        <MagnifyingGlass />
      </button>
      {showClearButton && !cleanInput && !searchMultiInput && (
        <button className="clear__Text" onClick={handleClear}>
          {Text.clickables.buttons.clearSearch}
        </button>
      )}
      {showClearButton && !cleanInput && !hasFocus && searchMultiInput && (
        <button className="clear__Text" onClick={handleClear} onMouseDown={preventLoseInputFocus}>
          {Text.clickables.buttons.clearSearch}
        </button>
      )}
    </div>
  );
};

Search.displayName = 'Search';
Search.propTypes = {
  cleanInput: propTypes.bool,
  clearInput: propTypes.func,
  focusPlaceholder: propTypes.string,
  handleChange: propTypes.func,
  handleSearch: propTypes.func,
  maxLength: propTypes.string,
  placeholder: propTypes.string,
  resetSearch: propTypes.func,
  searchedValue: propTypes.string,
  searchLabel: propTypes.string,
  searchMultiInput: propTypes.bool,
};

export default Search;

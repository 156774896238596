import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Header from '../../../components/forms/FormHeader';
import Notification from '../../../components/alerts/Notification';
import Password from '../../../components/forms/fields/Password';
import Input from '../../../components/forms/fields/Input';
import Alert from '../../../components/alerts/Alert';
import PasswordValidatorDisplay from '../../../components/forms/PasswordValidatorDisplay';
import TimerButton from '../../../components/buttons/TimerButton';
import { messages, clickables, features } from '../../../core/i18n/text';
import { parseQueryString } from '../../../services/helpers/urlHelper';
import { actions as loginActions } from '../duck';
import { bindActionCreators } from 'redux';
import ACTNowLogo from '../../../assets/svgs/actNowLogoWhite.svg';
import ConfirmationMessage from '../../../components/forms/ConfirmationMessage';
import { Helmet } from 'react-helmet';
import _ from 'lodash/fp';

const MAX_CODE_LENGTH = 6;

const Text = { messages, clickables, features };

let closeNotificationTimer;
let waitTime = 30;
class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changed: false,
      displayNotification: false,
      validationCode: '',
      email: '',
      password: '',
      passwordValidations: {
        '10 characters': false,
        '1 lowercase letter': false,
        '1 digit': false,
        '1 special character': false,
        '1 uppercase letter': false,
        'No spaces': true,
      },
      errors: {
        validPassword: false,
        validationCode: '',
      },
      displayRequiredMessage: {
        passwordEmpty: false,
        passwordFormatWrong: false,
        hint: true,
      },
      alert: {
        show: false,
        message: '',
      },
    };
    this.props.actions.clearForgotPasswordFlow();
  }

  componentDidMount() {
    this.getEmail();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.forgotPasswordResponse.data) {
      this.setState({
        displayNotification: true,
      });
      closeNotificationTimer = setTimeout(this.handleNotificationClose, 5000);
    }
    if (nextProps.resetPasswordResponse.data) {
      this.setState({
        changed: true,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(this.props)(prevProps)) {
      let show = false,
        message = '';
      if (_.get('resetPasswordResponse.error.response')(this.props)) {
        show = true;
        message = this.props.resetPasswordResponse.error.response.data.Error;
      }
      if (_.get('forgotPasswordResponse.error.response')(this.props)) {
        show = true;
        message = this.props.forgotPasswordResponse.error.response.data.Error;
      }
      this.setState({
        alert: {
          show,
          message,
        },
      });
    }
  }

  getEmail() {
    const params = parseQueryString(this.props.location.search);
    if (!params.confirmationCode || !params.username) {
      this.props.history.push('/');
      return null;
    }
    this.setState({
      email: params.username,
      validationCode: params.confirmationCode,
    });
  }

  onPasswordChange = password => {
    this.setState({
      password,
    });
    this.validatePassword(password);
  };

  onPasswordFocus = password => {
    this.setState(prevState => ({
      displayRequiredMessage: {
        ...prevState.displayRequiredMessage,
        passwordEmpty: false,
        passwordFormatWrong: false,
        hint: false,
      },
    }));
  };

  onPasswordBlur = password => {
    this.setState(prevState => ({
      displayRequiredMessage: {
        ...prevState.displayRequiredMessage,
        hint: true,
      },
    }));
  };

  onValidationFocus = () => {
    this.setState({
      errors: {
        ...this.state.errors,
        validationCode: '',
      },
    });
  };

  onCodeChange = e => {
    let code = e.target.value.trim();

    if (code.length > MAX_CODE_LENGTH) {
      code = code.slice(0, 6);
    }

    this.setState({
      validationCode: code,
    });
  };

  validatePassword = password => {
    const passwordRx = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[.#?!@$%^&*-])\S{10,}$/;
    let passwordValidations = {
      '10 characters': true,
      '1 lowercase letter': true,
      '1 digit': true,
      '1 special character': true,
      '1 uppercase letter': true,
      'No spaces': true,
    };

    if (passwordRx.test(password)) {
      this.setState(prevState => ({
        errors: {
          ...prevState.errors,
          validPassword: true,
        },
      }));
    } else {
      passwordValidations['10 characters'] = password.length >= 10 ? true : false;
      passwordValidations['1 lowercase letter'] = password.match(/[a-z]/) ? true : false;
      passwordValidations['1 digit'] = password.match(/\d/) ? true : false;
      passwordValidations['1 special character'] = password.match(/[.#?!@$%^&*-]/) ? true : false;
      passwordValidations['1 uppercase letter'] = password.match(/[A-Z]/) ? true : false;
      passwordValidations['No spaces'] = !password.match(/\s/) ? true : false;

      this.setState(prevState => ({
        errors: {
          ...prevState.errors,
          validPassword: false,
        },
        passwordValidations,
      }));
    }
  };

  handleNotificationClose = () => {
    this.setState({
      displayNotification: false,
    });
    clearTimeout(closeNotificationTimer);
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.actions.clearForgotPasswordFlow();

    const isPasswordEmpty = this.state.password === '' ? true : false;
    const isPasswordValid = this.state.errors.validPassword;
    const isValidationCodeEmpty = this.state.validationCode === '' ? true : false;
    if (isValidationCodeEmpty) {
      this.setState({
        errors: {
          ...this.state.errors,
          validationCode: Text.messages.error.validationRequired,
        },
      });
    }
    this.setState(prevState => ({
      displayRequiredMessage: {
        ...prevState.displayRequiredMessage,
        passwordEmpty: isPasswordEmpty,
        passwordFormatWrong: !isPasswordValid,
      },
    }));

    if (!isPasswordEmpty && isPasswordValid && !isValidationCodeEmpty) {
      const data = {
        password: this.state.password,
        username: this.state.email,
        validationCode: this.state.validationCode,
      };
      this.props.actions.resetPassword(data);
    }
  };

  handleRedirect = () => {
    this.props.history.push('/login');
  };

  render() {
    if (this.state.changed) {
      return (
        <div className="loginPage">
          <div style={{ display: 'contents' }}>
            <div className="loginPageLogoText__Container">
              <img src={ACTNowLogo} alt="ACTNow-Logo" />
              <p className="loginPage__Text">{Text.features.login.bgImgText}</p>
            </div>
            <ConfirmationMessage
              buttonAction={this.handleRedirect}
              title={Text.messages.success.changedPassword.title}
              message={[Text.messages.success.changedPassword.body]}
              buttonText={Text.clickables.buttons.continueToLogin}
            />
          </div>
        </div>
      );
    }

    let passwordView;

    if (!this.state.displayRequiredMessage.hint) {
      if (this.state.errors.validPassword) {
        passwordView = (
          <div className="passwordView__Container">
            <Alert type="successNoBkg" message={Text.messages.success.securePassword} />
          </div>
        );
      } else {
        passwordView = (
          <div className="passwordView__Container">
            <div className="validation__Base">
              <PasswordValidatorDisplay validations={this.state.passwordValidations} />
            </div>
          </div>
        );
      }
    }

    return (
      <div className="loginPage">
        <Helmet>
          <title>Reset Password - ACT-Now</title>
        </Helmet>
        <div style={{ display: 'contents' }}>
          <div className="loginPageLogoText__Container">
            <img src={ACTNowLogo} alt="ACTNow-Logo" />
            <p className="loginPage__Text">{Text.features.login.bgImgText}</p>
          </div>
          {this.state.displayNotification && (
            <Notification
              noHeader={true}
              text={Text.messages.success.codeSentNotification}
              handleClose={this.handleNotificationClose}
              type="Success"
            />
          )}
          <div className="resetPassword__Container">
            <Header title={Text.features.login.rpTitle} />
            {this.state.alert.show && <Alert type="error" message={this.state.alert.message} />}
            <form onSubmit={this.handleSubmit}>
              <div className="resetPassword__formsContainer">
                <div className="validation__Container">
                  <div className="code__Container">
                    <Input
                      type={'number'}
                      label={'Validation code'}
                      onChange={this.onCodeChange}
                      name={'Validation code'}
                      value={this.state.validationCode}
                      error={this.state.errors.validationCode}
                      onFocus={this.onValidationFocus}
                    />
                  </div>
                  <TimerButton
                    timer={waitTime}
                    buttonText={Text.clickables.buttons.resendCode}
                    onClick={this.props.actions.forgotPassword.bind(this, this.state.email)}
                  />
                </div>
                <Password
                  displayError={this.state.displayRequiredMessage.passwordFormatWrong}
                  displayEmpty={this.state.displayRequiredMessage.passwordEmpty}
                  displayHint={this.state.displayRequiredMessage.hint}
                  onChange={this.onPasswordChange}
                  onFocus={this.onPasswordFocus}
                  onBlur={this.onPasswordBlur}
                />
                {passwordView}
              </div>
              <button className="bigButton">{Text.clickables.buttons.changePassword}</button>
            </form>
            <div className="loginForm__Footer">
              <div>
                {Text.clickables.links.alreadyHaveAccount} &nbsp;
                <a className="registerForm__Link" href="/login">
                  {clickables.links.clickHere}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    resetPasswordResponse: state.loginReducer.resetPassword,
    forgotPasswordResponse: state.loginReducer.forgotPassword,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ ...loginActions }, dispatch),
  };
};

export { ResetPassword };
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPassword));

import React from 'react';
import propTypes from 'prop-types';
import _ from 'lodash/fp';
import { ReactComponent as TitleDropdownArrow } from '../../assets/svgs/blueDropdownArrow.svg';
import { ReactComponent as CheckMark } from '../../assets/svgs/blueCheckmark.svg';
import './Select.scss';

class Select extends React.Component {
  constructor(props) {
    super(props);

    const selectedOption =
      props.options.length && props.selectedValue
        ? props.options.find(opt => opt.value === props.selectedValue)
        : {};

    this.state = {
      showDropdown: false,
      selectedOption: selectedOption,
    };

    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.selectOption = this.selectOption.bind(this);
    this.setDropdownRef = this.setDropdownRef.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { selectedValue, options } = this.props;

    if (
      (selectedValue || !_.isEmpty(selectedValue)) &&
      _.isEmpty(this.state.selectedOption) &&
      options.length
    ) {
      this.setState({
        selectedOption: this.props.options.find(opt => opt.value === selectedValue),
      });
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleOutsideClick, true);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick);
  }

  handleOutsideClick = e => {
    if (this.dropdownRef && !this.dropdownRef.contains(e.target)) {
      this.setState({
        showDropdown: false,
      });
    }
  };

  toggleSelect = () => {
    this.setState({
      showDropdown: true,
    });
  };

  setDropdownRef(dropdownRef) {
    this.dropdownRef = dropdownRef;
  }

  selectOption = option => {
    this.setState({
      selectedOption: option,
      showDropdown: false,
    });

    this.props.onChange(option);
  };

  render() {
    const { options } = this.props;
    const { selectedOption } = this.state;

    return (
      <div className="select__Container">
        <h1 tabIndex="0" id="main" className="title__Container">
          <div onClick={this.toggleSelect}>
            <span>{selectedOption.label}</span>
            <TitleDropdownArrow />
          </div>
          {this.state.showDropdown && (
            <div className="select__Dropdown" ref={this.setDropdownRef}>
              <div className="arrow"></div>
              <ul>
                {options.map(option => (
                  <li
                    key={`option-${option.label}`}
                    className={`${option.value === selectedOption.value ? 'selected' : ''}`}
                    onClick={() => this.selectOption(option)}>
                    {option.value === selectedOption.value && (
                      <CheckMark className="dropdown__Checkmark" />
                    )}
                    {option.label}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </h1>
      </div>
    );
  }
}

Select.propTypes = {
  options: propTypes.arrayOf(
    propTypes.shape({
      value: propTypes.oneOfType([propTypes.string, propTypes.number]),
    })
  ).isRequired,
  onChange: propTypes.func,
};

Select.defaultProps = {
  onChange: () => {},
};

export default Select;

import React, { useCallback, useMemo } from 'react';
import _ from 'lodash/fp';
import propTypes from 'prop-types';
import Select from 'react-select';

import 'react-accessible-dropdown/style.css';
import './DropDown.scss';

const DropDown = ({
  className = '',
  customHeight,
  customLabel,
  customLabelFunc = () => {},
  disabled = false,
  error,
  handleMenuOpen = () => {},
  isClearable = false,
  label,
  loading = false,
  multiSelect = false,
  name,
  noPlaceholder = false,
  noTooltip,
  onChange = () => {},
  optionLabelKey = 'label',
  options = [],
  optionValueKey = 'value',
  placeholder,
  searchable = false,
  value,
}) => {
  /**
   * useMemo()
   */
  const customStyles = useMemo(
    () => ({
      menuList: base => ({
        ...base,
        maxHeight: customHeight ? customHeight : 220,
      }),
      multiValue: (base, state) => {
        return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
      },
      multiValueLabel: (base, state) => {
        return state.data.isFixed
          ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
          : base;
      },
      multiValueRemove: (base, state) => {
        return state.data.isFixed ? { ...base, display: 'none' } : base;
      },
    }),
    [customHeight]
  );

  /**
   * useCallback()
   */
  const displayTooltipFlag = useCallback(value => {
    return value && (!_.isEmpty(value) || !!value.length);
  }, []);

  const evaluateLabel = useCallback(
    option => {
      if (_.isString(option)) return option;
      if (customLabel) return customLabelFunc(option);
      if (multiSelect && Array.isArray(option)) return option.map(evaluateLabel).join(', ');
      return Array.isArray(option) ? option[0][optionLabelKey] : option[optionLabelKey];
    },
    [customLabel, customLabelFunc, multiSelect, optionLabelKey]
  );

  const onMenuOpen = useCallback(() => {
    handleMenuOpen();
    setTimeout(() => {
      const selectedEl = document.getElementsByClassName('DropDown__menu')[0];
      if (selectedEl) {
        selectedEl.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
      }
    }, 15);
  }, [handleMenuOpen]);

  const onKeyDown = useCallback(
    e => {
      if (e.keyCode === 13) onMenuOpen();
    },
    [onMenuOpen]
  );

  /**
   * render
   */
  return (
    <div className={`Dropdown__Container ${className} ${error ? 'error' : ''}`}>
      <div className={`DropDown__Label ${error ? 'error' : ''}`}>{label}</div>
      <div className="tooltip Dropdown__tooltip__Container">
        <Select
          className={`DropDown ${error ? 'error' : ''}`}
          classNamePrefix="DropDown"
          closeMenuOnSelect={!multiSelect}
          getOptionLabel={option => evaluateLabel(option)}
          getOptionValue={option => option[optionValueKey]}
          isClearable={isClearable}
          isDisabled={disabled}
          isLoading={loading}
          isMulti={multiSelect}
          isSearchable={searchable}
          name={name}
          onChange={onChange}
          onKeyDown={e => onKeyDown(e)}
          onMenuOpen={onMenuOpen}
          options={options}
          placeholder={
            placeholder ? placeholder : !noPlaceholder ? `Select ${label.toLowerCase()}` : ''
          }
          styles={customStyles}
          tabIndex="0"
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: '#d9d9d9',
            },
          })}
          value={value}
        />
        {displayTooltipFlag(value) && !noTooltip ? (
          <span className="tooltiptext tooltip__top">{evaluateLabel(value)}</span>
        ) : null}
      </div>
      {error && <div className="DropDown__Error">Selection is required</div>}
    </div>
  );
};

DropDown.propTypes = {
  className: propTypes.string,
  customLabelFunc: propTypes.func,
  handleMenuOpen: propTypes.func,
  loading: propTypes.bool,
  multiSelect: propTypes.bool,
  noPlaceholder: propTypes.bool,
  onChange: propTypes.func,
  optionLabelKey: propTypes.string,
  optionValueKey: propTypes.string,
  searchable: propTypes.bool,
};

export default DropDown;

import React, { useCallback } from 'react';
import propTypes from 'prop-types';
import _ from 'lodash/fp';
import moment from 'moment';
import { features } from '../../core/i18n/text';

const Radio = ({
  defaultOption,
  disableOption,
  error,
  handleDateSelection,
  hasDescription,
  items = [],
  serverDateTime,
  testDateId,
}) => {
  const onKeyDown = useCallback(
    (e, id) => {
      if (e.keyCode === 13) {
        handleDateSelection({ target: { id } });
      }
    },
    [handleDateSelection]
  );

  const getMessage = useCallback(
    item => {
      const rosterEndDate = moment(item.regAssignEndDate).format('MM.DD.YYYY');
      const testEndDate = moment(item.endTestDate).format('MM.DD.YYYY');

      const serverDate = moment(serverDateTime).format('MM.DD.YYYY');
      const currentDate = serverDate || moment(new Date()).format('MM.DD.YYYY');

      if (moment(currentDate, 'MM.DD.YYYY').isAfter(moment(testEndDate, 'MM.DD.YYYY'))) {
        return (
          <div className="RegisterStudents__modalAlert error">{features.roster.testingEnded}</div>
        );
      } else if (
        !moment(currentDate, 'MM.DD.YYYY').isAfter(moment(testEndDate, 'MM.DD.YYYY')) &&
        moment(currentDate, 'MM.DD.YYYY').isAfter(moment(rosterEndDate, 'MM.DD.YYYY'))
      ) {
        return (
          <div className="RegisterStudents__modalAlert error">
            {`${features.roster.shippingConcluded} not ${features.roster.newEnrollments}`}
          </div>
        );
      } else {
        return null;
      }
    },
    [serverDateTime]
  );

  return (
    <div>
      {items.map(item => {
        return (
          <label
            className={`${item.id === disableOption ? 'container disabled' : 'container'} ${
              item.icon ? 'icon' : ''
            }`}
            htmlFor={item.id}
            key={item.id}
            onKeyDown={e => onKeyDown(e, item.id)}>
            <span className={item.className}>{item.displayText}</span>
            <input
              checked={!_.isNil(defaultOption) && defaultOption === item.id}
              disabled={item.id === disableOption}
              id={item.id}
              name="radio"
              onChange={handleDateSelection}
              tabIndex="-1"
              type="radio"
              value={item.displayText}
            />
            <span
              className={`checkmark ${error ? 'error' : ''} ${
                defaultOption === item.id ? 'checked' : ''
              }`}
              tabIndex="0"
            />
            {hasDescription && <div>{item.description}</div>}
            {item.id === (testDateId || '') && getMessage(item)}
            {item.icon && <item.icon />}
          </label>
        );
      })}
    </div>
  );
};

Radio.displayName = 'Radio';
Radio.propTypes = {
  items: propTypes.arrayOf(propTypes.object).isRequired,
  handleDateSelection: propTypes.func.isRequired,
  disableOption: propTypes.string,
};

export default Radio;

const feat = 'login';

const LOGIN = `${feat}/LOGIN`;
const LOGOUT = `${feat}/LOGOUT`;
const ACC_VERIFICATION = `${feat}/ACC_VERIFICATION`;
const FORGOT_PASSWORD = `${feat}/FORGOT_PASSWORD`;
const RESET_PASSWORD = `${feat}/RESET_PASSWORD`;
const GET_TNC = `${feat}/GET_TNC`;

export { LOGIN, LOGOUT, ACC_VERIFICATION, FORGOT_PASSWORD, RESET_PASSWORD, GET_TNC };

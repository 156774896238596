import React from 'react';
import './MainSelector.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import InfoIcon from '../../assets/svgs/infoSmall.svg';
import DropDown from '../inputs/DropDown';
import * as Text from '../../core/i18n/text';

const MainSelector = props => {
  const { show, showCloseIcon, close, values, handleChange, handleSave, showWarning } = props;

  const handleKeyPress = () => ({ key }) => {
    if (key === 'Enter') {
      close();
    }
  };

  return (
    <>
      <Modal
        isOpen={show}
        className="MainSelector__Container"
        ariaHideApp={false}
        aria={{
          labelledby: 'Program Selector Modal',
          describedby:
            'Modal is displayed to allow user to verify program, admin, and organization selections',
          modal: 'true',
        }}
        closeTimeoutMS={200}>
        <div className="MainSelector__Title">
          {Text.features.header.mainSelector.title}
          {showCloseIcon && (
            <FontAwesomeIcon
              tabIndex="0"
              title={Text.common.labels.close}
              className="Modal__Close"
              icon={faTimes}
              onClick={close}
              onKeyPress={handleKeyPress()}
            />
          )}
        </div>
        <div className="MainSelector__Description">
          {Text.features.header.mainSelector.description}
        </div>
        <div className="MainSelector__Body">
          <DropDown
            label="Program"
            options={values.program.options}
            value={values.program.value.label}
            onChange={handleChange.programChange}
            error={values.program.error}
            placeholder={values.program.value.label ? values.program.value.label : null}
          />
          <DropDown
            label="Administration"
            options={values.admin.options}
            value={values.admin.value.label}
            onChange={handleChange.adminChange}
            error={values.admin.error}
            placeholder={values.admin.value.label ? values.admin.value.label : null}
          />
          <DropDown
            label="Organization"
            options={values.org.options}
            value={values.org.value.label}
            onChange={handleChange.orgChange}
            error={values.org.error}
            placeholder={values.org.value.label ? values.org.value.label : null}
          />
        </div>
        <div className={`MainSelector__ButtonContainer ${showWarning ? 'one' : ''}`}>
          {showWarning && (
            <div className="MainSelector__Info">
              <img src={InfoIcon} alt="info" />
              {Text.features.header.mainSelector.warning}
            </div>
          )}
          <button type="button" className="bigButton MainSelector__Button" onClick={handleSave}>
            <hr className="Modal__Delimiter bottom" />
            {Text.features.header.mainSelector.button}
          </button>
        </div>
      </Modal>
    </>
  );
};

export default MainSelector;

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Header from '../../../components/header/Header';
import SidebarNav from '../../../components/sidebar-nav/SidebarNav';
import { Tabs, OrgCrumbs, OrgSelect } from '../../../components/index';
import { actions as coreActions } from '../../../core/duck';
import { features as text, clickables } from '../../../core/i18n/text';
import _ from 'lodash/fp';
import './TestManagementRouter.scss';
import TestManagementOrganization from '../test-management-organization/TestManagementOrganization';
import TestManagementTestEvents from '../test-management-test-events/TestManagementTestEvents';
import TestManagementShipmentsTracking from '../test-management-shipments-tracking/TestManagementShipmentsTracking';
import { actions as orgManagementActions } from '../../administration/duck';
import Reports from '../../../components/reports/Reports';
import { getPermission } from '../../../core/duck/selectors';

let currentOrgPartId = 0;
let tabs = [
  {
    title: clickables.buttons.subTabs.organization,
    url: 'organization',
    forSchoolOnly: false,
    avail: true,
    component: TestManagementOrganization,
  },
  {
    title: clickables.buttons.subTabs.testEvents,
    url: 'test-events',
    avail: true,
    forSchoolOnly: true,
    component: TestManagementTestEvents,
  },
  {
    title: clickables.buttons.subTabs.shipmentsTracking,
    url: 'shipments-tracking',
    avail: true,
    forSchoolOnly: false,
    component: TestManagementShipmentsTracking,
  },
  {
    title: clickables.buttons.subTabs.reports,
    url: 'reports',
    forSchoolOnly: false,
    avail: true,
    permissions: ['hasReportsPermissions'],
    component: Reports,
  },
];
let currentOrg = {};

class TestManagementRouter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '',
      CurrentComponent: null,
      typeOfSelectedOrg: null,
      orgTree: [],
      tabs,
      childOrgs: [],
      offset: 0,
    };
  }

  componentDidMount() {
    const { orgHierarchy } = this.props;
    currentOrgPartId = parseInt(this.props.match.params.orgPartId);
    if (orgHierarchy?.data) {
      if (orgHierarchy.data[0].orgPartId === currentOrgPartId) {
        this.setState({ orgTree: orgHierarchy.data });
        this.toogleOrgVisibility(orgHierarchy.data[orgHierarchy.data.length - 1].type);
      } else {
        this.retrieveOrgHierachy(currentOrgPartId);
      }
    } else {
      this.retrieveOrgHierachy(currentOrgPartId);
    }
    this.props.actions.getInvitationStatus({ orgPartId: this.props.match.params.orgPartId });

    const url = this.props.match.url;
    for (let i = 0; i < this.state.tabs.length; i++) {
      if (url.includes(this.state.tabs[i].url)) {
        const CurrentComponent = this.state.tabs[i].component;
        this.setState({
          activeTab: this.state.tabs[i].url,
          CurrentComponent,
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.match)(this.props.match)) {
      currentOrgPartId = parseInt(this.props.match.params.orgPartId);
      this.retrieveOrgHierachy(currentOrgPartId);
      const url = this.props.match.url;
      for (let i = 0; i < this.state.tabs.length; i++) {
        if (url.includes(this.state.tabs[i].url)) {
          const CurrentComponent = this.state.tabs[i].component;
          this.setState({
            activeTab: this.state.tabs[i].url,
            CurrentComponent,
          });
        }
      }
    }
    if (
      !_.isEqual(prevProps.orgHierarchy)(this.props.orgHierarchy) &&
      this.props.orgHierarchy.data
    ) {
      currentOrgPartId = parseInt(this.props.match.params.orgPartId);
    }
    if (
      this.state.orgTree.length > 0 &&
      this.props.hasReportsPermissions !== prevProps.hasReportsPermissions
    ) {
      currentOrg = this.state.orgTree[this.state.orgTree.length - 1];
      this.toogleOrgVisibility(currentOrg.type);
    }
    if (
      !_.isEmpty(this.props.organizations) &&
      this.props.organizations?.loading !== prevProps.organizations?.loading
    ) {
      this.toogleOrgVisibility(currentOrg.type);
    }
  }

  setOffset = offset => {
    this.setState({
      offset,
    });
  };

  handleTabChange = tab => {
    this.setState({
      activeTab: tab,
    });
  };

  /* API call */
  retrieveOrgHierachy(orgPartId) {
    this.props.actions.getOrgHierarchy({ orgPartId }).then(response => {
      const data = response.data?.data;
      currentOrg = data[data.length - 1];
      this.setState({ orgTree: data });
      this.toogleOrgVisibility(currentOrg.type);
    });
  }

  toogleOrgVisibility = currentOrgType => {
    let cloneTabs = _.cloneDeep(tabs);
    if (currentOrgType && (currentOrgType === 'school' || currentOrgType === 'nonpub')) {
      cloneTabs[2].avail = currentOrg.hasReportsPermissions;
      cloneTabs.forEach(tab => (tab.avail = tab.forSchoolOnly && tab.avail));
      if (this.props.history.location.pathname.includes('organization')) {
        this.props.history.replace(
          `/testevents/test-events/orgPartId/${parseInt(this.props.match.params.orgPartId)}`
        );
      }
    } else {
      cloneTabs.forEach(tab => (tab.avail = !tab.forSchoolOnly && tab.avail));
      if (this.props.history.location.pathname.includes('test-events')) {
        this.props.history.replace(
          `/testevents/organization/orgPartId/${parseInt(this.props.match.params.orgPartId)}`
        );
      }
    }
    if (currentOrgType && currentOrgType === 'state') {
      cloneTabs = cloneTabs.filter(function(item) {
        return item.title !== clickables.buttons.subTabs.shipmentsTracking;
      });
    }
    if (!cloneTabs.find(tab => tab.title === clickables.buttons.subTabs.shipmentsTracking)) {
      this.props.history.replace(
        `/testevents/organization/orgPartId/${parseInt(this.props.match.params.orgPartId)}`
      );
    }
    cloneTabs[2].avail = this.props.hasReportsPermissions;
    this.setState({ tabs: cloneTabs });
    const school =
      this.props.organizations?.data?.length && !this.props.organizations.loading
        ? this.props.organizations?.data?.find(item => item.orgPartId === currentOrg.orgPartId)
        : this.props.parentStatus?.data;

    if (!_.isEmpty(school) && (school.status === 'pending' || school.status === 'inactive')) {
      cloneTabs[2].avail = false;
      this.setState({ tabs: cloneTabs });
    }
  };

  render() {
    const { orgTree, activeTab, tabs, CurrentComponent } = this.state;
    return (
      <div className="testManagement__Page">
        <Header />
        <div className="_outter_">
          <SidebarNav active="eventManage" />
          <div className="sidebar__Separator sidebar_fit_table">
            <OrgCrumbs
              {...this.props}
              crumbs={orgTree}
              rootName={text.testManagement.breadcrumbsBase}
            />
            <OrgSelect
              currentOrg={orgTree[orgTree.length - 1]}
              parentOrg={orgTree.length === 1 ? orgTree[0] : orgTree[orgTree.length - 2]}
            />
            <Tabs
              tabs={tabs}
              basePath="/testevents"
              currentOrgPartId={this.props.match.params.orgPartId}
              handleTabChange={this.handleTabChange}
              activeTab={activeTab}
              {...this.props}
            />
            {CurrentComponent ? (
              <CurrentComponent {...this.props} currentOrg={currentOrg} category="now_event" />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { userInfoReducer, rosterReducer, orgManagementReducer } = state;
  return {
    orgHierarchy: userInfoReducer.orgHierarchy,
    hasReportsPermissions: getPermission(state, 'now_test_event_reports'),
    organizations: rosterReducer.childOrganizations,
    parentStatus: orgManagementReducer.inviteStatus,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ ...coreActions, ...orgManagementActions }, dispatch),
  };
};

export { TestManagementRouter };

export default connect(mapStateToProps, mapDispatchToProps)(TestManagementRouter);

const feat = 'students';

const BULK_UPDATE_OPTIONS = `${feat}/BULK_UPDATE_OPTIONS`;
const GET_RECORDS = `${feat}/GET_RECORDS`;
const GET_NCR_MATERIAL_KIT = `${feat}/GET_NCR_MATERIAL_KIT`;
const GET_TESTINGDATES = `${feat}/GET_TESTINGDATES`;
const REG_STUDENTS = `${feat}/REG_STUDENTS`;
const UPLOAD_FILE = `${feat}/UPLOAD_FILE`;
const GET_FILES = `${feat}/GET_FILES`;
const GET_DOWNLOADURLS = `${feat}/GET_DOWNLOADURLS`;
const GET_STUDENTS_BY_TEST_EVENT = `${feat}/GET_STUDENTS_BY_TEST_EVENT`;
const GET_STUDENTS_BY_TEST_FORMAT = `${feat}/GET_STUDENTS_BY_TEST_FORMAT`;
const GET_STUDENTS_BY_GRADES = `${feat}/GET_STUDENTS_BY_GRADES`;
const GET_STUDENTS_BY_TEST = `${feat}/GET_STUDENTS_BY_TEST`;
const GET_CHILD_ORGS_SUMMARY = `${feat}/GET_CHILD_ORGS_SUMMARY`;
const GET_CHILD_ORGS = `${feat}/GET_CHILD_ORGS`;
const GET_ENTITY_DEF = `${feat}/GET_ENTITY_DEF`;
const GET_REG_TYPES = `${feat}/GET_REG_TYPES`;
const UPDATE_REGISTRATION_OPTIONS = `${feat}/UPDATE_REGISTRATION_OPTIONS`;
const UPDATE_STUDENT_DETAILS = `${feat}/UPDATE_STUDENT_DETAILS`;
const ADD_STUDENT = `${feat}/ADD_STUDENT`;
const SEARCH_STUDENT = `${feat}/SEARCH_STUDENT`;
const REQEST_STUDENT_TRANSFER = `${feat}/REQEST_STUDENT_TRANSFER`;
const UNENROLL_STUDENT = `${feat}/UNENROLL_STUDENT`;
const GET_SENT_TRANSFER_REQUESTS = `${feat}/GET_SENT_TRANSFER_REQUESTS`;
const GET_RECEIVED_TRANSFER_REQUESTS = `${feat}/GET_RECEIVED_TRANSFER_REQUESTS`;
const CANCEL_STUDENT_TRANSFER_REQUEST = `${feat}/CANCEL_STUDENT_TRANSFER_REQUEST`;
const UPDATE_STUDENT_TRANSFER_REQUEST = `${feat}/UPDATE_STUDENT_TRANSFER_REQUEST`;
const GET_TIMINGS = `${feat}/GET_TIMINGS`;
const GET_ACCOMMODATION_OPTIONS = `${feat}/GET_ACCOMMODATION_OPTIONS`;
const GET_REG_ACCOMS = `${feat}/GET_REG_ACCOMS`;

export {
  BULK_UPDATE_OPTIONS,
  GET_RECORDS,
  GET_NCR_MATERIAL_KIT,
  GET_TESTINGDATES,
  REG_STUDENTS,
  UPLOAD_FILE,
  GET_FILES,
  GET_DOWNLOADURLS,
  GET_STUDENTS_BY_TEST_EVENT,
  GET_STUDENTS_BY_TEST_FORMAT,
  GET_STUDENTS_BY_GRADES,
  GET_STUDENTS_BY_TEST,
  GET_CHILD_ORGS_SUMMARY,
  GET_CHILD_ORGS,
  GET_ENTITY_DEF,
  GET_REG_TYPES,
  UPDATE_REGISTRATION_OPTIONS,
  UPDATE_STUDENT_DETAILS,
  SEARCH_STUDENT,
  REQEST_STUDENT_TRANSFER,
  UNENROLL_STUDENT,
  GET_SENT_TRANSFER_REQUESTS,
  GET_RECEIVED_TRANSFER_REQUESTS,
  CANCEL_STUDENT_TRANSFER_REQUEST,
  UPDATE_STUDENT_TRANSFER_REQUEST,
  ADD_STUDENT,
  GET_TIMINGS,
  GET_ACCOMMODATION_OPTIONS,
  GET_REG_ACCOMS,
};

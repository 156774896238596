import React, { useEffect, useState } from 'react';
import Input from '../../../components/forms/fields/Input';
import _ from 'lodash/fp';
import Alert from '../../../components/alerts/Alert';
import DropDown from '../../../components/inputs/DropDown';
import Checkbox from '../../../components/inputs/Checkbox';
import DetailsInputContainer from './components/DetailsInputContainer';
import { FormControlLabel } from '@material-ui/core';
import { features } from '../../../core/i18n/text';

const Step1 = ({
  testValue,
  setTestValue,
  receiptValue,
  setReceiptValue,
  eventAutoAssignValue,
  setEventAutoAssignValue,
  testDates,
  testErrors,
  setTestErrors,
}) => {
  const getTestOptions = () => {
    const filteredTests = _.uniqBy('testingEventId')(testDates);
    return filteredTests.map(item => ({
      label: item.testingEventName,
      value: item.testingEventId,
    }));
  };

  const getReceiptOptions = () => {
    const filteredReceipts = testDates.filter(item => item.testingEventId === testValue.value);
    return filteredReceipts.map(item => ({ label: item.name, value: item.name, ...item }));
  };

  const handleTestChange = e => {
    setTestValue(e);
    setReceiptValue({});
    setTestErrors({ ...testErrors, testValue: false });
  };

  const handleReceiptChange = e => {
    setReceiptValue(e);
    setTestErrors({ ...testErrors, receiptValue: false });
  };

  const handleEventAutoAssignChange = e => {
    setEventAutoAssignValue(e.target.checked);
  };

  const testOptions = getTestOptions();
  const receiptOptions = getReceiptOptions();

  return (
    <div className="step-1-container">
      <h4 className="steps-title">{features.orgManagement.participation.modal.receiptDates}</h4>
      <Alert type="info" message={features.orgManagement.participation.modal.receiptInfo} />
      <div className="input-container">
        <div>
          <span className="label">{features.orgManagement.participation.modal.testWindow}</span>
          <DropDown
            options={testOptions}
            value={testValue.label}
            placeholder={testValue.label ? testValue.label : 'Select test window'}
            onChange={handleTestChange}
            error={testErrors.testValue}
            disabled={!Boolean(testOptions.length)}
            searchable={false}
            noTooltip
          />
        </div>
        <div>
          <span className="label">{features.orgManagement.participation.modal.receiptWeek}</span>
          <DropDown
            options={receiptOptions}
            value={receiptValue.lastName}
            placeholder={receiptValue.label ? receiptValue.label : 'Select receipt week'}
            onChange={handleReceiptChange}
            error={testErrors.receiptValue}
            disabled={!Boolean(receiptOptions.length)}
            searchable={false}
            noTooltip
          />
        </div>
        <div className="step1-checkbox">
          <FormControlLabel
            control={
              <Checkbox
                checked={eventAutoAssignValue}
                handleChange={handleEventAutoAssignChange}
                defaultChecked
              />
            }
            label={features.orgManagement.participation.modal.eventAutoAssign}
          />
        </div>
      </div>
    </div>
  );
};

const Step2 = ({ setDetails, details, detailsErrors, setDetailsErrors, isDeadlineExpired }) => {
  const onInputChange = e => {
    if (e.target.name !== 'phone' || e.target.value.length <= 10) {
      setDetails({ ...details, [e.target.name]: e.target.value });
      setDetailsErrors({ ...detailsErrors, [e.target.name]: '' });
    }
  };

  return (
    <div className="step-2-container">
      <h4 className="steps-title">{features.orgManagement.participation.modal.testCoordinator}</h4>
      {isDeadlineExpired && (
        <Alert type="info" message={features.orgManagement.participation.modal.deadlineExpired} />
      )}
      <DetailsInputContainer
        details={details}
        detailsErrors={detailsErrors}
        onInputChange={onInputChange}
      />
    </div>
  );
};

const Step3 = ({
  details,
  setShippingDetails,
  shippingDetails,
  isChecked,
  setIsChecked,
  detailsErrors,
  setDetailsErrors,
  processedOrder,
}) => {
  const [newDetails, setNewDetails] = useState(shippingDetails);

  const onInputChange = e => {
    if (e.target.name !== 'phone' || e.target.value.length <= 10) {
      setNewDetails({ ...newDetails, [e.target.name]: e.target.value });
      setShippingDetails({ ...shippingDetails, [e.target.name]: e.target.value });
      setDetailsErrors({ ...detailsErrors, [e.target.name]: '' });
    }
  };

  const onKeyDown = e => {
    if (e.keyCode === 13) {
      setIsChecked(!isChecked);
    }
  };

  useEffect(() => {
    setDetailsErrors({});
    if (!isChecked) {
      setShippingDetails(newDetails);
    } else {
      setShippingDetails(details);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChecked]);

  return (
    <div className="step-2-container">
      <h4 className="steps-title">{features.orgManagement.participation.modal.shippingContract}</h4>
      {processedOrder > 0 && (
        <Alert type="info" message={features.orgManagement.participation.modal.ordersProcessed} />
      )}
      <label className="checkbox-container">
        <Checkbox
          handleChange={() => setIsChecked(!isChecked)}
          checked={isChecked}
          label="coordinator-checkbox"
          onKeyDown={onKeyDown}
        />
        <span>Same as test coordinator</span>
      </label>

      <DetailsInputContainer
        details={isChecked ? details : newDetails}
        isChecked={isChecked}
        detailsErrors={detailsErrors}
        onInputChange={onInputChange}
      />
    </div>
  );
};

const Step4 = ({
  shippingAddress,
  setShippingAddress,
  addressErrors,
  setAddressErrors,
  processedOrder,
}) => {
  const onInputChange = e => {
    setShippingAddress({ ...shippingAddress, [e.target.name]: e.target.value });
    setAddressErrors({ ...addressErrors, [e.target.name]: '' });
  };
  return (
    <div className="step-2-container">
      <h4 className="steps-title">{features.orgManagement.participation.modal.shippingAddress}</h4>
      {processedOrder > 0 && (
        <Alert type="info" message={features.orgManagement.participation.modal.ordersProcessed} />
      )}
      <div className="details-container">
        <div className="label-container">
          <Input
            label="Address line 1"
            disabled={shippingAddress.isDistrictShip}
            error={addressErrors.address1}
            onChange={onInputChange}
            name="address1"
            value={shippingAddress.address1}
            maxLength="60"
          />
          <span>{features.orgManagement.participation.modal.poBox}</span>
        </div>
        <div className="label-container">
          <Input
            label="Address line 2"
            disabled={shippingAddress.isDistrictShip}
            error={addressErrors.address2}
            onChange={onInputChange}
            name="address2"
            value={shippingAddress.address2}
            maxLength="60"
          />
          <span>{features.orgManagement.participation.modal.optional}</span>
        </div>
        <Input
          label="City"
          disabled={shippingAddress.isDistrictShip}
          error={addressErrors.city}
          onChange={onInputChange}
          name="city"
          value={shippingAddress.city}
          maxLength="30"
        />
        <div className="short-field-container">
          <Input
            label="State"
            disabled={shippingAddress.isDistrictShip}
            error={addressErrors.stateCode}
            onChange={onInputChange}
            name="stateCode"
            value={shippingAddress.stateCode}
            maxLength="2"
          />
        </div>
        <div className="short-field-container">
          <Input
            label="Zip Code"
            disabled={shippingAddress.isDistrictShip}
            error={addressErrors.postalCode}
            onChange={onInputChange}
            name="postalCode"
            value={shippingAddress.postalCode}
            maxLength="5"
          />
        </div>
      </div>
    </div>
  );
};

const ShippingSteps = ({
  step,
  receiptValue,
  setReceiptValue,
  setTestValue,
  testValue,
  setIsChecked,
  isChecked,
  shippingDetails,
  setShippingDetails,
  details,
  setDetails,
  shippingAddress,
  setShippingAddress,
  testDates,
  testErrors,
  setTestErrors,
  detailsErrors,
  setDetailsErrors,
  addressErrors,
  setAddressErrors,
  orgPart,
  isDeadlineExpired,
  processedOrder,
  eventAutoAssignValue,
  setEventAutoAssignValue,
}) => {
  useEffect(() => {
    if (orgPart && orgPart.testingEventReceiptWindowId) {
      const selectedEvent = testDates.find(item => item.id === orgPart.testingEventReceiptWindowId);
      if (selectedEvent && _.isEmpty(receiptValue) && _.isEmpty(testValue)) {
        setTestValue({
          label: selectedEvent.testingEventName,
          value: selectedEvent.testingEventId,
        });
        setReceiptValue({
          ...selectedEvent,
          label: selectedEvent.name,
          value: selectedEvent.id,
        });
        console.log('selectedEvent', selectedEvent);
        console.log('selectedEvent.autoAssignFlag', selectedEvent.autoAssignFlag);
        if (typeof selectedEvent.autoAssignFlag !== 'undefined') {
          setEventAutoAssignValue(selectedEvent.autoAssignFlag);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  switch (step) {
    case 1:
      return (
        <Step1
          testValue={testValue}
          setTestValue={setTestValue}
          setReceiptValue={setReceiptValue}
          receiptValue={receiptValue}
          testDates={testDates}
          testErrors={testErrors}
          setTestErrors={setTestErrors}
          eventAutoAssignValue={eventAutoAssignValue}
          setEventAutoAssignValue={setEventAutoAssignValue}
        />
      );
    case 2:
      return (
        <Step2
          details={details}
          setDetails={setDetails}
          detailsErrors={detailsErrors}
          setDetailsErrors={setDetailsErrors}
          isDeadlineExpired={isDeadlineExpired}
        />
      );
    case 3:
      return (
        <Step3
          details={details}
          setShippingDetails={setShippingDetails}
          shippingDetails={shippingDetails}
          isChecked={isChecked}
          setIsChecked={setIsChecked}
          detailsErrors={detailsErrors}
          step={step}
          setDetailsErrors={setDetailsErrors}
          processedOrder={processedOrder}
        />
      );

    case 4:
      return (
        <Step4
          shippingAddress={shippingAddress}
          setShippingAddress={setShippingAddress}
          addressErrors={addressErrors}
          setAddressErrors={setAddressErrors}
          processedOrder={processedOrder}
        />
      );
    default:
      return <div></div>;
  }
};

export default ShippingSteps;

import React from 'react';
import propTypes from 'prop-types';
import './FilterCheckboxGroup.scss';
import Checkbox from '../inputs/Checkbox';
import _ from 'lodash/fp';

const FilterCheckboxGroup = ({
  propName,
  options = [],
  label = 'label',
  value = 'value',
  setFilterState,
}) => {
  return (
    <div className="roster__Filter__Options">
      {options.map((option, index) => (
        <div
          className="roster__Filter__Option"
          key={option[value] ? option[value] : index}
          aria-label={option[label]}>
          <Checkbox
            checked={_.get('checked')(option) ? option.checked : false}
            handleChange={() => {
              let updatedOptions = [...options];
              updatedOptions[index] = {
                ...option,
                checked: !option.checked,
              };
              setFilterState(propName, updatedOptions);
            }}
          />
          <span className="filters__Label">{option[label]}</span>
        </div>
      ))}
    </div>
  );
};

FilterCheckboxGroup.propTypes = {
  propName: propTypes.string,
  options: propTypes.arrayOf(
    propTypes.shape({
      checked: propTypes.bool,
      label: propTypes.string,
      value: propTypes.string,
    })
  ),
  label: propTypes.string,
  value: propTypes.string,
  setFilterState: propTypes.func,
};

FilterCheckboxGroup.defaultProps = {
  label: 'label',
  value: 'value',
};

export default FilterCheckboxGroup;

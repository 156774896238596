import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Header from '../../components/header/Header';
import ContractInfo from '../../components/landingPage/ContractInfo';
import Tile from '../../components/landingPage/Tile';
import RoosterIcon from '../../assets/svgs/rosterTile.svg';
import ContractIcon from '../../assets/svgs/contractIcon.svg';
import OrgManagementIcon from '../../assets/svgs/orgManagementTile.svg';
import EventManagerIcon from '../../assets/svgs/eventManagerIcon.svg';
import ScheduleIcon from '../../assets/svgs/scheduleIcon.svg';
import Notification from '../../components/alerts/Notification';
import * as Text from '../../core/i18n/text';
import { actions as coreActions } from '../../core/duck';
import { actions as landingActions } from '../landing/duck';
import { actions as loginActions } from '../login/duck';
import _ from 'lodash/fp';
import { getPermission } from '../../core/duck/selectors';
import { mapAdminsByProgramn } from '../../core/duck/selectors';
import Alert from '../../components/alerts/Alert';
import TermsAndConditions from './terms-and-conditions/TermsAndConditions';
import Auth from '../../core/services/AuthToken';
import { CommonServices } from '../../services';
import moment from 'moment';

let closeNotificationTimer;
let notificationText = '';

class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayNotification: false,
      contract: {},
      programs: [],
      impendingDeadline: 0,
    };
  }

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.showSuccessUpdateNotification) {
      this.setNotification();
    }
    if (!this.props.userInfo) {
      this.props.actions.getUserInfo();
    }
    if (window.sessionStorage.getItem('contractInfo')) {
      let contractInfo = JSON.parse(window.sessionStorage.getItem('contractInfo'));
      this.props.actions.getProgramAdminStaticInfo({
        contractId: contractInfo.id,
      });
      this.setState({
        contract: {
          ...contractInfo,
        },
      });
    }
    if (!this.props.programs) {
      this.props.actions.getUserPrograms();
    }
    if (this.props.userInfo && this.props.userInfo.data) {
      this.props.actions.getTerms(this.props.userInfo.data.id);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.location.state &&
      this.props.location.state.showSuccessUpdateNotification &&
      !this.state.displayNotification
    ) {
      this.setNotification();
    }
    if (
      !_.isEqual(prevProps.contractInfo)(this.props.contractInfo) &&
      _.get('contractInfo.data')(this.props)
    ) {
      this.setState({
        contract: { ...this.props.contractInfo.data },
      });
      window.sessionStorage.setItem(
        'contractInfo',
        JSON.stringify({
          ...this.props.contractInfo.data,
        })
      );
    }
    if (
      !_.isEqual(prevProps.userInfo)(this.props.userInfo) &&
      !this.props.userInfo.loading &&
      this.props.userInfo.data
    ) {
      this.props.actions.getTerms(this.props.userInfo.data.id);
    }
    if (!_.isEqual(prevProps.orgHierarchy)(this.props.orgHierarchy)) {
      if (!this.props.orgHierarchy?.loading && this.props.orgHierarchy?.data?.length > 0) {
        const { orgId, adminId } = this.props.orgHierarchy.data[0];

        CommonServices.getImpendingDeadlines(adminId, orgId).then(response =>
          this.setState({ impendingDeadline: response.data.count })
        );
      }
    }
    if (this.props.isLogoutSuccess) {
      window.localStorage.removeItem('isSidebarOpen');
      window.sessionStorage.clear();
      Auth.remove();
    }
  }

  setNotification = () => {
    if (this.props.location.state.notificationText) {
      notificationText = this.props.location.state.notificationText;
    }
    this.setState({
      displayNotification: true,
    });
    closeNotificationTimer = setTimeout(this.handleNotificationClose, 5000);
    this.props.history.replace({
      state: {},
    });
  };

  checkOrg = () => {
    if (this.props.orgHierarchy?.data?.length > 0) {
      return this.props.orgHierarchy.data[this.props.orgHierarchy.data.length - 1].type;
    }
    return '';
  };

  handleNotificationClose = () => {
    this.setState({
      displayNotification: false,
    });
    clearTimeout(closeNotificationTimer);
  };

  disableDeadlines = async () => {
    const { orgId, adminId } = this.props.orgHierarchy?.data[0];
    try {
      await CommonServices.disableImpendingDeadlines(adminId, orgId);
      CommonServices.getImpendingDeadlines(adminId, orgId).then(response =>
        this.setState({ impendingDeadline: response.data.count })
      );
    } catch (error) {}
  };

  render() {
    const { contract } = this.state;
    const { programsWithAdmins } = this.props;
    const nonEnrolledUser =
      _.get(this.props)('programs.data') && this.props.programs.data.length === 0;
    var earlyAccess = true;
    if (!this.props.orgHierarchy?.loading && this.props.orgHierarchy?.data?.length) {
      //this.props.orgHierarchy.data[0]['startDate']='2024-07-27';
      console.log(this.props.orgHierarchy.data[0]['startDate']);
      const currentDate = moment(new Date()).format('YYYY-MM-DD');
      if (
        !(
          currentDate >= this.props.orgHierarchy.data[0]['startDate'] &&
          currentDate <= this.props.orgHierarchy.data[0]['endDate']
        )
      ) {
        earlyAccess = false;
      }
    }
    return (
      <div className="landingPage">
        <Header />
        {this.state.displayNotification && (
          <Notification
            text={notificationText}
            handleClose={this.handleNotificationClose}
            type="Success"
          />
        )}
        <div className="landingPage__Container">
          {_.get(this.props)('programs.data') && this.props.programs.data.length === 0
            ? ''
            : contract &&
              contract.id && (
                <ContractInfo
                  logo={contract.logoUrl}
                  name={contract.name}
                  code={contract.code}
                  url={contract.homepageUrl}
                  hasContract
                  programsWithAdmins={programsWithAdmins}
                  adminId={
                    !this.props.orgHierarchy?.loading && this.props.orgHierarchy?.data?.length
                      ? this.props.orgHierarchy.data[0].adminId
                      : ''
                  }
                />
              )}
          <div className="landingPage__ContentContainer">
            <div className={`landingPage__Welcome ${nonEnrolledUser ? 'landing__margin' : ''}`}>
              {this.props.userInfo && this.props.userInfo.data && (
                <div>
                  <span className="landingPage__WelcomeTitle">{`${Text.features.landing.welcome.title} `}</span>
                  <span className="landingPage__WelcomeUserName">{`${this.props.userInfo.data.firstName} ${this.props.userInfo.data.lastName}`}</span>
                </div>
              )}
              {!nonEnrolledUser && (
                <div className="landingPage__WelcomeDesc">
                  {Text.features.landing.welcome.description}
                </div>
              )}
              {!earlyAccess && (
                <div className="alert__Large__Container">
                  <div className="alert_Border info"></div>
                  <div className="alert__Container full__Width">
                    This administration is not available. Check your ACT-hosted program webpage to
                    know when administrations are open and preparation activities can take place.
                  </div>
                </div>
              )}
            </div>
            {nonEnrolledUser && (
              <Alert
                type="info"
                message={Text.features.landing.nonEnrolledUserMessage}
                secondText={Text.features.landing.pleaseAsk}
                secondTextSameParagraph={true}
                className="nonEnrolled__message"></Alert>
            )}
            <div className="tiles__Container">
              {!nonEnrolledUser && earlyAccess && (
                <>
                  {this.props.hasContractPermission && (
                    <Tile
                      title={Text.features.landing.tiles.contract.title}
                      description={Text.features.landing.tiles.contract.description}
                      icon={ContractIcon}
                      buttonText={Text.features.landing.tiles.contract.button}
                      redirect={`/contract/organization/orgPartId/${parseInt(
                        this.props.match.params.orgPartId
                      )}`}
                    />
                  )}
                  {this.props.hasSchedulePermission && (
                    <Tile
                      title={Text.features.landing.tiles.scheduleOfEvents.title}
                      description={Text.features.landing.tiles.scheduleOfEvents.description}
                      icon={ScheduleIcon}
                      buttonText={Text.features.landing.tiles.scheduleOfEvents.button}
                      redirect={`/schedule/orgPartId/${parseInt(
                        this.props.match.params.orgPartId
                      )}`}
                      impendingDeadline={this.state.impendingDeadline}
                      disableDeadlines={this.disableDeadlines}
                      hasDeadlines
                    />
                  )}
                  {this.props.hasParticipationPermission && (
                    <Tile
                      title={Text.features.landing.tiles.orgManagement.title}
                      description={Text.features.landing.tiles.orgManagement.description}
                      icon={OrgManagementIcon}
                      buttonText={Text.features.landing.tiles.orgManagement.button}
                      redirect={`/administration/${
                        this.checkOrg() === 'school' ? 'participation' : 'organization'
                      }/orgPartId/${parseInt(this.props.match.params.orgPartId)}`}
                    />
                  )}
                  {this.props.hasRegistrationPermission && (
                    <Tile
                      title={Text.features.landing.tiles.roster.title}
                      description={Text.features.landing.tiles.roster.description}
                      icon={RoosterIcon}
                      buttonText={Text.features.landing.tiles.roster.button}
                      redirect={`/students/organization/orgPartId/${parseInt(
                        this.props.match.params.orgPartId
                      )}`}
                    />
                  )}
                  {this.props.hasTestEventsPermission && (
                    <Tile
                      title={Text.features.landing.tiles.eventManager.title}
                      description={Text.features.landing.tiles.eventManager.description}
                      icon={EventManagerIcon}
                      buttonText={Text.features.landing.tiles.eventManager.button}
                      redirect={`/testevents/organization/orgPartId/${parseInt(
                        this.props.match.params.orgPartId
                      )}`}
                      newPage
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <TermsAndConditions
          userInfo={this.props.userInfo ? this.props.userInfo.data : ''}
          actions={this.props.actions}
          terms={this.props.terms ? this.props.terms.data : []}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { userInfoReducer, landingReducer, loginReducer } = state;
  return {
    userInfo: userInfoReducer.userInfo,
    programs: userInfoReducer.programs,
    orgHierarchy: userInfoReducer.orgHierarchy,
    terms: userInfoReducer.terms,
    contractInfo: landingReducer.contractInfo,
    hasContractPermission: getPermission(state, 'now_contract_manage'),
    hasTestEventsPermission: getPermission(state, 'now_test_event_view'),
    hasRegistrationPermission: getPermission(state, 'now_reg_tile'),
    hasParticipationPermission: getPermission(state, 'now_org_tile'),
    hasSchedulePermission: getPermission(state, 'now_schedule_view'),
    programsWithAdmins: mapAdminsByProgramn(state),
    isLogoutSuccess: loginReducer.logout.data,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ ...coreActions, ...landingActions, ...loginActions }, dispatch),
  };
};

LandingPage.displayName = 'LandingPage';

export { LandingPage };

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);

import * as types from './types';

const initialState = {
  uploadedFiles: null,
  downloadUrls: null,
  inviteStatus: null,
  descendantOrganizations: null,
  descendantOrgTypes: null,
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.UPLOAD_FILE:
      return {
        ...state,
        fileUploadStatus: action.payload,
      };
    case types.GET_FILES:
      return {
        ...state,
        uploadedFiles: action.payload,
      };
    case types.GET_DOWNLOAD_URLS:
      return {
        ...state,
        downloadUrls: action.payload,
      };
    case types.GET_INVITE_STATUS:
      return {
        ...state,
        inviteStatus: action.payload,
      };
    case types.RESPOND_TO_INVITE:
      return {
        ...state,
        respondToInvite: action.payload,
      };
    case types.GET_PARTICIPATION_STATUS:
      return {
        ...state,
        participationStatus: action.payload,
      };
    case types.GET_STAFF:
      return {
        ...state,
        staff: action.payload,
      };
    case types.GET_USER_PART_DETAILS:
      return {
        ...state,
        userPartDetails: action.payload ? action.payload.data : null,
      };
    case types.GET_STAFF_DOWNLOAD_URLS:
      return {
        ...state,
        staffDownloadUrls: action.payload,
      };
    case types.GET_STAFF_FILES:
      return {
        ...state,
        staffUploadedFiles: action.payload,
      };
    case types.VERIFY_USER_BY_EMAIL:
      return {
        ...state,
        verifiedUserByEmail: action.payload,
      };
    case types.GET_POSSIBLE_ROLES:
      return {
        ...state,
        possibleUserRoles: action.payload,
      };
    case types.GET_USER_ROLES:
      return {
        ...state,
        userRoles: action.payload,
      };
    case types.GET_ALL_USER_ROLES:
      return {
        ...state,
        allUserRoles: action.payload,
      };
    case types.INVITE_USER_WITH_ROLES:
      return {
        ...state,
        userInvitationRoles: action.payload,
      };
    case types.AUTHORIZE_USER_WITH_ROLES:
      return {
        ...state,
        userAuthorizationRoles: action.payload,
      };
    case types.GET_AVAILABLE_EVENTS:
      return {
        ...state,
        availableTestingEvents: action.payload,
      };
    case types.SAVE_INACTIVE_USER_ROLES:
      return {
        ...state,
        saveInactiveUserRoles: action.payload,
      };
    case types.SAVE_ACTIVE_USER_ROLES:
      return {
        ...state,
        saveActiveUserRoles: action.payload,
      };
    case types.GET_ROLES:
      return {
        ...state,
        roles: action.payload,
      };
    case types.GET_ORGPART_DESCENDANTS:
      return {
        ...state,
        orgPartDescendants: action.payload,
      };
    case types.GET_DESCENDANT_ORGS:
      return {
        ...state,
        descendantOrganizations: action.payload,
      };
    case types.GET_DESCENDANT_ORG_TYPES:
      return {
        ...state,
        descendantOrgTypes: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import Modal from '../../../components/modal/AlertModal';
import Parser from 'html-react-parser';
import Validator from 'email-validator';

import { actions } from '../duck';
import * as Text from '../../../core/i18n/text';

import Header from '../../../components/forms/FormHeader';
import Email from '../../../components/forms/fields/Input';
import Password from '../../../components/forms/fields/Password';
import Alert from '../../../components/alerts/Alert';
import PasswordValidatorDisplay from '../../../components/forms/PasswordValidatorDisplay';
import Checkbox from '../../../components/inputs/Checkbox';
import ConfirmationMessage from '../../../components/forms/ConfirmationMessage';
import ReCAPTCHA from 'react-google-recaptcha';

class RegisterForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      modalIsOpen: false,
      passwordValidations: {
        '10 characters': false,
        '1 lowercase letter': false,
        '1 digit': false,
        '1 special character': false,
        '1 uppercase letter': false,
        'No spaces': true,
      },
      recaptchaValue: '',
      displayRequiredMessage: {
        passwordEmpty: false,
        passwordFormatWrong: false,
        hint: true,
        tnc: false,
        recaptcha: false,
      },
      errors: {
        email: '',
        validPassword: false,
        tncChecked: false,
        noInternet: navigator.onLine,
        recaptcha: false,
      },
    };
    this.recaptchaRef = React.createRef();
  }

  componentWillMount() {
    this.props.actions.getTnc();
  }

  componentDidUpdate() {
    if (this.props.verifyError && this.state.errors.email === '') {
      this.setState(prevState => ({
        errors: {
          ...prevState.errors,
          email: Text.messages.error.emailTaken,
        },
      }));
    }
  }

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
    });
  };

  openTermsModal = e => {
    e.preventDefault();
    this.setState({
      modalIsOpen: true,
    });
  };

  onEmailChange = e => {
    let email = e.target.value.trim();

    this.setState({
      email,
    });
  };

  onPasswordChange = password => {
    this.setState({
      password,
    });
    this.validatePassword(password);
  };

  onPasswordFocus = password => {
    this.setState(prevState => ({
      displayRequiredMessage: {
        ...prevState.displayRequiredMessage,
        passwordEmpty: false,
        passwordFormatWrong: false,
        hint: false,
      },
    }));
  };

  onPasswordBlur = password => {
    this.setState(prevState => ({
      displayRequiredMessage: {
        ...prevState.displayRequiredMessage,
        hint: true,
      },
    }));
  };

  onCheckChange = event => {
    const { checked } = event.target;
    this.setState(prevState => ({
      errors: {
        ...prevState.errors,
        tncChecked: checked,
      },
      displayRequiredMessage: {
        ...prevState.displayRequiredMessage,
        tnc: false,
      },
    }));
  };

  isEmpty = () => {
    const isEmailEmpty = this.state.email.trim() === '' ? true : false;
    const isPasswordEmpty = this.state.password === '' ? true : false;
    const isTncChecked = this.state.errors.tncChecked;
    const isCaptchaChecked = this.state.recaptchaValue === '' ? false : true;
    const isEmailValid = Validator.validate(this.state.email.trim());
    const emailError = isEmailEmpty
      ? Text.messages.error.emailRequired
      : isEmailValid
      ? ''
      : Text.messages.error.emailInvalid;

    this.setState(prevState => ({
      displayRequiredMessage: {
        ...prevState.displayRequiredMessage,
        passwordEmpty: isPasswordEmpty,
        tnc: !isTncChecked,
        recaptcha: !this.state.recaptchaValue,
      },
      errors: {
        ...prevState.errors,
        email: emailError,
      },
    }));

    return isEmailEmpty || isPasswordEmpty || !isTncChecked || !isEmailValid || !isCaptchaChecked;
  };

  validateInput = () => {
    const isPasswordValid = this.state.errors.validPassword;
    this.setState(prevState => ({
      displayRequiredMessage: {
        ...prevState.displayRequiredMessage,
        passwordFormatWrong: !isPasswordValid,
      },
    }));
    return !this.isEmpty() && isPasswordValid;
  };

  validatePassword = password => {
    const passwordRx = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[.#?!@$%^&*-])\S{10,}$/;
    let passwordValidations = {
      '10 characters': true,
      '1 lowercase letter': true,
      '1 digit': true,
      '1 special character': true,
      '1 uppercase letter': true,
      'No spaces': true,
    };

    if (passwordRx.test(password)) {
      this.setState(prevState => ({
        errors: {
          ...prevState.errors,
          validPassword: true,
        },
      }));
    } else {
      passwordValidations['10 characters'] = password.length >= 10 ? true : false;
      passwordValidations['1 lowercase letter'] = password.match(/[a-z]/) ? true : false;
      passwordValidations['1 digit'] = password.match(/\d/) ? true : false;
      passwordValidations['1 special character'] = password.match(/[.#?!@$%^&*-]/) ? true : false;
      passwordValidations['1 uppercase letter'] = password.match(/[A-Z]/) ? true : false;
      passwordValidations['No spaces'] = !password.match(/\s/) ? true : false;

      this.setState(prevState => ({
        errors: {
          ...prevState.errors,
          validPassword: false,
        },
        passwordValidations,
      }));
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    if (this.validateInput()) {
      this.props.actions.register(
        this.state.email,
        this.state.password,
        this.props.tnc.UUID,
        this.state.recaptchaValue
      );
    } else {
      this.props.actions.verifyAction({ loading: false, data: null, error: null });
    }
  };

  handleRedirect = () => {
    this.props.history.push('/login');
  };

  handleCaptcha = () => {
    this.setState(prevState => ({
      recaptchaValue: this.recaptchaRef.current.getValue(),
      displayRequiredMessage: {
        ...prevState.displayRequiredMessage,
        recaptcha: false,
      },
    }));
  };

  render() {
    if (this.props.isRegisterSuccess) {
      const msg = [
        <div>
          <div className="description_label">
            {Text.features.register.confirmation.description.email}{' '}
          </div>
          <div className="register_email">
            <strong>{this.state.email}</strong>
          </div>
        </div>,
        Text.features.register.confirmation.description.check,
      ];
      return (
        <ConfirmationMessage title={Text.features.register.confirmation.title} message={msg} />
      );
    }

    let tncAgreement = (
      <span className="tnc__Text">
        {Text.features.register.ageAgreement}, and I agree to the&nbsp;
        <span
          className="registerForm__Link"
          style={{ cursor: 'pointer' }}
          tabIndex="0"
          onClick={this.openTermsModal}
          onKeyDown={e => e.keyCode === 13 && this.openTermsModal(e)}>
          {Text.features.register.tnc}
        </span>
      </span>
    );

    let passwordView;

    if (!this.state.displayRequiredMessage.hint) {
      if (this.state.errors.validPassword) {
        passwordView = (
          <div className="passwordView__Container">
            <Alert type="successNoBkg" message={Text.messages.success.securePassword} />
          </div>
        );
      } else {
        passwordView = (
          <div className="passwordView__Container">
            <div className="validation__Base">
              <PasswordValidatorDisplay validations={this.state.passwordValidations} />
            </div>
          </div>
        );
      }
    }

    return (
      <div className="registerForm__Container">
        <Header title={Text.features.register.title} />
        {!this.state.errors.noInternet && (
          <Alert type="error" message={Text.messages.error.connection} />
        )}
        {this.props.registerError && <Alert type="error" message={this.props.registerError} />}
        <form onSubmit={this.handleSubmit}>
          <Email
            name={'email'}
            label={Text.common.fields.email}
            value={this.state.email}
            error={this.state.errors.email}
            onChange={this.onEmailChange}
          />
          <Password
            displayError={this.state.displayRequiredMessage.passwordFormatWrong}
            displayEmpty={this.state.displayRequiredMessage.passwordEmpty}
            displayHint={this.state.displayRequiredMessage.hint}
            onChange={this.onPasswordChange}
            onFocus={this.onPasswordFocus}
            onBlur={this.onPasswordBlur}
          />
          {passwordView}
          <div className="registerForm__CheckboxWrapper">
            <Checkbox
              checked={!!this.state.errors.tncChecked}
              value={this.state.errors.tncChecked}
              handleChange={this.onCheckChange}
              error={this.state.displayRequiredMessage.tnc}
            />
            {tncAgreement}
          </div>
          {this.state.displayRequiredMessage.tnc && (
            <div className="error-message">{Text.messages.error.tnc}</div>
          )}
          <div
            className={`recaptcha-container ${
              this.state.displayRequiredMessage.recaptcha ? 'error' : ''
            }`}>
            <ReCAPTCHA
              sitekey="6LfBNw0TAAAAAL16TzcMgB_3WrVibbqWyIF6sq8P"
              onChange={this.handleCaptcha}
              ref={this.recaptchaRef}
              style={{ width: '100%' }}
            />
            {this.state.displayRequiredMessage.recaptcha && (
              <div className="error-message">{Text.messages.error.reCaptcha}</div>
            )}
          </div>
          <button className="bigButton" disabled={this.props.isRegisterPending}>
            {Text.clickables.buttons.continueCreateAccount}
          </button>
        </form>
        {this.props.tnc && (
          <Modal
            show={this.state.modalIsOpen}
            title={Text.features.login.tncModal}
            close={this.closeModal}
            body={Parser(this.props.tnc.Terms)}></Modal>
        )}
        <div className="loginForm__Footer">
          <div>
            {Text.clickables.links.alreadyHaveAccount} &nbsp;
            <a className="registerForm__Link" href="/login">
              {Text.clickables.links.clickHere}
            </a>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isRegisterSuccess: state.createAccountReducer.register.data,
    isRegisterPending: state.createAccountReducer.register.loading,
    registerError: state.createAccountReducer.register.error,
    verifyError: state.createAccountReducer.verify.error,
    tnc: state.createAccountReducer.tnc.data,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RegisterForm));

import React, { useCallback, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';

import { FilesBaseComponent } from '../../../components';
import SubNavigation from '../../../components/navigation/SubNavigation';

import { OrgManagementServices } from '../../../services';

import { usePermission, useStore } from '../../../store/hooks';

const AdministrationFiles = ({ activeSubTab }) => {
  /**
   * react-router-dom hooks
   */
  const params = useParams();

  /**
   * useStore()
   */
  const [orgHierarchyResponse] = useStore('orgHierarchy');

  /**
   * usePermission()
   */
  const hasFilePermissions = usePermission('now_org_part_files');
  const hasUserFilePermissions = usePermission('now_user_files');

  /**
   * useMemo()
   */
  const currentOrg = useMemo(() => {
    if (!orgHierarchyResponse?.data) return;
    return orgHierarchyResponse.data[orgHierarchyResponse.data.length - 1];
  }, [orgHierarchyResponse]);

  const subTabs = useMemo(
    () =>
      [
        {
          display: hasFilePermissions && currentOrg?.allowChildOrgsFlag,
          id: '1',
          text: 'Org invitation',
          url: 'participate',
        },
        {
          display: hasUserFilePermissions,
          id: '2',
          text: 'Users invite',
          url: 'staffinvite',
        },
      ].filter(({ display }) => display),
    [currentOrg, hasFilePermissions, hasUserFilePermissions]
  );

  const activeTab = useMemo(() => {
    if (!subTabs.length) return;
    if (activeSubTab) {
      const subTab = subTabs.find(tab => tab.url === activeSubTab);
      if (subTab) return subTab.id;
    }
    return subTabs[0].id;
  }, [activeSubTab, subTabs]);

  /**
   * useCallback()
   */
  const formatTextToDisplay = useCallback(
    ({ text, url }) => (
      <Link
        to={{
          pathname: `/administration/files/${url}/orgPartId/${params.orgPartId}`,
        }}>
        {text}
      </Link>
    ),
    [params.orgPartId]
  );

  /**
   * render
   */
  if (!hasFilePermissions || !hasUserFilePermissions) return null;

  return (
    <>
      <Helmet>
        <title>Participation Files - ACT-Now</title>
      </Helmet>
      {subTabs.length > 1 && (
        <SubNavigation
          activeItem={activeTab}
          formatTextToDisplay={formatTextToDisplay}
          handleClick={() => {}}
          items={subTabs}
        />
      )}
      {activeSubTab === 'staffinvite' || !currentOrg?.allowChildOrgsFlag ? (
        <FilesBaseComponent
          featureName="staffInvite"
          getUploadedFiles={OrgManagementServices.getStaffUploadedFiles}
        />
      ) : (
        <FilesBaseComponent
          featureName="orgManagement"
          getUploadedFiles={OrgManagementServices.getUploadedFiles}
        />
      )}
    </>
  );
};
export default AdministrationFiles;

import React from 'react';
import Modal from 'react-modal';
import { ReactComponent as NoInternetIcon } from '../../assets/svgs/NoInternetConnection.svg';
import { messages } from '../../core/i18n/text';
import Loader from '../loader/Loader';
import './NoInternetModal.scss';

const NoInternetModal = () => {
  return (
    <Modal isOpen={true} className="NoInternet__Container" ariaHideApp={false}>
      <NoInternetIcon />
      <div className="NoInternet__Title">{messages.noInternet.title}</div>
      <div className="NoInternet__Desc">{messages.noInternet.desc}</div>
      <div className="NoInternet__Reconnecting">
        <Loader />
        {messages.noInternet.reconnecting}
      </div>
    </Modal>
  );
};

export default NoInternetModal;

const feat = 'contract';

const UPLOAD_FILE = `${feat}/UPLOAD_FILE`;
const GET_FILES = `${feat}/GET_FILES`;
const GET_DOWNLOAD_URLS = `${feat}/GET_DOWNLOAD_URLS`;
const GET_ORGANIZATIONS = `${feat}/GET_ORGANIZATIONS`;
const GET_PARENT_ORGS = `${feat}/GET_PARENT_ORG`;
const GET_CHILD_ORG_TYPES = `${feat}/GET_CHILD_ORG_TYPES`;
const UPDATE_ORG = `${feat}/UPDATE_ORG`;
const CREATE_ORG = `${feat}/CREATE_ORG`;
const ORG_ENTITY_DEF = `${feat}/ORG_ENTITY_DEF`;

export {
  UPLOAD_FILE,
  GET_FILES,
  GET_DOWNLOAD_URLS,
  GET_ORGANIZATIONS,
  GET_PARENT_ORGS,
  GET_CHILD_ORG_TYPES,
  UPDATE_ORG,
  CREATE_ORG,
  ORG_ENTITY_DEF,
};

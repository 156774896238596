import React from 'react';
import { Helmet } from 'react-helmet';

import { ContractServices } from '../../../services';

import { FilesBaseComponent } from '../../../components/index';

const ContractFiles = () => {
  return (
    <>
      <Helmet>
        <title>Contract Files - ACT-Now</title>
      </Helmet>
      <FilesBaseComponent
        featureName="contract"
        getUploadedFiles={ContractServices.getUploadedFiles}
        useRootOrgId
      />
    </>
  );
};

export default ContractFiles;

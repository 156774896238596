import _ from 'lodash/fp';
import { createDispatchFunction } from '../../../services/helpers/stateHelper';

import { OrgManagementServices } from '../../../services';
import * as types from './types';

export function uploadAction(payload) {
  return {
    type: types.UPLOAD_FILE,
    payload,
  };
}

export function getFilesAction(payload) {
  return {
    type: types.GET_FILES,
    payload,
  };
}

export function getDownloadUrlsAction(payload) {
  return {
    type: types.GET_DOWNLOAD_URLS,
    payload,
  };
}

export function getInvitationStatusAction(payload) {
  return {
    type: types.GET_INVITE_STATUS,
    payload,
  };
}

export function respondToInviteAction(payload) {
  return {
    type: types.RESPOND_TO_INVITE,
    payload,
  };
}

export function getParticipationStatusInviteAction(payload) {
  return {
    type: types.GET_PARTICIPATION_STATUS,
    payload,
  };
}

export function getStaffAction(payload) {
  return {
    type: types.GET_STAFF,
    payload,
  };
}

export function getUserPartDetailsAction(payload) {
  return {
    type: types.GET_USER_PART_DETAILS,
    payload,
  };
}

export function getStaffDownloadUrlsAction(payload) {
  return {
    type: types.GET_STAFF_DOWNLOAD_URLS,
    payload,
  };
}
export function verifyUserByEmailAction(payload) {
  return {
    type: types.VERIFY_USER_BY_EMAIL,
    payload,
  };
}

export function getRolesByOrgAction(payload) {
  return {
    type: types.GET_POSSIBLE_ROLES,
    payload,
  };
}

export function getUserRolesAction(payload) {
  return {
    type: types.GET_USER_ROLES,
    payload,
  };
}

export function getAllUserRolesAction(payload) {
  return {
    type: types.GET_ALL_USER_ROLES,
    payload,
  };
}

export function inviteUserWithRolesAction(payload) {
  return {
    type: types.INVITE_USER_WITH_ROLES,
    payload,
  };
}

export function authorizeUserWithRolesAction(payload) {
  return {
    type: types.AUTHORIZE_USER_WITH_ROLES,
    payload,
  };
}

export function getAvailableTestingEventsAction(payload) {
  return {
    type: types.GET_AVAILABLE_EVENTS,
    payload,
  };
}

export function saveInactiveUserRolesAction(payload) {
  return {
    type: types.SAVE_INACTIVE_USER_ROLES,
    payload,
  };
}

export function saveActiveUserRolesAction(payload) {
  return {
    type: types.SAVE_ACTIVE_USER_ROLES,
    payload,
  };
}
export function getRolesAction(payload) {
  return {
    type: types.GET_ROLES,
    payload,
  };
}

export function getOrgPartDescendantsAction(payload) {
  return {
    type: types.GET_ORGPART_DESCENDANTS,
    payload,
  };
}

export function getStaffUploadedFilesAction(payload) {
  return {
    type: types.GET_STAFF_FILES,
    payload,
  };
}

export function inviteOrgAction(payload) {
  return {
    type: types.INVITE_ORG,
    payload,
  };
}

export function getDescendantOrgTypesAction(payload) {
  return {
    type: types.GET_DESCENDANT_ORG_TYPES,
    payload,
  };
}

export function getDescendantOrgsAction(payload) {
  return {
    type: types.GET_DESCENDANT_ORGS,
    payload,
  };
}

export function uploadFile(data, orgId) {
  return createDispatchFunction(
    _.partial(OrgManagementServices.uploadFile)([data, orgId]),
    uploadAction
  );
}

export function getUploadedFiles(params) {
  return createDispatchFunction(
    _.partial(OrgManagementServices.getUploadedFiles)([params]),
    getFilesAction
  );
}

export function getDownloadUrls(orgPartId, jobId) {
  return createDispatchFunction(
    _.partial(OrgManagementServices.getDownloadUrls)([orgPartId, jobId]),
    getDownloadUrlsAction
  );
}

export function getInvitationStatus(params) {
  return createDispatchFunction(
    _.partial(OrgManagementServices.getInvitationStatus)([params]),
    getInvitationStatusAction
  );
}

export function respondToInvite(data) {
  return createDispatchFunction(
    _.partial(OrgManagementServices.respondToInvite)([data]),
    respondToInviteAction
  );
}

export function getParticipationStatus(data) {
  return createDispatchFunction(
    _.partial(OrgManagementServices.getParticipationStatus)([data]),
    getParticipationStatusInviteAction
  );
}

export function getStaff(data) {
  return createDispatchFunction(_.partial(OrgManagementServices.getStaff)([data]), getStaffAction);
}

export function getUserPartDetails(data) {
  return createDispatchFunction(
    _.partial(OrgManagementServices.getStaff)([data]),
    getUserPartDetailsAction
  );
}

export function getStaffUploadedFiles(params) {
  return createDispatchFunction(
    _.partial(OrgManagementServices.getStaffUploadedFiles)([params]),
    getStaffUploadedFilesAction
  );
}

export function getStaffDownloadUrls(orgPartId, jobId) {
  return createDispatchFunction(
    _.partial(getStaffUrls)([orgPartId, jobId]),
    getStaffDownloadUrlsAction
  );
}
export function verifyUserByEmail(email, orgPartId) {
  return createDispatchFunction(
    _.partial(OrgManagementServices.verifyUserByEmail)([email, orgPartId]),
    verifyUserByEmailAction
  );
}

export function getRolesByOrg(params) {
  return createDispatchFunction(
    _.partial(OrgManagementServices.getRolesByOrg)([params]),
    getRolesByOrgAction
  );
}

export function getUserRoles(orgPartId, email) {
  return createDispatchFunction(
    _.partial(OrgManagementServices.getUserRoles)([orgPartId, email]),
    getUserRolesAction
  );
}

export function getAllUserRoles(userAssignments) {
  return createDispatchFunction(
    _.partial(OrgManagementServices.getAllUserRoles)([userAssignments]),
    getAllUserRolesAction
  );
}

export function inviteUserWithRoles(body) {
  return createDispatchFunction(
    _.partial(OrgManagementServices.inviteUserWithRoles)([body]),
    inviteUserWithRolesAction
  );
}

export function authorizeUserWithRoles(body) {
  return createDispatchFunction(
    _.partial(OrgManagementServices.authorizeUserWithRoles)([body]),
    authorizeUserWithRolesAction
  );
}

export function getAvailableTestingEvents(params) {
  return createDispatchFunction(
    _.partial(OrgManagementServices.getAvailableTestingEvents)([params]),
    getAvailableTestingEventsAction
  );
}

export function saveInactiveUserRoles(body) {
  return createDispatchFunction(
    _.partial(OrgManagementServices.saveInactiveUserRoles)([body]),
    saveInactiveUserRolesAction
  );
}

export function saveActiveUserRoles(body) {
  return createDispatchFunction(
    _.partial(OrgManagementServices.saveActiveUserRoles)([body]),
    saveActiveUserRolesAction
  );
}

export function getStaffUrls(orgPartId, jobId) {
  return OrgManagementServices.getStaffDownloadUrls(orgPartId)([jobId]);
}

export function getRoles(data) {
  return createDispatchFunction(_.partial(OrgManagementServices.getRoles)([data]), getRolesAction);
}

export function getOrgPartDescendants(orgPartId, adminId) {
  return createDispatchFunction(
    _.partial(OrgManagementServices.getOrgPartDescendants)([orgPartId, adminId]),
    getOrgPartDescendantsAction
  );
}

export function inviteOrg(data) {
  return createDispatchFunction(
    _.partial(OrgManagementServices.inviteOrg)([data]),
    inviteOrgAction
  );
}

export function getDescendantOrgTypes(code) {
  return createDispatchFunction(
    _.partial(OrgManagementServices.getDescendantOrgTypes)([code]),
    getDescendantOrgTypesAction
  );
}

export function getDescendantOrgs(params) {
  return createDispatchFunction(
    _.partial(OrgManagementServices.getDescendantOrgs)([params]),
    getDescendantOrgsAction
  );
}

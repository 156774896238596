import React, { useContext, useMemo } from 'react';

import TextContext from '../../../core/providers/TextProvider';

import './Counter.scss';

const Counter = ({ count, loadingData, offset = 0, totalCount }) => {
  /**
   * useContext()
   */
  const Text = useContext(TextContext);

  /**
   * useMemo()
   */
  const end = useMemo(() => (count + offset < totalCount ? count + offset : totalCount), [
    count,
    offset,
    totalCount,
  ]);

  const start = useMemo(() => offset + 1, [offset]);

  const resultText = useMemo(
    () => (totalCount === 1 ? Text.features.student.result : Text.features.student.results),
    [Text, totalCount]
  );

  if (loadingData) return <p className="counter-results"></p>;

  if (totalCount === end && !offset)
    return (
      <p className="counter-results">
        <strong>{`${totalCount} `}</strong>
        {resultText}
      </p>
    );

  return (
    <p className="counter-results">
      {totalCount > 0 ? (
        <span>
          <strong>
            {start}-{end}
          </strong>{' '}
          out of <strong>{totalCount}</strong> {resultText}
        </span>
      ) : (
        <strong>{Text.features.student.zeroResults}</strong>
      )}
    </p>
  );
};

export default Counter;

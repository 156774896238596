import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions as orgManagementActions } from '../duck';
import * as text from '../../../core/i18n/text';
import OrganizationTab from '../../../components/organization-tab/OrganizationTab';
import _ from 'lodash/fp';
import { ReactComponent as PenIcon } from '../../../assets/svgs/pen.svg';
import { ReactComponent as GoToArrow } from '../../../assets/svgs/goToArrow.svg';
import { ReactComponent as PlusIcon } from '../../../assets/svgs/plusSmall.svg';
import './AdministrationOrganization.scss';
import Buttons from '../../../components/data-panel/buttons/Buttons';
import { countArray } from '../../../core/constants';
import moment from 'moment';

const initialSearch = {
  nameOrCode: '',
};

const inputList = [
  {
    placeholder: 'Org name or code',
    maxLength: '30',
    type: 'text',
    name: 'nameOrCode',
  },
];

const mapUiStatus = [
  {
    status: 'active',
    label: text.features.organization.filters.participation.confirmedParticipating,
    button: text.features.organization.buttons.edit,
    svg: PenIcon,
  },
  {
    status: 'inactive',
    label: text.features.organization.filters.participation.declinedParticipations,
    button: text.features.organization.buttons.edit,
    svg: PenIcon,
  },
  {
    status: 'pending',
    label: text.features.organization.filters.participation.notConfirmed,
    button: text.features.organization.buttons.confirm,
    svg: null,
  },
  {
    status: 'notInvited',
    label: text.features.organization.filters.participation.notParticipating,
    button: text.features.organization.buttons.addToAdmin,
    svg: PlusIcon,
  },
];

const filterSections = [
  {
    callParameter: 'status',
    name: 'participation',
    options: mapUiStatus.map(filter => {
      return {
        value: filter.status,
        label: filter.label,
      };
    }),
    title: text.features.organization.filters.participation.title,
    type: 'checkbox',
  },
  {
    callParameter: 'orgType',
    name: 'type',
    options: [],
    title: text.features.organization.filters.type,
    type: 'checkbox',
  },
];

const initialColumns = [
  {
    accessor: 'orgcode',
    fields: ['orgcode'],
    Header: 'Local org code',
    name: 'Local org code',
    type: 'string',
    width: 130,
  },
  {
    accessor: 'orgtype',
    Cell: e => <div className="text-capitalize">{e.cell.row.original.orgtype}</div>,
    fields: ['orgtype'],
    Header: 'Type',
    name: 'Type',
    width: 145,
  },
  {
    accessor: 'status',
    Cell: e => <div>{mapUiStatus.find(s => s.status === e.cell.row.original.status).label}</div>,
    fields: ['status'],
    Header: 'Status',
    name: 'Status',
    width: 190,
  },
];

const linkButtons = value => {
  return {
    school: [
      {
        basePath: '/administration/participation',
        svg: mapUiStatus.find(s => s.status === value).svg,
        text: mapUiStatus.find(s => s.status === value).button,
      },
    ],
    district: [
      {
        basePath: '/administration/organization',
        svg: GoToArrow,
        text: text.features.organization.buttons.seeOrgs,
      },
    ],
  };
};

const getTypeOptions = typesList =>
  typesList.map(type => ({ value: type.orgTypeCode, label: type.orgTypeName }));

class AdministrationOrganization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtersSections: _.cloneDeep(filterSections),
      nonStikyColumns: initialColumns,
    };
  }

  componentDidMount() {
    if (!_.get('data')(this.props.parentStatus)) {
      this.props.actions.getParentStatus({ orgPartId: this.props.currentOrg.orgPartId });
    }
    this.props.actions.getDescendantOrgTypes(this.props.currentOrg.type).then(() => {
      const { descendantOrgTypes } = this.props;
      if (_.get('data')(descendantOrgTypes) && descendantOrgTypes.data.length > 1) {
        const typeOptions = getTypeOptions(descendantOrgTypes.data);
        const newListFilters = _.cloneDeep(this.state.filtersSections);
        newListFilters[newListFilters.length - 1].options = typeOptions;
        this.setState({
          filtersSections: newListFilters,
        });
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (
      !_.isEqual(this.props.currentOrg)(prevProps.currentOrg) &&
      (!this.props.descendantOrgTypes || !this.props.descendantOrgTypes.loading)
    ) {
      this.props.actions.getDescendantOrgTypes(this.props.currentOrg.type).then(() => {
        const { descendantOrgTypes } = this.props;
        if (_.get('data')(descendantOrgTypes) && descendantOrgTypes.data.length > 1) {
          const typeOptions = getTypeOptions(descendantOrgTypes.data);
          const newListFilters = _.cloneDeep(this.state.filtersSections);
          newListFilters[newListFilters.length - 1].options = typeOptions;
          this.setState({
            filtersSections: newListFilters,
          });
        }
      });
      this.props.actions.getData({
        ...this.props.currentOrg,
        ...{
          limit: countArray[0],
        },
      });
    }
  }

  inviteAction = async org => {
    await this.props.actions.inviteOrg({
      orgId: org.orgId,
      adminId: this.props.currentOrg?.adminId,
      status: 'pending',
      inactiveReason: null,
    });
  };
  setNonStikyColumns = newTableColumns => {
    this.setState({ nonStikyColumns: newTableColumns });
  };

  setTableColumns = (linkButtons, inviteButton, getInviteData) => {
    const serverDateTimeValue = moment(this.props.serverDateTime?.data).format('MM.DD.YYYY');
    return [
      {
        id: 'leftColumns',
        columns: [
          {
            Header: 'Org name',
            accessor: 'orgname',
            width: 406,
            length: this.state.nonStikyColumns.length + 2,
            Cell: e => <span className="bold-txt">{e.cell.row.original.orgname}</span>,
          },
        ],
      },
      {
        id: 'middleColumns',
        columns: this.state.nonStikyColumns,
      },
      {
        id: 'rightColumns',
        columns: [
          {
            id: 'button',
            Header: ' ',
            width: 170,
            length: this.state.nonStikyColumns.length + 2,
            accessor: 'button',
            Cell: e => (
              <div className="table__Cell table__Buttons">
                <Buttons
                  item={e.cell.row.original}
                  linkButtons={linkButtons(
                    e.cell.row.original.status,
                    e.cell.row.original.orgpartaddenddate
                  )}
                  inviteButton={inviteButton}
                  getInviteData={getInviteData}
                  hasEndDate
                  serverDateTimeValue={serverDateTimeValue}
                  parentStatus={this.props.parentStatus?.data?.status}
                  isAdministration
                />
              </div>
            ),
            type: 'button',
          },
        ],
      },
    ];
  };

  render() {
    const inviteButton = {
      text: text.features.organization.buttons.invite,
      action: this.inviteAction,
    };
    const serverDateTimeValue = moment(this.props.serverDateTime?.data).format('MM.DD.YYYY');

    return (
      <OrganizationTab
        currentOrg={this.props.currentOrg}
        {...this.props}
        inputList={inputList}
        initialSearch={initialSearch}
        organizations={this.props.organizations}
        filters={this.state.filtersSections}
        currentOrgPartId={this.props.currentOrg?.orgPartId}
        linkButtons={linkButtons}
        inviteButton={inviteButton}
        initialColumns={initialColumns}
        nonStikyColumns={this.state.nonStikyColumns}
        setTableColumns={this.setTableColumns}
        setColumns={this.setNonStikyColumns}
        serverDateTimeValue={serverDateTimeValue}
      />
    );
  }
}

const mapStateToProps = state => {
  const { userInfoReducer, orgManagementReducer } = state;
  return {
    orgHierarchy: userInfoReducer.orgHierarchy,
    organizations: orgManagementReducer.descendantOrganizations,
    descendantOrgTypes: orgManagementReducer.descendantOrgTypes,
    serverDateTime: state.userInfoReducer.serverDateTime,
    parentStatus: orgManagementReducer.inviteStatus,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        getData: orgManagementActions.getDescendantOrgs,
        getDescendantOrgTypes: orgManagementActions.getDescendantOrgTypes,
        inviteOrg: orgManagementActions.inviteOrg,
        getParentStatus: orgManagementActions.getInvitationStatus,
      },
      dispatch
    ),
  };
};

export { AdministrationOrganization };

export default connect(mapStateToProps, mapDispatchToProps)(AdministrationOrganization);

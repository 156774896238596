import Axios from 'axios';
import http from '../core/services/Http';
import Config from '../core/config';

const nowQueryUrl = '/tcm/query';
const nowRegUrl = '/now/reg';
const nowOrgUrl = '/now/org';

export default class RosterServices {
  static bulkUpdateOptions(data) {
    return http.put(`${Config.apiUrl}${nowQueryUrl}/registrations/bulkEdit`, data);
  }

  static getOrgHierarchy({ orgPartId }) {
    return http.get(`${Config.apiUrl}${nowQueryUrl}/rosterBreadcrumb?&orgPartId=${orgPartId}`);
  }

  static getRecords(params) {
    const queryString = ['limit', 'offset', 'orgPartId']
      .concat(
        [
          'accomRosterFlag',
          'deliveryMode',
          'filter',
          'firstNameStartWith',
          'grade',
          'lastNameStartWith',
          'nonCollegeReportableFlag',
          'regType',
          'studentId',
          'timing',
        ].filter(key =>
          ['accomRosterFlag', 'nonCollegeReportableFlag'].includes(key)
            ? params.accommodations?.includes(key)
            : params[key]
        )
      )
      .map(key => {
        switch (key) {
          case 'accomRosterFlag':
          case 'nonCollegeReportableFlag':
            return `${key}=1`;
          case 'filter':
            return `infilter=${params[key]}`;
          default:
            return `${key}=${params[key]}`;
        }
      })
      .join('&');

    return http.get(`${Config.apiUrl}${nowQueryUrl}/orgPartRegList?${queryString}`);
  }

  static getTestingDates({ orgPartId }) {
    return http.get(
      `${Config.apiUrl}${nowQueryUrl}/orgPartAvailableTestingEvents?orgPartId=${orgPartId}`
    );
  }

  static getNcrMaterialKitOptions({ adminId, nonCollegeReportableFlag, regTypeId }) {
    return http.get(
      `${Config.apiUrl}${nowQueryUrl}/orderKits?adminId=${adminId}&nonCollegeReportableFlag=${
        nonCollegeReportableFlag ? 1 : 0
      }&regTypeId=${regTypeId}`
    );
  }

  static getAccommodationOptions({ adminId, deliveryMode }) {
    return http.get(
      `${Config.apiUrl}${nowQueryUrl}/accommodationDataByTypeAndProgram?capacity_type_code=${deliveryMode}&admin_id=${adminId}`
    );
  }

  static getRegAccoms(regId) {
    return http.get(`${Config.apiUrl}${nowQueryUrl}/regAccom?regId=${regId}`);
  }

  static registerStudents(data) {
    return http.put(`${Config.apiUrl}${nowRegUrl}/assign`, data);
  }

  static regWillNotTestStudents(data) {
    return http.put(`${Config.apiUrl}${nowRegUrl}/nottesting`, data);
  }

  static async uploadFile(data, orgId) {
    const initUpload = await http.post(`${Config.apiUrl}${nowOrgUrl}/${orgId}/initializeJob`, data);
    let uploadFile;
    const { jobId, url } = initUpload.data;
    if (initUpload.status === 200 && jobId && url) {
      try {
        uploadFile = await Axios.put(`${url}`, data.file);
        if (uploadFile.status === 200) {
          return http.post(`${Config.apiUrl}${nowOrgUrl}/${orgId}/executeJob/${jobId}`, [
            data.fileName,
          ]);
        }
      } catch {
        return http.put(`${Config.apiUrl}${nowOrgUrl}/${orgId}/job/${jobId}/stopJob`);
      }
    }
  }

  static getUploadedFiles({ adminId, jobTypeCode, limit, orgId, page }) {
    return http.get(
      `${Config.apiUrl}${nowOrgUrl}/${orgId}/jobs/${jobTypeCode}/admin/${adminId}?limit=${limit}&page=${page}`
    );
  }

  static getDownloadUrls(orgPartId, jobId) {
    return http.get(`${Config.apiUrl}${nowOrgUrl}/${orgPartId}/getDownloadUrls/${jobId}`);
  }

  static getStudentsByTestEvent(orgPartId) {
    return http.get(
      `${Config.apiUrl}${nowQueryUrl}/rosterOverviewChartTestingEvents?orgPartId=${orgPartId}`
    );
  }

  static getStudentsByTestFormat(orgPartId) {
    return http.get(
      `${Config.apiUrl}${nowQueryUrl}/rosterOverviewChartFormat?orgPartId=${orgPartId}`
    );
  }

  static getStudentsByGrades(orgPartId) {
    return http.get(
      `${Config.apiUrl}${nowQueryUrl}/rosterOverviewChartGrades?orgPartId=${orgPartId}`
    );
  }

  static getStudentsByTest(orgPartId) {
    return http.get(
      `${Config.apiUrl}${nowQueryUrl}/rosterOverviewChartRegTypes?orgPartId=${orgPartId}`
    );
  }

  static getChildOrgsWithSummary(params) {
    let url = `${Config.apiUrl}${nowQueryUrl}/rosterChildOrgsWithSummary?`;
    if (params.orgPartId) {
      url += `&orgPartId=${params.orgPartId}`;
    }
    if (params.adminId) {
      url += `&adminId=${params.adminId}`;
    }
    if (params.limit) {
      url += `&limit=${params.limit}`;
    }
    if (params.offset) {
      url += `&offset=${params.offset}`;
    }
    if (params.filter) {
      url += `&filter=${params.filter}`;
    }
    if (params.status) {
      url += `&status=${params.status}`;
    }
    return http.get(url);
  }

  static getChildOrgs({ adminId, orgPartId }) {
    return http.get(
      `${Config.apiUrl}${nowQueryUrl}/rosterChildOrgs?orgPartId=${orgPartId}&adminId=${adminId}`
    );
  }

  static getEntityDef({ adminId }) {
    return http.get(`${Config.apiUrl}/now/entitydef/reg/admin/${adminId}`);
  }

  static getRegTypes({ adminId, contractId }) {
    return http.get(`${Config.apiUrl}/now/contract/${contractId}/admin/${adminId}/regtypes`);
  }

  // static getTestEvents(orgPartId) {
  //   return http.get(
  //     `${Config.apiUrl}${nowQueryUrl}/list/orgPartAvailableTestingEvents?orgPartId=${orgPartId}`
  //   )
  // }

  static updateRegistrationOptions(adminId, body) {
    return http.put(`${Config.apiUrl}${nowRegUrl}/${adminId}/registrations`, body);
  }

  static updateStudent(data) {
    return http.put(`${Config.apiUrl}${nowRegUrl}`, data);
  }

  static addStudent(data) {
    return http.post(`${Config.apiUrl}${nowRegUrl}`, data);
  }

  static searchStudent(orgPartId, lastName, externalStudentId, dateOfBirth) {
    return http.get(
      `${Config.apiUrl}${nowQueryUrl}/students?orgPartId=${orgPartId}&lastName=${lastName}&externalStudentId=${externalStudentId}&dateOfBirth=${dateOfBirth}`
    );
  }

  static requestStudentTransfer(data) {
    return http.post(`${Config.apiUrl}/now/regtransfer`, data);
  }

  static unenrollStudent(data) {
    return http.post(`${Config.apiUrl}${nowRegUrl}/unenroll`, data);
  }

  static getSentTransferRequests(data) {
    return http.get(
      `${Config.apiUrl}${nowQueryUrl}/sentTransferRequests?orgPartId=${data.orgPartId}`
    );
  }

  static getReceivedTransferRequests(data) {
    return http.get(
      `${Config.apiUrl}${nowQueryUrl}/receivedTransferRequests?orgPartId=${data.orgPartId}`
    );
  }

  static cancelStudentTransferRequest(data) {
    return http.get(
      `${Config.apiUrl}${nowQueryUrl}/receivedTransferRequests?orgPartId=${data.orgPartId}`
    );
  }

  static updateStudentTransferRequest(data) {
    return http.put(`${Config.apiUrl}/now/regtransfer`, data);
  }

  static getAccommodations({ regId }) {
    return http.get(`${Config.apiUrl}/tcm/query/regAccom?regId=${regId}`);
  }

  static getTimings({ orgPartId }) {
    return http.get(`${Config.apiUrl}${nowQueryUrl}/orgPartTimings?orgPartId=${orgPartId}`);
  }
}

import React from 'react';
import propTypes from 'prop-types';
import Input from '../fields/Input';
import * as Text from '../../../core/i18n/text';
import _ from 'lodash/fp';
import { ReactComponent as MagnifyingGlass } from '../../../assets/svgs/magnifyingGlass.svg';
import './SearchMultiInput.scss';

class SearchMultiInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showClearButton: false,
    };
  }

  handleKeyPress = e => {
    e.key === 'Enter' && this.handleSearch();
  };

  preventLoseInputFocus = e => {
    e.preventDefault();
  };

  handleSearch = () => {
    if (!_.isEmpty(_.omitBy(_.isEmpty)(this.props.searchedValues))) {
      this.props.handleSearch();
    }
    this.setState({
      showClearButton: true,
    });
  };

  handleClear = () => {
    this.setState({ showClearButton: false });
    this.props.resetSearch();
    this.props.clearInput();
  };

  render() {
    const { showClearButton } = this.state;
    const {
      handleChange,
      searchedValues,
      cleanInput,
      className,
      inputList,
      searchLabel,
    } = this.props;
    return (
      <div className={`multi__Search__Container ${className}`} onKeyPress={this.handleKeyPress}>
        {searchLabel && <span className="search__label">{searchLabel}</span>}
        {inputList.map((inputData, idx) => (
          <Input
            key={idx}
            placeholder={inputData.placeholder}
            name={inputData.name}
            type={inputData.type}
            onChange={handleChange}
            value={searchedValues[inputData.name]}
            maxLength={inputData.maxLength}
            disabled={inputData.disabled}
            tooltip={inputData.tooltip}
          />
        ))}
        <button
          aria-label={Text.common.fields.search}
          className="searchIcon__Button"
          onClick={this.handleSearch}
          onMouseDown={this.preventLoseInputFocus}>
          <MagnifyingGlass />
        </button>
        {showClearButton && !cleanInput && (
          <button
            className="linkButton link"
            onClick={this.handleClear}
            onMouseDown={this.preventLoseInputFocus}>
            {Text.clickables.buttons.clearSearch}
          </button>
        )}
      </div>
    );
  }
}

SearchMultiInput.displayName = 'Search';
SearchMultiInput.propTypes = {
  placeholder: propTypes.string,
  focusPlaceholder: propTypes.string,
  searchedValue: propTypes.string,
  maxLength: propTypes.string,
  handleChange: propTypes.func,
  handleSearch: propTypes.func,
  resetSearch: propTypes.func,
  clearInput: propTypes.func,
  cleanInput: propTypes.bool,
  searchLabel: propTypes.string,
};

export default SearchMultiInput;

import Axios from 'axios';
import Config from '../core/config';
import http from '../core/services/Http';

const nowOrgUrl = '/now/org';
const nowQueryUrl = '/tcm/query';

export default class CommonServices {
  static getOrgHierarchy({ orgPartId }) {
    return http.get(`${Config.apiUrl}${nowQueryUrl}/rosterBreadcrumb?&orgPartId=${orgPartId}`);
  }

  static getUserPermissions({ orgPartId }) {
    return http.get(`${Config.apiUrl}${nowQueryUrl}/permisionCode?orgPartId=${orgPartId}`);
  }

  static getAppUrl(code) {
    return http.get(`${Config.apiUrl}/now/applicationUrl/${code}`);
  }

  static getChildOrgs(params) {
    let url = `${Config.apiUrl}${nowQueryUrl}/rosterChildOrgs?`;
    if (params.orgPartId) {
      url += `&orgPartId=${params.orgPartId}`;
    }
    if (params.adminId) {
      url += `&adminId=${params.adminId}`;
    }
    if (params.limit) {
      url += `&limit=${params.limit}`;
    }
    if (params.offset) {
      url += `&offset=${params.offset}`;
    }
    if (params.filter) {
      url += `&filter=${params.filter}`;
    }
    if (params.status) {
      url += `&status=${params.status}`;
    }
    return http.get(url);
  }

  static getFileTemplate(code) {
    return http.get(`${Config.apiUrl}/now/templates?code=${code}`);
  }

  static getServerDateTime() {
    return http.get(`${Config.apiUrl}/tcm/serverdatetime`);
  }

  static getReportsPdf(id) {
    return http.post(`${Config.apiUrl}/now/orgpart/${id}/generatePdf`);
  }

  static getScheduleOfEvents({ adminId, orgId, params: { deadlines = '', format, roles = '' } }) {
    const queryString = [
      `deadLinesOnly=${deadlines.split(',').includes('deadlinesOnly') ? 1 : 0}`,
      `onlineOnly=${format === 'onlineOnly' ? 1 : 0}`,
      `orgId=${orgId}`,
      `paperOnly=${format === 'paperOnly' ? 1 : 0}`,
      `roles=${roles}`,
      `testingDaysOnly=${deadlines.split(',').includes('testingDaysOnly') ? 1 : 0}`,
    ].join('&');
    return http.get(`${Config.apiUrl}/now/admin/${adminId}/schedule?${queryString}`);
  }

  static getScheduleOfEventsRoles() {
    return http.get(`${Config.apiUrl}/now/schedule/roles`);
  }

  static getScheduleOfEventsPdf({ adminId, orgId }) {
    return http.get(`${Config.apiUrl}/now/admin/${adminId}/schedule/pdf?orgId=${orgId}`);
  }

  static getImpendingDeadlines(adminId, orgId) {
    return http.get(
      `${Config.apiUrl}/now/admin/${adminId}/schedule/impendingDeadlines?orgId=${orgId}`
    );
  }

  static disableImpendingDeadlines(adminId, orgId) {
    return http.post(
      `${Config.apiUrl}/now/admin/${adminId}/schedule/impendingDeadlines?orgId=${orgId}`
    );
  }

  static getDownloadUrls = ({ jobId, orgId }) =>
    http.get(`${Config.apiUrl}${nowOrgUrl}/${orgId}/getDownloadUrls/${jobId}`);

  static uploadFile = orgId => async data => {
    const initUpload = await http.post(`${Config.apiUrl}${nowOrgUrl}/${orgId}/initializeJob`, data);
    const { jobId, url } = initUpload.data;
    if (initUpload.status !== 200 || !jobId || !url) return;

    try {
      const uploadFile = await Axios.put(`${url}`, data.file);
      if (uploadFile.status !== 200) return;

      return http.post(`${Config.apiUrl}${nowOrgUrl}/${orgId}/executeJob/${jobId}`, [
        data.fileName,
      ]);
    } catch {
      return http.put(`${Config.apiUrl}${nowOrgUrl}/${orgId}/job/${jobId}/stopJob`);
    }
  };
}

import * as types from './types';
import { states } from '../../../services/helpers/stateStatus';

const initialState = {
  register: states.init(),
  verify: states.init(),
  tnc: states.init(),
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.REGISTER:
      return {
        ...state,
        register: action.payload,
      };
    case types.VERIFY:
      return {
        ...state,
        verify: action.payload,
      };
    case types.GET_TNC:
      return {
        ...state,
        tnc: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;

import * as types from './types';

const initialState = {
  uploadedFiles: null,
  downloadUrls: null,
  reloadData: false,
  loadingData: false,
  parentOrgs: {},
  childOrgTypes: {},
  updatedOrg: {},
  orgEntityDef: [],
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.UPLOAD_FILE:
      return {
        ...state,
        fileUploadStatus: action.payload,
      };
    case types.GET_FILES:
      return {
        ...state,
        uploadedFiles: action.payload,
      };
    case types.GET_DOWNLOAD_URLS:
      return {
        ...state,
        downloadUrls: action.payload,
      };
    case types.GET_ORGANIZATIONS:
      return {
        ...state,
        organizations: action.payload,
        loadingOrg: action.payload.loading,
      };
    case types.GET_PARENT_ORGS:
      return {
        ...state,
        parentOrgs: action.payload,
      };
    case types.GET_CHILD_ORG_TYPES:
      return {
        ...state,
        childOrgTypes: action.payload,
      };
    case types.UPDATE_ORG:
      return {
        ...state,
        updatedOrg: action.payload,
      };
    case types.CREATE_ORG:
      return {
        ...state,
        updatedOrg: action.payload,
      };
    case types.ORG_ENTITY_DEF:
      return {
        ...state,
        orgEntityDef: action.payload.data || [],
      };
    default:
      return state;
  }
};

export default reducer;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { RosterServices } from '../../../services';

import { getProgramConfiguration } from '../../../core/utils';

import { FilesBaseComponent } from '../../../components/index';

import { usePermission, useStore } from '../../../store/hooks';

import './FileReports.scss';
import '../../../components/tabs/Tabs.scss';

const FileReports = () => {
  /**
   * useStore()
   */
  const [orgHierarchyResponse] = useStore('orgHierarchy');
  const [programsResponse, programsStore] = useStore('programs');

  /**
   * usePermission()
   */
  const hasAccommFilePermissions = usePermission('now_reg_accom_files');
  const hasRegFilePermissions = usePermission('now_reg_files');

  /**
   * useState()
   */
  const [activeTab, setActiveTab] = useState('Registration');

  /**
   * useStore()
   */
  const handleTabClick = useCallback(e => {
    setActiveTab(e.target.innerText);
  }, []);

  // TODO: Ideally, handle via Context
  const program = useMemo(() => {
    if (!orgHierarchyResponse?.data?.length || !programsResponse?.data?.length) return;
    return programsResponse.data.find(
      program => program.programId === orgHierarchyResponse.data[0].programId
    );
  }, [orgHierarchyResponse, programsResponse]);

  const tabs = useMemo(
    () =>
      [
        {
          featureName: 'roster',
          title: 'Registration',
        },
        { featureName: 'accommodations', title: 'Accommodations' },
      ].filter(({ featureName }) => {
        switch (featureName) {
          case 'accommodations':
            return (
              getProgramConfiguration(`accommodations.allowFileUpload`)(program) &&
              hasAccommFilePermissions
            );
          case 'roster':
            return (
              getProgramConfiguration(`roster.allowFileUpload`)(program) && hasRegFilePermissions
            );
          default:
            return false;
        }
      }),
    [hasAccommFilePermissions, hasRegFilePermissions, program]
  );

  const featureName = useMemo(() => {
    if (!program?.parentCode || !activeTab || !tabs?.length) return;
    return tabs.find(tab => tab.title === activeTab)?.featureName;
  }, [activeTab, program, tabs]);

  useEffect(() => {
    if (programsResponse?.loading || programsResponse?.data?.length) return;
    programsStore.fetch();
  }, [programsResponse, programsStore]);

  useEffect(() => {
    if (!tabs?.length || tabs.some(({ title }) => activeTab === title)) return;
    setActiveTab(tabs[0].title);
  }, [activeTab, tabs]);

  if (!tabs?.length) return null;

  return (
    <>
      <Helmet>
        <title>Registration Files - ACT-Now</title>
      </Helmet>
      <div role="tablist" className="tabs__Container">
        {tabs.map(({ title }) => (
          <div
            role="tab"
            key={title}
            className={`tabs__Single tab__Border ${activeTab !== title && 'tab__NotActive'}`}>
            <Link
              className={`tabs__Single text ${activeTab === title ? 'active' : ''}`}
              onClick={handleTabClick}
              to={{ pathname: `` }}>
              {title}
            </Link>
            {activeTab === title && <div className="noBorder"></div>}
          </div>
        ))}
      </div>
      {(hasAccommFilePermissions || hasRegFilePermissions) && (
        <FilesBaseComponent
          featureName={featureName}
          getUploadedFiles={RosterServices.getUploadedFiles}
        />
      )}
    </>
  );
};

export default FileReports;

import React, { Component } from 'react';
import propTypes from 'prop-types';

import { UserServices } from '../../../services';

import { messages, clickables } from '../../../core/i18n/text';
import ErrorIcon from '../../../assets/svgs/redX.svg';

import FormComplete from '../../../components/forms/FormComplete';
import { ExpiredLink } from '..';

const Text = { messages, clickables };

class EmailVerification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      verified: false,
      error: false,
      invalid: false,
      expired: false,
    };
    this.checkVerification = this.checkVerification.bind(this);
    this.renderOnVerified = this.renderOnVerified.bind(this);
    this.checkVerification();
  }

  checkVerification() {
    const data = { ...this.props };
    UserServices.verifyUser(data)
      .then(() => {
        this.setState({ verified: true });
      })
      .catch(err => {
        const msg = err.response.data.Error;
        if (msg.includes('already verified')) {
          this.setState({
            verified: true,
            error: true,
          });
        } else if (msg.includes('does not exist')) {
          this.setState({
            error: true,
            invalid: true,
          });
        } else {
          this.setState({
            error: true,
            expired: true,
          });
        }
      });
  }

  renderOnVerified() {
    const { verified, error, invalid } = this.state;
    let msg = '';
    let title = '';

    if (invalid) {
      msg = Text.messages.error.emailVerification;
      return (
        <FormComplete
          icon={ErrorIcon}
          message={msg}
          className="bigButton"
          buttonText={Text.clickables.buttons.continueToLogin}
        />
      );
    }

    if (verified) {
      if (error) {
        title = Text.messages.error.emailAlreadyVerified.title;
        msg = Text.messages.error.emailAlreadyVerified.body;
      } else {
        title = Text.messages.success.emailVerificationComplete.title;
        msg = Text.messages.success.emailVerificationComplete.body;
      }
      return (
        <FormComplete
          title={title}
          message={msg}
          className="bigButton"
          buttonText={Text.clickables.buttons.continueToLogin}
        />
      );
    }
    return null;
  }

  render() {
    const { verified, error, expired } = this.state;
    const { username } = this.props;
    if (!verified && !error) {
      return null;
    }
    return expired ? <ExpiredLink email={username} /> : this.renderOnVerified();
  }
}

EmailVerification.defaultProps = {
  verificationCode: '',
  username: '',
};

EmailVerification.propTypes = {
  verificationCode: propTypes.string,
  username: propTypes.string,
};

export default EmailVerification;

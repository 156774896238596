import { UserServices } from '../../../services';

import * as types from './types';

export function registerAction(payload) {
  return {
    type: types.REGISTER,
    payload,
  };
}

export function verifyAction(payload) {
  return {
    type: types.VERIFY,
    payload,
  };
}

export function getTncAction(payload) {
  return {
    type: types.GET_TNC,
    payload,
  };
}

const resetState = () => {
  return dispatch => {
    dispatch(registerAction({ loading: false, data: null, error: null }));
    dispatch(verifyAction({ loading: false, data: null, error: null }));
  };
};

export function register(email, password, tncVersion, recaptcha) {
  return dispatch => {
    resetState();
    dispatch(registerAction({ loading: true, data: null, error: null }));

    UserServices.verifyUserExists({ email })
      .then(success => {
        if (success.data.result) {
          dispatch(verifyAction({ loading: false, data: null, error: 'Username already exists.' }));
          dispatch(registerAction({ loading: false, data: null, error: null }));
        } else {
          dispatch(verifyAction({ loading: false, data: false, error: null }));
          UserServices.registerUser({ email, password, tncVersion, recaptcha })
            .then(success => {
              if (success.data.User.Username === email.toUpperCase()) {
                dispatch(registerAction({ loading: false, data: true, error: null }));
              }
            })
            .catch(err => {
              dispatch(
                registerAction({ loading: false, data: null, error: err.response.data.Error })
              );
            });
        }
      })
      .catch(err => {
        dispatch(registerAction({ loading: false, data: null, error: err.response.data.Error }));
      });
  };
}

export function getTnc() {
  return dispatch => {
    dispatch(getTncAction({ loading: true, data: null, error: null }));
    UserServices.getTnc()
      .then(success => {
        dispatch(getTncAction({ loading: false, data: success.data, error: null }));
      })
      .catch(err => {
        const tncError = {
          UUID: null,
          Terms:
            '<p class="text-center">ACT ACCOUNT TERMS AND CONDITIONS OF USE</p>\r\n<p class="text-center" style="color: red">WE APOLOGIZE THAT THE TERMS COULD NOT BE LOADED AT THIS TIME. PLEASE TRY REFRESHING THE PAGE.</p>\r\n',
        };
        dispatch(getTncAction({ loading: false, data: tncError, error: null }));
      });
  };
}

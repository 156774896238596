import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions as rosterActions } from '../duck';
import OrganizationTab from '../../../components/organization-tab/OrganizationTab';
import Buttons from '../../../components/data-panel/buttons/Buttons';
import * as text from '../../../core/i18n/text';

const statuses = {
  active: 'Confirmed participation',
  inactive: 'Declined participation',
  pending: 'Not confirmed',
};

const initialSearch = {
  filter: '',
};

const inputList = [
  {
    placeholder: 'Org name or code',
    maxLength: '30',
    type: 'string',
    name: 'filter',
  },
];

const initialColumns = [
  {
    Header: 'Local org code',
    name: 'Local org code',
    accessor: 'orgcode',
    fields: ['orgcode'],
    type: 'string',
    width: 130,
  },
  {
    Header: 'Status',
    name: 'Status',
    accessor: 'orgPartStatus',
    fields: ['orgPartStatus'],
    Cell: e =>
      statuses[e.cell.row.original.orgPartStatus] || e.cell.row.original.orgPartStatus || '-',
    width: 190,
  },
  {
    Header: 'Students',
    name: 'Students',
    accessor: 'regTotal',
    fields: ['regTotal'],
    type: 'number',
    width: 87,
  },
];

const filterSections = [
  {
    name: 'participation',
    title: text.features.organization.filters.participation.title,
    type: 'checkbox',
    options: [
      {
        value: 'active',
        label: text.features.organization.filters.participation.confirmedParticipating,
      },
      {
        value: 'inactive',
        label: text.features.organization.filters.participation.declinedParticipations,
      },
      {
        value: 'pending',
        label: text.features.organization.filters.participation.notConfirmed,
      },
    ],
    callParameter: 'status',
  },
];

class StudentsOrganizationTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nonStikyColumns: initialColumns,
    };
  }
  setNonStikyColumns = newTableColumns => {
    this.setState({ nonStikyColumns: newTableColumns });
  };
  setTableColumns = () => {
    return [
      {
        id: 'leftColumns',
        columns: [
          {
            Header: 'Org name',
            name: 'Org name',
            accessor: 'orgname',
            fields: ['orgname'],
            length: 2 + this.state.nonStikyColumns.length,
            width: 406,
            type: 'actionCell',
            Cell: ({ cell }) => {
              return <div>{cell.value}</div>;
            },
          },
        ],
      },

      {
        id: 'middleColumns',
        columns: this.state.nonStikyColumns,
      },
      {
        id: 'rightColumns',
        columns: [
          {
            Header: '',
            name: 'Student count',
            accessor: 'orgPartStatus2',
            fields: ['orgPartStatus2'],
            width: 200,
            length: 2 + this.state.nonStikyColumns.length,
            type: 'button',
            Cell: ({ cell }) => {
              return <Buttons item={cell.row.original} />;
            },
          },
        ],
      },
    ];
  };

  render() {
    return (
      <OrganizationTab
        {...this.props}
        inputList={inputList}
        initialSearch={initialSearch}
        currentOrgPartId={this.props.currentOrgPartId}
        organizations={this.props.organizations}
        filters={filterSections}
        initialColumns={initialColumns}
        nonStikyColumns={this.state.nonStikyColumns}
        setTableColumns={this.setTableColumns}
        setColumns={this.setNonStikyColumns}
        filterFirst={true}
        usingOrgPartId={true}
        stickyColumnsLength={1}
        emptyStateMessage={text.messages.info.registrationEmptyState}
        title="Students Organization - ACT-Now"
        filtersOnlyOnDistrict={false}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    organizations: state.rosterReducer.childOrganizationsSummary,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        getData: rosterActions.getChildOrgsWithSummary,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentsOrganizationTab);

import React from 'react';
import propTypes from 'prop-types';
import './Select.scss';

const SimpleSelect = ({ options, onChange, description }) => {
  return (
    <div className="roster__RightContainer">
      <div className="roster__CountSelector">
        <span hidden={true}>{description}</span>
        <select onChange={onChange}>
          {options.map((count, index) => {
            return (
              <option key={index} value={count}>
                {count}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

SimpleSelect.propTypes = {
  options: propTypes.arrayOf(propTypes.number),
  onChange: propTypes.func,
  description: propTypes.string,
};

SimpleSelect.defaultProps = {
  onChange: () => {},
};

export default SimpleSelect;

import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

import { ReactComponent as ACTLogo } from './../../assets/svgs/nowlogo.svg';
import { ReactComponent as DropDownArrow } from '../../assets/svgs/dropdownArrow.svg';
import { ReactComponent as NotificationBellIcon } from '../../assets/svgs/notificationBell.svg';
import { ReactComponent as NotificationBellIconWithBadge } from '../../assets/svgs/notificationBellIconWithBadge.svg';
import { ReactComponent as ProfileIcon } from './../../assets/svgs/profile.svg';
import { ReactComponent as SwitchToTcm } from './../../assets/svgs/switchToTcm.svg';

import { actions as coreActions } from '../../core/duck';
import { actions as notifActions } from '../../features/notifications/duck';

import MainSelector from './MainSelector';
import ProfileBox from './ProfileBox';
import { getPermission } from '../../core/duck/selectors';
import { NotificationBox } from '../../features/notifications';
import { UserServices } from '../../services';

import { CPCADispatchContext } from '../../core/providers/CPCAContext';
import TextContext from '../../core/providers/TextProvider';

import './Header.scss';
import { DARK_GREY, BACKGROUND_OFF_WHITE, BORDER_GRAY } from '../../styles/base/_settings.scss';
import { useStore } from '../../store/hooks';

let closeNotificationTooltipTimer;
let hasOneOption = {
  program: false,
  admin: false,
  org: false,
};

const defaultMainSelector = {
  program: {
    error: false,
    options: [],
    value: {
      label: '',
      value: '',
    },
  },
  admin: {
    error: false,
    options: [],
    value: {
      label: '',
      value: '',
    },
  },
  org: {
    error: false,
    options: [],
    value: {
      label: '',
      value: '',
    },
  },
};

const LightTooltip = withStyles(() => ({
  popper: {
    '&.MuiTooltip-popper': {
      zIndex: '109',
    },
  },
  tooltip: {
    alignItems: 'center',
    backgroundColor: BACKGROUND_OFF_WHITE,
    boxShadow: `0 0 0.5rem ${BORDER_GRAY}`,
    border: `0.1rem solid ${BORDER_GRAY}`,
    borderRadius: '0.8rem',
    color: DARK_GREY,
    display: 'flex',
    fontSize: '1.4rem',
    fontWeight: 'bold',
    height: '4rem',
    justifyContent: 'center',
    lineHeight: '2.4rem',
    padding: '0 1.6rem',
    position: 'relative',
    zIndex: '9999',
  },
  arrow: {
    color: BACKGROUND_OFF_WHITE,
    '&:before': {
      border: `0.1rem solid ${BORDER_GRAY}`,
    },
  },
}))(Tooltip);

export default function Header(props) {
  /**
   *  react-router-dom hooks
   */
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const params = useParams();

  /**
   * useContext()
   */
  const dispatchCPCA = useContext(CPCADispatchContext);
  const Text = useContext(TextContext);

  /**
   *  useDispatch()
   */
  const dispatch = useDispatch();

  /**
   *  useRef()
   */
  const notifRef = useRef(null);
  const profileRef = useRef(null);

  /**
   *  useStore()
   */
  const [, contractInfoStore] = useStore('contractInfo');
  const [orgHierarchyResponse, orgHierarchyStore] = useStore('orgHierarchy');
  const [, programAdminStaticInfoStore] = useStore('programAdminStaticInfo');
  const [, userPermissionsStore] = useStore('userPermissions');

  /**
   *  useState()
   */
  const [mainSelector, setMainSelector] = useState(defaultMainSelector);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [orgPartId, setOrgPartId] = useState('');
  const [scrollHeight, setScrollHeight] = useState(0);
  const [selectorClosedState, setSelectorClosedState] = useState('');
  const [showMainSelector, setShowMainSelector] = useState(false);
  const [showNotificationBox, setShowNotificationBox] = useState(false);
  const [showNotificationTooltip, setShowNotificationTooltip] = useState(false);
  const [showProfileBox, setShowProfileBox] = useState(false);

  /**
   *  useSelector()
   */
  const adminsResponse = useSelector(state => state.userInfoReducer.admins);
  const appUrlResponse = useSelector(state => state.userInfoReducer.appUrl);
  const hasTCMLaunchPermission = useSelector(state => getPermission(state, 'now_tcm_launch'));
  const notViewedNotificationsCountResponse = useSelector(
    state => state.notificationReducer.notViewedNotificationsCount
  );
  const orgsResponse = useSelector(state => state.userInfoReducer.orgs);
  const programsResponse = useSelector(state => state.userInfoReducer.programs);
  const userInfoResponse = useSelector(state => state.userInfoReducer.userInfo);

  /**
   *  useCallback()
   */
  const goToTcm = useCallback(() => {
    if (!appUrlResponse?.data) return;
    window.location.href = `${appUrlResponse.data}/dashboard`;
  }, [appUrlResponse]);

  const handleKeyPress = useCallback(e => {
    if (e.key === 'Enter') {
      setShowMainSelector(true);
    }
  }, []);

  const handleSave = useCallback(() => {
    const errors = {
      program: !mainSelector.program.value.value,
      admin: !mainSelector.admin.value.value,
      org: !mainSelector.org.value.value,
    };

    setMainSelector(m => ({
      ...m,
      admin: { ...m.admin, error: errors.admin },
      org: { ...m.org, error: errors.org },
      orgPartId: errors.org ? params.orgPartId : m.org.value.value,
      program: { ...m.program, error: errors.program },
    }));

    if (errors.program || errors.admin || errors.org) return;

    setShowMainSelector(false);

    history.push(`/landingPage/orgPartId/${parseInt(mainSelector.org.value.value)}`, {
      showSuccessUpdateNotification: true,
      notificationText: 'Selection successfully saved',
    });
  }, [history, mainSelector, params]);

  const handleScroll = useCallback(() => {
    if (document.documentElement.scrollTop > 2) {
      setScrollHeight(document.documentElement.scrollHeight);
    } else {
      setScrollHeight(0);
    }
  }, []);

  const handleSwitchKeyDown = useCallback(
    e => {
      if (e.keyCode === 13) {
        goToTcm();
      }
    },
    [goToTcm]
  );

  // Set the mainSelector data based on the response from the API
  const populateCPCA = useCallback(
    key => response => {
      const propMap = {
        admin: ['adminId', 'adminName'],
        org: ['orgPartId', 'orgName'],
        program: ['programId', 'programName'],
      };
      const [idProp, nameProp] = propMap[key];
      const options = response?.data?.map(option => ({
        value: option[idProp],
        label: option[nameProp],
      }));
      if (!options?.length) return;

      hasOneOption[key] = options.length === 1;
      setMainSelector(m => {
        if (hasOneOption[key]) {
          return { ...m, [key]: { ...m[key], options, value: options[0] } };
        }
        const value = options?.find(x => x.value === m[key].value.value);
        if (value) {
          return { ...m, [key]: { ...m[key], options, value } };
        }
        const keys = ['program', 'admin', 'org'];
        const i = keys.indexOf(key);
        return keys.slice(i + 1).reduce(
          (acc, k) => ({
            ...acc,
            [k]: { ...defaultMainSelector[k] },
          }),
          { ...m, [key]: { ...m[key], options, value: { ...defaultMainSelector[key].value } } }
        );
      });
    },
    []
  );

  const resetMainSelector = useCallback(key => {
    setMainSelector(m => ({
      ...m,
      [key]: { ...defaultMainSelector[key] },
    }));
  }, []);

  const toggleNotificationBox = useCallback(() => {
    setShowNotificationBox(prev => !prev);
  }, []);

  const toggleProfileBox = useCallback(() => {
    setShowProfileBox(prev => !prev);
  }, []);

  const updateBadge = useCallback(() => {
    if (!userInfoResponse?.data) return;

    dispatch(notifActions.getNotViewedNotificationsCount(userInfoResponse.data.id));
  }, [dispatch, userInfoResponse]);

  const handleCPCAChange = useCallback(
    key => value => {
      setMainSelector(m => {
        if (value) {
          return { ...m, [key]: { ...m[key], value, error: false } };
        }
        if (hasOneOption[key]) {
          return { ...m, [key]: { ...m[key], value: m[key].options[0], error: false } };
        }
        const v = m[key].options?.find(x => x.value === m[key].value.value) ||
          m[key].value.value || {
            ...defaultMainSelector[key].value,
          };

        if (!v.value) {
          if (['program'].includes(key)) {
            resetMainSelector('admin');
          }
          if (['program', 'admin'].includes(key)) {
            resetMainSelector('org');
          }
        }
        return { ...m, [key]: { ...m[key], value: v, error: true } };
      });
    },
    [resetMainSelector]
  );

  const handleDocumentClick = useCallback(
    e => {
      if (notifRef.current && !notifRef.current.contains(e.target) && showNotificationBox) {
        toggleNotificationBox();
      }
      if (profileRef.current && !profileRef.current.contains(e.target) && showProfileBox) {
        toggleProfileBox();
      }
    },
    [
      notifRef,
      profileRef,
      showNotificationBox,
      showProfileBox,
      toggleNotificationBox,
      toggleProfileBox,
    ]
  );

  const handleNotificationsKeyDown = useCallback(
    e => {
      if (e.keyCode === 13) {
        toggleNotificationBox();
      }
    },
    [toggleNotificationBox]
  );

  const handleProfileKeyDown = useCallback(
    e => {
      if (e.keyCode === 13) {
        toggleProfileBox();
      }
    },
    [toggleProfileBox]
  );

  /**
   * useEffects
   */

  // Load necessary information for display/permissions
  useEffect(() => {
    dispatch(coreActions.getAppUrl('tcm'));
    dispatch(coreActions.getUserInfo());
    dispatch(coreActions.getUserPrograms());
  }, [dispatch]);

  // Attempt to determine the mainSelector data
  useEffect(() => {
    // If the orgPartId is in the URL, get the org hierarchy data
    if (params.orgPartId) {
      orgHierarchyStore.fetch({ orgPartId: params.orgPartId });
      setShowMainSelector(false);
      return;
    }

    // If the mainSelector data is in sessionStorage, set the mainSelector state and close the selector
    const sessionSelection = JSON.parse(window.sessionStorage.getItem('mainSelector'));
    if (sessionSelection) {
      history.push(`/landingPage/orgPartId/${parseInt(sessionSelection.orgPartId)}`, {
        showSuccessUpdateNotification: false,
      });
      setShowMainSelector(false);
      return;
    }

    setShowMainSelector(true);
  }, [history, orgHierarchyStore, params.orgPartId]);

  // When orgHierarchyResponse is set from dispatch, populate the mainSelector and fetch needed options data
  useEffect(() => {
    if (
      !orgHierarchyResponse?.data?.length ||
      !programsResponse?.data?.length ||
      !userInfoResponse?.data?.id
    )
      return;

    const childOrg = orgHierarchyResponse.data[orgHierarchyResponse.data.length - 1];
    const rootOrg = orgHierarchyResponse.data[0];

    const mainSelectorData = {
      program: {
        error: false,
        options:
          programsResponse.data.map(option => ({
            label: option.programName,
            value: option.programId,
          })) || [],
        value: {
          label: rootOrg.programName,
          value: rootOrg.programId,
        },
      },
      admin: {
        error: false,
        options: [],
        value: {
          label: rootOrg.adminName,
          value: rootOrg.adminId,
        },
      },
      org: {
        error: false,
        options: [],
        value: {
          label: rootOrg.orgName,
          value: rootOrg.orgPartId,
        },
      },
      orgPartId: childOrg.orgPartId,
    };

    setMainSelector(mainSelectorData);
    setOrgPartId(rootOrg.orgPartId);
    setSelectorClosedState(`${rootOrg.programName} - ${rootOrg.adminName} - ${rootOrg.orgName}`);
    setShowMainSelector(false);

    dispatchCPCA({
      type: 'setProgram',
      payload: programsResponse.data.find(p => p.programId === rootOrg.programId),
    });

    UserServices.userOrgPartId(userInfoResponse.data.id, rootOrg.orgPartId);

    const contractId = rootOrg.contractId;

    contractInfoStore.fetch({ contractId });
    programAdminStaticInfoStore.fetch({ contractId });
    userPermissionsStore.fetch({ orgPartId: rootOrg.orgPartId });
  }, [
    contractInfoStore,
    dispatch,
    dispatchCPCA,
    orgHierarchyResponse,
    programAdminStaticInfoStore,
    programsResponse,
    userInfoResponse,
    userPermissionsStore,
  ]);

  // When the program is set in the mainSelector data, fetch the admin options
  useEffect(() => {
    if (!mainSelector.program.value?.value) return;

    dispatch(coreActions.getUserAdmins(mainSelector.program.value.value));
  }, [dispatch, mainSelector.program.value]);

  // When the admin is set in the mainSelector data, fetch the org options
  useEffect(() => {
    if (!mainSelector.admin.value?.value) return;

    dispatch(coreActions.getUserOrgs(mainSelector.admin.value.value));
  }, [dispatch, mainSelector.admin.value]);

  // When the mainSelector data is set and the selector is closed, set the selectorClosedState and persist the data in sessionStorage
  useEffect(() => {
    if (
      ['admin', 'org', 'program'].some(
        key => !mainSelector[key].value.value || mainSelector[key].error
      ) ||
      !mainSelector.orgPartId
    )
      return;

    window.sessionStorage.setItem('mainSelector', JSON.stringify(mainSelector));
  }, [mainSelector]);

  useEffect(() => {
    if (props.logoOnly || !userInfoResponse?.data?.selectedOrgPartId || match.url !== '/') return;

    history.replace(`/landingPage/orgPartId/${userInfoResponse.data.selectedOrgPartId}`);
  }, [dispatch, history, match, props.logoOnly, userInfoResponse]);

  useEffect(() => {
    if (
      notViewedNotificationsCountResponse?.loading ||
      !(notViewedNotificationsCountResponse?.data?.count >= 0)
    )
      return;

    setNotificationsCount(notViewedNotificationsCountResponse.data.count);
    setShowNotificationTooltip(
      notViewedNotificationsCountResponse.data.count > 0 &&
        location.state &&
        location.state.fromLogin
    );
  }, [notViewedNotificationsCountResponse, location]);

  useEffect(() => {
    populateCPCA('program')(programsResponse);
  }, [populateCPCA, programsResponse]);

  useEffect(() => {
    populateCPCA('admin')(adminsResponse);
  }, [adminsResponse, populateCPCA]);

  useEffect(() => {
    populateCPCA('org')(orgsResponse);
  }, [orgsResponse, populateCPCA]);

  useEffect(() => {
    if (!showNotificationTooltip) return;

    closeNotificationTooltipTimer = setTimeout(() => {
      setShowNotificationTooltip(false);
      clearTimeout(closeNotificationTooltipTimer);
      if (location.state && location.state.fromLogin) {
        history.push({
          state: {
            fromLogin: false,
          },
        });
      }
    }, 3000);
  }, [history, location, showNotificationTooltip]);

  useEffect(() => {
    if (props.logoOnly || !userInfoResponse || userInfoResponse.loading) return;
    if (
      userInfoResponse.error ||
      (userInfoResponse.data &&
        (!userInfoResponse.data.firstName ||
          !userInfoResponse.data.lastName ||
          !userInfoResponse.data.workPhone))
    ) {
      history.push('/personalInformation', {
        edit: false,
        from: window.location.pathname,
      });
    } else if (userInfoResponse.data?.id && !notViewedNotificationsCountResponse) {
      dispatch(notifActions.getNotViewedNotificationsCount(userInfoResponse.data.id));
    }
  }, [props.logoOnly, dispatch, history, notViewedNotificationsCountResponse, userInfoResponse]);

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick, false);
    window.addEventListener('scroll', handleScroll, false);
    return () => {
      window.removeEventListener('scroll', handleScroll, false);
      document.removeEventListener('click', handleDocumentClick, false);
    };
  }, [handleDocumentClick, handleScroll]);

  /**
   * Render
   */

  return props.logoOnly ? (
    <div className="header__Container">
      <div className="logo__container">
        <ACTLogo alt="Act Logo" />
        {hasTCMLaunchPermission && (
          <div className="switch__container">
            <div className="separator"></div>
            <LightTooltip title="Switch to ACT TCM" arrow>
              <div onClick={goToTcm} className="switch__logo">
                <SwitchToTcm />
              </div>
            </LightTooltip>
          </div>
        )}
      </div>
    </div>
  ) : (
    <div className={`${scrollHeight ? 'stiky' : ''} header__Container`}>
      <div className="logo__container">
        <Link to={`/landingPage/orgPartId/${orgPartId}`}>
          <ACTLogo alt="Act Logo" />
        </Link>
        {hasTCMLaunchPermission && (
          <div className="switch__container">
            <div className="separator"></div>
            <LightTooltip title="Switch to ACT TCM" arrow>
              <div
                onClick={goToTcm}
                onKeyDown={handleSwitchKeyDown}
                className="switch__logo"
                tabIndex="0">
                <SwitchToTcm />
              </div>
            </LightTooltip>
          </div>
        )}
      </div>
      {programsResponse?.data?.length > 0 && (
        <>
          <div
            className="header__Main"
            tabIndex="0"
            onClick={() => {
              setShowMainSelector(true);
            }}
            onKeyPress={handleKeyPress}>
            {selectorClosedState}
            <DropDownArrow className="header__arrow" />
          </div>
          <MainSelector
            show={showMainSelector}
            showCloseIcon={!!params.orgPartId}
            close={() => setShowMainSelector(false)}
            values={mainSelector}
            handleChange={{
              programChange: handleCPCAChange('program'),
              adminChange: handleCPCAChange('admin'),
              orgChange: handleCPCAChange('org'),
            }}
            handleSave={handleSave}
            showWarning={
              !(
                window.location.pathname === '/' || window.location.pathname.includes('landingPage')
              )
            }
          />
        </>
      )}
      <div className="icon__Container">
        <div className="profile__Container" ref={notifRef}>
          <LightTooltip title={Text.features.notifications.title} arrow>
            <div
              className="bell__Container"
              onClick={toggleNotificationBox}
              tabIndex="0"
              onKeyDown={handleNotificationsKeyDown}>
              {notificationsCount > 0 ? (
                <>
                  <NotificationBellIconWithBadge
                    id="notificationIconId"
                    className={showNotificationBox ? '' : 'header__Icon notification__Icon'}
                  />
                  <span
                    className={`${notificationsCount > 9 ? 'wide-badge' : ''} badge`}
                    onClick={toggleNotificationBox}>
                    {notificationsCount > 99 ? '99+' : notificationsCount}
                  </span>
                </>
              ) : (
                <NotificationBellIcon
                  id="notificationIconId"
                  className={showNotificationBox ? '' : 'header__Icon notification__Icon'}
                />
              )}
            </div>
          </LightTooltip>

          {/* <div className="arrowBox header__tooltip">
            <div className="arrow moved-arrow"></div>
            {Text.features.notifications.title}
          </div> */}
          {showNotificationTooltip && (
            <div className="arrowBox notification__tooltip">
              <div className="arrow moved-arrow"></div>
              {`You have ${notificationsCount} unread notifications`}
            </div>
          )}
          <NotificationBox onClose={updateBadge} show={showNotificationBox} />
        </div>
        <div className="profile__Container profile__icon" ref={profileRef}>
          <LightTooltip title={Text.features.header.profile} arrow>
            <ProfileIcon
              id="profileIconId"
              onClick={toggleProfileBox}
              className={showProfileBox ? '' : 'header__Icon'}
              tabIndex="0"
              onKeyDown={handleProfileKeyDown}
            />
          </LightTooltip>
          {/* <div className="arrowBox header__tooltip">
            <div className="arrow moved-arrow"></div>
            {Text.features.header.profile}
          </div> */}
          {showProfileBox && (
            <div className="arrowBox adjusted">
              <div className="arrow moved-arrow"></div>
              <ProfileBox />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

Header.displayName = 'Header';
Header.defaultProps = {
  logoOnly: false,
};

import React from 'react';
import propTypes from 'prop-types';

import Success from '../../assets/svgs/greenCheckCircle.svg';

const FormComplete = props => {
  const { title, message, link, className, buttonText, icon } = props;

  return (
    <div className="formComplete__Container">
      <div className="formComplete__Icon">
        <img src={icon} alt="icon" />
      </div>
      <div className="formComplete__Title">{title}</div>
      <div className="formComplete__Text">{message}</div>
      {buttonText && (
        <button
          type="button"
          className={className}
          onClick={() => {
            window.location.href = link;
          }}>
          {buttonText}
        </button>
      )}
    </div>
  );
};

FormComplete.displayName = 'FormComplete';
FormComplete.defaultProps = {
  icon: Success,
  title: null,
  message: null,
  link: '/login',
};
FormComplete.propTypes = {
  message: propTypes.string,
  link: propTypes.string,
  className: propTypes.string.isRequired,
  buttonText: propTypes.string.isRequired,
  icon: propTypes.any,
};

export default FormComplete;

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import propTypes from 'prop-types';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { RosterServices } from '../../../services/';

import { actions as coreActions } from '../../../core/duck/';

import { useStore } from '../../../store/hooks';

import RadioControl from '../../../components/inputs/Radio';
import TextArea from '../../../components/forms/TextArea';

import TextContext from '../../../core/providers/TextProvider';

const RegisterStudentsModal = ({ closeModal, selection, setNotificationProps }) => {
  /**
   * useContext()
   */
  const Text = useContext(TextContext);

  /**
   * useDispatch()
   */
  const dispatch = useDispatch();

  /**
   * useSelector()
   */
  const serverDateTime = useSelector(state => state.userInfoReducer.serverDateTime);

  /**
   * useStore()
   */
  const [testingDatesResponse] = useStore('testingDates');

  /**
   * useState()
   */
  const [formData, setFormData] = useState({ errors: {} });

  /**
   * useMemo()
   */
  const disableUnregister = useMemo(
    () => Object.values(selection).some(student => student?.testingEventId !== 'Not assigned'),
    [selection]
  );

  const disableOptionId = useMemo(() => (!disableUnregister ? '000' : '0'), [disableUnregister]);

  const regIDs = useMemo(
    () =>
      Object.values(selection)
        .filter(Boolean)
        .map(({ regId }) => regId),
    [selection]
  );

  const testingDates = useMemo(() => {
    if (!testingDatesResponse?.data) return [];
    return [
      { displayText: 'Un-assign', id: '000' },
      ...testingDatesResponse.data.map(
        ({ endTestDate, regAssignEndDate, testingEvent, testingEventId }) => ({
          displayText: testingEvent,
          endTestDate: endTestDate,
          id: testingEventId.toString(),
          regAssignEndDate: regAssignEndDate,
        })
      ),
      { displayText: 'Will not test', id: 'NOT' },
    ];
  }, [testingDatesResponse]);

  /**
   * useCallback()
   */
  const handleDateSelection = useCallback(e => {
    e.persist();
    setFormData(prev => ({
      ...prev,
      testDateId: e.target.id,
      errors: { ...prev.errors, testDateId: null },
    }));
  }, []);

  const handleSubmit = useCallback(() => {
    if (!formData.testDateId)
      return setFormData(prev => ({ ...prev, errors: { ...prev.errors, testDateId: true } }));

    let request;
    if (formData.testDateId === 'NOT') {
      if (!formData.reason.trim())
        return setFormData(prev => ({
          ...prev,
          errors: { ...prev.errors, reason: 'Field is required' },
        }));

      const data = regIDs.map(regId => ({
        notTesting: true,
        reason: formData.reason.trim(),
        regId,
      }));
      request = RosterServices.regWillNotTestStudents(data);
    } else {
      const data = {
        regIDs,
        testingEventId: formData.testDateId === '000' ? null : formData.testDateId,
      };
      request = RosterServices.registerStudents(data);
    }

    request
      .then(response => {
        if (response.data === regIDs.length) {
          setNotificationProps({
            show: true,
            text: `${regIDs.length} Students successfully updated`,
            type: 'Success',
          });
        } else {
          setNotificationProps({
            show: true,
            text: `${response.data} out of ${regIDs.length} Students successfully updated`,
            type: 'Warning',
          });
        }
        closeModal(true)();
      })
      .catch(() => {
        setNotificationProps({
          show: true,
          text: Text.messages.error.generic,
          type: 'Error',
        });
      });
  }, [closeModal, formData, regIDs, setNotificationProps, Text.messages.error.generic]);

  const handleTextareaChange = useCallback(e => {
    e.persist();
    setFormData(prev => ({
      ...prev,
      reason: e.target.value,
      errors: { ...prev.errors, reason: null },
    }));
  }, []);

  /**
   * useEffect()
   */
  useEffect(() => {
    dispatch(coreActions.getServerDateTime());
  }, [dispatch]);

  /**
   * render()
   */
  return (
    <Modal
      ariaHideApp={false}
      className="RegisterStudents__modal"
      closeTimeoutMS={200}
      contentLabel="Register Students"
      isOpen={true}
      onRequestClose={closeModal(false)}>
      <div className="RegisterStudents__modalWrapper">
        <div className="RegisterStudents__modalHeader">
          <p className="RegisterStudents__modalTitle">Register Students</p>
          <FontAwesomeIcon
            className="RegisterStudents__close"
            icon={faTimes}
            onClick={closeModal(false)}
            tabIndex="0"
          />
        </div>
        <div className="RegisterStudents__modalSubtitle">
          <hr className="RegisterStudents__modalDelimiter" />
          {`${regIDs.length} Student(s) selected`}
        </div>
        <p className="RegisterStudents__modalTitle RegisterStudents__modalTitle_Small">
          Select Test Event
        </p>
        {!!formData.errors.testDateId && (
          <div className="RegisterStudents__modalAlert">
            You must select an option in order to save.
          </div>
        )}
        <RadioControl
          defaultOption={formData.testDateId}
          disableOption={disableOptionId}
          handleDateSelection={handleDateSelection}
          items={testingDates}
          serverDateTime={serverDateTime?.data}
          testDateId={formData.testDateId}
        />
        {formData.testDateId === 'NOT' && (
          <TextArea
            error={formData.errors.reason || ''}
            handleTextAreaChange={handleTextareaChange}
            maxLength="500"
            nrCharactes={formData.reason?.length || 0}
            placeHolder="Reason"
          />
        )}
      </div>
      <div className="RegisterStudents__modalButtonWrapper">
        <hr className="RegisterStudents__modalDelimiter" />
        <button
          type="button"
          className="bigButton RegisterStudents__modalButton"
          onClick={handleSubmit}>
          Save
        </button>
      </div>
    </Modal>
  );
};

export default RegisterStudentsModal;

RegisterStudentsModal.displayName = 'RegisterStudentsModal';

RegisterStudentsModal.propTypes = {
  closeModal: propTypes.func.isRequired,
  selection: propTypes.object.isRequired,
  setNotificationProps: propTypes.func.isRequired,
};

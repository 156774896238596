import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BehaviorSubject } from 'rxjs';
import _ from 'lodash/fp';
import { states, STATUS } from '../services/helpers/stateStatus';
import {
  getContractAdminInfo,
  getContractInfo,
  getProgramAdminStaticInfo,
} from '../features/landing/duck/actions';
import {
  getAvailableTestingEvents,
  getInvitationStatus,
  getRolesByOrg,
} from '../features/administration/duck/actions';
import {
  getChildOrgs,
  getEntityDef,
  getNcrMaterialKitOptions,
  getRegTypes,
  getStudentsRecords,
  getTestingDates,
  getTimings,
} from '../features/students/duck/actions';
import { getOrgHierarchy, getUserPermissions, getUserPrograms } from '../core/duck/actions';

/**
 * NOTE: To add a new store, follow these steps:
 * - Add an action to the actions object
 * - Add params to the previousParams object
 * - Add a selector function to the selectors object
 * - Add a subject to the subjects object
 * - Create a store using the 'createStore' function, then add that store to the 'stores' object
 */

const actions = {
  childOrganizations: getChildOrgs,
  contractAdminInfo: getContractAdminInfo,
  contractInfo: getContractInfo,
  entityDef: getEntityDef,
  invitationStatus: getInvitationStatus,
  ncrMaterialKitOptions: getNcrMaterialKitOptions,
  orgHierarchy: getOrgHierarchy,
  programAdminStaticInfo: getProgramAdminStaticInfo,
  programs: getUserPrograms,
  regTypes: getRegTypes,
  roles: getRolesByOrg,
  studentsRecords: getStudentsRecords,
  testingDates: getTestingDates,
  testingEvents: getAvailableTestingEvents,
  timings: getTimings,
  userPermissions: getUserPermissions,
};

// Initial states for each store
const initialStates = Object.keys(actions).reduce(
  (acc, key) => ({ ...acc, [key]: { ...states[STATUS.init] } }),
  {}
);

// Selector functions for each store
const selectors = {
  childOrganizations: state => state.rosterReducer.childOrganizations,
  contractAdminInfo: state => state.landingReducer.contractAdminInfo,
  contractInfo: state => state.landingReducer.contractInfo,
  entityDef: state => state.rosterReducer.entityDef,
  invitationStatus: state => state.orgManagementReducer.inviteStatus,
  ncrMaterialKitOptions: state => state.rosterReducer.ncrMaterialKitOptions,
  orgHierarchy: state => state.userInfoReducer.orgHierarchy,
  programAdminStaticInfo: state => state.landingReducer.programAdminStaticInfo,
  programs: state => state.userInfoReducer.programs,
  regTypes: state => state.rosterReducer.regTypes,
  roles: state => state.orgManagementReducer.possibleUserRoles,
  studentsRecords: state => state.rosterReducer.studentsRecords,
  testingDates: state => state.rosterReducer.testingDates,
  testingEvents: state => state.orgManagementReducer.testingEvents,
  timings: state => state.rosterReducer.timings,
  userPermissions: state => state.userInfoReducer.userPermissions,
};

// Subjects (BehaviorSubjects) for each store
const subjects = Object.keys(actions).reduce(
  (acc, name) => ({ ...acc, [name]: new BehaviorSubject({ ...initialStates[name] }) }),
  {}
);

// Keep track of parameters that were previously used to fetch data, to reduce refetching data unnecessarily
const previousParams = {
  childOrganizations: { adminId: null, orgPartId: null },
  contractAdminInfo: { adminId: null, contractId: null },
  contractInfo: { contractId: null },
  entityDef: { adminId: null },
  invitationStatus: { orgPartId: null },
  ncrMaterialKitOptions: { adminId: null, nonCollegeReportableFlag: null, regTypeId: null },
  orgHierarchy: { orgPartId: null },
  programs: {},
  programAdminStaticInfo: { contractId: null },
  regTypes: { adminId: null, contractId: null },
  roles: { orgPartId: null },
  studentsRecords: { limit: null, offset: null, orgPartId: null },
  testingDates: { orgPartId: null },
  testingEvents: { orgPartId: null },
  timings: { orgPartId: null },
  userPermissions: { orgPartId: null },
};

// Clear the previous parameters and response data for a given store
const clear = name => {
  previousParams[name] = {
    ...Object.keys(previousParams[name]).reduce((acc, key) => ({ ...acc, [key]: null }), {}),
  };
  subjects[name].next({ ...initialStates[name] });
};

// Fetch data for a given store (currently achieved via dispatch/subscribe pattern)
const fetch = dispatch => name => params => {
  // If some parameters are undefined, or if the parameters are the same as the previous parameters, do nothing
  if (
    Object.keys(previousParams[name]).length &&
    (Object.values(params).some(_.isNil) || _.isEqual(previousParams[name])(params))
  )
    return;

  previousParams[name] = { ...params };
  dispatch(actions[name](params));
};

// Create a store with a given name
const createStore = name => dispatch => ({
  clear: () => clear(name),
  fetch: fetch(dispatch)(name),
  subscribe: setState => subjects[name].subscribe(setState),
});

/**
 * Store instances
 */
const childOrganizationsStore = createStore('childOrganizations');
const contractAdminInfoStore = createStore('contractAdminInfo');
const contractInfoStore = createStore('contractInfo');
const entityDefStore = createStore('entityDef');
const invitationStatusStore = createStore('invitationStatus');
const ncrMaterialKitOptionsStore = createStore('ncrMaterialKitOptions');
const orgHierarchyStore = createStore('orgHierarchy');
const programAdminStaticInfoStore = createStore('programAdminStaticInfo');
const programsStore = createStore('programs');
const regTypesStore = createStore('regTypes');
const rolesStore = createStore('roles');
const studentsRecordsStore = createStore('studentsRecords');
const testingDatesStore = createStore('testingDates');
const testingEventsStore = createStore('testingEvents');
const timingsStore = createStore('timings');
const userPermissionsStore = createStore('userPermissions');

/**
 * useStore() custom hook
 */
const useStore = name => {
  const dispatch = useDispatch();
  const response = useSelector(selectors[name]);
  const [stream, setStream] = useState({});

  // Define stores, passing in the dispatch function
  const stores = useMemo(
    () => ({
      childOrganizations: childOrganizationsStore(dispatch),
      contractAdminInfo: contractAdminInfoStore(dispatch),
      contractInfo: contractInfoStore(dispatch),
      entityDef: entityDefStore(dispatch),
      invitationStatus: invitationStatusStore(dispatch),
      ncrMaterialKitOptions: ncrMaterialKitOptionsStore(dispatch),
      orgHierarchy: orgHierarchyStore(dispatch),
      programAdminStaticInfo: programAdminStaticInfoStore(dispatch),
      programs: programsStore(dispatch),
      regTypes: regTypesStore(dispatch),
      roles: rolesStore(dispatch),
      studentsRecords: studentsRecordsStore(dispatch),
      testingDates: testingDatesStore(dispatch),
      testingEvents: testingEventsStore(dispatch),
      timings: timingsStore(dispatch),
      userPermissions: userPermissionsStore(dispatch),
    }),
    [dispatch]
  );

  // Subscribe to the store, updating the stream when the store changes
  useEffect(() => {
    const subscription = stores[name].subscribe({ next: setStream });
    return () => subscription.unsubscribe();
  }, [name, stores]);

  // React to changes in the response
  useEffect(() => subjects[name].next(response), [name, response]);

  return [stream, stores[name]];
};

/**
 *  getPermission()
 */
const usePermission = permission => {
  const userPermissionsResponse = useSelector(selectors.userPermissions);

  return userPermissionsResponse?.data?.some(({ code }) => permission === code);
};

export { usePermission, useStore };

import React from 'react';
import './Notifications.scss';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash/fp';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';

import Header from '../../../components/header/Header';
import { common, features } from '../../../core/i18n/text';
import AlertModal from '../../../components/modal/AlertModal';
import { actions as coreActions } from '../../../core/duck';
import { actions as notifActions } from '../duck';
import SidebarNav from '../../../components/sidebar-nav/SidebarNav';

const Text = { common, features };

const CHUNK_SIZE = 12;
const NUMBER_OF_NOTIFICATIONS = 10;

class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasMore: true,
      items: [],
      count: 0,
      notification: null,
      showModal: false,
      totalNumberOfNotifications: 0,
    };
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
  }

  componentDidMount() {
    if (!this.props.userInfo) {
      this.props.actions.getUserInfo();
    }
    if (
      this.props.userInfo &&
      this.props.userInfo.data &&
      this.state.totalNumberOfNotifications === 0
    ) {
      this.props.actions.getNotifications({
        userId: this.props.userInfo.data.id,
        skip: this.state.count,
        limit: CHUNK_SIZE,
      });
      this.props.actions.getNotViewedNotificationsCount(this.props.userInfo.data.id);
      this.props.actions.getViewedNotificationsCount(this.props.userInfo.data.id);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !_.isEqual(prevProps.userInfo)(this.props.userInfo) &&
      this.props.userInfo &&
      this.props.userInfo.data &&
      this.state.totalNumberOfNotifications === 0
    ) {
      this.props.actions.getNotifications({
        userId: this.props.userInfo.data.id,
        skip: this.state.count,
        limit: CHUNK_SIZE,
      });
      this.props.actions.getNotViewedNotificationsCount(this.props.userInfo.data.id);
      this.props.actions.getViewedNotificationsCount(this.props.userInfo.data.id);
    }

    if (
      this.props.viewedNotificationsCount &&
      this.props.viewedNotificationsCount.data &&
      this.props.notViewedNotificationsCount &&
      this.props.notViewedNotificationsCount.data &&
      this.state.totalNumberOfNotifications === 0
    ) {
      this.setState({
        totalNumberOfNotifications:
          this.props.viewedNotificationsCount.data.count +
          this.props.notViewedNotificationsCount.data.count,
      });
    }

    if (
      !_.isEqual(this.props.notifications)(prevProps.notifications) &&
      this.props.notifications &&
      this.props.notifications.data
    ) {
      this.setState({
        items: this.state.items.concat(this.props.notifications.data),
      });
      this.setState({
        count: this.state.count + CHUNK_SIZE,
      });

      if (this.state.count > this.state.totalNumberOfNotifications) {
        this.setState({
          hasMore: false,
        });
      }
    }
  }

  loadMore = () => {
    if (this.state.hasMore && this.state.items && this.props.userInfo && this.props.userInfo.data) {
      this.props.actions.getNotifications({
        userId: this.props.userInfo.data.id,
        skip: this.state.count,
        limit: CHUNK_SIZE,
      });
    }
  };

  getTime(date) {
    return moment(date).fromNow();
  }

  open(notification) {
    this.setState(
      {
        showModal: true,
        notification,
      },
      () => {
        this.props.actions.readNotification({
          userId: this.props.userInfo.data.id,
          notification: {
            notificationId: notification.id,
          },
        });
      }
    );
  }

  onKeyDown = (e, notification) => {
    if (e.keyCode === 13) {
      this.open(notification);
    }
  };

  close() {
    this.setState({
      showModal: false,
      items: this.state.items.map(notification => {
        if (notification.id === this.state.notification.id) {
          notification.status = 'VIEWED';
        }
        return notification;
      }),
    });
    this.props.actions.getNotificationsByStatus({
      userId: this.props.userInfo.data.id,
      skip: 0,
      limit: NUMBER_OF_NOTIFICATIONS,
      status: 'NOT_VIEWED',
    });
    this.props.actions.getNotViewedNotificationsCount(this.props.userInfo.data.id);
  }

  render() {
    return (
      <>
        <div className="Notifications__Page">
          <Header />
          <div className="_outter_">
            <SidebarNav />
            <div className="sidebar__Separator">
              <div className="Notifications__Back">
                <span className="Notifications__Title">{Text.features.notifications.title}</span>
              </div>

              <InfiniteScroll
                dataLength={this.state.items.length}
                next={this.loadMore.bind(this)}
                hasMore={this.state.hasMore}>
                <div className="Notifications__Container">
                  <ul>
                    {this.state.items.map((notification, index) => {
                      const viewed = notification.status !== 'NOT_VIEWED' ? 'disabled' : '';
                      return (
                        <li key={index}>
                          <div className={`Notifications__Bullet ${viewed}`}></div>
                          <div className={`Notifications__Text ${viewed}`}>
                            <span>
                              {notification.title
                                ? notification.title
                                : 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt exercitationem earum sed sit pariatur, vel maiores voluptatibus illum voluptate!'}
                            </span>
                            <div className={`Notifications__Time ${viewed}`}>
                              <span>{this.getTime(notification.created)}</span>
                              <span className="Notifications__Status">
                                {notification.status === 'NOT_VIEWED' ? ' - Unread' : ''}
                              </span>
                            </div>
                          </div>
                          <div
                            className="Notifications__Open link"
                            onClick={() => this.open(notification)}
                            onKeyDown={e => this.onKeyDown(e, notification)}
                            tabIndex="0">
                            {Text.common.labels.open}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </InfiniteScroll>
            </div>
            {this.state.notification && (
              <AlertModal
                show={this.state.showModal}
                title={features.notifications.modalTitle}
                bodyTitle={this.state.notification.title}
                body={[this.state.notification.body]}
                note={this.getTime(this.state.notification.created)}
                close={this.close}
                showButton
              />
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    userInfo: state.userInfoReducer.userInfo,
    notifications: state.notificationReducer.notifications,
    viewedNotificationsCount: state.notificationReducer.viewedNotificationsCount,
    notViewedNotificationsCount: state.notificationReducer.notViewedNotificationsCount,
    notificationRead: state.notificationReducer.notificationRead,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ ...coreActions, ...notifActions }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);

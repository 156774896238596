import * as types from './types';

const initialState = {
  notifications: [],
  statusNotifications: [],
  notViewedNotificationsCount: null,
  viewedNotificationsCount: null,
  notificationRead: null,
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.GET_NOT_VIEWED_NOTIFICATIONS_COUNT:
      return {
        ...state,
        notViewedNotificationsCount: action.payload,
      };
    case types.GET_VIEWED_NOTIFICATIONS_COUNT:
      return {
        ...state,
        viewedNotificationsCount: action.payload,
      };
    case types.GET_NOTIFICATIONS_BY_STATUS:
      return {
        ...state,
        statusNotifications: action.payload,
      };
    case types.GET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };
    case types.READ_NOTIFICATION:
      return {
        ...state,
        notificationRead: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;

import React from 'react';
import propTypes from 'prop-types';

const Checkbox = ({ checked, value, handleChange, error }) => {
  const onKeyDown = e => {
    if (e.keyCode === 13) {
      handleChange();
    }
  };

  return (
    <div className="checkbox__Group">
      <label className="checkbox__Container">
        <input type="checkbox" checked={checked} value={value} onChange={handleChange} />
        <span
          aria-label={checked ? 'checked' : 'unchecked'}
          className={`checkbox__Mark ${error ? 'error' : ''}`}
          tabIndex="0"
          onKeyDown={e => onKeyDown(e)}
        />
      </label>
    </div>
  );
};

Checkbox.displayName = 'Checkbox';
Checkbox.defaultProps = {
  value: false,
};
Checkbox.propTypes = {
  checked: propTypes.bool.isRequired,
  value: propTypes.bool,
  handleChange: propTypes.func.isRequired,
};

export default Checkbox;

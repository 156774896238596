import React from 'react';
import propTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { common } from '../../../core/i18n/text';

const Text = { common };

const Phone = props => {
  const {
    label,
    phoneError,
    onPhoneChange,
    phoneValue,
    inputProps,
    required,
    extension,
    extensionError,
    onExtensionChange,
    extensionValue,
  } = props;
  return (
    <div className="phone__Container">
      <div>
        <label
          className={`field__Label${phoneError ? ' field__Label-error' : ''}`}
          htmlFor="phone-form-control">
          {label}
        </label>
        <div>
          <PhoneInput
            inputClass="field__Input-error"
            country="us"
            onlyCountries={['us']}
            // preferredCountries={['us']}
            onChange={onPhoneChange}
            value={phoneValue}
            inputProps={inputProps}
            autoFormat
            disableCountryCode={false}
          />
          {phoneError && <div className="field__ErrorMessage">{phoneError}</div>}
          {!required && <div className="phone__optional">{Text.common.labels.optional}</div>}
        </div>
      </div>
      {extension && (
        <div className="phone__extension__container">
          <label
            className={`field__Label${extensionError !== undefined ? ' field__Label-error' : ''}`}
            htmlFor="phoneExtension">
            {Text.common.fields.extension}
          </label>
          <input
            className="field__Input phone__extension"
            onChange={onExtensionChange}
            value={extensionValue}
            maxLength="10"
            name="phoneExtension"
            id="phoneExtension"
          />
          {extensionError !== undefined ? (
            <div className="phone__optional field__ErrorMessage">{extensionError}</div>
          ) : (
            ''
          )}
          {extension && !extensionError && (
            <div className="phone__optional">{Text.common.labels.optional}</div>
          )}
        </div>
      )}
    </div>
  );
};

Phone.displayName = 'Phone';
Phone.defaultProps = {
  phoneError: undefined,
  extensionError: undefined,
  extensionValue: '',
  onExtensionChange: null,
};
Phone.propTypes = {
  label: propTypes.string.isRequired,
  phoneError: propTypes.string,
  onPhoneChange: propTypes.func.isRequired,
  phoneValue: propTypes.string.isRequired,
  inputProps: propTypes.shape({ name: propTypes.string }).isRequired,
  required: propTypes.bool.isRequired,
  extension: propTypes.bool.isRequired,
  extensionError: propTypes.string,
  onExtensionChange: propTypes.func,
  extensionValue: propTypes.string,
};

export default Phone;

import * as types from './types';

const initialState = {
  studentsRecords: null,
  studentsRegistered: null,
  testingDates: null,
  uploadedFiles: null,
  downloadUrls: null,
  fileUploadStatus: null,
  regTypes: null,
  timings: null,
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.BULK_UPDATE_OPTIONS:
      return {
        ...state,
        bulkUpdateOptions: action.payload,
      };
    case types.GET_RECORDS:
      return {
        ...state,
        studentsRecords: action.payload,
      };
    case types.GET_NCR_MATERIAL_KIT:
      return {
        ...state,
        ncrMaterialKitOptions: action.payload,
      };
    case types.GET_TESTINGDATES:
      return {
        ...state,
        testingDates: action.payload,
      };
    case types.REG_STUDENTS:
      return {
        ...state,
        studentsRegistered: action.payload,
      };
    case types.UPLOAD_FILE:
      return {
        ...state,
        fileUploadStatus: action.payload,
      };
    case types.GET_FILES:
      return {
        ...state,
        uploadedFiles: action.payload,
      };
    case types.GET_DOWNLOADURLS:
      return {
        ...state,
        downloadUrls: action.payload,
      };
    case types.GET_STUDENTS_BY_TEST_EVENT:
      return {
        ...state,
        studentsByTestEvent: action.payload,
      };
    case types.GET_STUDENTS_BY_TEST_FORMAT:
      return {
        ...state,
        studentsByTestFormat: action.payload,
      };
    case types.GET_STUDENTS_BY_GRADES:
      return {
        ...state,
        studentsByGrades: action.payload,
      };
    case types.GET_STUDENTS_BY_TEST:
      return {
        ...state,
        studentsByTest: action.payload,
      };
    case types.GET_CHILD_ORGS_SUMMARY:
      return {
        ...state,
        childOrganizationsSummary: action.payload,
      };
    case types.GET_CHILD_ORGS:
      return {
        ...state,
        childOrganizations: action.payload,
      };
    case types.GET_ENTITY_DEF:
      return {
        ...state,
        entityDef: action.payload,
      };
    case types.GET_REG_TYPES:
      return {
        ...state,
        regTypes: action.payload,
      };
    case types.UPDATE_STUDENT_DETAILS:
      return {
        ...state,
        studentDetails: action.payload,
      };
    case types.ADD_STUDENT:
      return {
        ...state,
        studentDetails: action.payload,
      };
    case types.SEARCH_STUDENT:
      return {
        ...state,
        searchStudent: action.payload,
      };
    case types.REQEST_STUDENT_TRANSFER:
      return {
        ...state,
        requestStudentTransfer: action.payload,
      };
    case types.UNENROLL_STUDENT:
      return {
        ...state,
        unenrollStudent: action.payload,
      };
    case types.GET_SENT_TRANSFER_REQUESTS:
      return {
        ...state,
        sentTransferRequests: action.payload,
      };
    case types.GET_RECEIVED_TRANSFER_REQUESTS:
      return {
        ...state,
        receivedTransferRequests: action.payload,
      };
    case types.CANCEL_STUDENT_TRANSFER_REQUEST:
      return {
        ...state,
        cancelStudentTransferRequest: action.payload,
      };
    case types.UPDATE_STUDENT_TRANSFER_REQUEST:
      return {
        ...state,
        updateStudentTransferRequest: action.payload,
      };
    case types.GET_TIMINGS:
      return {
        ...state,
        timings: action.payload || [],
      };
    case types.GET_ACCOMMODATION_OPTIONS:
      return {
        ...state,
        accommodationOptions: action.payload,
      };
    case types.GET_REG_ACCOMS:
      return {
        ...state,
        regAccoms: { data: [], ...action.payload },
      };
    default:
      return state;
  }
};

export default reducer;

import React, { useMemo } from 'react';
import propTypes from 'prop-types';

import { ReactComponent as WhiteAlertIcon } from '../../assets/svgs/alertWhite.svg';
import { ReactComponent as WhiteCheckIcon } from '../../assets/svgs/alertCheckmark.svg';

const Notification = ({ handleClose, noHeader = false, text, type = 'Success' }) => {
  /**
   * useMemo()
   */
  const img = useMemo(() => (type === 'Error' ? WhiteAlertIcon : WhiteCheckIcon), [type]);

  /**
   * render
   */
  return (
    <div>
      <div
        className={`Notification__Container Notification__Container${type} ${
          noHeader ? 'shiftUp' : ''
        }`}>
        <img src={img} alt="" />
        <div className={`Notification__Text Notification__Text${type}`}>{text}</div>
        <span className={`Notification__Close `} onClick={handleClose}>
          <div></div>
        </span>
      </div>
    </div>
  );
};

Notification.displayName = 'Notification';

Notification.propTypes = {
  handleClose: propTypes.func.isRequired,
  noHeader: propTypes.bool,
  text: propTypes.string.isRequired,
  type: propTypes.string,
};

export default Notification;

import React from 'react';
import Steps, { Step } from 'rc-steps';
import { ReactComponent as CheckMark } from '../../assets/svgs/blueCheckmarkRounded.svg';

import './Stepper.scss';

const Stepper = ({ current, stepsTotal }) => {
  return (
    <Steps current={current} direction="vertical" icons={{ finish: <CheckMark /> }}>
      {stepsTotal.map(value => (
        <Step key={value.id} title={value.title} />
      ))}
    </Steps>
  );
};

export default Stepper;

import _ from 'lodash/fp';
import { createDispatchFunction } from '../../../services/helpers/stateHelper';

import { LandingServices } from '../../../services';
import * as types from './types';

export function getContractAdminInfoAction(payload) {
  return {
    type: types.GET_CONTRACT_ADMIN_DETAIL,
    payload,
  };
}

export function getContractInfoAction(payload) {
  return {
    type: types.GET_CONTRACT_INFO,
    payload,
  };
}

export function getProgramAdminStaticInfoAction(payload) {
  return {
    type: types.GET_PROGRAM_ADMIN_STATIC_INFO,
    payload,
  };
}

export function getContractAdminInfo(data) {
  return createDispatchFunction(
    _.partial(LandingServices.getContractAdminInfo)([data]),
    getContractAdminInfoAction
  );
}

export function getContractInfo(data) {
  return createDispatchFunction(
    _.partial(LandingServices.getContractInfo)([data]),
    getContractInfoAction
  );
}

export function getProgramAdminStaticInfo(data) {
  return createDispatchFunction(
    _.partial(LandingServices.getProgramAdminStaticInfo)([data]),
    getProgramAdminStaticInfoAction
  );
}

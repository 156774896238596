import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Header from '../../../components/header/Header';
import SidebarNav from '../../../components/sidebar-nav/SidebarNav';
import { Tabs } from '../../../components/index';
import { actions as coreActions } from '../../../core/duck';
import ContractFiles from '../contract-files/ContractFiles';
import ContractOrganizations from '../contract-organizations/ContractOrganizations';
import _ from 'lodash/fp';
import { getPermission } from '../../../core/duck/selectors';
import './ContractRouter.scss';

let tabs = [
  {
    title: 'Organization',
    url: 'organization',
    avail: true,
    component: ContractOrganizations,
  },
  {
    title: 'Files',
    url: 'files',
    avail: true,
    component: ContractFiles,
  },
  {
    title: 'ContractDetails',
    url: 'contract-details',
    avail: false,
    component: '',
  },
  {
    title: 'OperationalReports',
    url: 'operational-reports',
    avail: false,
    component: '',
  },
];

let currentOrg = {};
let rootOrg = {};
let currentOrgPartId;

class ContractRouter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '',
      TabComponent: null,
      typeOfSelectedOrg: null,
      orgTree: [],
      tabs,
    };
  }

  componentDidMount() {
    const url = this.props.match.url;
    for (let i = 0; i < this.state.tabs.length; i++) {
      if (url.includes(this.state.tabs[i].url)) {
        const CurrentComponent = this.state.tabs[i].component;
        this.setState({
          activeTab: this.state.tabs[i].url,
          TabComponent: CurrentComponent,
        });
      }
    }
    const { orgHierarchy } = this.props;
    currentOrgPartId = parseInt(this.props.match.params.orgPartId);
    if (orgHierarchy?.data) {
      currentOrg = orgHierarchy.data.find(obj => {
        return Number(obj.orgPartId) === Number(currentOrgPartId);
      });
      rootOrg = this.props.orgHierarchy.data[0];
      if (orgHierarchy.data[0].orgPartId === currentOrgPartId) {
        this.setState({ orgTree: orgHierarchy.data });
      } else {
        this.retrieveOrgHierachy(currentOrgPartId);
      }
    } else {
      this.retrieveOrgHierachy(currentOrgPartId);
    }
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.match)(this.props.match)) {
      const url = this.props.match.url;
      for (let i = 0; i < this.state.tabs.length; i++) {
        if (url.includes(this.state.tabs[i].url)) {
          const CurrentComponent = this.state.tabs[i].component;
          this.setState({
            activeTab: this.state.tabs[i].url,
            TabComponent: CurrentComponent,
          });
        }
      }
      currentOrgPartId = parseInt(this.props.match.params.orgPartId);
      this.retrieveOrgHierachy(currentOrgPartId);
    }
  }

  /* API call */
  retrieveOrgHierachy(orgPartId) {
    this.props.actions.getOrgHierarchy({ orgPartId }).then(response => {
      const data = response.data?.data;
      currentOrg = data[data.length - 1];
      rootOrg = data[0];
      this.setState({ orgTree: data });
    });
  }

  handleTabChange = tab => {
    this.setState({
      activeTab: tab,
    });
  };

  render() {
    const { activeTab, tabs, TabComponent } = this.state;
    return (
      <div className="roster__Page">
        <Header />
        {this.props.hasContractPermission && (
          <div className="_outter_">
            <SidebarNav active="contract" />
            <div className="sidebar__Separator sidebar_fit_table">
              <h1 id="main" className="contract__Title">
                <span>
                  {rootOrg.orgName} ({rootOrg.orgCode})
                </span>
              </h1>
              <Tabs
                tabs={tabs}
                basePath="/contract"
                currentOrgPartId={this.props.match.params.orgPartId}
                handleTabChange={this.handleTabChange}
                activeTab={activeTab}
                {...this.props}
              />
              {TabComponent ? (
                <TabComponent {...this.props} currentOrg={currentOrg} rootOrg={rootOrg} />
              ) : null}
            </div>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { userInfoReducer } = state;
  return {
    orgHierarchy: userInfoReducer.orgHierarchy,
    hasContractPermission: getPermission(state, 'now_contract_manage'),
  };
};
const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ ...coreActions }, dispatch),
  };
};

export { ContractRouter };
export default connect(mapStateToProps, mapDispatchToProps)(ContractRouter);

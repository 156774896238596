import _ from 'lodash/fp';
import { createDispatchFunction } from '../../../services/helpers/stateHelper';
import { UserServices } from '../../../services';

import * as types from './types';

export function personalInfoAction(response) {
  return {
    type: types.GET_USERINFO,
    userInfo: response,
  };
}

function updatePersonalInfo(data) {
  return UserServices.updatePersonalInfo(data);
}

export function updatePersonalInformation(data) {
  return createDispatchFunction(_.partial(updatePersonalInfo)([data]), personalInfoAction);
}

function createPersonalInfo(data) {
  return UserServices.createPersonalInfo(data);
}

export const createPersonalInformation = data => {
  return createDispatchFunction(_.partial(createPersonalInfo)([data]), personalInfoAction);
};

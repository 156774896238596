import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import _ from 'lodash/fp';

import { countArray } from '../../core/constants';

import { ReactComponent as FilterIcon } from '../../assets/svgs/filterIconBlue.svg';

import Alert from '../../components/alerts/Alert';
import BulkActions from './bulkActions/BulkActions';
import ColumnManager from '../column-manager/ColumnManager';
import Counter from './counter/Counter';
import CustomReactTable from '../table/Table';
import Filters from '../filters/Filters';
import Loader from '../loader/Loader';
import NoResults from '../table/NoResults';
import Pagination from './pagination/Pagination';
import Search from './search/Search';

import TextContext from '../../core/providers/TextProvider';

import './DataPanel.scss';

const DataPanelContainer = ({
  addNewItemIcon: AddNewItemIcon = null,
  addNewItemLabel,
  bulkActions,
  deselectAllActionHandle,
  emptyStateComponent,
  filters = [],
  getRecords,
  handleAddNewItem,
  handleHeaderCheckboxChange,
  hasActiveDefaultFilter,
  hasBulkAction,
  hasFocus,
  hideAddNew,
  initialColumns,
  initialSearch,
  inputList,
  inviteButton,
  isHeaderCheckboxSelected,
  linkButtons,
  loadingData,
  nonStikyColumns,
  searchInfoBanner,
  searchMultiInput,
  searchParams,
  selectAllActionHandle,
  selectionsNumber,
  setColumns,
  setTableColumns,
  stickyColumnsLength,
  tableData,
  totalCount,
}) => {
  /**
   * react-router-dom hooks
   */
  const location = useLocation();

  /**
   * useContext()
   */
  const Text = useContext(TextContext);

  /**
   * useState()
   */
  const [cleanInput, setCleanInput] = useState(true);
  const [formattedHeaders, setFormattedHeaders] = useState([]);
  const [initialFilters, setInitialFilters] = useState();
  const [newHeaders, setNewHeaders] = useState([]);
  const [openedDropdown, setOpenedDropdown] = useState(false);
  const [params, setParams] = useState({
    filtersParams: filters.reduce(
      (acc, { callParameter }) => ({
        ...acc,
        [callParameter]: callParameter === 'status' && hasActiveDefaultFilter ? 'active' : '',
      }),
      {}
    ),
    limit: countArray[0],
    offset: 0,
    searchedValues: initialSearch,
  });
  const [previousParams, setPreviousParams] = useState({});
  const [searchInputList, setSearchInputList] = useState(inputList);
  const [searchedValues, setSearchedValues] = useState(initialSearch);
  const [showFilters, setShowFilters] = useState(!!filters?.length);

  /**
   * useMemo()
   */
  const [, page, tab] = useMemo(() => location.pathname.split('/'), [location.pathname]);

  const columns = useMemo(() => {
    if (!setTableColumns) return;
    return setTableColumns(
      linkButtons,
      inviteButton,
      (offset, limit, searchedValues, filtersParams) =>
        getRecords({ filtersParams, limit, offset, searchedValues })
    );
  }, [getRecords, inviteButton, linkButtons, setTableColumns]);

  /**
   * useCallback()
   */
  const changeLimit = useCallback(limit => {
    setParams(prev => ({ ...prev, limit, offset: 0 }));
  }, []);

  const changeOffset = useCallback(offset => setParams(prev => ({ ...prev, offset })), []);

  const clearFilters = useCallback(() => {
    setParams(prev => ({ ...prev, filtersParams: initialFilters }));
  }, [initialFilters]);

  const clearInput = useCallback(() => setSearchedValues(initialSearch), [initialSearch]);

  const filter = useCallback(
    filtersParams => {
      if (_.isEqual(filtersParams)(params.filtersParams)) return;
      setParams(prev => ({ ...prev, filtersParams }));
    },
    [params.filtersParams]
  );

  const handleChange = useCallback(
    e => {
      const { name, value } = e.target;

      if (
        searchParams.lastName &&
        searchParams.firstName &&
        name === searchParams.lastName &&
        value === ''
      )
        setSearchedValues(prev => ({
          ...prev,
          [searchParams.firstName]: '',
          [searchParams.lastName]: '',
        }));
      setSearchedValues(prev => ({ ...prev, [name]: value }));
    },
    [searchParams]
  );

  const handleSearch = useCallback(() => {
    if (_.isEqual(searchedValues)(params.searchedValues)) return;
    setParams(prev => ({ ...prev, offset: 0, searchedValues }));
  }, [params.searchedValues, searchedValues]);

  const resetSearch = useCallback(() => {
    setSearchedValues(initialSearch);
    setParams(prev => ({ ...prev, searchedValues: initialSearch }));
  }, [initialSearch]);

  const toggleShowFilters = useCallback(() => setShowFilters(prev => !prev), []);

  const handleKeyDown = useCallback(
    e => {
      if (e.keyCode !== 13) return;
      toggleShowFilters();
    },
    [toggleShowFilters]
  );

  /**
   * useEffect()
   */
  useEffect(() => {
    if (initialFilters || _.isEmpty(params.filtersParams)) return;
    setInitialFilters(params.filtersParams);
  }, [initialFilters, params.filtersParams]);

  useEffect(() => {
    if (_.isEqual(params)(previousParams)) return;
    setPreviousParams(params);
    getRecords(params);
  }, [getRecords, params, previousParams]);

  useEffect(() => {
    if (!searchMultiInput) return;
    setSearchInputList(prev =>
      prev.map(input => {
        if (input.name === searchParams.firstName)
          return { ...input, disabled: !searchedValues[searchParams.lastName] };
        return { ...input };
      })
    );
  }, [searchedValues, searchMultiInput, searchParams]);

  useEffect(() => setCleanInput(Object.values(searchedValues).every(value => !value)), [
    searchedValues,
  ]);

  useEffect(() => {
    if (!cleanInput) return;
    setParams(prev => ({ ...prev, searchedValues: initialSearch }));
  }, [cleanInput, initialSearch]);

  useEffect(() => {
    if (!initialColumns) return;
    setColumns(
      initialColumns
        .filter(col => _.isEmpty(newHeaders) || newHeaders.some(({ name }) => name === col.Header))
        .map(col => ({ ...col, length: newHeaders.length + stickyColumnsLength }))
    );
  }, [initialColumns, newHeaders, setColumns, stickyColumnsLength]);

  useEffect(() => {
    const columnsStr = window.sessionStorage.getItem(`${page}-${tab}-columns`);
    if (!columnsStr) return;
    setNewHeaders(JSON.parse(columnsStr));
  }, [page, tab]);

  useEffect(() => {
    if (_.isEmpty(newHeaders)) return;

    window.sessionStorage.setItem(
      `${page}-${tab}-columns`,
      JSON.stringify(newHeaders.filter(column => column.checked))
    );
  }, [newHeaders, page, tab]);

  useEffect(() => {
    if (!setTableColumns) return;
    setFormattedHeaders(setTableColumns([]));
  }, [setTableColumns]);

  useEffect(() => {
    setOpenedDropdown(false);
  }, [tableData]);

  /**
   * render
   */
  if (
    emptyStateComponent &&
    ['orgId', 'roles', 'status'].every(key => !params.filtersParams[key]) &&
    ['filter', 'nameOrCode', 'nameOrEmail'].every(key => !params.searchedValues[key]) &&
    tableData?.length === 0
  )
    return emptyStateComponent;

  return (
    <div className="data-panel-container">
      <div className="data-panel-search-container">
        {!showFilters && !!filters.length && (
          <div
            className="filter-button"
            onClick={() => toggleShowFilters()}
            onKeyDown={handleKeyDown}
            tabIndex="0">
            <FilterIcon />
            Show filters
            <div className="vertical-separator"></div>
          </div>
        )}

        <Search
          cleanInput={cleanInput}
          clearInput={clearInput}
          handleChange={handleChange}
          handleSearch={handleSearch}
          hasFocus={hasFocus}
          inputList={searchInputList}
          maxLength={'30'}
          resetSearch={resetSearch}
          searchedValues={searchedValues}
          searchLabel={Text.common.labels.searchBy}
          searchMultiInput={searchMultiInput}
        />

        {!hideAddNew && handleAddNewItem && AddNewItemIcon && (
          <div className="individual-action">
            <button className="linkButton" onClick={() => handleAddNewItem()}>
              <AddNewItemIcon />
              <span>{addNewItemLabel}</span>
            </button>
          </div>
        )}
      </div>
      {searchInfoBanner && (
        <div className="hide-table-msg-container">
          <Alert type="info" message={searchInfoBanner} />
        </div>
      )}
      {!searchInfoBanner && (
        <>
          <div className="data-actions-container">
            <Counter
              count={params.limit}
              loadingData={loadingData}
              offset={params.offset}
              totalCount={totalCount}
            />
            <div className="data-actions">
              <div>
                {hasBulkAction && (
                  <BulkActions
                    deselectActionHandle={deselectAllActionHandle}
                    options={bulkActions}
                    selectActionHandle={selectAllActionHandle}
                    selectionsNumber={selectionsNumber}
                  />
                )}
              </div>
              <div className="pagination-container">
                {setColumns && (
                  <ColumnManager
                    headerColumns={nonStikyColumns}
                    initialColumns={initialColumns}
                    page={page}
                    setNewHeaders={setNewHeaders}
                    tab={tab}
                  />
                )}
                <Pagination
                  count={params.limit}
                  offset={params.offset}
                  onChange={changeLimit}
                  openedDropdown={openedDropdown}
                  rosterConst={countArray}
                  setOffset={changeOffset}
                  setOpenedDropdown={setOpenedDropdown}
                  totalCount={totalCount}
                />
              </div>
            </div>
          </div>
          <div className={`data-panel-content ${!showFilters && 'no-filters'}`}>
            {showFilters && (
              <Filters
                filter={filter}
                filterFirst={showFilters}
                hide={toggleShowFilters}
                sections={filters}
                show={showFilters}
                values={params.filtersParams}
              />
            )}
            {loadingData ? (
              <Loader />
            ) : !!tableData?.length ? (
              <CustomReactTable
                columns={columns || formattedHeaders}
                data={tableData}
                handleHeaderCheckboxChange={handleHeaderCheckboxChange}
                isHeaderCheckboxSelected={isHeaderCheckboxSelected}
                wideTable={!showFilters}
              />
            ) : (
              <NoResults
                clearInputAndFilters={() => {
                  clearFilters();
                  clearInput();
                  resetSearch();
                }}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default DataPanelContainer;

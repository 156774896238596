import moment from 'moment';
import { createSelector } from 'reselect';
import _ from 'lodash/fp';

const getAvailableTestingEvents = state => state.orgManagementReducer.availableTestingEvents;
const getServerDateTime = state => state.userInfoReducer.serverDateTime;

const isWindowEvent = testingEvent => {
  return (
    !!testingEvent.endTestDate &&
    moment(testingEvent.endTestDate).isAfter(moment(testingEvent.testDate), 'day')
  );
};

const filterTestingEvents = (availableTestingEvents, serverDateTime, nrOfDays) =>
  availableTestingEvents.filter(testEvent => {
    const currentDate = moment(serverDateTime).format('YYYY-MM-DD');
    const assignmentDate = isWindowEvent(testEvent) ? testEvent.endTestDate : testEvent.testDate;

    return moment(assignmentDate)
      .add(nrOfDays, 'd')
      .isSameOrAfter(currentDate);
  });

export const filteredTestingEvents = createSelector(
  [getAvailableTestingEvents, getServerDateTime],
  (availableTestingEvents, serverDateTime) => {
    if (_.get('data')(availableTestingEvents)) {
      return filterTestingEvents(availableTestingEvents.data, serverDateTime, 7) || [];
    }
    return [];
  }
);

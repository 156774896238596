import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import _ from 'lodash/fp';

import { RosterServices } from '../../../services/';
import { getEntityDef } from '../../../services/helpers/dataHelper';

import { ReactComponent as AccommodationIcon } from '../../../assets/svgs/accommodationIcon.svg';
import { ReactComponent as AddIcon } from '../../../assets/svgs/addIcon.svg';
import { ReactComponent as ErrorIcon } from '../../../assets/svgs/error.svg';

import AlertModal from '../../../components/modal/AlertModal';
import Checkbox from '../../../components/inputs/Checkbox';
import DataPanelContainer from '../../../components/data-panel/DataPanelContainer';
import LeftButtons from '../../../components/data-panel/profileButtons/ProfileButtons';
import Notification from '../../../components/alerts/Notification';

import {
  EditStudentsModal,
  RegisterStudentsModal,
  StudentsInfoModal,
  UpdateRegistrationOptionsModal,
} from '../';

import { countArray } from '../../../core/constants';
import { getPermission } from '../../../core/duck/selectors';

import { useStore } from '../../../store/hooks';

import TextContext from '../../../core/providers/TextProvider';

import './Students.scss';

let closeUpdateSuccessNotificationTimer = null;

const SEARCH_PARAMS = {
  firstName: 'firstNameStartWith',
  lastName: 'lastNameStartWith',
  studentId: 'studentId',
};

const getAccommodationOptions = program => {
  switch (program?.parentCode) {
    case 'act':
      return [
        { value: 'nonCollegeReportableFlag', label: 'Non-College Reportable' },
        { value: 'accomRosterFlag', label: 'ACT approved' },
      ];
    case 'preact':
    case 'workkeys':
      return [{ value: 'accomRosterFlag', label: 'Yes' }];
    default:
      return [];
  }
};

const initialSearch = Object.values(SEARCH_PARAMS).reduce(
  (acc, key) => ({ ...acc, [key]: '' }),
  {}
);

const onlineFlagVal = {
  false: 'Paper',
  true: 'Online',
};

const testingEventVal = {
  notAssigned: 'Not assigned',
  true: 'Will not test',
};

const initialColumns = [
  {
    accessor: 'testingEvent',
    Cell: e =>
      testingEventVal[e.cell.row.original.testingEvent] || e.cell.row.original.testingEvent || '-',
    Header: 'Assigned event',
    name: 'Assigned event',
    type: '',
    width: 141,
  },
  {
    accessor: 'externalStudentId',
    Header: 'State student ID',
    name: 'State student ID',
    type: 'number',
    width: 157,
  },
  {
    accessor: 'onlineFlag',
    Cell: e =>
      onlineFlagVal[e.cell.row.original.onlineFlag] || e.cell.row.original.onlineFlag || '-',
    Header: 'Format',
    name: 'Format',
    type: '',
    width: 74,
  },
  {
    accessor: 'timing',
    Header: 'Timing category',
    name: 'Timing category',
    type: '',
    width: 155,
  },
  {
    accessor: 'regTypeName',
    Header: 'Test option',
    name: 'Test option',
    type: '',
    width: 187,
  },
  {
    accessor: 'dateOfBirth',
    Header: 'Date of birth',
    name: 'Date of birth',
    type: 'number',
    width: 124,
  },
  {
    accessor: 'grade',
    Header: 'Grade',
    name: 'Grade',
    type: 'number',
    width: 64,
  },

  {
    accessor: 'gender',
    Header: 'Gender',
    name: 'Gender',
    type: '',
    width: 150,
  },
  {
    accessor: 'reportingHighSchoolCode',
    Header: 'Reporting HS code',
    name: 'Reporting HS code',
    type: 'number',
    width: 160,
  },
  {
    accessor: 'claimCode',
    Header: 'ACT student code',
    name: 'ACT student code',
    type: 'number',
    width: 157,
  },
  {
    accessor: 'orgName',
    Header: 'Org name',
    name: 'Org name',
    type: '',
    width: 406,
  },
];

const StudentsManagement = props => {
  /**
   *  react-router-dom hooks
   */
  const history = useHistory();
  const location = useLocation();

  /**
   * useContext()
   */
  const Text = useContext(TextContext);

  /**
   * useStore()
   */
  const [contractAdminInfoResponse, contractAdminInfoStore] = useStore('contractAdminInfo');
  const [entityDefResponse, entityDefStore] = useStore('entityDef');
  const [invitationStatusResponse] = useStore('invitationStatus');
  const [orgHierarchyResponse] = useStore('orgHierarchy');
  const [programsResponse] = useStore('programs');
  const [regTypesResponse, regTypesStore] = useStore('regTypes');
  const [studentsRecordsResponse, studentsRecordsStore] = useStore('studentsRecords');
  const [testingDatesResponse, testingDatesStore] = useStore('testingDates');
  const [timingsResponse, timingsStore] = useStore('timings');

  /**
   *  useState()
   */
  const [errorModal, setErrorModal] = useState({ display: false });
  const [headerCheckboxChecked, setHeaderCheckboxChecked] = useState(false);
  const [nonStickyColumns, setNonStickyColumns] = useState(initialColumns);
  const [notificationProps, setNotificationProps] = useState({ show: false });
  const [registrationModal, setRegistrationModal] = useState({ display: false });
  const [searchedData, setSearchedData] = useState(false);
  const [selectedRecords, setSelectedRecords] = useState({});
  const [studentEditModal, setStudentEditModal] = useState({ display: false });
  const [studentInfoModal, setStudentInfoModal] = useState({ display: false });
  const [unenrollModal, setUnenrollModal] = useState({ display: false });
  const [updateOptionsModal, setUpdateOptionsModal] = useState({ display: false });

  /**
   *  useSelector()
   */
  const hasStudentPermissions = useSelector(state => getPermission(state, 'now_reg_view'));
  const hasStudentFilesPermission = useSelector(state => getPermission(state, 'now_reg_files'));
  const hasUsersManagePermissions = useSelector(state => getPermission(state, 'now_user_manage'));

  /**
   *  useMemo()
   */
  const bulkActions = useMemo(
    () => [
      {
        handler: () => setRegistrationModal(prev => ({ ...prev, display: true })),
        label: Text.common.labels.studentsBulkAssignmentAction,
        mainOption: true,
      },
      {
        handler: () => setUpdateOptionsModal(prev => ({ ...prev, display: true })),
        label: Text.common.labels.studentsBulkUpdateAction,
      },
    ],
    [Text]
  );

  const currentOrg = useMemo(() => {
    if (!orgHierarchyResponse?.data) return {};
    return _.last(orgHierarchyResponse.data);
  }, [orgHierarchyResponse]);

  const {
    adminId,
    allowChildOrgsFlag,
    contractId,
    orgPartId,
    programId,
    testCenterFlag,
  } = currentOrg;

  const program = useMemo(() => {
    if (!programsResponse?.data) return {};
    return programsResponse.data.find(p => p.programId === programId);
  }, [programId, programsResponse]);

  const filterSections = useMemo(() => {
    const accommodationsOptions = getAccommodationOptions(program);
    const deliveryModeOptions =
      getEntityDef('deliveryMode')(entityDefResponse?.data)?.options || [];
    const gradeLimits = contractAdminInfoResponse?.data?.[0].gradesAllowed?.split(',');
    const gradeOptions =
      getEntityDef('grade')(entityDefResponse?.data)
        ?.options.filter(option => !gradeLimits || gradeLimits.includes(option.value))
        .sort((a, b) => parseInt(b.value, 10) - parseInt(a.value, 10)) || [];
    const regTypeOptions =
      regTypesResponse?.data?.map(({ code, name }) => ({ value: code, label: name })) || [];
    const testingDatesOptions = [
      {
        testingEvent: Text.features.roster.filters.registration.notAssigned,
        testingEventId: 'notAssigned',
      },
    ]
      .concat(testingDatesResponse?.data || [])
      .concat([
        {
          testingEvent: Text.features.roster.filters.notTesting,
          testingEventId: 'notTesting',
        },
      ]);
    const timingOptions =
      timingsResponse?.data?.map(({ code, name }) => ({ value: code, label: name })) || [];
    return [
      {
        name: 'testingDates',
        title: Text.features.roster.filters.eventAssignment.title,
        type: 'dropdown',
        options: testingDatesOptions,
        callParameter: 'filter',
        placeholder: Text.features.roster.filters.eventAssignment.select,
        label: 'testingEvent', //options property that will be used as label
        value: 'testingEventId', //options property that will be used as value
      },
      {
        name: 'regType',
        title: Text.features.roster.filters.testOption.title,
        type: 'checkbox',
        options: regTypeOptions,
        callParameter: 'regType',
      },
      {
        name: 'timingCategory',
        title: Text.features.roster.filters.timingCategory.title,
        type: 'checkbox',
        options: timingOptions,
        callParameter: 'timing',
      },
      {
        name: 'deliveryMode',
        title: Text.features.roster.filters.format.title,
        type: 'checkbox',
        options: deliveryModeOptions,
        callParameter: 'deliveryMode',
      },
      {
        name: 'accommodations',
        title: Text.features.roster.filters.accommodations.title,
        type: 'checkbox',
        options: accommodationsOptions,
        callParameter: 'accommodations',
      },
      {
        name: 'grades',
        title: Text.features.roster.filters.grade.title,
        type: 'dropdown',
        options: gradeOptions,
        callParameter: 'grade',
        placeholder: Text.features.roster.filters.grade.select,
      },
    ];
  }, [
    contractAdminInfoResponse,
    entityDefResponse,
    program,
    regTypesResponse,
    testingDatesResponse,
    Text,
    timingsResponse,
  ]);

  const hideTable = useMemo(() => allowChildOrgsFlag && !searchedData, [
    allowChildOrgsFlag,
    searchedData,
  ]);

  const inputList = useMemo(
    () => [
      {
        placeholder: 'Last name',
        maxLength: '30',
        type: 'text',
        name: SEARCH_PARAMS.lastName,
      },
      {
        placeholder: 'First name',
        maxLength: '30',
        type: 'text',
        name: SEARCH_PARAMS.firstName,
        disabled: true,
        tooltip: Text.features.roster.disabledTooltip,
      },
      {
        placeholder: 'State Student ID',
        maxLength: '20',
        type: 'text',
        name: SEARCH_PARAMS.studentId,
      },
    ],
    [Text.features.roster.disabledTooltip]
  );

  const totalCount = useMemo(() => studentsRecordsResponse?.meta?.totalCount, [
    studentsRecordsResponse,
  ]);

  /**
   *  useCallback()
   */
  const closeEditModal = useCallback(() => {
    setStudentEditModal({ display: false });
  }, []);

  const closeErrorModal = useCallback(() => {
    setErrorModal({ display: false });
  }, []);

  const closeInfoModal = useCallback(() => {
    setStudentInfoModal({ display: false });
  }, []);

  const closeNotification = useCallback(() => {
    setNotificationProps({ show: false });
  }, []);

  const closeUnenrollModal = useCallback(() => {
    setUnenrollModal({ display: false });
  }, []);

  const formatStudentRecords = useCallback(
    records =>
      records?.map(({ dateOfBirth, ...record }) => ({
        ...record,
        dateOfBirth: dateOfBirth ? moment(dateOfBirth).format('L') : null,
      })),
    []
  );

  const getEntityLabels = useCallback(
    student => {
      if (!entityDefResponse?.data) return {};
      const gender = entityDefResponse.data
        .find(x => x.code === 'gender')
        .options.find(x => x.value === student.gender?.toUpperCase())?.label;
      const grade = entityDefResponse.data
        .find(x => x.code === 'grade')
        .options.find(x => x.value === student.grade)?.label;
      const timingCategory = timingsResponse?.data
        ? timingsResponse.data.find(item => item.name === student.timing)?.name || ''
        : undefined;
      return {
        gender,
        grade,
        timingCategory,
      };
    },
    [entityDefResponse, timingsResponse]
  );

  const getStudentsRecords = useCallback(
    ({ filtersParams, limit = countArray[0], offset = 0, searchedValues = {} }) => {
      const searchParams = Object.entries(searchedValues).reduce(
        (acc, [key, value]) => ({ ...acc, [key]: value.trim().replace(/[^-a-zA-Z0-9.,' ]/g, '') }),
        {}
      );
      studentsRecordsStore.clear();
      studentsRecordsStore.fetch({
        limit,
        offset,
        orgPartId,
        ...searchParams,
        ...filtersParams,
      });
      setSearchedData(
        ['firstNameStartsWith', 'lastNameStartsWith', 'studentId'].some(
          key => searchParams[key]?.length
        )
      );
    },
    [orgPartId, studentsRecordsStore]
  );

  const closeRegistrationModal = useCallback(
    clearSelection => () => {
      setRegistrationModal({ display: false });
      if (clearSelection) {
        setSelectedRecords({});
        getStudentsRecords({ offset: studentsRecordsResponse?.meta?.offset });
      }
    },
    [getStudentsRecords, studentsRecordsResponse]
  );

  const closeUpdateOptionsModal = useCallback(
    clearSelection => () => {
      setUpdateOptionsModal({ display: false });
      if (clearSelection) {
        setSelectedRecords({});
        getStudentsRecords({ offset: studentsRecordsResponse?.meta?.offset });
      }
    },
    [getStudentsRecords, studentsRecordsResponse]
  );

  const handleDeselectAllStudents = useCallback(() => {
    setSelectedRecords({});
  }, []);

  const handleStudentSelection = useCallback((e, student) => {
    // If the record is already selected, deselect it. Otherwise, select it.
    setSelectedRecords(prev => ({
      ...prev,
      [student.regId]: prev[student.regId] ? undefined : student,
    }));
  }, []);

  const handleSelectAllStudents = useCallback(() => {
    setSelectedRecords(prev => ({
      ...prev,
      ...(studentsRecordsResponse?.data || []).reduce(
        (acc, student) => ({
          ...acc,
          [student.regId]: headerCheckboxChecked ? undefined : student,
        }),
        {}
      ),
    }));
    setHeaderCheckboxChecked(prev => !prev);
  }, [headerCheckboxChecked, studentsRecordsResponse]);

  const openEditModal = useCallback(
    (student = {}) => {
      closeInfoModal();
      const multipleRegistrations =
        studentsRecordsResponse?.data?.filter(
          record =>
            record.lastName === student.lastName &&
            record.externalStudentId === student.externalStudentId
        ).length > 1;
      setStudentEditModal({ display: true, multipleRegistrations, student });
    },
    [closeInfoModal, studentsRecordsResponse]
  );

  const openInfoModal = useCallback(student => {
    setStudentInfoModal({ display: true, student });
  }, []);

  const openUnenrollModal = useCallback(
    (regId = '') => {
      closeInfoModal();
      setUnenrollModal({ display: true, regId });
    },
    [closeInfoModal]
  );

  const displayEditStudentsNotification = useCallback(
    (editFlag = true) => {
      setNotificationProps({
        show: true,
        text: editFlag ? Text.messages.success.editStudent : Text.messages.success.addStudent,
        type: 'Success',
      });
      getStudentsRecords({});
    },
    [getStudentsRecords, Text.messages.success.addStudent, Text.messages.success.editStudent]
  );

  const formatTableData = useCallback(
    data =>
      _.pipe([
        _.map(student => ({ ...student, ...getEntityLabels(student) })),
        formatStudentRecords,
      ])(data),
    [getEntityLabels, formatStudentRecords]
  );

  const handleKeyDown = useCallback((e, student) => e.keyCode === 13 && openInfoModal(student), [
    openInfoModal,
  ]);

  const handleUnenrollModalSubmit = useCallback(() => {
    RosterServices.unenrollStudent({ id: parseInt(unenrollModal.regId) })
      .then(() => getStudentsRecords({}))
      .catch(error => setErrorModal({ display: true, message: error.response.data.message }));
    closeUnenrollModal();
  }, [closeUnenrollModal, getStudentsRecords, unenrollModal.regId]);

  const renderFirstColumn = useCallback(
    () =>
      [
        testCenterFlag && {
          accessor: 'id',
          Cell: ({ cell }) => {
            const checked = Boolean(
              Object.values(selectedRecords).find(
                student => student?.regId === cell.row.original.regId
              )
            );
            return (
              <Checkbox
                handleChange={e => handleStudentSelection(e, cell.row.original)}
                checked={checked}
              />
            );
          },
          type: 'checkbox',
          width: 40,
        },
        {
          accessor: 'lastName',
          Header: 'Student name',
          length: nonStickyColumns.length + 1,
          type: 'nameActionCell',
          width: 345,
          Cell: ({ cell }) => {
            return (
              <>
                <div
                  onClick={() => openInfoModal(cell.row.original)}
                  onKeyDown={e => handleKeyDown(e, cell.row.original)}
                  className="link"
                  tabIndex="0">
                  {`${cell.row.original.lastName}, ${cell.row.original.firstName} ${cell.row.original.middleName}`}
                </div>
                {cell.row.original.accomRosterFlag && (
                  <AccommodationIcon className={testCenterFlag ? 'middle-item' : ''} tabIndex="0" />
                )}
                <LeftButtons
                  currentOrg={currentOrg}
                  hasStudentFilesPermission={hasStudentFilesPermission}
                  hasUsersManagePermissions={hasUsersManagePermissions}
                  item={cell.row.original}
                  openEditModal={openEditModal}
                  openUnenrollModal={({ regId }) => openUnenrollModal(regId)}
                />
              </>
            );
          },
        },
      ].filter(Boolean),
    [
      currentOrg,
      handleKeyDown,
      handleStudentSelection,
      hasStudentFilesPermission,
      hasUsersManagePermissions,
      nonStickyColumns,
      openEditModal,
      openInfoModal,
      openUnenrollModal,
      selectedRecords,
      testCenterFlag,
    ]
  );

  const setTableColumns = useCallback(
    () => [
      {
        id: 'leftColumns',
        sticky: 'left',
        columns: renderFirstColumn(),
      },
      {
        id: 'rightColumns',
        columns: nonStickyColumns,
      },
    ],
    [nonStickyColumns, renderFirstColumn]
  );

  /**
   *  useEffect()
   */
  useEffect(() => contractAdminInfoStore.fetch({ adminId, contractId }), [
    adminId,
    contractAdminInfoStore,
    contractId,
  ]);

  useEffect(() => entityDefStore.fetch({ adminId }), [adminId, entityDefStore]);

  useEffect(() => regTypesStore.fetch({ adminId, contractId }), [
    adminId,
    contractId,
    regTypesStore,
  ]);

  useEffect(() => getStudentsRecords({}), [getStudentsRecords]);

  useEffect(() => testingDatesStore.fetch({ orgPartId }), [orgPartId, testingDatesStore]);

  useEffect(() => timingsStore.fetch({ orgPartId }), [orgPartId, timingsStore]);

  // If the location state indicates that the user's personal information was updated, display a notification to that effect
  useEffect(() => {
    if (!location.state?.showSuccessUpdateNotification) return;

    setNotificationProps({
      show: true,
      text: Text.messages.success.personalInformationUpdated,
      type: 'Success',
    });
    history.replace({
      state: {},
    });
  }, [history, location.state, Text.messages.success.personalInformationUpdated]);

  // If there was an error fetching the student records
  useEffect(() => {
    if (!studentsRecordsResponse?.error) return;

    setNotificationProps({
      show: true,
      text: Text.messages.error.generic,
      type: 'Error',
    });
  }, [studentsRecordsResponse, Text.messages.error.generic]);

  // If all of the students on the current page are selected, check the header checkbox
  useEffect(() => {
    if (!studentsRecordsResponse?.data) return;

    if (
      studentsRecordsResponse.data.length &&
      studentsRecordsResponse.data.every(student => selectedRecords[student.regId])
    ) {
      setHeaderCheckboxChecked(true);
    }
  }, [selectedRecords, studentsRecordsResponse]);

  // Update the location, as needed, based on user permissions
  useEffect(() => {
    if (hasStudentPermissions || !invitationStatusResponse?.data || !orgPartId) return;

    if (allowChildOrgsFlag) return history.push(`/students/organization/orgPartId/${orgPartId}`);
    if (invitationStatusResponse.data.status !== 'active')
      return history.replace(`/students/overview/orgPartId/${orgPartId}`);
    if (location.pathname.includes('organization'))
      return history.replace(`/students/studentstable/orgPartId/${orgPartId}`);
  }, [
    allowChildOrgsFlag,
    hasStudentPermissions,
    history,
    invitationStatusResponse,
    location.pathname,
    orgPartId,
  ]);

  // Close notifications after 5 seconds
  useEffect(() => {
    if (!notificationProps.show) return;

    closeUpdateSuccessNotificationTimer = setTimeout(() => {
      setNotificationProps({ show: false });
      clearTimeout(closeUpdateSuccessNotificationTimer);
    }, 5000);
  }, [history, notificationProps.show]);

  /**
   *  Render
   */
  if (!hasStudentPermissions) return null;

  return (
    <>
      <Helmet>
        <title>Registration Student Search - ACT-Now</title>
      </Helmet>
      <DataPanelContainer
        {...props}
        addNewItemIcon={AddIcon}
        addNewItemLabel={Text.features.roster.addStudentLabel}
        bulkActions={bulkActions}
        deselectAllActionHandle={handleDeselectAllStudents}
        filters={!allowChildOrgsFlag ? filterSections : []}
        getRecords={getStudentsRecords}
        handleAddNewItem={openEditModal}
        handleHeaderCheckboxChange={handleSelectAllStudents}
        hasBulkAction={testCenterFlag}
        hideAddNew={allowChildOrgsFlag}
        initialColumns={initialColumns}
        initialSearch={initialSearch}
        inputList={inputList}
        isCheckboxSelected={handleStudentSelection}
        isHeaderCheckboxSelected={headerCheckboxChecked}
        loadingData={studentsRecordsResponse?.loading}
        nonStikyColumns={nonStickyColumns}
        offset={studentsRecordsResponse?.meta?.offset}
        orgPartId={orgPartId}
        searchInfoBanner={hideTable && Text.messages.info.mustSearchFirst}
        searchLabel={Text.common.labels.searchBy}
        searchMultiInput={true}
        searchParams={SEARCH_PARAMS}
        selectAllActionHandle={handleSelectAllStudents}
        selectionsNumber={Object.values(selectedRecords).filter(Boolean).length}
        setColumns={setNonStickyColumns}
        setTableColumns={setTableColumns}
        stickyColumnsLength={0}
        tableData={!hideTable && formatTableData(studentsRecordsResponse?.data || [])}
        totalCount={!hideTable ? totalCount : 0}
      />
      {notificationProps.show && (
        <Notification
          handleClose={closeNotification}
          text={notificationProps.text}
          type={notificationProps.type}
        />
      )}
      {studentEditModal.display && (
        <EditStudentsModal
          closeModal={closeEditModal}
          currentOrg={currentOrg}
          multipleRegistrations={studentEditModal.multipleRegistrations}
          showNotification={displayEditStudentsNotification}
          student={studentEditModal.student}
        />
      )}
      {studentInfoModal.display && (
        <StudentsInfoModal
          closeModal={closeInfoModal}
          currentOrg={currentOrg}
          openEditModal={openEditModal}
          openUnenrollModal={openUnenrollModal}
          student={studentInfoModal.student}
        />
      )}
      {unenrollModal.display && (
        <AlertModal
          bodyTitle="Are you sure? This action will delete the student record and any event assignment."
          buttonAction={handleUnenrollModalSubmit}
          buttonText={Text.features.roster.modal.unenroll}
          className="ConfirmationModal__Container"
          close={closeUnenrollModal}
          secondaryButton={{
            text: 'Back',
            action: closeUnenrollModal,
            redButton: false,
          }}
          show={true}
          showButton={true}
          title=""
        />
      )}
      {registrationModal.display && (
        <RegisterStudentsModal
          closeModal={closeRegistrationModal}
          selection={selectedRecords}
          setNotificationProps={setNotificationProps}
        />
      )}
      {updateOptionsModal.display && (
        <UpdateRegistrationOptionsModal
          closeModal={closeUpdateOptionsModal}
          currentOrg={currentOrg}
          data={entityDefResponse?.data}
          selection={selectedRecords}
          setNotificationProps={setNotificationProps}
        />
      )}
      {errorModal.display && (
        <AlertModal
          body={[
            <>
              <p className="error__Icon">
                <ErrorIcon />
              </p>
              <p className="error_Description">{errorModal.message}</p>
            </>,
          ]}
          className="errorModal__Container"
          close={closeErrorModal}
          show={true}
          showButton={true}
        />
      )}
    </>
  );
};

export default StudentsManagement;

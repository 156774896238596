import React, { useMemo } from 'react';
import propTypes from 'prop-types';

import { ReactComponent as ErrorIcon } from '../../assets/svgs/alert2.svg';
import { ReactComponent as InfoIcon } from '../../assets/svgs/info.svg';
import { ReactComponent as SuccessIcon } from '../../assets/svgs/success.svg';

const Alert = ({
  bold,
  className = '',
  hasFullWidth,
  message = 'Oops, something went wrong.',
  noBkg,
  secondText,
  secondTextSameParagraph = false,
  type = 'error',
}) => {
  /**
   * useMemo()
   */
  const fullWidth = useMemo(() => (hasFullWidth ? 'full__Width' : ''), [hasFullWidth]);

  const icon = useMemo(() => {
    switch (type) {
      case 'error':
      case 'warning':
        return <ErrorIcon className="alert__Icon" />;
      case 'info':
        return <InfoIcon className="alert__Icon" />;
      default:
        return <SuccessIcon className="alert__Icon" />;
    }
  }, [type]);

  /**
   * render
   */
  return (
    <div className="alert__Large__Container">
      <div className={`alert_Border ${noBkg ? 'no-background' : type}`}></div>
      <div className={`alert__Container ${noBkg ? 'no-background' : type} ${fullWidth}`}>
        <div className="alert__icon__container">{icon}</div>
        <div className={`alert__TextContainer ${className} `}>
          <p className={`alert__Text ${type} ${bold ? 'alert__BoldText' : ''} `}>
            <span dangerouslySetInnerHTML={{ __html: message }} />
            {secondText && secondTextSameParagraph && (
              <div className="alert__Text">{secondText}</div>
            )}
          </p>
          {secondText && !secondTextSameParagraph && (
            <div className={`alert__Text ${type}`}>{secondText}</div>
          )}
        </div>
      </div>
    </div>
  );
};

Alert.displayName = 'Alert';

Alert.propTypes = {
  className: propTypes.string,
  message: propTypes.string,
  secondTextSameParagraph: propTypes.bool,
  type: propTypes.string,
};

export default Alert;

import React, { useContext, useEffect, useState } from 'react';

import './ShippingDetailsModal.scss';
import Modal from '../../../components/modal/AlertModal';
import Stepper from '../../../components/stepper/Stepper';
import ShippingSteps from './ShippingSteps';
import { OrgManagementServices } from '../../../services';
import _ from 'lodash/fp';
import Validator from 'email-validator';
import ValidationHelper from '../../../services/helpers/validationHelper';
import Radio from '../../../components/inputs/Radio';
import Alert from '../../../components/alerts/Alert';
import { Typography } from '@material-ui/core';
import TextContext from '../../../core/providers/TextProvider';

const stepsTotal = [
  {
    id: 1,
    title: 'Response',
  },
  {
    id: 2,
    title: 'Receipt dates',
  },
  {
    id: 3,
    title: 'Primary test coordinator',
  },
  {
    id: 4,
    title: 'Shipping contact',
  },
  {
    id: 5,
    title: 'Shipping address',
  },
];

const buttons = {
  5: 'Use selected address',
  6: 'Retry',
};

const ShippingDetailsModal = ({
  closeModal,
  getInvitationStatus,
  goBack,
  handleNotificationModal,
  isDeadlineExpired,
  isEdit,
  orgPart,
  showModal,
  testDates,
}) => {
  /**
   * useContext()
   */
  const Text = useContext(TextContext);

  const [step, setStep] = useState(1);
  const [testValue, setTestValue] = useState({});
  const [receiptValue, setReceiptValue] = useState({});
  const [details, setDetails] = useState({});
  const [shippingDetails, setShippingDetails] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const [shippingAddress, setShippingAddress] = useState({});
  const [testErrors, setTestErrors] = useState({});
  const [detailsErrors, setDetailsErrors] = useState({});
  const [suggestedAddress, setSuggestedAddress] = useState({});
  const [addressErrors, setAddressErrors] = useState({});
  const [selectedOption, setSelectedOption] = useState('suggested');
  const [newOrgPart, setNewOrgPart] = useState({});
  const [processedOrder, setProcessedOrder] = useState(0);
  const [eventAutoAssignValue, setEventAutoAssignValue] = useState(false);
  const [showAutoAssignModal, setShowAutoAssignModal] = useState(false);

  const validateInp = details => {
    const student = {
      firstName: details.firstName,
      lastName: details.lastName,
      phone: details.phone,
      email: details.email,
    };
    const errors = ValidationHelper.validate(student, {
      lastName: { personName: true, required: true, maxLength: 35 },
      firstName: { personName: true, required: true, maxLength: 35 },
      phone: { numeric: true, required: true, minLength: 10, maxLength: 10 },
      extension: { numeric: true, maxLength: 10 },
      email: { required: true },
    });

    if (!ValidationHelper.personName(details.lastName)) {
      errors.lastName = `${Text.messages.error.onlyAlphanumericAndSymbols}`;
    }
    if (!ValidationHelper.personName(details.firstName)) {
      errors.firstName = `${Text.messages.error.onlyAlphanumericAndSymbols}`;
    }
    if (details.extension && !ValidationHelper.numeric(details.extension)) {
      errors.extension = `${Text.messages.error.onlyNumeric}`;
    }

    if (details.phone && !ValidationHelper.numeric(details.phone)) {
      errors.phone = `${Text.messages.error.onlyNumeric}`;
    }
    if (details.email) {
      const isEmailValid = Validator.validate(details.email);

      if (!isEmailValid) {
        errors.email = `${Text.messages.error.emailInvalid}`;
      }
    }

    if (details.phone && details.phone.length < 10 && !errors.phone) {
      errors.phone = `${Text.messages.error.firstPhoneInvalid}`;
    }

    setDetailsErrors(errors);
    return errors;
  };

  const validateAddress = address => {
    const addressDetails = {
      address1: address.address1,
      city: address.city,
      stateCode: address.stateCode,
      postalCode: address.postalCode,
    };

    const errors = ValidationHelper.validate(addressDetails, {
      address1: { required: true, maxLength: 60 },
      address2: { maxLength: 60 },
      city: { required: true, maxLength: 30 },
      stateCode: { required: true, minLength: 2, maxLength: 2 },
      postalCode: { required: true, maxLength: 9 },
    });

    if (address.address1 && !ValidationHelper.alphaNumericWithSpaces(address.address1)) {
      errors.address1 = `${Text.messages.error.onlyAlphanumeric}`;
    }

    if (address.address2 && !ValidationHelper.alphaNumericWithSpaces(address.address2)) {
      errors.address2 = `${Text.messages.error.onlyAlphanumeric}`;
    }

    if (address.city && !ValidationHelper.alphaNumericWithSpaces(address.city)) {
      errors.city = `${Text.messages.error.onlyAlphanumeric}`;
    }

    if (address.stateCode && !ValidationHelper.alpha(address.stateCode)) {
      errors.stateCode = `${Text.messages.error.onlyAlpha}`;
    }

    if (address.postalCode && !ValidationHelper.numeric(address.postalCode)) {
      errors.postalCode = `${Text.messages.error.onlyNumeric}`;
    }

    if (address.postalCode && address.postalCode.length !== 5) {
      errors.postalCode = `${Text.messages.error.zipCodeLength}`;
    }

    setAddressErrors(errors);
    return errors;
  };

  const handleStep1Next = () => {
    let errors = {};
    if (_.isEmpty(testValue)) {
      errors = { ...errors, testValue: true };
    }
    if (_.isEmpty(receiptValue)) {
      errors = { ...errors, receiptValue: true };
    }
    if (!_.isEmpty(errors)) {
      setTestErrors(errors);
    } else {
      setStep(step + 1);
    }
    if (eventAutoAssignValue) {
      setShowAutoAssignModal(true);
      setTimeout(() => {
        setShowAutoAssignModal(false);
      }, 3000);
    }
  };

  const handleStep2Next = details => {
    const errors = validateInp(details);
    if (_.isEmpty(errors)) {
      setStep(step + 1);
    }
  };

  const handleNotification = () => {
    handleNotificationModal(true);
    setTimeout(() => {
      handleNotificationModal(false);
    }, 3000);
  };

  const respondToInvite = async body => {
    await OrgManagementServices.respondToInvite(body);
    OrgManagementServices.initializeTechnicalReadiness({ orgPartId: orgPart.orgPartId });
    getInvitationStatus();
    setStep(isDeadlineExpired ? 2 : 1);
    closeModal();
    handleNotification();
  };

  const suggestAddress = async body => {
    try {
      const response = await OrgManagementServices.validateAddress(shippingAddress);

      if (response.data.exactMatch) {
        try {
          await respondToInvite(body);
        } catch {
          setStep(6);
        }
      } else {
        setSuggestedAddress(response.data.suggestedAddress);
        setStep(step + 1);
      }
    } catch (err) {
      const match = err.response.data.message?.match(/PO Box (?<field>address\d)/);
      if (match) {
        setAddressErrors({ [match.groups.field]: Text.messages.error.addressPOBox });
      } else {
        try {
          await respondToInvite(body);
        } catch {
          setStep(6);
        }
      }
    }
  };

  const handleStep4Next = async () => {
    const body = {
      status: 'active',
      tcFirstName: details.firstName,
      tcLastName: details.lastName,
      tcPhone: details.phone,
      tcPhoneExt: details.extension,
      tcEmailAddress: details.email,
      shipFirstName: shippingDetails.firstName,
      shipLastName: shippingDetails.lastName,
      shipPhone: shippingDetails.phone,
      shipPhoneExt: shippingDetails.extension,
      shipEmailAddress: shippingDetails.email,
      shipAddress1: shippingAddress.address1,
      shipAddress2: shippingAddress.address2,
      shipCity: shippingAddress.city,
      shipStateCode: shippingAddress.stateCode,
      shipPostalCode: shippingAddress.postalCode,
      testingEventReceiptWindowId: receiptValue.id,
      id: orgPart.orgPartId,
      adminId: orgPart.adminId,
      orgId: orgPart.orgId,
      eventAutoAssign: eventAutoAssignValue,
    };

    setNewOrgPart(body);

    const errors = validateAddress(shippingAddress);
    if (_.isEmpty(errors)) {
      await suggestAddress(body);
    }
  };

  const handleStep5Next = async () => {
    const address = selectedOption === 'suggested' ? suggestedAddress : shippingAddress;
    const body = {
      ...newOrgPart,
      shipAddress1: address.address1,
      shipAddress2: address.address2,
      shipCity: address.city,
      shipStateCode: address.stateCode,
      shipPostalCode: address.postalCode,
    };
    setNewOrgPart(body);
    try {
      await respondToInvite(body);
    } catch {
      setStep(step + 1);
    }
  };

  const handleStep6Next = async () => {
    await respondToInvite(newOrgPart);
  };

  const handleNext = () => {
    switch (step) {
      case 2:
        return handleStep2Next(details);
      case 3:
        return handleStep2Next(shippingDetails);
      case 4:
        return handleStep4Next();
      case 5:
        return handleStep5Next();
      case 6:
        return handleStep6Next();
      default:
        return handleStep1Next();
    }
  };

  const clearData = () => {
    setStep(isDeadlineExpired ? 2 : 1);
    setDetails(isEdit ? details : {});
    setShippingDetails(isEdit ? shippingDetails : {});
    setShippingAddress(isEdit ? shippingAddress : {});
    setIsChecked(false);
    setTestValue(isEdit ? testValue : {});
    setReceiptValue(isEdit ? receiptValue : {});
    setEventAutoAssignValue(isEdit ? eventAutoAssignValue : false);
  };

  const handleClose = () => {
    clearData();
    closeModal();
  };

  const handleBack = () => {
    if (isDeadlineExpired && step === 2) {
      closeModal();
    } else {
      if (step > 1) {
        if (step !== 6) {
          setStep(step - 1);
        } else {
          const steps = _.isEmpty(suggestedAddress) ? 2 : 1;
          setStep(step - steps);
        }
      } else {
        goBack();
        clearData();
      }
    }
  };

  const handleRadioChange = e => {
    setSelectedOption(e.target.id);
  };

  useEffect(() => {
    if (orgPart) {
      setEventAutoAssignValue(orgPart.eventAutoAssign === '1');
      setDetails({
        firstName: orgPart.tcFirstName,
        lastName: orgPart.tcLastName,
        phone: orgPart.tcPhone,
        extension: orgPart.tcPhoneExt,
        email: orgPart.tcEmailAddress,
      });
      setShippingDetails({
        firstName: orgPart.shipFirstName,
        lastName: orgPart.shipLastName,
        phone: orgPart.shipPhone,
        extension: orgPart.shipPhoneExt,
        email: orgPart.shipEmailAddress,
      });
      (async () => {
        const shippingDetails = await OrgManagementServices.getShippingDetails(orgPart.orgPartId);

        setShippingAddress({
          address1: shippingDetails.data.address1,
          address2: shippingDetails.data.address2,
          city: shippingDetails.data.city,
          stateCode: shippingDetails.data.stateCode,
          postalCode: shippingDetails.data.postalCode,
          isDistrictShip: shippingDetails.data.isDistrictShip,
        });
      })();
    }
  }, [orgPart]);

  useEffect(() => {
    (async () => {
      if (isDeadlineExpired) {
        try {
          const response = await OrgManagementServices.getProcessedOrder(orgPart.orgPartId);
          setProcessedOrder(response.data.count);
          setStep(2);
        } catch {
          setProcessedOrder(0);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeadlineExpired]);

  return (
    <Modal
      show={showModal}
      title={Text.features.orgManagement.participation.respond}
      close={handleClose}
      showButton
      buttonText={step < 5 ? 'Continue' : buttons[step]}
      className="shipping-details-modal"
      buttonAction={handleNext}
      secondaryButton={{
        text: step === 5 ? 'Edit entered address' : 'Back',
        action: () => handleBack(),
        redButton: false,
      }}>
      <div className="shipping-details-modal-container">
        {Boolean(step) && step < 5 && (
          <>
            <div className="shipping-details-content">
              <ShippingSteps
                testValue={testValue}
                setTestValue={setTestValue}
                setReceiptValue={setReceiptValue}
                receiptValue={receiptValue}
                step={step}
                details={details}
                setDetails={setDetails}
                setShippingDetails={setShippingDetails}
                shippingDetails={shippingDetails}
                isChecked={isChecked}
                setIsChecked={setIsChecked}
                setShippingAddress={setShippingAddress}
                shippingAddress={shippingAddress}
                testDates={testDates}
                testErrors={testErrors}
                setTestErrors={setTestErrors}
                detailsErrors={detailsErrors}
                setDetailsErrors={setDetailsErrors}
                addressErrors={addressErrors}
                setAddressErrors={setAddressErrors}
                orgPart={orgPart}
                isDeadlineExpired={isDeadlineExpired}
                processedOrder={processedOrder}
                eventAutoAssignValue={eventAutoAssignValue}
                setEventAutoAssignValue={setEventAutoAssignValue}
              />
            </div>
            <div className={`stepper-container ${isDeadlineExpired ? 'expired' : ''}`}>
              <Stepper stepsTotal={stepsTotal} current={step} />
            </div>
          </>
        )}
      </div>
      {step === 5 && (
        <div className="radio-container">
          <h4 className="steps-title">{Text.features.orgManagement.participation.confirm}</h4>
          <Radio
            items={[
              {
                id: 'suggested',
                displayText: 'Suggested address',
                description: `${suggestedAddress.address1}, ${
                  suggestedAddress.address2 ? `${suggestedAddress.address2},` : ''
                } ${suggestedAddress.city}, ${suggestedAddress.stateCode}, ${
                  suggestedAddress.postalCode
                } `,
                className: selectedOption === 'suggested' && 'active',
              },
              {
                id: 'entered',
                displayText: 'Entered address',
                description: `${shippingAddress.address1}, ${
                  shippingAddress.address2 ? `${shippingAddress.address2},` : ''
                } ${shippingAddress.city}, ${shippingAddress.stateCode}, ${
                  shippingAddress.postalCode
                } `,
                className: selectedOption === 'entered' && 'active',
              },
            ]}
            handleDateSelection={handleRadioChange}
            defaultOption={selectedOption}
            hasDescription
          />
        </div>
      )}
      {step === 6 && (
        <div>
          <h4 className="steps-title">{Text.features.orgManagement.participation.errorSummary}</h4>
          <Alert type="error" message={Text.messages.error.submitAgain} />
        </div>
      )}
      <Modal show={showAutoAssignModal}>
        <Typography variant="h5" className="modal-title">
          {Text.features.orgManagement.participation.modal.confirmEventAutoAssign}
          {orgPart?.orgPartAddEndDate}
        </Typography>
      </Modal>
    </Modal>
  );
};
export default ShippingDetailsModal;

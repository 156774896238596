import React from 'react';
import Alert from '../../../../components/alerts/Alert';
import _ from 'lodash/fp';
import * as Text from '../../../../core/i18n/text';
import './OrderDetailsModal.scss';
import moment from 'moment';

const GeneralDetailsComponent = ({ orderDetails, errorGeneralDetailsNotification }) => {
  return !_.isEmpty(orderDetails) ? (
    <>
      <div className="info-container">
        <div className="info-list">
          <strong>{Text.features.testManagement.testEvents.labels.orderedDate}</strong>
          {orderDetails.dateOrdered ? moment(orderDetails.dateOrdered).format('MM/DD/YYYY') : '-'}
        </div>
        <div className="info-list">
          <strong>{Text.features.testManagement.testEvents.labels.orderType}</strong>
          {_.capitalize(orderDetails.type)}
        </div>
      </div>
      <div className="info-container">
        <div>
          <h5>{Text.features.testManagement.testEvents.labels.contactInformation}</h5>
          <div className="info-list">
            <ul className="labels">
              <li>{Text.features.testManagement.testEvents.labels.contactName}</li>
              <li>{Text.features.testManagement.testEvents.labels.phone}</li>
              <li>{Text.features.testManagement.testEvents.labels.extension}</li>
            </ul>
            <ul className="values">
              <li>{orderDetails.contactInfo?.name || '-'}</li>
              <li>{orderDetails.contactInfo?.phoneNumber || '-'}</li>
              <li>{orderDetails.contactInfo?.extensionNumber || '-'}</li>
            </ul>
          </div>
        </div>

        <div>
          <h5>{Text.features.testManagement.testEvents.labels.shippingAddress}</h5>
          <div className="info-list">
            <ul className="labels">
              <li>{Text.features.testManagement.testEvents.labels.address}</li>
              <li>{Text.features.testManagement.testEvents.labels.city}</li>
              <li>{Text.features.testManagement.testEvents.labels.state}</li>
              <li>{Text.features.testManagement.testEvents.labels.zip}</li>
            </ul>
            <ul className="values">
              <li>{orderDetails.address?.address || '-'}</li>
              <li>{orderDetails.address?.city || '-'}</li>
              <li>{orderDetails.address?.state || '-'}</li>
              <li>{orderDetails.address?.zip || '-'}</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  ) : errorGeneralDetailsNotification ? (
    <Alert type="error" message={Text.messages.error.generic} />
  ) : (
    <Alert type="info" message={Text.features.testManagement.testEvents.messages.noDetails} />
  );
};

export default GeneralDetailsComponent;

const TOGGLE_SIDEBAR = 'nav/TOGGLE_SIDEBAR';
const SET_LAST_ACTIVE = 'nav/SET_LAST_ACTIVE';

function toggleSidebarAction(payload) {
  return {
    type: TOGGLE_SIDEBAR,
    payload,
  };
}

export function toggleSidebar(payload) {
  return toggleSidebarAction(payload);
}

function lastActiveAction(payload) {
  return {
    type: SET_LAST_ACTIVE,
    payload,
  };
}

export function setLastActive(payload) {
  return lastActiveAction(payload);
}

const initialState = {
  isOpen: null,
  lastActive: 'home',
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return {
        ...state,
        isOpen: action.payload,
      };
    case SET_LAST_ACTIVE:
      return {
        ...state,
        lastActive: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;

import React, { useCallback, useContext, useEffect, useState, useRef } from 'react';
import _ from 'lodash/fp';

import { Alert, Loader } from '../../../components';
import AlertModal from '../../../components/modal/AlertModal';
import RadioControl from '../../../components/inputs/Radio';
import TextArea from '../../../components/forms/TextArea';

import { OrgManagementServices } from '../../../services';

import TextContext from '../../../core/providers/TextProvider';

import './BulkNotificationsModal.scss';

const BulkNotificationsModal = ({ closeModal, handleNotificationShow, orgPartId }) => {
  /**
   * useContext()
   */
  const Text = useContext(TextContext);

  /**
   * useState()
   */
  const [error, setError] = useState(false);
  const [note, setNote] = useState('');
  const [notification, setNotification] = useState({});
  const [notificationOptions, setNotificationOptions] = useState([]);
  const [notificationTypes, setNotificationTypes] = useState({});

  const modalContentRef = useRef(null);

  /**
   * useCallback()
   */
  const handleInputChange = useCallback(
    e => {
      setNote('');
      setNotification(notificationTypes.find(({ code }) => code === e.target.id));
    },
    [notificationTypes]
  );

  const handleTextAreaChange = useCallback(
    e => {
      e.persist();
      setNote(e.target.value);
      setNotification(prev => ({
        ...prev,
        template: notificationTypes
          .find(({ code }) => code === prev.code)
          .template.replace('ADDITIONAL_TEXT', e.target.value),
      }));
    },
    [notificationTypes]
  );

  const replaceInTemplate = useCallback(
    (replaced, replacedWith) =>
      _.map(obj => ({
        ...obj,
        template: obj.template.replaceAll(replaced, replacedWith),
      })),
    []
  );

  /**
   * useEffect()
   */
  useEffect(() => {
    OrgManagementServices.getBulkNotification().then(response => {
      const types = _.pipe([
        replaceInTemplate(/{{|}}/g, ''),
        replaceInTemplate('href="NOW_LOGIN_URL"', `href="${window.location.origin}/login"`),
        replaceInTemplate('href="NOW_REGISTER_URL"', `href="${window.location.origin}/register"`),
      ])(response.data);
      setNotificationTypes(types);
      setNotification(types[0]);

      const options = response.data.map(({ code, name }) => ({ id: code, displayText: name }));
      setNotificationOptions(options);
    });
  }, [replaceInTemplate]);

  useEffect(() => {
    if (!notification.code) return;
    setError(false);
    if (modalContentRef.current) modalContentRef.current.scrollIntoView();
  }, [notification.code, setError]);

  const sendNotification = useCallback(() => {
    setError(false);
    try {
      OrgManagementServices.sendBulkNotification({
        additionalText: note,
        code: notification.code,
        orgPartId,
      }).then(() => {
        handleNotificationShow();
        closeModal();
      });
    } catch (e) {
      setError(true);
    }
  }, [closeModal, handleNotificationShow, note, notification, orgPartId]);

  const handleClearTextArea = useCallback(() => {
    setNotification(prev => ({
      ...prev,
      template: notificationTypes.find(({ code }) => code === prev.code).template,
    }));
    setNote('');
  }, [notificationTypes]);

  return (
    <AlertModal
      buttonAction={sendNotification}
      buttonLoading={false}
      buttonText={Text.features.orgManagement.bulkNotification.sendNotification}
      className="bulk_notifications_modal"
      close={() => closeModal()}
      note={
        <div>
          {error && (
            <Alert type="error" message={Text.features.messages.error.generic} hasFullWidth />
          )}
          <div className="notification">
            {Text.features.orgManagement.bulkNotification.notificationType}
          </div>
          {!_.isEmpty(notification) && (
            <div>
              <RadioControl
                defaultOption={notification.code}
                disableOption=""
                handleDateSelection={handleInputChange}
                items={notificationOptions}
              />
              <p className="aditional_text">
                {Text.features.orgManagement.bulkNotification.aditionalText}
                <span> {Text.features.orgManagement.bulkNotification.optional}</span>
              </p>
              <TextArea
                handleTextAreaChange={handleTextAreaChange}
                maxLength="500"
                nrCharactes={note.length}
                onClear={handleClearTextArea}
                placeHolder=""
                showClear={note.length > 0}
                value={note}
              />
              <div className="notification preview">
                {Text.features.orgManagement.bulkNotification.notificationPreview}
              </div>
            </div>
          )}
        </div>
      }
      secondaryButton={{
        action: () => closeModal(),
        redButton: false,
        text: 'Cancel',
      }}
      show={true}
      showButton={true}
      title={Text.features.orgManagement.bulkNotification.modalTitle}>
      {!_.isEmpty(notification) ? (
        <>
          <div className="modal_content" ref={modalContentRef}>
            <div dangerouslySetInnerHTML={{ __html: notification.template }}></div>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </AlertModal>
  );
};

export default BulkNotificationsModal;

import React from 'react';
import propTypes from 'prop-types';
import './SubNavigation.scss';

const SubNavigation = props => {
  const { items, activeItem, handleClick, id, propToDisplay, formatTextToDisplay } = props;

  return (
    <div>
      <div className="subNavigation__Container">
        {items.map(item => {
          return (
            <div
              key={item[id]}
              id={item[id]}
              className={`subNavigation__Item ${item[id] === activeItem ? 'active' : ''}`}
              onClick={handleClick}>
              {formatTextToDisplay ? formatTextToDisplay(item) : item[propToDisplay]}
              <div className="borderBottom"></div>
              {item[id] === Number(activeItem) && <div className="noBorder"></div>}
            </div>
          );
        })}
      </div>
      <hr className="tabs__Divider" />
    </div>
  );
};

SubNavigation.prototype = {
  items: propTypes.array,
  activeItem: propTypes.oneOfType([propTypes.string, propTypes.number]),
  handleClick: propTypes.func,
  id: propTypes.string,
  propToDisplay: propTypes.string,
  formatTextToDisplay: propTypes.func,
};

SubNavigation.defaultProps = {
  id: 'id',
  propToDisplay: 'text',
};

export default SubNavigation;

import _ from 'lodash/fp';
import { createDispatchFunction } from '../../../services/helpers/stateHelper';
import { NotificationServices } from '../../../services';

import * as types from './types';

function getNotificationsAction(payload) {
  return {
    type: types.GET_NOTIFICATIONS,
    payload,
  };
}

function getNotificationsByStatusAction(payload) {
  return {
    type: types.GET_NOTIFICATIONS_BY_STATUS,
    payload,
  };
}

function readNotificationAction(payload) {
  return {
    type: types.READ_NOTIFICATION,
    payload,
  };
}

function getNotViewedNotificationsCountAction(payload) {
  return {
    type: types.GET_NOT_VIEWED_NOTIFICATIONS_COUNT,
    payload,
  };
}

function getViewedNotificationsCountAction(payload) {
  return {
    type: types.GET_VIEWED_NOTIFICATIONS_COUNT,
    payload,
  };
}

export function getNotifications(data) {
  return createDispatchFunction(
    _.partial(NotificationServices.getNotifications)([data]),
    getNotificationsAction
  );
}

export function getNotificationsByStatus(data) {
  return createDispatchFunction(
    _.partial(NotificationServices.getNotificationsByStatus)([data]),
    getNotificationsByStatusAction
  );
}

export function readNotification(data) {
  return createDispatchFunction(
    _.partial(NotificationServices.readNotification)([data]),
    readNotificationAction
  );
}

export function getNotViewedNotificationsCount(userId) {
  return createDispatchFunction(
    _.partial(NotificationServices.getNotificationsCount)([userId, 'NOT_VIEWED']),
    getNotViewedNotificationsCountAction
  );
}

export function getViewedNotificationsCount(userId) {
  return createDispatchFunction(
    _.partial(NotificationServices.getNotificationsCount)([userId, 'VIEWED']),
    getViewedNotificationsCountAction
  );
}

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { clickables as text } from '../../core/i18n/text';
import { getPermission } from '../../core/duck/selectors';

import { ReactComponent as CarotIcon } from '../../assets/svgs/carot.svg';
import { ReactComponent as HomeIcon } from '../../assets/svgs/newHomeIcon.svg';
import { ReactComponent as OrgManagementIcon } from '../../assets/svgs/orgManagementSmall.svg';
import { ReactComponent as StudentIcon } from '../../assets/svgs/newRosterIcon.svg';
import { ReactComponent as EventManageIcon } from '../../assets/svgs/newEventManageIcon.svg';
import { ReactComponent as ContractIcon } from '../../assets/svgs/contractSmallIcon.svg';
import { ReactComponent as ScheduleIcon } from '../../assets/svgs/scheduleNavIcon.svg';

import { actions as coreActions } from '../../core/duck';
import _ from 'lodash/fp';

class SidebarNav extends React.Component {
  constructor(props) {
    super(props);
    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.state = {
      isSidebarOpen: false,
      orgPartId: '',
    };
  }

  componentDidMount() {
    if (!this.props.programs) {
      this.props.actions.getUserPrograms();
    }
    this.updateUI(JSON.parse(window.localStorage.getItem('isSidebarOpen')));
    if (window.sessionStorage.getItem('mainSelector')) {
      let sessionSelection = JSON.parse(window.sessionStorage.getItem('mainSelector'));
      const orgPartId = sessionSelection.org.value.value;
      if (sessionSelection.org.value && !this.state.orgPartId) {
        this.setState({
          orgPartId: orgPartId,
        });
      }
    }
  }

  updateUI(isOpen) {
    this.setState({ isSidebarOpen: isOpen });
    if (isOpen == null) {
      return;
    }
    if (isOpen) {
      if (document.querySelector('.rotating-right')) {
        document.querySelector('.icon__item__close').classList.remove('rotating-right');
      }
      if (document.querySelector('.close')) {
        document.querySelector('.sidebar__Container').classList.remove('close');
      }
      if (document.querySelector('.despand')) {
        document.querySelectorAll('.icon_background').forEach(x => x.classList.remove('despand'));
      }
      document.querySelector('.icon__item__close').classList.add('rotating-left');
      document.querySelector('.sidebar__Container').classList.add('open');
      document.querySelectorAll('.icon_background').forEach(x => x.classList.add('expand'));
    } else {
      if (document.querySelector('.open')) {
        document.querySelector('.sidebar__Container').classList.remove('open');
      }
      document.querySelector('.icon__item__close').classList.add('rotating-right');
      document.querySelector('.sidebar__Container').classList.add('close');
      document.querySelectorAll('.icon_background').forEach(x => x.classList.add('despand'));
    }
  }

  checkOrg = () => {
    if (this.props.orgHierarchy?.data) {
      return this.props.orgHierarchy.data[this.props.orgHierarchy.data.length - 1].type;
    }
    return '';
  };

  toggleSidebar = () => {
    const isOpen = JSON.parse(window.localStorage.getItem('isSidebarOpen'));
    window.localStorage.setItem('isSidebarOpen', !isOpen);
    this.updateUI(!isOpen);
  };

  render() {
    const { active } = this.props;
    const orgPartId = this.state.orgPartId
      ? this.state.orgPartId
      : parseInt(this.props.match.params.orgPartId);
    const nonEnrolledUser =
      _.get('programs.data')(this.props) && this.props.programs.data.length === 0;

    let aHome, aContract, aScheduleEvents, aStudents, aEventManage, aOrgManagement;
    aHome = aContract = aScheduleEvents = aStudents = aEventManage = aOrgManagement = '';

    switch (active) {
      case 'home':
        aHome = 'active';
        break;
      case 'contract':
        aContract = 'active';
        break;
      case 'scheduleOfEvents':
        aScheduleEvents = 'active';
        break;
      case 'orgManagement':
        aOrgManagement = 'active';
        break;
      case 'students':
        aStudents = 'active';
        break;
      case 'eventManage':
        aEventManage = 'active';
        break;
      default:
        break;
    }

    let tabs = [
      {
        displayName: 'home',
        active: aHome,
        onClick: `/landingPage/orgPartId/${orgPartId}`,
        svg: <HomeIcon className={`icon__item ${aHome}`} />,
        text: (
          <div className={`sidebar__Text__Container ${aHome}`}>
            <span>{text.buttons.tabs.home}</span>
          </div>
        ),
        tooltip: text.buttons.tabs.home,
      },
      {
        displayName: 'contract',
        onClick: `/contract/organization/orgPartId/${orgPartId}`,
        active: aContract,
        svg: <ContractIcon className="icon__item" />,
        text: (
          <div className={`sidebar__Text__Container ${aContract}`}>
            <span>{text.buttons.tabs.contract}</span>
          </div>
        ),
        tooltip: text.buttons.tabs.contract,
        notUsed: !this.props.hasContractPermission || nonEnrolledUser,
      },
      {
        displayName: 'scheduleOfEvents',
        onClick: `/schedule/orgPartId/${orgPartId}`,
        active: aScheduleEvents,
        svg: <ScheduleIcon className="icon__item" />,
        text: (
          <div className={`sidebar__Text__Container ${aScheduleEvents}`}>
            <span>{text.buttons.tabs.scheduleOfEvents}</span>
          </div>
        ),
        tooltip: text.buttons.tabs.scheduleOfEvents,
        notUsed: !this.props.hasSchedulePermission || nonEnrolledUser,
      },
      {
        displayName: 'orgManagement',
        onClick: `/administration/${
          this.checkOrg() === 'school' ? 'participation' : 'organization'
        }/orgPartId/${parseInt(this.props.match.params.orgPartId)}`,
        active: aOrgManagement,
        svg: <OrgManagementIcon className={`icon__item`} />,
        text: (
          <div className={`sidebar__Text__Container ${aOrgManagement}`}>
            <span>{text.buttons.tabs.orgManagement}</span>
          </div>
        ),
        tooltip: text.buttons.tabs.orgManagement,
        notUsed: nonEnrolledUser,
      },
      {
        displayName: 'students',
        onClick: `/students/organization/orgPartId/${parseInt(this.props.match.params.orgPartId)}`,
        active: aStudents,
        svg: <StudentIcon className={`icon__item ${aStudents}`} />,
        text: (
          <div className={`sidebar__Text__Container ${aStudents}`}>
            <span>{text.buttons.tabs.roster}</span>
          </div>
        ),
        tooltip: text.buttons.tabs.roster,
        notUsed: nonEnrolledUser,
      },
      {
        displayName: 'eventManage',
        onClick: `/testevents/organization/orgPartId/${parseInt(
          this.props.match.params.orgPartId
        )}`,
        active: aEventManage,
        svg: <EventManageIcon className={`icon__item ${aEventManage}`} />,
        text: (
          <div className={`sidebar__Text__Container ${aEventManage}`}>
            <span>{text.buttons.tabs.eventManagement}</span>
          </div>
        ),
        tooltip: text.buttons.tabs.eventManagement,
        notUsed: nonEnrolledUser || !this.props.hasTestEventsPermission,
      },
    ];

    return (
      <div className="sidebar__Container close">
        <div className="sidebar__Outter">
          <div role="tablist" className="sidebar__Inner">
            <a href="#main" className="skipToContent">
              Skip to content
            </a>
            {tabs.map((t, idx) => {
              return t.notUsed ? (
                ''
              ) : (
                <a
                  role="tab"
                  aria-selected={t.active ? 'true' : 'false'}
                  aria-label={t.tooltip}
                  key={idx}
                  className={`sidebar__Row  sidebar__Row__Element ${t.active}`}
                  href={t.onClick}>
                  <div className="sidebar__Icon__Container">{t.svg}</div>
                  {this.state.isSidebarOpen && t.text}
                  {!this.state.isSidebarOpen && (
                    <div className="sideBar__arrowBox sideBar__tooltip">{t.tooltip}</div>
                  )}
                </a>
              );
            })}
            <button
              aria-label="Toggle Tab Sidebar"
              className={`linkButton sidebar__Row sidebar__Row__Element ${
                this.state.isSidebarOpen ? 'toggle' : 'toggle-closed'
              }`}
              onClick={this.toggleSidebar}>
              <CarotIcon className="icon__item__close" />
              {this.state.isSidebarOpen && (
                <div className="sidebar__Text__Container carot">
                  <span>{text.buttons.tabs.collapse}</span>
                </div>
              )}
            </button>
            {!this.state.isSidebarOpen && (
              <div className="sideBar__arrowBox sideBar__tooltip">{text.buttons.tabs.collapse}</div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { userInfoReducer } = state;
  return {
    orgHierarchy: userInfoReducer.orgHierarchy,
    programs: userInfoReducer.programs,
    hasContractPermission: getPermission(state, 'now_contract_manage'),
    hasTestEventsPermission: getPermission(state, 'now_test_event_view'),
    hasSchedulePermission: getPermission(state, 'now_schedule_view'),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ ...coreActions }, dispatch),
  };
};

SidebarNav.displayName = 'SidebarNav';

export { SidebarNav };
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SidebarNav));

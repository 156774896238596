import './StudentsOverview.scss';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { actions as rosterActions } from '../duck';
import { actions as orgManagementActions } from '../../administration/duck';
import OverviewTile from './OverviewTile.jsx';
import { features } from '../../../core/i18n/text';
import Alert from '../../../components/alerts/Alert';
import _ from 'lodash/fp';
import { Helmet } from 'react-helmet';
import { countArray } from '../../../core/constants';

class StudentsOverview extends React.Component {
  componentDidMount() {
    const orgPartId = this.props.match.params.orgPartId;
    if (orgPartId) {
      this.props.actions.getStudentsByTestEvent(orgPartId);
      this.props.actions.getStudentsByTestFormat(orgPartId);
      this.props.actions.getStudentsByGrades(orgPartId);
      this.props.actions.getStudentsByTest(orgPartId);
      this.props.actions.getStudentsRecords({
        orgPartId: orgPartId,
        offset: 0,
        limit: countArray[0],
      });
      this.props.actions.getInvitationStatus({ orgPartId });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.orgPartId !== this.props.match.params.orgPartId) {
      const orgPartId = this.props.match.params.orgPartId;
      this.props.actions.getStudentsByTestEvent(orgPartId);
      this.props.actions.getStudentsByTestFormat(orgPartId);
      this.props.actions.getStudentsByGrades(orgPartId);
      this.props.actions.getStudentsByTest(orgPartId);
    }
  }

  render() {
    return (
      <div className="RosterOverview_Container">
        <Helmet>
          <title>Registration Overview - ACT-Now</title>
        </Helmet>
        {_.get('studentsRecords.meta.totalCount')(this.props) &&
        this.props.studentsRecords.meta.totalCount > 0 &&
        _.get('inviteStatus.data')(this.props) &&
        ((this.props.inviteStatus.data.isOrgPartDescendantActive &&
          this.props.inviteStatus.data.isOrgPartDescendantActive) ||
          this.props.inviteStatus.data.status === 'active') ? (
          <>
            {_.get('studentsByTestEvent.data')(this.props) &&
              this.props.studentsByTestEvent.data.length > 0 && (
                <OverviewTile
                  title={features.overview.studentsByTestEvent}
                  data={this.props.studentsByTestEvent.data}
                  id={'studentsByTestEventChart'}
                />
              )}
            {_.get('studentsByTestFormat.data')(this.props) &&
              this.props.studentsByTestFormat.data.length > 0 && (
                <OverviewTile
                  title={features.overview.studentsByTestFormat}
                  data={this.props.studentsByTestFormat.data}
                  id={'studentsByTestFormatChart'}
                />
              )}
            {_.get('studentsByGrades.data')(this.props) &&
              this.props.studentsByGrades.data.length > 0 && (
                <OverviewTile
                  title={features.overview.studentsByGrades}
                  data={this.props.studentsByGrades.data}
                  id={'studentsByGradesChart'}
                />
              )}
            {_.get('studentsByTest.data')(this.props) &&
              this.props.studentsByTest.data.length > 0 && (
                <OverviewTile
                  title={features.overview.studentsByTest}
                  data={this.props.studentsByTest.data}
                  id={'studentsByTestChart'}
                />
              )}
          </>
        ) : !_.get('studentsRecords.meta.totalCount')(this.props) &&
          !_.get('inviteStatus.data')(this.props) ? (
          ''
        ) : (
          <Alert type="info" message={features.overview.emptyState} />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { rosterReducer, orgManagementReducer } = state;
  return {
    studentsByTestEvent: rosterReducer.studentsByTestEvent,
    studentsByTestFormat: rosterReducer.studentsByTestFormat,
    studentsByGrades: rosterReducer.studentsByGrades,
    studentsByTest: rosterReducer.studentsByTest,
    studentsRecords: rosterReducer.studentsRecords,
    inviteStatus: orgManagementReducer.inviteStatus,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ ...rosterActions, ...orgManagementActions }, dispatch),
  };
};

export { StudentsOverview };
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StudentsOverview));

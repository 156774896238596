import Input from '../../../../components/forms/fields/Input';
import * as Text from '../../../../core/i18n/text';
import { features } from '../../../../core/i18n/text';
import Email from '../../../../components/forms/fields/Input';
import React from 'react';

const DetailsInputContainer = ({ details, isChecked, detailsErrors, onInputChange }) => {
  return (
    <div className="details-container">
      <Input
        label={Text.common.fields.lastName}
        error={detailsErrors.lastName}
        onChange={onInputChange}
        name="lastName"
        value={details.lastName || ''}
        maxLength="35"
        disabled={isChecked}
      />
      <Input
        label={Text.common.fields.firstName}
        error={detailsErrors.firstName}
        onChange={onInputChange}
        name="firstName"
        value={details.firstName || ''}
        maxLength="35"
        disabled={isChecked}
      />
      <div className="phone-container">
        <Input
          label={features.orgManagement.participation.modal.phone}
          error={detailsErrors.phone}
          onChange={onInputChange}
          name="phone"
          value={details.phone || ''}
          maxLength="10"
          disabled={isChecked}
        />
        <div className="extension">
          <Input
            label={Text.common.fields.extension}
            error={detailsErrors.extension}
            onChange={onInputChange}
            name="extension"
            value={details.extension || ''}
            maxLength="10"
            disabled={isChecked}
          />
          <span>{features.orgManagement.participation.modal.optional}</span>
        </div>
      </div>
      <Email
        name="email"
        label={features.orgManagement.participation.modal.email}
        value={details.email || ''}
        error={detailsErrors.email}
        onChange={onInputChange}
        disabled={isChecked}
      />
    </div>
  );
};

export default DetailsInputContainer;

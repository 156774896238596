import React from 'react';
import propTypes from 'prop-types';
import ProgressBar from './ProgressBar';
import WarningIcon from './../../assets/svgs/alert.svg';
import { ReactComponent as ClosedIcon } from './../../assets/svgs/close.svg';
import { useHistory, withRouter } from 'react-router-dom';
import * as Text from '../../core/i18n/text';

function Tile(props) {
  const history = useHistory();

  const handleRedirect = () => {
    props.newPage ? window.location.assign(props.redirect) : history.push(props.redirect);
  };

  return (
    <div role="group" className="tile__Container">
      <img src={props.icon} alt=""></img>
      <div role="heading" className="tile__Header">
        {props.title}
      </div>
      <div className="tile__Description">{props.description}</div>
      {props.completed && props.total && (
        <ProgressBar completed={props.completed} total={props.total} />
      )}
      {props.completeBy && (
        <p className="tile__Warning">
          <img src={WarningIcon} alt="Warning Icon"></img>
          Complete {props.title} by {props.completeBy}
        </p>
      )}
      {props.hasDeadlines && props.impendingDeadline > 0 && (
        <div className="tile__alert">
          {props.impendingDeadline} {Text.features.scheduleOfEvents.impendingDeadline}
          <ClosedIcon onClick={props.disableDeadlines} tabIndex="0" />
        </div>
      )}
      <div className="tile__ButtonContainer">
        <button className="openButton tile__Button" onClick={handleRedirect}>
          {props.buttonText}
        </button>
      </div>
    </div>
  );
}

Tile.displayName = 'Tile';

Tile.propTypes = {
  newPage: propTypes.bool,
  buttonText: propTypes.string,
  title: propTypes.string,
  description: propTypes.string,
  disableDeadlines: propTypes.func,
};

Tile.defaultProps = {
  newPage: false,
  impendingDeadline: 0,
};

export { Tile };
export default withRouter(Tile);

import React from 'react';
import { features } from '../../core/i18n/text';

const Text = { features };

const Footer = () => (
  <div className="footer__Container">
    <ul>
      <li className="footer__Text">
        <a
          className="link"
          href={Text.features.footer.terms.url}
          target="_blank"
          rel="noopener noreferrer">
          {Text.features.footer.terms.text}
        </a>
      </li>
      <li className="footer__Text">
        <a
          className="link"
          href={Text.features.footer.privacy.url}
          target="_blank"
          rel="noopener noreferrer">
          {Text.features.footer.privacy.text}
        </a>
      </li>
    </ul>
  </div>
);

Footer.displayName = 'Footer';
export default Footer;

import _ from 'lodash/fp';
import { createDispatchFunction } from '../../services/helpers/stateHelper';

import { UserServices, CommonServices } from '../../services';

import * as types from './types';

// action creators
export function getUserInfoAction(userInfo) {
  return {
    type: types.GET_USERINFO,
    userInfo,
  };
}

export function getUserOrganizationAction(organization) {
  return {
    type: types.GET_USER_ORGANIZATION,
    organization,
  };
}

export function getUserProgramsAction(programs) {
  return {
    type: types.GET_USER_PROGRAMS,
    programs,
  };
}

export function getUserAdminsAction(admins) {
  return {
    type: types.GET_USER_ADMINS,
    admins,
  };
}

export function getUserOrgsAction(orgs) {
  return {
    type: types.GET_USER_ORGS,
    orgs,
  };
}

export function getOrgHierarchyAction(orgHierarchy) {
  return {
    type: types.GET_ORG_HIERARCHY,
    orgHierarchy,
  };
}

export function getUserPermissionsAction(userPermissions) {
  return {
    type: types.GET_USER_PERMISSIONS,
    userPermissions,
  };
}

export function getChildOrgsAction(payload) {
  return {
    type: types.GET_CHILD_ORGS,
    payload,
  };
}

export function getAllAdminsByProgramAction(payload) {
  return {
    type: types.GET_ALL_ADMINS_BY_PROGRAM,
    payload,
  };
}

export function getAppUrlAction(payload) {
  return {
    type: types.GET_APP_URL,
    payload,
  };
}

export function getFileTamplateAction(payload) {
  return {
    type: types.GET_FILE_TEMPLATE,
    payload,
  };
}

export function getServerDateTimeAction(payload) {
  return {
    type: types.GET_SERVER_DATE_TIME,
    payload,
  };
}

export function getTermsAction(payload) {
  return {
    type: types.GET_TERMS,
    payload,
  };
}

// dispatch + helper functions
function sendProfileRequest() {
  return UserServices.getUserInfo();
}

export function getUserInfo() {
  return createDispatchFunction(_.partial(sendProfileRequest)([]), getUserInfoAction);
}

export function getUserOrganization(programId) {
  return createDispatchFunction(
    _.partial(UserServices.getUserOrganization)([programId]),
    getUserOrganizationAction
  );
}

export function getUserPrograms() {
  return createDispatchFunction(_.partial(UserServices.getUserPrograms)([]), getUserProgramsAction);
}

export function getUserAdmins(programId) {
  return createDispatchFunction(
    _.partial(UserServices.getUserAdmins)([programId]),
    getUserAdminsAction
  );
}

export function getAllAdminsByProgram(programs) {
  return createDispatchFunction(
    _.partial(UserServices.getAllAdminsByProgram)([programs]),
    getAllAdminsByProgramAction
  );
}

export function getUserOrgs(adminId) {
  return createDispatchFunction(_.partial(UserServices.getUserOrgs)([adminId]), getUserOrgsAction);
}

export function getOrgHierarchy(params) {
  return createDispatchFunction(
    _.partial(CommonServices.getOrgHierarchy)([params]),
    getOrgHierarchyAction
  );
}

export function getUserPermissions(params) {
  return createDispatchFunction(
    _.partial(CommonServices.getUserPermissions)([params]),
    getUserPermissionsAction
  );
}

export function getChildOrgs(params) {
  return createDispatchFunction(
    _.partial(CommonServices.getChildOrgs)([params]),
    getChildOrgsAction
  );
}

export function getAppUrl(code) {
  return createDispatchFunction(_.partial(CommonServices.getAppUrl)([code]), getAppUrlAction);
}

export function getFileTamplate(code) {
  return createDispatchFunction(
    _.partial(CommonServices.getFileTamplate)([code]),
    getFileTamplateAction
  );
}

export function getServerDateTime(code) {
  return createDispatchFunction(
    _.partial(CommonServices.getServerDateTime)([]),
    getServerDateTimeAction
  );
}

export function getTerms(programId) {
  return createDispatchFunction(_.partial(UserServices.getUserTerms)([programId]), getTermsAction);
}

import React from 'react';
import propTypes, { bool } from 'prop-types';
import invalidCheckIcon from '../../assets/svgs/grayCheckmark.svg';
import validCheckIcon from '../../assets/svgs/greenCheckmark.svg';

const PasswordValidationDisplay = ({ validations }) => {
  const keys = Object.keys(validations);
  const uiValidations = keys.map((k, idx) => {
    const val = validations[k];
    const check = val ? (
      <img src={validCheckIcon} alt="passedCondition" />
    ) : (
      <img src={invalidCheckIcon} alt="failedCondition" />
    );
    return (
      <div key={idx} className="validationBox">
        {check}
        <span className={`defaultValidationText ${val ? 'okValidationText' : ''}`}>{k}</span>
      </div>
    );
  });

  return <div style={{ display: 'contents' }}>{uiValidations}</div>;
};

PasswordValidationDisplay.displayName = 'PasswordValidationDisplay';
PasswordValidationDisplay.defaultProps = {
  validations: {},
};
PasswordValidationDisplay.propTypes = {
  validations: propTypes.objectOf(bool),
};

export default PasswordValidationDisplay;

import cookie from 'react-cookies';
import { UserServices } from '../../services';
import Config from '../config';

const ACCESS_TOKEN = 'at';
const ID_TOKEN = 'it';
const REFRESH_TOKEN = 'rt';

const getDomainName = hostName => {
  return hostName.substring(hostName.lastIndexOf('.', hostName.lastIndexOf('.') - 1) + 1);
};

const Auth = {
  get authToken() {
    if (!this.idToken) {
      const token = cookie.load(ID_TOKEN);

      if (token) {
        this.idToken = token;
        // we got the token from cookies, don't know the expiry => refresh those tokens
        this.refreshTokens();
        return token;
      }
    }
    return this.idToken;
  },

  set authToken(token) {
    this.idToken = token;
    this.setTokenCookie(token, ID_TOKEN, this.expiration);
  },

  get accessToken() {
    if (!this.aToken) {
      const token = cookie.load(ACCESS_TOKEN);
      if (token) {
        this.aToken = token;
        return token;
      }
    }
    return this.aToken;
  },

  set accessToken(token) {
    this.aToken = token;
    this.setTokenCookie(token, ACCESS_TOKEN, this.expiration);
  },

  get refreshToken() {
    if (!this.rToken) {
      const token = cookie.load(REFRESH_TOKEN);
      if (token) {
        this.rToken = token;
        return token;
      }
    }
    return this.rToken;
  },

  set refreshToken(token) {
    this.rToken = token;
    this.setTokenCookie(token, REFRESH_TOKEN);
  },

  get expiration() {
    return this.exp;
  },

  set expiration(time) {
    this.exp = time;
  },
  set appClient(code) {
    const cookieClient = cookie.load('client');
    //if code == '', then set it to empty string
    code = cookieClient && code ? cookieClient : code;
    this.client = code;
    this.setTokenCookie(code, 'client');
  },
  get appClient() {
    if (!this.client) {
      const code = cookie.load('client');
      if (code) {
        this.client = code;
        return code;
      }
    }
    return this.client;
  },

  setTokenCookie(token, tokenName, expires) {
    const secure = window.location.hostname !== 'localhost';
    const domain =
      window.location.hostname !== 'localhost'
        ? getDomainName(window.location.hostname)
        : 'localhost';
    if (token) {
      cookie.save(tokenName, token, {
        path: '/',
        domain,
        expires,
        secure,
      });
      return;
    }
    cookie.remove(tokenName, {
      path: '/',
      domain,
    });
  },

  setAuthTokens(data) {
    let expires = 0;
    const { id_token, refresh_token, access_token, expires_in } = data;

    if (expires_in) {
      expires = new Date();
      expires.setTime(expires.getTime() + expires_in * 1000);
    }
    this.expiration = expires;
    this.authToken = id_token;
    this.accessToken = access_token;
    this.appClient = Config.APP_CLIENT;
    if (refresh_token) {
      this.refreshToken = refresh_token;
    }

    // refresh tokens 10 seconds before actual expiration
    this.setRefreshTimer(expires_in - 10);
  },

  remove() {
    this.expiration = '';
    this.authToken = '';
    this.accessToken = '';
    this.refreshToken = '';
    this.appClient = '';

    cookie.remove(ACCESS_TOKEN);
    cookie.remove(ID_TOKEN);
    cookie.remove(REFRESH_TOKEN);

    if (this.refreshTimer) {
      clearTimeout(this.refreshTimer);
      this.refreshTimer = null;
    }

    window.location.href = `${window.location.origin}/login`;
  },

  async refreshTokens() {
    const rToken = this.refreshToken;
    if (rToken) {
      UserServices.refreshTokens(rToken)
        .then(success => {
          this.setAuthTokens(success.data);
        })
        .catch(() => {
          // refresh tokens revoked, clean up
          this.remove();
          window.location.href = `${window.location.origin}/login`;
        });
    } else {
      // no refresh token, clean up
      this.remove();
      window.location.href = `${window.location.origin}/login`;
    }
  },

  setRefreshTimer(time) {
    if (this.refreshTimer) {
      clearTimeout(this.refreshTimer);
    }
    this.refreshTimer = setTimeout(() => {
      this.refreshTokens();
      clearTimeout(this.refreshTimer);
    }, time * 1000);
  },

  checkTokens() {
    if (this.authToken || this.refreshToken) {
      return true;
    }
    return false;
  },
};

export default Auth;

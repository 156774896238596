import _ from 'lodash/fp';
import { config } from './programs';

const getProgramConfiguration = location => program => {
  if (!program) return {};
  const { parentCode } = program;
  return location ? _.get(location)(config[parentCode]) : config[parentCode];
};

const getText = ({ program }) => value => {
  switch (typeof value) {
    case 'function':
      return value({ program });
    case 'object':
      if (Array.isArray(value)) return value;
      return Object.fromEntries(
        Object.entries(value).map(([k, v]) => [k, getText({ program })(v)])
      );
    default:
      return value;
  }
};

export { getProgramConfiguration, getText };

import React from 'react';

const clickables = {
  buttons: {
    tabs: {
      collapse: 'Collapse',
      home: 'Home',
      contract: 'Contract',
      scheduleOfEvents: 'Schedule',
      orgManagement: 'Administration',
      roster: 'Students',
      eventManagement: 'Test Events',
      expand: 'Expand',
    },
    subTabs: {
      organization: 'Organization',
      testEvents: 'Test Events',
      shipmentsTracking: 'Shipments tracking',
      reports: 'Reports',
    },
    login: 'Log in',
    continueToLogin: 'Continue to log in',
    continueCreateAccount: 'Create account',
    saveInformation: 'Save',
    cancel: 'Cancel',
    confirmEmail: 'Confirm email',
    resendCode: 'Resend code',
    resendAccountVerification: 'Resend verification email',
    changePassword: 'Change password',
    goBackHome: 'Go back Home',
    editProfile: 'Edit profile',
    apply: 'Apply',
    clearSearch: 'Clear search',
  },
  links: {
    fpQuestion: 'Forgot your password?',
    clickHere: 'Click here',
    createAccount: 'Create account',
    alreadyHaveAccount: 'Already have an account?',
    dontHaveAccount: `Don't have an account?`,
  },
};

const common = {
  labels: {
    program: ({ program }) => {
      switch (program.parentCode) {
        case 'act':
          return 'The ACT';
        case 'preact':
          return 'PreACT';
        case 'workkeys':
          return 'WorkKeys';
        default:
          return '';
      }
    },
    save: 'Save',
    clear: 'Clear',
    close: 'Close',
    seeAll: 'See all',
    viewAll: 'View all',
    selectAll: 'Select all',
    deselectAll: 'Deselect all',
    studentsBulkAssignmentAction: 'Event assignment',
    studentsBulkUpdateAction: 'Update options',
    loadMore: 'Load more',
    loading: 'Loading...',
    help: 'Help',
    hide: 'Hide',
    learnMore: 'Learn more',
    open: 'Open',
    back: 'Back',
    goBack: 'Go back',
    optional: 'Optional',
    showMore: '+ Show More',
    displaying: 'Displaying',
    invite: 'Invite individual',
    active: 'Active',
    invited: 'Invited',
    searchBy: 'Search by',
    perPage: 'per page',
    continue: 'Continue',
  },
  fields: {
    firstName: 'First name',
    lastName: 'Last name',
    middleInitial: 'Middle initial',
    workEmail: 'Work email',
    firstPhone: 'Work phone number',
    secondPhone: 'Cell phone number',
    email: 'Email address',
    emailPlaceHolder: 'Enter your email address',
    password: 'Password',
    passwordPlaceHolder: 'Enter your password',
    extension: 'Extension',
    search: 'Search',
  },
};

const features = {
  contract: {
    title: 'Contract',
    fileUpload: {
      noFiles: {
        title: 'Submit your Organization Master File',
      },
      uploadFileBox: {
        title: 'Upload Organization Master File',
        description: 'Make sure the file contains all required organizational data shown in the ',
        hyperlink: {
          text: 'ACT Now Guidelines',
          link: '/pdfs/ContractOrgFileUpload.pdf',
        },
      },
      uploadModal: {
        title: 'Upload Organization Master File',
      },
      templateName: 'Contract organizations template',
    },
    organization: {
      orgName: 'Organization name',
      parentOrg: 'Parent organization',
      orgType: 'Organization type',
      orgCode: 'Local Organization code',
      orgCodeTooltip: 'ID usually assigned by the state or district.',
      address: 'Address',
      city: 'City',
      state: 'State',
      postalCode: 'Postal code',
      mdmCode: 'ACT-Assigned Code',
      NCESLink: 'Find my NCES code',
      addNewOrganization: 'Add new organization',
      editOrganization: 'Edit organization',
      saveOrgLabel: 'Save information',
      addOrgLabel: 'Add organization',
      editOrgSuccessMessage: 'Organization successfully edited',
      addOrgSuccessMessage: 'Organization successfully added',
      emptyState: {
        title: 'There are no organizations added yet',
        description: 'Add organizations one by one or upload a file to add several.',
        uploadButtonLabel: 'Upload file',
      },
    },
  },
  header: {
    profile: 'Profile',
    logout: 'Logout',
    mainSelector: {
      title: 'Program selector',
      description:
        'Please select program details to begin this session. You may change selections as needed.',
      warning: 'You will be redirected to homepage after saving',
      button: 'Save selection',
    },
  },
  footer: {
    version: 'Version: 1.0',
    terms: {
      copyright: '© 1998-2018 ACT, Inc. ',
      text: 'Terms of Use',
      url: 'http://www.act.org/content/act/en/terms-of-use.html',
    },
    privacy: {
      text: 'Privacy Policy',
      url: 'http://www.act.org/content/act/en/privacy-policy.html',
    },
  },
  landing: {
    contractInfoId: 'Contract ID: ',
    programModal: 'Program Details',
    welcome: {
      title: 'Welcome, ',
      description: 'Everything you need to create and organize your test events, teams and tasks.',
    },
    nonEnrolledUserMessage: 'Your user account is not tied to an organization.',
    pleaseAsk:
      'Please provide your email address to a coordinator who can generate an ACT Now invitation.',
    tiles: {
      orientation: {
        title: 'Orientation',
        description:
          'Prepare for a successful test experience by learning more about ACT Now, policies, products and services.',
        button: 'Learn more',
      },
      contract: {
        title: 'Contract',
        description: 'View program details and create a master listing of eligible organizations.',
        button: 'Open contract page',
      },
      scheduleOfEvents: {
        title: 'Schedule',
        description: 'View schedule of events for a certain administration.',
        button: 'Open events page',
      },
      orgManagement: {
        title: 'Administration',
        description: 'View or manage details for the administration selected above.',
        button: 'Manage an administration',
      },
      roster: {
        title: 'Students',
        description: 'Oversee student registrations, accommodations, and test event assignments.',
        completeBy: 'February 15',
        button: 'Manage students',
      },
      eventManager: {
        title: 'Test Events',
        description: 'Prepare and facilitate test events within an administration.',
        button: 'Open events page',
      },
    },
    termsAndConditions: {
      agreeLabel: 'I agree to the',
      infoMessage:
        'In order to continue, you must agree to the terms and conditions (scroll down the page if needed).',
      logOut: 'Logout and continue later',
      errorMessage: 'Something went wrong, please try again later.',
      successMessage: "We've sent a copy of the terms and conditions via email at",
      agreementError: 'You must agree to the',
      continue: 'to continue',
      acceptTerms: 'Accept terms',
      termsMessage: 'Accept latest terms and conditions',
    },
    terms: {
      backupCoordinatorTermsLabel: 'I agree to the Backup Coordinator Agreement',
      backupCoordinatorTitle: 'Backup Coordinator Agreement',
      coordinatorTermsLabel: 'I agree to the Test Coordinator Agreement',
      coordinatorTitle: 'Test Coordinator Agreement',
      agreementError: 'You must agree to the Test Coordinator Agreement to continue',
    },
  },
  login: {
    bgImgText: 'Everything you need to create and organize your test events, teams, and tasks.',
    title: 'Log into your workspace',
    description: 'To access ACT Now you may use the same log in credentials as TCM.',
    fpTitle: 'Reset your password',
    fpDescription: 'To reset your password, enter your email address below.',
    rpTitle: 'Enter a new password',
    tncModal: 'Terms and Conditions',
  },
  notFound: {
    title: 'Page Not Found',
    description:
      'Unfortunately the page you are looking for doesn’t exist (anymore) or there was an error in the link you followed or typed.',
  },
  notifications: {
    title: 'Notifications',
    modalTitle: 'Notification message',
    clickToRead: 'Click on notifications to read',
    noUnread: 'You have no unread messages',
  },
  personalInfo: {
    title: 'Personal Information',
  },
  register: {
    title: 'Create an ACT account',
    tnc: 'Terms & Conditions',
    ageAgreement: 'I am 18 years of age or older',
    confirmation: {
      title: 'Email sent',
      description: {
        email: 'An email with an activation link has been sent to ',
        check: 'Check spam/junk folders. It may take several minutes for the email to arrive.',
      },
    },
  },
  forgotPassword: {
    title: 'Check your email',
    description: 'Allow several minutes for receipt. Check spam or junk folders.',
  },
  student: {
    zeroResults: '0 results',
    result: 'result',
    results: 'results',
  },
  roster: {
    title: 'Roster Management',
    filters: {
      title: 'Filters',
      registration: {
        status: 'Event Assignment Status',
        assigned: 'Assigned',
        notAssigned: 'Not assigned',
      },
      eventAssignment: {
        title: 'Event assignment',
        select: 'Select test event',
      },
      timingCategory: {
        title: 'Timing category',
      },
      format: {
        title: 'Format',
      },
      accommodations: {
        title: 'Accommodations',
      },
      testOption: {
        title: 'Test option',
      },
      grade: {
        title: 'Grade',
        select: 'Select grade',
      },
      testingStatus: {
        title: 'Testing status',
      },
      allOptionsSelected: 'All options are selected',
      show: 'Show filters',
      notTesting: 'Will not test',
    },
    manageColumns: 'Manage Columns',
    displaying: 'Displaying',
    noResults: 'No results',
    disabledTooltip: 'Last name is required to be filled to enable first name',
    addStudentLabel: 'Add new student',
    testingEnded: 'This testing window has ended. Please check that your selection is correct.',
    shippingConcluded: 'Shipping has concluded for this window. Materials will',
    newEnrollments: 'be sent for new enrollments.',
    modal: {
      title: 'Student information',
      tooltipInfo:
        'Accessibility options not authorized by ACT prior to use. Scores used only for state/district testing',
      details: {
        notTestingFlag: 'Assigned Event',
        externalStudentId: 'State Student ID',
        onlineFlag: 'Format',
        nonCollegeReportable: 'Non-College Reportable (NCR)',
        timing: 'Timing Category',
        regTypeName: 'Test Option',
        dateOfBirth: 'Date of Birth',
        grade: 'Grade',
        gender: 'Gender',
        reportingHighSchoolCode: 'Reporting HS code',
        claimCode: 'ACT Student Code',
        //deliveryFormat: 'Delivery Format',
        orgName: 'School Name',
        taaPin: 'TAA PIN',
        accommOptOut: 'Opt-out',
      },
      errors: {
        deliveryMode: 'Select a delivery format',
      },
      willNotTest: 'Will not test',
      online: 'Online',
      paper: 'Paper',
      materialsRequired: 'Materials required',
      accommodationsNeeded: 'Accommodations needed (select all that apply)',
      edit: 'Edit student information',
      addInfoMessage: 'Complete if Student Transfer does not apply.',
      addStudentTitle: 'Add new student',
      format: 'Format',
      unenroll: 'Unenroll student',
      saveButton: 'Save information',
      standard: 'Standard',
      successUnenrollMessage: 'Student successfully unenrolled',
      accommodationOption: 'Accommodations option',
      selectMaterials: 'Select materials',
    },
    breadcrumbsBase: 'Students',
    assignStudents: {
      description: 'Selected records will remain selected even when hidden by filters',
      butttonText: 'Event Assignment',
      disabledTooltip: 'Select at least one record',
      selected: 'selected',
      deselectAll: '(deselect all)',
    },
    noResultsAvailable: 'No results available',
    fileUpload: {
      noFiles: {
        title: 'Submit your Student Registration File',
      },
      uploadFileBox: {
        title: 'Upload Student Registration File',
        description: 'Make sure the file contains all necessary student data shown in the ',
        hyperlink: {
          text: 'ACT Now Guidelines',
          link: ({ program }) => {
            switch (program.parentCode) {
              case 'act':
                return '/pdfs/ACTStudentFileUpload.pdf';
              case 'preact':
                return '/pdfs/PreACTStudentFileUpload.pdf';
              case 'workkeys':
                return '/pdfs/WorkkeysStudentFileUpload.pdf';
              default:
                return '';
            }
          },
        },
        butttonText: 'Upload file',
      },
      uploadModal: {
        title: 'Upload Student Registration File',
      },
      templateName: 'Registrations template',
    },
    studentTransfer: {
      requestBox: {
        title: 'Request transfer',
        desc: 'Select to request a student from another school in this testing program.',
        button: 'Request transfer',
      },
      approveDeclineModal: {
        title: {
          approve: 'Approve transfer',
          decline: 'Decline transfer',
        },
        button: {
          approve: 'Approve transfer',
          decline: 'Decline transfer',
        },
        details: [
          'State Student ID',
          'Grade',
          'Date of birth',
          'Requesting school',
          'Requestor',
          'District',
          'Request date',
          'Notes',
        ],
        declineReason: 'Decline reason',
        confirmationModal: {
          title: 'Are you sure you want to continue?',
          body:
            'This change will remove the event assignment along with any corresponding seat and booklet assignments.',
          button: 'Approve transfer',
        },
      },
      sendRequest: 'Send request',
      note: 'Add extra notes + contact info (Optional)',
      warning: 'There are no results based on your search criteria',
      error: 'Student is already associated to this organization.',
      details: [
        'State Student ID ',
        'Grade',
        'Date of birth',
        'Current school',
        'Current district',
      ],
      notification: 'Transfer successfully requested',
      cancelNotification: 'Transfer request successfully canceled',
      approveNotification: 'Transfer request successfully approved',
      declineNotification: 'Transfer request successfully declined',
      requestsReceived: 'Requests received',
      requestsSent: 'Requests sent',
      approve: 'Approve transfer',
      decline: 'Decline transfer',
    },
    downloadPdf: 'Student non-test instructions',
  },
  accommodations: {
    fileUpload: {
      noFiles: {
        title: 'Submit your Accommodation File',
      },
      uploadFileBox: {
        title: 'Upload Student Accommodation File',
        description: 'Make sure the file contains all necessary student data shown in the ',
        hyperlink: {
          text: 'ACT Now Guidelines',
          link: ({ program }) => {
            switch (program.parentCode) {
              case 'act':
                return '/pdfs/AccomsFileUpload.pdf';
              case 'preact':
                return '/pdfs/PreACTAccomsFileUpload.pdf';
              case 'workkeys':
                return '/pdfs/WorkkeysAccomsFileUpload.pdf';
              default:
                return '';
            }
          },
        },
        butttonText: 'Upload file',
      },
      uploadModal: {
        title: 'Upload Student Accommodation File',
      },
      templateName: 'Accommodations template',
    },
  },
  scheduleOfEvents: {
    title: 'Schedule',
    downloadPdf: 'Download PDF',
    tableHeader: {
      tasks: 'Task(s)',
      deadline: 'Deadline & test dates',
      roles: 'Authorized Role(s)',
    },
    noResults1: 'There are',
    noResults2: 'results available. Try clearing filters.',
    clearFilters: 'Clear filters',
    goToTcm: 'Go to TCM',
    goTo: 'Go to',
    paperDelivery: 'Paper delivery mode',
    onlineDelivery: 'Online delivery mode',
    noDetails: 'No details to display.',
    testDay: 'Test day',
    testWindow: 'Test window',
    impendingDeadline: 'impending deadlines',
    downloadSuccess: 'Schedule PDF downloaded.',
    noEventsMessage: 'There are no events to display.',
    dueToday: 'Due today',
    filters: {
      deadlines: {
        title: 'Deadlines',
        options: {
          deadlinesOnly: 'Only deadlines',
          testingDaysOnly: 'Only testing days',
        },
      },
      format: {
        title: 'Format',
        options: {
          all: 'All',
          onlineOnly: 'Online',
          paperOnly: 'Paper',
        },
      },
    },
  },
  fileUpload: {
    noFiles: {
      title: 'Submit your file',
      steps: {
        1: {
          title: '1. Prepare your CSV file',
          desc: [
            'Avoid upload errors by using ',
            ' and the file template to ensure critical data is included and correctly formatted. Do not delete template fields.',
          ],
          hyperlink: {
            text: 'ACT Now Guidelines',
            link:
              'https://www.act.org/content/dam/act/unsecured/documents/SDU-FileRequirementsandLoadingInstructions-CSV.pdf',
          },
        },
        2: {
          title: '2. Upload your file',
          desc:
            'Use the Upload File button on this page to drag and drop, or browse and select, your CSV file.',
        },
        3: {
          title: '3. Process and review',
          desc:
            'A notice will be sent once your file is processed. You may perform other tasks or load more files before coming back to review. Remember to check all uploads after processing.',
        },
      },
    },
    uploadFileBox: {
      title: 'Upload File',
      description: 'Make sure the file contains all necessary student data shown in the ',
      hyperlink: {
        text: 'ACT Now Guidelines',
        link: '/pdfs/StudentFileUpload.pdf',
      },
      butttonText: 'Upload file',
      secondaryButtonText: 'Download template',
    },
    uploadModal: {
      title: 'Upload Student Registration File',
      description: 'Drag and drop, or browse and select, CSV file.',
      hint: 'Drag and drop .csv file here',
      or: 'or',
      browse: 'Browse File',
      fileName: 'File name',
      uploadFile: 'Upload file',
      remove: 'Remove',
      dragHint: 'Drop the file here',
    },
    fileTiles: {
      labels: {
        pending: 'File is processing, please wait. You will receive a notification.',
        complete: 'Upload complete',
        completeErrors: 'Upload complete with errors',
        fail: 'Not uploaded',
      },
      buttons: {
        downloadFile: 'Download source file',
        downloadFileErrors: 'Download rows with errors',
        downloadError: 'Download error messages',
      },
      general: {
        uploaded: 'Uploaded',
        errors: 'rows with errors',
        records: 'rows saved',
        done: 'done',
        refresh: 'refresh',
        processed: 'processed on ',
        at: 'at',
        by: 'by',
        rows: 'rows',
      },
    },
  },
  staffInvite: {
    fileUpload: {
      noFiles: {
        title: 'Upload Users Invitation File',
      },
      uploadFileBox: {
        title: 'Upload Users Invitation File',
        description:
          'Make sure the file contains all the users needed for staffing your organization as shown in the ',
        hyperlink: {
          text: 'ACT Now Guidelines',
          link: '/pdfs/UserFileUpload.pdf',
        },
      },
      uploadModal: {
        title: 'Upload Users Invitation File',
      },
      templateName: 'Participating users template',
    },
  },
  organization: {
    tileLegend: {
      notTest: 'Not testing',
      notReady: 'Not assigned to event',
      ready: 'Assigned to event',
      tested: 'Tested',
    },
    legenTooltips: {
      notTest: 'Ineligible or unable to test',
      tested: 'Student test has been scored',
    },
    tileStatus: {
      status: 'Student testing status',
      noStud: 'No students loaded',
      noDescendant: 'No child organizations have confirmed they will participate.',
    },
    tooltip: 'School status of "Participating" is required for event assignment.',
    buttons: {
      seeOrgs: 'See orgs',
      edit: 'Edit',
      orgs: 'See organizations',
      stud: 'See students',
      overview: 'See overview',
      invite: 'Invite',
      confirm: 'Confirm',
      addToAdmin: 'Add to admin',
    },
    searchPlaceholder: 'Search by org name or code',
    filters: {
      participation: {
        title: 'Status',
        confirmedParticipating: 'Confirmed participation',
        declinedParticipations: 'Declined participation',
        notConfirmed: 'Not confirmed',
        notParticipating: 'Not participating',
      },
      type: 'Type',
    },
  },
  overview: {
    studentsByTestEvent: 'Students by test event',
    studentsByTestFormat: 'Students by test format',
    studentsByGrades: 'Students by grades',
    studentsByTest: 'Students by test',
    emptyState:
      'There are no details to display until at least one school confirms participation and student data is loaded.',
  },
  orientation: {
    title: 'Orientation',
    description:
      'Prepare for a successful test experience by learning more about ACT policies, products and services.',
  },
  orgManagement: {
    title: 'Organizations',
    breadcrumbsBase: 'Administration',
    emptyMessage: 'No matches found. Look for typos, alternate spellings or other search criteria.',
    fileUpload: {
      noFiles: {
        title: 'Submit your Participation File',
      },
      uploadFileBox: {
        title: 'Upload Participation File ',
        description:
          'Make sure the file contains all eligible schools and their required data as shown in the ',
        hyperlink: {
          text: 'ACT Now Guidelines',
          link: '/pdfs/ParticipationOrgFileUpload.pdf',
        },
      },
      uploadModal: {
        title: 'Upload Participation File',
      },
      templateName: 'Participating organizations template',
    },
    organization: {
      emptyState:
        'Please upload a Participation file via the Files tab, or select  "show not invited" to invite organizations individually.',
    },
    participation: {
      inviteAccepted: 'Invitation accepted',
      inviteDeclined: 'Invitation declined',
      acceptInvite: 'Confirm participation',
      declineInvite: 'Decline participation',
      reason: 'Reason',
      youreInvited: 'You have been invited',
      declineReason: 'Reason for declining',
      participationResponse: 'Participation response',
      respond: 'Respond',
      edit: 'Edit your response',
      confirm: 'Confirm address',
      errorSummary: 'Error summary',
      participationConfirmed: 'Participation confirmed.',
      clickToRespond: 'Click Respond button below to confirm or decline participation.',
      autoDecline: 'Invitation auto-declined due to lack of response.',
      modal: {
        receiptDates: 'Materials receipt dates',
        receiptInfo:
          'Select the earliest window you will test, and your preferred receipt week. Materials will only ship for students that have been assigned to a test event. Refer to your schedule of events for more information.',
        testWindow: 'Test window',
        receiptWeek: 'Receipt week',
        testCoordinator: 'Primary test coordinator',
        shippingContract: 'Shipping contact',
        shippingAddress: 'Shipping address',
        optional: 'Optional',
        poBox: 'Don’t use P.O. boxes',
        email: 'E-mail',
        phone: 'Work phone',
        ordersProcessed:
          'Orders have already been processed. This update will only impact future orders.',
        deadlineExpired: 'The deadline to complete steps 1 and 2 has expired.',
        eventAutoAssign: 'Auto-assign students to the selected test event?',
        confirmEventAutoAssign: 'Students will be automatically assigned through ',
      },
    },
    overview: {
      invitationStatus: {
        title: 'Invitation status',
        invitedSchool: 'School invited',
        notInvitedSchool: 'School not invited',
      },
      participationStatus: {
        title: 'Participation status',
        pending: 'Response pending',
        participating: 'Participating',
        notParticipating: 'Not participating',
      },
      emptyState:
        'There are no details to display. Please select the Files tab to upload your Participation file.',
    },
    users: {
      active: 'User can be assigned to a role.',
      invited: 'Awaiting invitee response.',
      emptyState: {
        title: 'No staff added yet',
        description:
          'In order to add staff to your organization, please upload a file that contains personnel or individually add staff',
        uploadButton: 'Upload file',
        inviteButton: 'Individual invite',
      },
      modal: {
        title: {
          inviteOrAuthorize: 'Invite individual',
          edit: 'Staff details',
        },
        successMessage: 'Existing user found!',
        infoMessage: '<b>No results found for this email address:</b>',
        infoMessage2: 'Invitee is not registered, or used a different email address.',
        desc: 'Enter the email address of the user you want to add to this administration.',
        confirmation: {
          cancel: 'Are you sure you want to exit without saving?',
          remove:
            'Are you sure? This action will remove the organization and any roles associated to it.',
        },
      },
      searchPlaceholder: 'Search by name or email',
    },
    bulkNotification: {
      sendReminder: 'Send reminder',
      modalTitle: 'Bulk notifications',
      sendNotification: 'Send notification',
      notificationType: 'Notification type',
      notificationPreview: 'Notification preview',
      aditionalText: 'Additional notification text',
      optional: '(optional)',
      success: 'Notification successfully sent.',
    },
  },
  testManagement: {
    breadcrumbsBase: 'Test Events',
    buttons: {
      seeTestEvents: 'See test events',
    },
    testEvents: {
      TCMLauncher: {
        title: 'Launch ACT Test Center Manager™ (TCM)',
        description: {
          bold: 'TCM ',
          normal:
            'is the interactive, digital application used by test site staff to prepare for and deliver the ACT test. TCM is available in web, Android, and iOS formats. To learn more, launch TCM and view user resources.',
        },
        button: 'Launch TCM',
      },
      labels: {
        submitted: 'Submitted',
        inTransit: 'In transit',
        delivered: 'Delivered',
        rejected: 'Rejected',
        approved: 'Approved',
        orderedDate: 'Ordered date',
        orderType: 'Order type',
        contactInformation: 'Contact information',
        contactName: 'Contact name',
        phone: 'Phone #',
        extension: 'Extension #',
        shippingAddress: 'Shipping address',
        address: 'Address',
        city: 'City',
        state: 'State',
        zip: 'Zip',
        carrier: 'Carrier',
        organization: 'Organization:',
        order: 'Order',
      },
      tiles: {
        studCount: 'Students count',
        assignedEvent: 'Assigned to event',
        standardPaper: 'Standard paper',
        standardOnline: 'Standard online',
        roomsAndStaff: 'TCM Rooms & Staff',
        roomsCreated: 'Rooms created',
        roomsStaffed: 'Rooms staffed',
      },
      messages: {
        declined: 'This organization declined participation for this administration.',
        notAccepted: 'This organization has not confirmed participation for this administration.',
        noShipments: 'There are no shipments to display at this time for this administration.',
        noDetails: 'There are no details to display at this time for this administration.',
        noOrders: 'No orders yet',
      },
      shipmentsModals: {
        title: 'Order details',
      },
    },
  },
  table: {
    noResults: {
      clear: 'Clear search and filters',
      zeroResults: () => (
        <>
          There are <strong>0</strong> results available. Try clearing search and/or filters.
        </>
      ),
    },
  },
};

const messages = {
  success: {
    changedPassword: {
      title: 'Password changed',
      body: 'Your password has been successfully changed',
    },
    codeSentNotification: 'Code was successfully sent to your email',
    registerComplete: `We've sent you an email. Use the link in the email to verify your account.`,
    emailVerificationComplete: {
      title: 'Account activated',
      body: 'Congratulations! Your account has been activated.',
    },
    securePassword: 'Your password is secure',
    personalInformationUpdated: 'Personal information successfully updated',
    fileUpload: 'File is being processed',
    editStudent: 'Student details saved',
    addStudent: 'Student successfully added.',
  },
  error: {
    connection: 'Connection error. There was a problem connecting to the server. Please try again.',
    generic: 'Something went wrong. Please try again.',
    submitAgain: 'Something went wrong. Please try submitting again.',

    tnc: 'In order to proceed, you must be 18 years of age and agree to the terms and conditions',

    emailRequired: 'Email address is required',
    emailInvalid: 'Invalid format for email address',
    emailSameAsUser: 'Same e-mail as the logged in user',
    emailTaken: 'An account with this information already exists',
    emailNotRegistered: 'Email is not registered.',

    passwordRequired: 'Password is required',
    validationRequired: 'Validation code is required',
    passwordInvalid:
      'Password must include at least 1 digit, 1 uppercase letter, 1 special character, no spaces',

    firstNameRequired: 'First name is required',
    lastNameRequired: 'Last name is required',
    middleInitialReqired: 'Middle initial is required',
    studentIDRequired: 'State Student ID is required',
    nameInvalid: `This field can’t contain any of the following characters: $ < > ~ * % : ( ) = + " ^ ! @ # & _ { }  \` ; / | ?`,
    nameInvalidNumbers: `This field can’t contain any of the following characters: $ < > ~ * % : = + " ^ ! @ { } \` | ? \\`,
    onlyAlphanumeric: `This field can only contain alphanumeric characters`,
    onlyNumeric: 'This field can only contain numeric values',
    onlyAlpha: 'This field can only contain letters',
    onlyUppercase: 'This field can only contain uppercase letters',
    firstPhoneRequired: 'Work phone number is required',
    firstPhoneInvalid: 'Phone number must have 10 digits',
    alphaNumericNameWithAt: `This field can’t contain any of the following characters: $ < > ~ * % : = + " ^ ! { } \` | ? \\`,
    onlyAlphanumericAndSymbols: `This field can only contain letters, numbers, and the following special symbols: dash (-), period (.), comma (,), apostrophe, and space`,
    invalidValidationCode: 'Invalid validation code',
    addressPOBox: 'Must be a physical address (no PO Boxes)',
    zipCodeInvalid: 'Zip code must have maximum 9 digits',
    zipCodeLength: 'Zip code must be 5 digits long',
    expiredEmailVerificationLink: {
      title: 'Expired link',
      body: 'The link has expired. To generate another link, enter your email address below.',
    },

    emailVerification: 'Verification unsuccessful',
    emailAlreadyVerified: {
      title: 'User is already verified',
      body: 'No need to verify again.',
    },
    dobRequired: 'Date of birth is required',
    invalidFileFormat: '<b>Invalid file type.</b> Please use only files with .csv extension.',

    fieldIsRequired: 'Field is required.',

    reCaptcha: 'Please verify that you are not a robot.',
    dateFormat: 'Format must be MM/DD/YYYY.',
    invalidDate: 'Invalid date.',
    incorrectDate: 'date is incorrect. Year must be between',
    reportingHighSchoolCodeError: 'Cannot contain all the 6 digits as zero',
  },
  warning: {
    multipleRegistrationsUpdate: 'Update will be applied to all records for this student',
    onlyNumbers: 'Only numbers are allowed',
    passwordHint:
      'Minimum of 10 characters, 1 digit, 1 uppercase letter, 1 lowercase letter, 1 special character, no spaces',
  },
  info: {
    mustSearchFirst: 'Search by student or go to Organization to view students by school.',
    orgEmtyState:
      'There are no organizations to display. Please select the Files tab to upload your Participation File.',
    registrationEmptyState:
      'There are no details to display. Participation file has not been loaded and/or school(s) status is not “Participating.”',
    contractEmptyState:
      'There are no organizations to display. Please select the Files tab to upload your Organization Master File.',
    testManagementEmptyState:
      'There are no details to display. Student data has not been loaded and/or school(s) status is not “Participating.”',
    editStudentModal:
      'Update student record in the fields below. Do not use to add, remove, or transfer students.',
  },
  noInternet: {
    title: 'There is no internet connection',
    desc: 'Please check your connection and retry',
    reconnecting: 'Reconnecting...',
  },
};

export { clickables, common, features, messages };

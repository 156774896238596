import React from 'react';
import FormHeader from '../forms/FormHeader';

export default function ConfirmationMessage(props) {
  const { title, message, buttonAction, buttonText } = props;

  return (
    <div className="formComplete__Container">
      <FormHeader title={title} description={message} />
      {buttonText && (
        <button type="button" className="bigButton" onClick={buttonAction}>
          {buttonText}
        </button>
      )}
    </div>
  );
}

ConfirmationMessage.displayName = 'ConfirmationMessage';
ConfirmationMessage.defaultProps = {
  title: '',
  message: '',
  buttonText: '',
};

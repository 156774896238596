import React, { useCallback, useContext, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';

import { Menu, MenuItem, Tooltip } from '@material-ui/core';

import Cancel from '@material-ui/icons/CancelOutlined';
import Edit from '@material-ui/icons/EditOutlined';
import { ReactComponent as DropdownArrow } from '../../../assets/svgs/blueDropdownArrow.svg';
import { ReactComponent as Pen } from '../../../assets/svgs/pen.svg';

import TextContext from '../../../core/providers/TextProvider';

import { DARK_GREY, BACKGROUND_OFF_WHITE, BORDER_GRAY } from '../../../styles/base/_settings.scss';

const StyledMenu = withStyles({
  paper: {
    boxShadow: `0 0 0.5rem ${BORDER_GRAY}`,
    border: `0.1rem solid ${BORDER_GRAY}`,
  },
})(props => (
  <Menu
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    elevation={0}
    getContentAnchorEl={null}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    fontSize: '1.4rem',
    fontFamily: 'Montserrat',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
    },
    '& svg': {
      width: '2rem',
      height: '2rem',
    },
  },
}))(MenuItem);

const LightTooltip = withStyles(() => ({
  arrow: {
    color: BACKGROUND_OFF_WHITE,
    '&:before': {
      border: `0.1rem solid ${BORDER_GRAY}`,
    },
  },
  tooltip: {
    backgroundColor: BACKGROUND_OFF_WHITE,
    border: `0.1rem solid ${BORDER_GRAY}`,
    borderRadius: '0.8rem',
    boxShadow: `0 0 0.5rem ${BORDER_GRAY}`,
    color: DARK_GREY,
    fontSize: '14px',
    padding: '1.3rem',
  },
}))(Tooltip);

const ProfileButtons = ({
  currentOrg,
  hasStudentFilesPermission,
  hasUsersManagePermissions,
  hasUsersViewPermissions,
  item,
  openEditModal,
  openInviteModal,
  openUnenrollModal,
}) => {
  /**
   * useContext()
   */
  const Text = useContext(TextContext);

  /**
   * useState()
   */
  const [anchorEl, setAnchorEl] = useState(null);

  /**
   * useCallback()
   */
  const handleClick = useCallback(
    e => anchorEl !== e.currentTarget && setAnchorEl(e.currentTarget),
    [anchorEl]
  );

  const handleClose = useCallback(() => setAnchorEl(null), []);

  const handleKeyDown = useCallback(
    (e, item, hasUsersViewPermissions) =>
      e.keyCode === 13 && hasUsersViewPermissions ? openInviteModal(item) : openEditModal(item),
    [openEditModal, openInviteModal]
  );

  const onKeyDown = useCallback(e => e.keyCode === 13 && handleClick(e), [handleClick]);

  /**
   * render
   */
  if (hasUsersViewPermissions || (hasUsersManagePermissions && !currentOrg?.testCenterFlag))
    return (
      <Pen
        aria-label={Text.features.orgManagement.participation.edit}
        className="link profile-button"
        onClick={() => (hasUsersViewPermissions ? openInviteModal(item) : openEditModal(item))}
        onKeyDown={e => handleKeyDown(e, item, hasUsersViewPermissions)}
        tabIndex="0"
      />
    );

  if (hasStudentFilesPermission && currentOrg?.testCenterFlag)
    return (
      <>
        <LightTooltip title={item.value ?? ''} arrow>
          <span>{item.value}</span>
        </LightTooltip>
        <DropdownArrow
          aria-controls="customized-menu"
          aria-haspopup="true"
          className="link profile-button-menu"
          onClick={handleClick}
          onKeyDown={event => onKeyDown(event)}
          tabIndex="0"
        />
        <StyledMenu
          anchorEl={anchorEl}
          anchorOrigin={{
            horizontal: 'center',
            vertical: 'bottom',
          }}
          disableScrollLock={true}
          id="customized-menu"
          keepMounted
          onClose={handleClose}
          open={Boolean(anchorEl)}
          transformOrigin={{
            horizontal: 'center',
            vertical: 'top',
          }}>
          <StyledMenuItem>
            <div
              onClick={() => {
                openEditModal(item);
                handleClose();
              }}>
              <div className="edit-container">
                <Edit className="link" />
              </div>
              <span className="link" style={{ marginLeft: '0.5rem' }}>
                {Text.features.roster.modal.edit}
              </span>
            </div>
          </StyledMenuItem>
          <StyledMenuItem>
            {openUnenrollModal && (
              <div
                onClick={() => {
                  openUnenrollModal(item);
                  handleClose();
                }}
                className="unenroll">
                <div className="delete-icon" />
                <span className="link" style={{ marginLeft: '0.5rem' }}>
                  {Text.features.roster.modal.unenroll}
                </span>
              </div>
            )}
          </StyledMenuItem>
        </StyledMenu>
      </>
    );

  if (hasStudentFilesPermission && openEditModal)
    return (
      <div className="action-cell">
        <LightTooltip title={item.value ?? ''} arrow>
          <div>{item.value}</div>
        </LightTooltip>
        <LightTooltip title={Text.features.roster.modal.edit} arrow>
          <Edit className="link" onClick={() => openEditModal(item.row.original)} />
        </LightTooltip>
      </div>
    );

  if (!hasStudentFilesPermission && openUnenrollModal)
    return (
      <div
        onClick={() => (hasStudentFilesPermission ? openUnenrollModal(item.regId) : {})}
        className="action-cell link">
        <LightTooltip title={item.value ?? ''} arrow>
          <span>{item.value}</span>
        </LightTooltip>
        <div onClick={() => openUnenrollModal(item.regId)}>
          <Cancel />
          <span className="link">{Text.features.roster.modal.unenroll}</span>
        </div>
      </div>
    );

  return null;
};

export default ProfileButtons;

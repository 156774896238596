import React from 'react';
// import './Tooltip.scss';

const TooltipElement = props => {
  const { content, children, className } = props;

  return (
    <div className={`tooltip ${className}`}>
      {children}
      <div className="tooltiptext tooltip__top" dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );
};

export default TooltipElement;

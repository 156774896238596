import React, { useState } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { withStyles } from '@material-ui/core/styles';

const CustomSubmenuItem = withStyles(() => ({
  root: {
    fontSize: '1.4rem',
    lineHeight: '2.4rem',
    padding: '0',
    fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}))(MenuItem);

const AssingnStaffInTCMButton = ({ availableEvents, appUrl }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <button className="bigButton AssignStaffButton" onClick={handleClick}>
        Assign staff in TCM
        <FontAwesomeIcon icon={faChevronDown} />
      </button>
      {availableEvents.length > 0 && (
        <Menu
          id="events"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          getContentAnchorEl={null}
          MenuListProps={{ disablePadding: true }}
          onClose={() => setAnchorEl(null)}
          disableScrollLock={true}
          keepMounted
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}>
          <div className="AssignStaff__DropDown">
            Select test event
            <hr />
            <div className="AssignStaff__EventsContainer">
              {availableEvents.map(event => {
                return (
                  <CustomSubmenuItem onClick={handleClose} key={event.testCenterEventId}>
                    <a
                      href={`${appUrl.data}/test-event/${event.testCenterEventId}/staff-and-rooms`}>
                      {event.testingEvent}
                    </a>
                  </CustomSubmenuItem>
                );
              })}
            </div>
          </div>
        </Menu>
      )}
    </div>
  );
};

export default AssingnStaffInTCMButton;

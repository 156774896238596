import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { generatePath } from 'react-router';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import _ from 'lodash/fp';
import propTypes from 'prop-types';

import { ReactComponent as TitleDropdownArrow } from '../../assets/svgs/blueDropdownArrow.svg';
import { ReactComponent as CheckMark } from '../../assets/svgs/blueCheckmark.svg';

import RosterServices from '../../services/RosterServices';

import TextInput from '../inputs/TextInput';

import TextContext from '../../core/providers/TextProvider';

import './OrgSelect.scss';

const OrgSelect = ({ currentOrg = {}, parentOrg }) => {
  /**
   * react-router-dom hooks
   */
  const history = useHistory();
  const match = useRouteMatch();
  const params = useParams();

  /**
   * useContext()
   */
  const Text = useContext(TextContext);

  /**
   * useRef()
   */
  const dropdownRef = useRef(null);

  /**
   * useState()
   */
  const [childOrganizationsResponse, setChildOrganizationsResponse] = useState([]);
  const [dropdown, setDropdown] = useState({ display: false });

  /**
   * useMemo()
   */
  const siblingOrganizations = useMemo(() => {
    if (!childOrganizationsResponse?.data) return [];
    return childOrganizationsResponse.data
      .reduce((acc, org) => {
        if (org.orgPartId === currentOrg.orgPartId) return [org, ...acc];
        return [...acc, org];
      }, [])
      .filter(
        org =>
          org.orgPartId &&
          (!dropdown.searchedValue || org.orgname.toLowerCase().includes(dropdown.searchedValue))
      );
  }, [childOrganizationsResponse, currentOrg.orgPartId, dropdown.searchedValue]);

  /**
   * useCallback()
   */
  const clearInput = useCallback(() => setDropdown(prev => ({ ...prev, searchedValue: '' })), []);

  const handleSearchChange = useCallback(e => {
    const searchedValue = e.target.value.toLowerCase();
    setDropdown(prev => ({ ...prev, searchedValue }));
  }, []);

  const toggleDropdown = useCallback(() => {
    setDropdown(prev => ({ display: !prev.display, searchedValue: '' }));
  }, []);

  const handleClick = useCallback(
    e => {
      if (dropdownRef.current && !dropdownRef.current?.contains(e.target)) {
        toggleDropdown();
      }
    },
    [toggleDropdown]
  );

  const handleSelection = useCallback(
    org => {
      if (org.orgPartId.toString() === params.orgPartId) return;
      const url = generatePath(match.path, {
        orgPartId: org.orgPartId,
        tabName: params.tabName,
      });
      history.push(url);
      toggleDropdown();
    },
    [history, match.path, params.orgPartId, params.tabName, toggleDropdown]
  );

  /**
   * useEffect()
   */
  useEffect(() => {
    document.addEventListener('mousedown', handleClick, false);
    return () => {
      document.removeEventListener('mousedown', handleClick, false);
    };
  }, [handleClick]);

  useEffect(() => {
    if (_.isEmpty(parentOrg)) return;
    const { adminId, orgPartId } = parentOrg;
    RosterServices.getChildOrgs({ adminId, orgPartId }).then(response => {
      setChildOrganizationsResponse(response.data);
    });
  }, [parentOrg]);

  /**
   * render
   */
  if (!currentOrg.orgPartId) return null;

  return (
    <div className="select__Organization__Container">
      <h1
        className={`title__Container ${
          childOrganizationsResponse?.data?.length > 1 &&
          currentOrg.orgPartId !== parentOrg.orgPartId
            ? 'clickable__Title'
            : ''
        }`}
        id="main"
        onClick={
          childOrganizationsResponse?.data?.length > 1 &&
          currentOrg.orgPartId !== parentOrg.orgPartId
            ? toggleDropdown
            : undefined
        }
        tabIndex={childOrganizationsResponse?.data?.length > 1 ? '0' : '-1'}>
        <span>
          {currentOrg.orgName} ({currentOrg.orgCode})
        </span>
        {childOrganizationsResponse?.data?.length > 1 &&
          currentOrg.orgPartId !== parentOrg.orgPartId && (
            <TitleDropdownArrow onClick={() => toggleDropdown} />
          )}
      </h1>
      {dropdown.display && (
        <div className="Organizations__Dropdown" ref={dropdownRef}>
          <div className="arrow"></div>
          <div className="Organization__Search">
            <TextInput
              className={'field__Input field__Container'}
              handleChange={handleSearchChange}
              inputValue={dropdown.searchedValue}
              placeholder={Text.common.fields.search}
            />
            {dropdown.searchedValue?.length > 0 && (
              <span className="clear" onClick={() => clearInput()}>
                {Text.common.labels.clear}
              </span>
            )}
          </div>
          {siblingOrganizations.length > 0 ? (
            <ul>
              {siblingOrganizations.map(org => {
                const selected = org.orgPartId === currentOrg.orgPartId;
                return (
                  <li
                    className={selected ? 'selected' : ''}
                    key={org.orgPartId}
                    onClick={() => handleSelection(org)}>
                    {selected && <CheckMark className="dropdown__Checkmark" />}
                    {org.orgname} ({org.orgcode})
                  </li>
                );
              })}
            </ul>
          ) : (
            <p>{Text.features.roster.noResultsAvailable}</p>
          )}
        </div>
      )}
    </div>
  );
};

OrgSelect.propTypes = {
  currentOrg: propTypes.object,
  parentOrg: propTypes.object,
};

export default OrgSelect;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { actions as loginActions } from '../../features/login/duck';
import { features, clickables } from '../../core/i18n/text';
import Auth from '../../core/services/AuthToken';

const Text = { features };

export default function ProfileBox(props) {
  const dispatch = useDispatch();
  const isLogoutSuccess = useSelector(state => state.loginReducer.logout.data);
  const userInfo = useSelector(state => state.userInfoReducer.userInfo);

  const history = useHistory();
  const match = useRouteMatch();

  const handleLogout = () => {
    dispatch(loginActions.logout());
  };

  const handleOnKeyDown = e => {
    if (e.keyCode === 13) {
      handleLogout();
    }
  };

  React.useEffect(() => {
    if (isLogoutSuccess) {
      window.localStorage.removeItem('isSidebarOpen');
      window.sessionStorage.clear();
      Auth.remove();
    }
  }, [isLogoutSuccess]);

  const handleEditProfileInfo = () => {
    history.push(`/personalInformation/orgPartId/${parseInt(match.params.orgPartId)}`, {
      edit: true,
      from: window.location.pathname,
    });
  };

  return (
    <div className="profileBox__Container">
      <div className="profileBox__Name__Wrapper">
        <div className="profileBox__Name">
          {userInfo && `${userInfo.data.lastName}, ${userInfo.data.firstName}`}
        </div>
      </div>
      <p className="profileBox__email">{userInfo && userInfo.data.emailAddress}</p>
      <div className="profileBox__Controls">
        <button
          type="button"
          className="bigButton profileBox__button"
          onClick={handleEditProfileInfo}>
          {clickables.buttons.editProfile}
        </button>
        <span
          className="profileBox__link link"
          onClick={handleLogout}
          tabIndex="0"
          onKeyDown={handleOnKeyDown}>
          {Text.features.header.logout}
        </span>
      </div>
    </div>
  );
}

ProfileBox.displayName = 'ProfileBox';

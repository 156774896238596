import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './AdministrationUsers.scss';
import * as Text from '../../../core/i18n/text';
import { actions as orgManagementActions } from '../duck';
import AlertModal from '../../../components/modal/AlertModal';
import Input from '../../../components/forms/fields/Input';
import DropDown from '../../../components/inputs/DropDown';
import Alert from '../../../components/alerts/Alert';
import Loader from '../../../components/loader/Loader';
import _ from 'lodash/fp';
import Validator from 'email-validator';
import { getPermission } from '../../../core/duck/selectors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as BinIcon } from '../../../assets/svgs/Bin.svg';
import { ReactComponent as SuccessIcon } from '../../../assets/svgs/success.svg';

let closeConfirmationFromCancel = false;
let userEntry = null;
let orgPartIdToRemove = null;
let orgIndexToRemove = null;
let mainSelector;

const mapRoles = (rolesList, possibleRoles = []) => {
  let roles = [];
  if (possibleRoles.length) {
    rolesList.forEach(role => {
      roles.push({
        label: role.name,
        value: role.id,
        isFixed: !possibleRoles.find(r => r.id === role.id),
      });
    });
  } else {
    rolesList.forEach(role => {
      roles.push({ label: role.name, value: role.id });
    });
  }

  return roles;
};

class AssignUserRolesModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalDescription: true,
      modalType: '',
      email: '',
      firstName: '',
      lastName: '',
      isInvited: false,
      personnelId: '',
      isLoading: false,
      userEntryAssignments: [],
      errors: {
        email: '',
        organization: {},
        firstName: '',
        lastName: '',
        roles: {},
        generic: '',
      },
      orgDropdown: [],
      orgsAndRoles: [
        {
          orgPartId: '',
          orgPartLabel: '',
          orgCode: '',
          selectedRoles: [],
          possibleRoles: [],
          hadPreviousRoles: false,
        },
      ],
      secondaryButton: {
        text: '',
        action: null,
        redButton: false,
      },
      showConfirmationModal: false,
      showRemoveModal: false,
      userStatus: '',
      serverError: null,
      orgsToDelete: [],
    };

    this.initialState = _.cloneDeep(this.state);
    delete this.initialState.orgDropdown;
  }

  componentDidMount() {
    mainSelector = JSON.parse(window.sessionStorage.getItem('mainSelector'));
    if (!_.get('orgPartDescendants.data')(this.props)) {
      this.props.actions.getOrgPartDescendants(
        this.props.match.params.orgPartId,
        mainSelector.admin.value.value
      );
    } else {
      this.populateOrgs(this.props.orgPartDescendants.data);
    }

    this.setState(state => {
      return {
        showModalDescription: true,
        modalType: 'emailSearch',
      };
    });
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.verifiedUserByEmail)(this.props.verifiedUserByEmail)) {
      if (this.state.orgDropdown.length === 1 && this.state.orgsAndRoles[0].orgPartId === '') {
        this.handleOrgChange(this.state.orgDropdown[0], '');
      }
    }

    if (this.props.userEntry !== userEntry) {
      userEntry = this.props.userEntry;
      if (this.props.userEntry) {
        let name = this.props.userEntry.personnelName.split(', ');
        this.setOrgsAndRoles(userEntry, false);
        this.setState({
          isLoading: true,
          firstName: name[1].trim(),
          lastName: name[0].trim(),
          email: this.props.userEntry.emailAddress,
          userStatus: this.props.userEntry.status,
          personnelId: this.props.userEntry.personnelId,
          //TODO: userEntry needs to include orgPartId so that preselected values can be set
        });

        this.setState(state => {
          return {
            showModalDescription: false,
            modalType: 'edit',
          };
        });
        // if (this.state.orgDropdown.length === 1) {
        //   this.handleOrgChange(this.state.orgDropdown[0], '', this.props.userEntry.emailAddress);
        // }
      } else {
        this.setState(state => {
          return {
            showModalDescription: true,
            modalType: 'emailSearch',
          };
        });
      }
    }
    if (
      !_.isEqual(prevProps.orgPartDescendants)(this.props.orgPartDescendants) &&
      _.get('orgPartDescendants.data')(this.props)
    ) {
      this.populateOrgs(this.props.orgPartDescendants.data);
    }
    if (!_.isEqual(prevProps.saveInactiveUserRoles)(this.props.saveInactiveUserRoles)) {
      this.handleInvitationResponse(this.props.saveInactiveUserRoles);
    }
    if (!_.isEqual(prevProps.userAuthorizationRoles)(this.props.userAuthorizationRoles)) {
      this.handleInvitationResponse(this.props.userAuthorizationRoles);
    }
    if (!_.isEqual(prevProps.userInvitationRoles)(this.props.userInvitationRoles)) {
      this.handleInvitationResponse(this.props.userInvitationRoles);
    }
    if (!_.isEqual(prevProps.saveActiveUserRoles)(this.props.saveActiveUserRoles)) {
      this.handleInvitationResponse(this.props.saveActiveUserRoles);
    }
  }

  fetchAssignments = async (userEntry, inviteUserFlag = false) => {
    let assignmentsList;

    await this.props.actions.getUserPartDetails({
      orgPartId: this.props.currentOrg.orgPartId,
      adminId: this.props.currentOrg.adminId,
      email: userEntry.emailAddress.trim(),
    });
    //set selected assignment as first
    assignmentsList = _.cloneDeep(this.props.userPartDetails);

    if (!inviteUserFlag) {
      assignmentsList.splice(
        assignmentsList.findIndex(u => +u.orgPartId === +userEntry.orgPartId),
        1
      );
      assignmentsList.unshift(userEntry);
    }

    if (assignmentsList.length) {
      await this.props.actions.getAllUserRoles(assignmentsList);
      assignmentsList.forEach(assignment => {
        assignment.existentRoles = this.props.allUserRoles.data.find(
          el => el.orgPartId === assignment.orgPartId
        ).list;
      });
    }
    return assignmentsList;
  };

  setOrgsAndRoles = async (userEntry, inviteUserFlag) => {
    let clonedOrgsAndRoles = _.cloneDeep(this.state.orgsAndRoles);
    let possibleRoles = this.props.possibleUserRoles.data;
    let userEntryAssignments = userEntry
      ? await this.fetchAssignments(userEntry, inviteUserFlag)
      : [];

    if (userEntryAssignments.length) {
      userEntryAssignments.forEach((userAssignment, idx) => {
        clonedOrgsAndRoles[idx] = {
          ...this.initialState.orgsAndRoles[0],
          orgPartId: userAssignment.orgPartId,
          orgPartLabel: userAssignment.orgName,
          orgCode: userAssignment.orgCode,
          selectedRoles: mapRoles(userAssignment.existentRoles, possibleRoles),
          possibleRoles: mapRoles(possibleRoles),
          hadPreviousRoles: !!userAssignment.orgPartId,
        };
      });
    } else {
      clonedOrgsAndRoles[0] = {
        ...clonedOrgsAndRoles[0],
        possibleRoles: mapRoles(possibleRoles),
      };
    }

    this.setState({
      orgsAndRoles: clonedOrgsAndRoles,
      isLoading: false,
      isInvited: Boolean(userEntryAssignments.length),
      userEntryAssignments,
    });
  };

  handleInvitationResponse = response => {
    if (_.get('error.response.data')(response)) {
      this.setState({ serverError: response.error.response.data });
    }
    if (_.get('data')(response)) {
      this.handleModalClose();
    }
  };

  populateOrgs = orgs => {
    let options = [];
    orgs.map(org => {
      return options.push({ label: org.orgName, value: org.orgPartId, code: org.orgCode });
    });
    if (options.length === 1) {
      const { userEntry } = this.props;
      const { userEntryAssignments } = this.state;
      const user = userEntry || userEntryAssignments[0];
      this.handleOrgChange(options[0], '', user?.emailAddress || null);
    }
    this.setState({ orgDropdown: options });
  };

  handleModalClose = () => {
    this.setState(this.initialState);
    userEntry = null;
    this.props.close();
  };

  handleInviteClose = cancel => {
    closeConfirmationFromCancel = cancel === true;
    this.state.modalType === 'emailSearch'
      ? this.handleModalClose()
      : this.setState({ showConfirmationModal: true });
  };

  handleConfirmationModalClose = () => {
    this.setState({ showConfirmationModal: false });
  };

  handleCancel = () => {
    this.handleConfirmationModalClose();
    if (closeConfirmationFromCancel) {
      this.setState(this.initialState);
      this.setState(state => {
        return {
          showModalDescription: true,
          modalType: 'emailSearch',
        };
      });
    } else {
      this.handleModalClose();
    }
  };

  handleRoleChange = (e, idx) => {
    let orgsAndRoles = _.cloneDeep(this.state.orgsAndRoles);
    let errors = _.cloneDeep(this.state.errors);
    orgsAndRoles[idx].selectedRoles = e === null ? [] : e;
    let errFlag = orgsAndRoles[idx].selectedRoles.length;
    errors.roles[idx] = errFlag === 0;

    this.setState({
      orgsAndRoles,
      errors,
    });
  };

  handleOrgChange = (e, oldOrgId, email = null, originalId) => {
    let orgsAndRoles = _.cloneDeep(this.state.orgsAndRoles);
    let errors = _.cloneDeep(this.state.errors);
    let currentSelIdx = orgsAndRoles.findIndex(x => +x.orgPartId === +oldOrgId);
    let existentAssignment = this.state.userEntryAssignments.find(el => +el.orgPartId === +e.value);

    if (currentSelIdx >= 0) {
      orgsAndRoles[currentSelIdx].orgPartId = e.value;
      orgsAndRoles[currentSelIdx].orgPartLabel = e.label;
      orgsAndRoles[currentSelIdx].orgCode = e.code;
      orgsAndRoles[currentSelIdx].selectedRoles = existentAssignment
        ? mapRoles(existentAssignment.existentRoles, orgsAndRoles[currentSelIdx].possibleRoles)
        : [];
      errors.organization[currentSelIdx] = false;
      this.setState({ orgsAndRoles, errors });
    }
    if (originalId && originalId !== e.value && originalId.length) {
      if (this.state.orgsToDelete.length === 0) {
        this.setState({
          orgsToDelete: [originalId],
        });
      } else {
        const deleteOrgId = this.state.orgsToDelete.find(item => item === originalId);
        const organizations = this.state.orgsToDelete;
        if (deleteOrgId !== originalId) {
          organizations.push(originalId);
          this.setState({
            orgsToDelete: organizations,
          });
        }
      }
    } else {
      const removeDeleted = this.state.orgsToDelete.filter(item => item !== originalId);
      this.setState({
        orgsToDelete: removeDeleted,
      });
    }

    /* this causes an issue when e.value is null for a given org option */
    // this.props.actions.getUserRoles(e.value, email ? email : this.state.email);
  };

  onInputChange = (event, type) => {
    const inputValue = event.target.value.trim();
    const inputName = event.target.name;

    this.setState(prevState => ({
      [inputName]: inputValue,
      modalType: type,
      errors: {
        ...prevState.errors,
        [inputName]: '',
      },
    }));
  };

  handleEmailSubmit = async e => {
    e.preventDefault();
    if (this.validateEmailInput()) {
      this.setState({
        isLoading: true,
      });
      await this.props.actions.verifyUserByEmail(
        this.state.email.trim(),
        this.props.orgHierarchy.data[this.props.orgHierarchy.data.length - 1].orgPartId
      );
      if (
        _.get('data')(this.props.verifiedUserByEmail) &&
        this.props.verifiedUserByEmail.data.length > 0
      ) {
        this.setOrgsAndRoles(this.props.verifiedUserByEmail.data[0], true);
        this.setState({
          firstName: this.props.verifiedUserByEmail.data[0].firstName,
          lastName: this.props.verifiedUserByEmail.data[0].lastName,
          personnelId: this.props.verifiedUserByEmail.data[0].personnelId,
        });
        this.props.verifiedUserByEmail.data[0].personnelId
          ? this.setState(state => {
              return {
                showModalDescription: false,
                modalType: 'existingAccount',
                secondaryButton: {
                  text: 'Cancel',
                  redButton: false,
                  action: () => this.handleInviteClose(true),
                },
              };
            })
          : this.setState(state => {
              return {
                showModalDescription: false,
                modalType: 'newAccount',
                secondaryButton: {
                  text: 'Cancel',
                  redButton: false,
                  action: () => this.handleInviteClose(true),
                },
              };
            });
      } else {
        this.setOrgsAndRoles();
        this.setState(state => {
          return {
            showModalDescription: false,
            modalType: 'newAccount',
            isLoading: false,
            secondaryButton: {
              text: 'Cancel',
              redButton: false,
              action: () => this.handleInviteClose(true),
            },
          };
        });
      }
    }
  };

  validateEmailInput = () => {
    const isEmailEmpty = this.state.email.trim() === '';
    const isEmailValid = Validator.validate(this.state.email.trim());
    const isEmailLoggedIn =
      this.state.email.toUpperCase() === this.props.userInfo.data.emailAddress.toUpperCase();
    const emailError = isEmailEmpty
      ? Text.messages.error.emailRequired
      : !isEmailValid
      ? Text.messages.error.emailInvalid
      : isEmailLoggedIn
      ? Text.messages.error.emailSameAsUser
      : '';
    this.setState(prevState => ({
      errors: {
        ...prevState.errors,
        email: emailError,
      },
    }));
    this.setState(state => {
      return {
        showModalDescription: true,
        modalType: 'emailSearch',
      };
    });
    return !(isEmailEmpty || !isEmailValid || isEmailLoggedIn);
  };

  isStringValid = string => {
    const format = /[$<>~*%:!_@#^&*()?;:="+{}|/|]/;
    return !format.test(string);
  };

  validateDetailsInput = () => {
    let { firstName, lastName, errors } = this.state;

    if (firstName.trim() === '') {
      errors['firstName'] = `${Text.messages.error.firstNameRequired}`;
    } else if (!this.isStringValid(firstName)) {
      errors['firstName'] = `${Text.messages.error.nameInvalid}`;
    } else {
      errors['firstName'] = '';
    }

    if (lastName.trim() === '') {
      errors['lastName'] = `${Text.messages.error.lastNameRequired}`;
    } else if (!this.isStringValid(lastName)) {
      errors['lastName'] = `${Text.messages.error.nameInvalid}`;
    } else {
      errors['lastName'] = '';
    }

    let rolesErrors = {},
      orgErrors = {};
    this.state.orgsAndRoles.forEach((org, index) => {
      if (org.selectedRoles.length === 0) {
        rolesErrors[index] = true;
      } else {
        rolesErrors[index] = false;
      }
      if (org.orgPartId === '') {
        orgErrors[index] = true;
      } else {
        orgErrors[index] = false;
      }
    });
    errors.organization = orgErrors;
    errors.roles = rolesErrors;
    this.setState({ errors });
    const noOrgErrors = Object.values(orgErrors).filter(x => x).length === 0;
    const noRoleErrors = Object.values(rolesErrors).filter(x => x).length === 0;

    return !(!!errors['firstName'] || !!errors['lastName'] || !noOrgErrors || !noRoleErrors);
  };

  handleAuthorize = () => {
    if (this.validateDetailsInput()) {
      let body = {};
      let orgPartInvitations = [];
      this.state.orgsAndRoles.forEach(org => {
        let roles = [];
        org.selectedRoles.forEach(role => {
          const roleObject = this.props.possibleUserRoles.data.find(
            posRole => role.value === posRole.id
          );
          if (roleObject) {
            roles.push(roleObject);
          } else if (role.isFixed) {
            roles.push({ id: role.value });
          }
        });
        orgPartInvitations.push({ orgPartId: org.orgPartId, roles });
      });
      if (this.state.orgsToDelete.length) {
        this.state.orgsToDelete.map(item =>
          orgPartInvitations.push({ orgPartId: item, roles: [] })
        );
      }

      switch (this.state.modalType) {
        case 'newAccount': {
          body = {
            emailAddress: this.state.email,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            admin: mainSelector.admin.value.label,
            orgPartInvitations,
          };
          this.state.isInvited
            ? this.props.actions.saveInactiveUserRoles(body)
            : this.props.actions.inviteUserWithRoles(body);
          break;
        }
        case 'existingAccount': {
          body = {
            personnelId: this.state.personnelId,
            roleAssignments: orgPartInvitations,
          };
          this.state.isInvited
            ? this.props.actions.saveActiveUserRoles(body)
            : this.props.actions.authorizeUserWithRoles(body);
          break;
        }
        case 'edit': {
          if (this.state.userStatus === 'active') {
            body = {
              personnelId: this.state.personnelId,
              roleAssignments: orgPartInvitations,
            };
            this.props.actions.saveActiveUserRoles(body);
          } else {
            body = {
              emailAddress: this.state.email,
              firstName: this.state.firstName,
              lastName: this.state.lastName,
              admin: mainSelector.admin.value.label,
              orgPartInvitations,
            };
            this.props.actions.saveInactiveUserRoles(body);
          }
          break;
        }
        default:
          break;
      }
      // this.handleModalClose();
    }
  };

  handleAddNewOrg = e => {
    e.preventDefault();
    let orgsAndRoles = this.state.orgsAndRoles;
    const possibleRoles = this.props.possibleUserRoles.data;

    orgsAndRoles.push({
      orgPartId: '',
      orgPartLabel: '',
      orgCode: '',
      selectedRoles: [],
      possibleRoles: mapRoles(possibleRoles),
      hadPreviousRoles: false,
    });
    this.setState({ orgsAndRoles });
  };

  handleRemoveClick = (orgToRemove, index) => {
    orgPartIdToRemove = orgToRemove;
    orgIndexToRemove = index;
    this.setState({ showRemoveModal: true });
  };

  handleRemoveModalClose = () => {
    orgPartIdToRemove = null;
    orgIndexToRemove = null;
    this.setState({ showRemoveModal: false });
  };

  confirmRemoval = () => {
    let orgsAndRoles = this.state.orgsAndRoles;
    if (
      this.state.orgsAndRoles.filter(x => x.orgPartId === orgPartIdToRemove)[0].hadPreviousRoles
    ) {
      if (this.state.personnelId) {
        let body = {
          personnelId: this.state.personnelId,
          roleAssignments: [{ orgPartId: orgPartIdToRemove, roles: [] }],
        };
        this.props.actions.saveActiveUserRoles(body);
      } else {
        const mainSelector = JSON.parse(window.sessionStorage.getItem('mainSelector'));
        let body = {
          emailAddress: this.state.email,
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          admin: mainSelector.admin.value.label,
          orgPartInvitations: [{ orgPartId: orgPartIdToRemove, roles: [] }],
        };
        this.props.actions.saveInactiveUserRoles(body);
      }
    }
    orgsAndRoles.splice(orgIndexToRemove, 1);
    this.setState({ orgsAndRoles, showRemoveModal: false });
    if (orgsAndRoles.length === 0) {
      if (userEntry) {
        this.handleModalClose();
      } else {
        this.setState(this.initialState);
        this.setState(state => {
          return {
            showModalDescription: true,
            modalType: 'emailSearch',
          };
        });
      }
    }
  };

  verifyAllSelectedRolesArePossible = idx =>
    this.state.orgsAndRoles[idx].selectedRoles.some(
      el => !this.state.orgsAndRoles[idx].possibleRoles.find(elem => elem.value === el.value)
    );

  generateModalContent() {
    let content = null;
    const { state } = this;

    if (this.state.isLoading) {
      return [<Loader />];
    } else {
      let dropDownArea = (
        <>
          {_(state.orgsAndRoles.length).times(idx => (
            <div className="OrgManagementUsers__OrgAndRoleContainer" key={idx}>
              <DropDown
                label="Organization"
                options={state.orgDropdown.filter(
                  el =>
                    !state.orgsAndRoles.some(
                      org =>
                        +org.orgPartId === +el.value &&
                        +el.value !== +state.orgsAndRoles[idx].orgPartId
                    )
                )}
                name={state.orgsAndRoles[idx].orgPartLabel}
                value={
                  state.orgsAndRoles[idx] && state.orgsAndRoles[idx].orgPartId
                    ? {
                        label: state.orgsAndRoles[idx].orgPartLabel,
                        value: state.orgsAndRoles[idx].orgPartId,
                        code: state.orgsAndRoles[idx].orgCode,
                      }
                    : null
                }
                placeholder={state.orgDropdown.length === 1 ? state.orgDropdown[0].label : null}
                onChange={e =>
                  this.handleOrgChange(
                    e,
                    state.orgsAndRoles[idx].orgPartId,
                    null,
                    this.props.userPartDetails && this.props.userPartDetails[idx]
                      ? this.props.userPartDetails[idx].orgPartId
                      : ''
                  )
                }
                error={state.errors.organization && state.errors.organization[idx]}
                disabled={!this.props.hasUsersManagePermissions || state.orgDropdown.length === 1}
                searchable
                customLabel
                customLabelFunc={option => `${option.label} (${option.code})`}
              />
              {!this.verifyAllSelectedRolesArePossible(idx) && (
                <div className="OrgManagementUsers__OrgDropdownButtonContainer">
                  <button
                    type="button"
                    className="redButton"
                    onClick={() => this.handleRemoveClick(state.orgsAndRoles[idx].orgPartId, idx)}>
                    <BinIcon />
                    Remove
                  </button>
                </div>
              )}
              <DropDown
                label="Roles"
                options={state.orgsAndRoles[idx].possibleRoles}
                value={state.orgsAndRoles[idx].selectedRoles}
                onChange={e => this.handleRoleChange(e, idx)}
                multiSelect
                searchable
                error={state.errors.roles && state.errors.roles[idx]}
              />
              <hr></hr>
            </div>
          ))}
          {state.orgDropdown.length > 1 && (
            <button className="OrgManagementUsers__AddNewRole" onClick={this.handleAddNewOrg}>
              <FontAwesomeIcon className="" icon={faPlus} onClick={this.switchMode} />
              Add role to another organization
            </button>
          )}
        </>
      );
      switch (this.state.modalType) {
        case 'emailSearch': {
          content = [
            <form className="OrgManagementUsers__EmailContainer" onSubmit={this.handleEmailSubmit}>
              <Input
                name={'email'}
                label={Text.common.fields.email}
                value={state.email}
                error={state.errors.email}
                onChange={e => this.onInputChange(e, 'emailSearch')}
              />
              <button
                type="submit"
                className={`bigButton OrgManagementUsers__SearchButton ${
                  state.errors.email.length > 0 ? 'shiftUp' : ''
                }`}>
                Search
              </button>
            </form>,
          ];
          break;
        }
        case 'newAccount': {
          content = [
            <div className="">
              {state.isInvited ? (
                <>
                  <span className="OrgManagementUsers__ExistingName">{state.email}</span>
                  <span className="usersTable__text usersTable__status__invited">
                    {Text.common.labels.invited}
                  </span>
                </>
              ) : (
                <Alert
                  type="info"
                  message={
                    Text.features.orgManagement.users.modal.infoMessage + ' ' + this.state.email
                  }
                  secondText={Text.features.orgManagement.users.modal.infoMessage2}
                  noBkg
                />
              )}
              <form className="OrgManagementUsers__NewAccount">
                <div className="OrgManagementUsers__NameArea">
                  <Input
                    label={Text.common.fields.lastName}
                    error={state.errors.lastName}
                    onChange={e => this.onInputChange(e, 'newAccount')}
                    name={'lastName'}
                    value={state.lastName}
                    maxLength="16"
                  />
                  <Input
                    label={Text.common.fields.firstName}
                    error={state.errors.firstName}
                    onChange={e => this.onInputChange(e, 'newAccount')}
                    name={'firstName'}
                    value={state.firstName}
                    maxLength="12"
                  />
                </div>
                {dropDownArea}
              </form>
            </div>,
          ];
          break;
        }
        case 'existingAccount': {
          content = [
            <form className="OrgManagementUsers__NewAccount">
              {!state.isInvited && (
                <Alert
                  type="successNoBkg"
                  message={Text.features.orgManagement.users.modal.successMessage}
                  noBkg
                  bold
                />
              )}
              <div className="OrgManagementUsers__NameContainer">
                <div>
                  <span className="OrgManagementUsers__ExistingName">
                    {`${state.lastName}, ${state.firstName}`}
                  </span>
                  {state.isInvited && (
                    <span className="usersTable__text usersTable__status__active">
                      <SuccessIcon />
                      {Text.common.labels.active}
                    </span>
                  )}
                </div>
                <span>{state.email}</span>
              </div>
              {dropDownArea}
            </form>,
          ];
          break;
        }
        case 'edit': {
          content = [
            <form className="OrgManagementUsers__NewAccount">
              {state.userStatus === 'active' ? (
                <div className="OrgManagementUsers__NameContainer">
                  <div>
                    <span className="OrgManagementUsers__ExistingName">
                      {`${state.lastName}, ${state.firstName}`}
                    </span>
                    <span className="usersTable__text usersTable__status__active">
                      <SuccessIcon /> {Text.common.labels.active}
                    </span>
                  </div>
                  <span>{state.email}</span>
                </div>
              ) : (
                <>
                  <span className="OrgManagementUsers__ExistingName">{state.email}</span>
                  <span className="usersTable__text usersTable__status__invited">
                    {Text.common.labels.invited}
                  </span>
                  <div className="OrgManagementUsers__NameArea">
                    <Input
                      label={Text.common.fields.lastName}
                      error={state.errors.lastName}
                      onChange={e => this.onInputChange(e, 'edit')}
                      name={'lastName'}
                      value={state.lastName}
                      maxLength="16"
                    />
                    <Input
                      label={Text.common.fields.firstName}
                      error={state.errors.firstName}
                      onChange={e => this.onInputChange(e, 'edit')}
                      name={'firstName'}
                      value={state.firstName}
                      maxLength="12"
                    />
                  </div>
                </>
              )}
              {dropDownArea}
            </form>,
          ];
          break;
        }
        default:
          break;
      }
      if (state.serverError) {
        content.unshift(<Alert type="error" message={state.serverError} />);
      }
      return content;
    }
  }

  render() {
    return (
      <>
        <AlertModal
          show={this.props.showModal}
          title={
            this.state.modalType === 'edit'
              ? Text.features.orgManagement.users.modal.title.edit
              : Text.features.orgManagement.users.modal.title.inviteOrAuthorize
          }
          note={this.state.showModalDescription ? Text.features.orgManagement.users.modal.desc : ''}
          body={this.generateModalContent()}
          close={this.handleInviteClose}
          showButton={!this.state.showModalDescription}
          buttonText={
            this.props.hasUsersManagePermissions
              ? this.state.isInvited || this.state.modalType === 'edit'
                ? 'Save'
                : this.state.modalType === 'newAccount'
                ? 'Invite user'
                : 'Authorize'
              : null
          }
          buttonAction={this.props.hasUsersManagePermissions ? this.handleAuthorize : null}
          secondaryButton={
            this.props.hasUsersManagePermissions
              ? this.state.secondaryButton.text !== ''
                ? this.state.secondaryButton
                : null
              : null
          }
        />
        <AlertModal
          className="ConfirmationModal__Container"
          show={this.state.showConfirmationModal}
          buttonAction={this.handleCancel}
          close={this.handleConfirmationModalClose}
          title=""
          bodyTitle={Text.features.orgManagement.users.modal.confirmation.cancel}
          showButton={true}
          buttonText="Cancel changes"
          secondaryButton={{
            text: 'Back',
            action: this.handleConfirmationModalClose,
            redButton: false,
          }}
        />
        <AlertModal
          className="ConfirmationModal__Container"
          show={this.state.showRemoveModal}
          close={this.handleRemoveModalClose}
          title=""
          buttonAction={this.confirmRemoval}
          bodyTitle={Text.features.orgManagement.users.modal.confirmation.remove}
          showButton={true}
          buttonText="Remove"
          secondaryButton={{
            text: 'Cancel',
            action: this.handleRemoveModalClose,
            redButton: false,
          }}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    verifiedUserByEmail: state.orgManagementReducer.verifiedUserByEmail,
    userPartDetails: state.orgManagementReducer.userPartDetails,
    allUserRoles: state.orgManagementReducer.allUserRoles,
    orgHierarchy: state.userInfoReducer.orgHierarchy,
    childOrganizations: state.userInfoReducer.childOrganizations,
    possibleUserRoles: state.orgManagementReducer.possibleUserRoles,
    userRoles: state.orgManagementReducer.userRoles,
    userInfo: state.userInfoReducer.userInfo,
    orgPartDescendants: state.orgManagementReducer.orgPartDescendants,
    userInvitationRoles: state.orgManagementReducer.userInvitationRoles,
    userAuthorizationRoles: state.orgManagementReducer.userAuthorizationRoles,
    saveInactiveUserRoles: state.orgManagementReducer.saveInactiveUserRoles,
    saveActiveUserRoles: state.orgManagementReducer.saveActiveUserRoles,

    hasUsersManagePermissions: getPermission(state, 'now_user_manage'),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        verifyUserByEmail: orgManagementActions.verifyUserByEmail,
        getUserPartDetails: orgManagementActions.getUserPartDetails,
        getAllUserRoles: orgManagementActions.getAllUserRoles,
        getRolesByOrg: orgManagementActions.getRolesByOrg,
        getUserRoles: orgManagementActions.getUserRoles,
        inviteUserWithRoles: orgManagementActions.inviteUserWithRoles,
        authorizeUserWithRoles: orgManagementActions.authorizeUserWithRoles,
        saveInactiveUserRoles: orgManagementActions.saveInactiveUserRoles,
        saveActiveUserRoles: orgManagementActions.saveActiveUserRoles,
        getOrgPartDescendants: orgManagementActions.getOrgPartDescendants,
      },
      dispatch
    ),
  };
};

export { AssignUserRolesModal };
export default connect(mapStateToProps, mapDispatchToProps)(AssignUserRolesModal);

import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import TextContext from '../../../core/providers/TextProvider';

import { ReactComponent as DropdownArrow } from '../../../assets/svgs/dropdownArrow.svg';
import { ReactComponent as FirstPageIcon } from '../../../assets/svgs/firstPageArrow.svg';
import { ReactComponent as LastPageIcon } from '../../../assets/svgs/lastPageArrow.svg';
import { ReactComponent as NextPageIcon } from '../../../assets/svgs/nextArrow.svg';
import { ReactComponent as PrevPageIcon } from '../../../assets/svgs/prevArrow.svg';

import './Pagination.scss';

const CustomSubmenuItem = withStyles(() => ({
  root: {
    fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
    fontSize: '1.4rem',
    justifyContent: 'flex-end',
  },
}))(MenuItem);

const Pagination = ({
  count,
  offset,
  onChange,
  openedDropdown,
  rosterConst,
  setOffset,
  setOpenedDropdown,
  totalCount,
}) => {
  /**
   * useContext()
   */
  const Text = useContext(TextContext);

  /**
   * useRef()
   */
  const ref = useRef(null);

  /**
   * useState()
   */
  const [anchorEl, setAnchorEl] = useState(null);
  const [countValue, setCountValue] = useState(count);

  /**
   * useMemo()
   */
  const currentPage = useMemo(() => offset / countValue + 1, [countValue, offset]);

  const numberOfPages = useMemo(() => (totalCount ? Math.ceil(totalCount / countValue) : 0), [
    countValue,
    totalCount,
  ]);

  const disabledClass = useMemo(() => (numberOfPages < 2 ? 'disabled' : ''), [numberOfPages]);

  /**
   * useCallback()
   */
  const goToPage = useCallback(
    tablePage => {
      switch (tablePage) {
        case 'first':
          setOffset(0);
          break;
        case 'last':
          setOffset(countValue * Math.floor((totalCount - 1) / countValue));
          break;
        case 'next':
          setOffset(offset + countValue);
          break;
        case 'prev':
          setOffset(offset - countValue);
          break;
        default:
      }
    },
    [countValue, offset, setOffset, totalCount]
  );

  const handleChange = useCallback(
    value => {
      setOffset(value * countValue);
      setOpenedDropdown(false);
    },
    [countValue, setOpenedDropdown, setOffset]
  );

  const handleClick = useCallback(e => setAnchorEl(e.currentTarget), []);

  const handleClickOutside = useCallback(
    e => ref.current && !ref.current.contains(e.target) && setOpenedDropdown(false),
    [ref, setOpenedDropdown]
  );

  const handleClose = useCallback(() => setAnchorEl(null), []);

  const openDropdownHandle = useCallback(() => setOpenedDropdown(!openedDropdown), [
    openedDropdown,
    setOpenedDropdown,
  ]);

  const handleKeyPress = useCallback(e => e.key === 'Enter' && openDropdownHandle(), [
    openDropdownHandle,
  ]);

  const changeCount = useCallback(
    e => {
      const newCountValue = Number(e.target.value);
      onChange(newCountValue);
      setCountValue(newCountValue);
      setOffset(0);
      handleClose();
    },
    [handleClose, onChange, setOffset]
  );

  /**
   * useEffect()
   */
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [handleClickOutside]);

  /**
   * render
   */
  return (
    <div className="pagination-container">
      <div className="count-selector-container">
        <button
          aria-haspopup="true"
          className={`${Boolean(anchorEl) ? 'active-btn' : ''}`}
          onClick={handleClick}>
          {countValue}
          <DropdownArrow />
        </button>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          disableScrollLock={true}
          getContentAnchorEl={null}
          id="count-menu"
          MenuListProps={{ disablePadding: true }}
          onClose={() => setAnchorEl(null)}
          open={Boolean(anchorEl)}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}>
          {rosterConst &&
            rosterConst.map((count, index) => (
              <CustomSubmenuItem key={index} value={count} onClick={changeCount}>
                {count}
              </CustomSubmenuItem>
            ))}
        </Menu>
        <span>{Text.common.labels.perPage}</span>
      </div>
      <div className="page-navigation-container">
        <button
          aria-label="First page"
          className="page-navigation-button left-navigation"
          disabled={offset === 0 || totalCount === 0}
          onClick={() => goToPage('first')}
          type="button">
          <FirstPageIcon />
        </button>
        <button
          aria-label="Previous page"
          className="page-navigation-button left-navigation"
          disabled={offset === 0 || totalCount === 0}
          onClick={() => goToPage('prev')}
          type="button">
          <PrevPageIcon />
        </button>
        <span
          className={`pageNumber-container ${numberOfPages === 1 ? 'no-event' : disabledClass} ${
            openedDropdown ? 'opened-dropdown' : ''
          }`}
          onClick={openDropdownHandle}
          onKeyPress={handleKeyPress}
          tabIndex={`${numberOfPages > 1 ? '0' : ''}`}>
          page {numberOfPages > 0 ? `${currentPage}/${numberOfPages}` : `1 / 1`}
          {numberOfPages > 1 && (
            <DropdownArrow className="pageNumber__arrow" aria-label="Page number" />
          )}
        </span>
        <span className={`dropdown-options-container`}>
          {openedDropdown && numberOfPages > 1 && (
            <div ref={ref} className="dropdown-options">
              {[...Array(numberOfPages)].map((_v, i) => (
                <div
                  className="dropdown-option"
                  key={i}
                  onClick={() => handleChange(i + 1)}
                  onKeyPress={e => {
                    e.key === 'Enter' && handleChange(i + 1);
                  }}
                  tabIndex="0">
                  {numberOfPages > 0 ? `${i + 1}/${numberOfPages}` : '1 / 1'}
                </div>
              ))}
            </div>
          )}
        </span>
        <button
          aria-label="Next page"
          className="page-navigation-button right-navigation"
          disabled={offset + countValue >= totalCount || totalCount === 0}
          onClick={() => goToPage('next')}
          type="button">
          <NextPageIcon />
        </button>
        <button
          aria-label="last-page"
          className="page-navigation-button right-navigation"
          disabled={offset + countValue >= totalCount || totalCount === 0}
          onClick={() => goToPage('last')}
          type="button">
          <LastPageIcon />
        </button>
      </div>
    </div>
  );
};

export default Pagination;

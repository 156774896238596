import React from 'react';
import propTypes from 'prop-types';

const TextInput = ({
  setRef,
  inputValue,
  placeholder,
  handleChange,
  onFocus,
  onBlur,
  maxLength,
  noPlaceholder,
  disabled,
  name,
}) => {
  return (
    <input
      onFocus={onFocus}
      onBlur={onBlur}
      className="search__Input"
      name={name}
      type="text"
      ref={setRef}
      value={inputValue}
      placeholder={!!noPlaceholder === true ? '' : placeholder}
      onChange={handleChange}
      maxLength={maxLength}
      aria-label="Input text"
      disabled={disabled}
    />
  );
};

TextInput.displayName = 'TextInput';
TextInput.propTypes = {
  setRef: propTypes.any,
  inputValue: propTypes.string.isRequired,
  placeholder: propTypes.string.isRequired,
  handleChange: propTypes.func.isRequired,
  onFocus: propTypes.func,
  onBlur: propTypes.func,
  maxLength: propTypes.string,
  noPlaceholder: propTypes.bool,
  disabled: propTypes.bool,
  name: propTypes.string,
};

TextInput.defaultProps = {
  disabled: false,
  name: '',
};

export default TextInput;

import _ from 'lodash/fp';
import { createDispatchFunction } from '../../../services/helpers/stateHelper';

import { RosterServices } from '../../../services';
import * as types from './types';

export function bulkUpdateOptionsAction(payload) {
  return {
    type: types.BULK_UPDATE_OPTIONS,
    payload,
  };
}

export function getStudentsRecordsAction(payload) {
  return {
    type: types.GET_RECORDS,
    payload,
  };
}

export function getNcrMaterialKitAction(payload) {
  return {
    type: types.GET_NCR_MATERIAL_KIT,
    payload,
  };
}

export function registerStudentsAction(payload) {
  return {
    type: types.REG_STUDENTS,
    payload,
  };
}

export function getTestingDatesAction(payload) {
  return {
    type: types.GET_TESTINGDATES,
    payload,
  };
}

export function uploadAction(payload) {
  return {
    type: types.UPLOAD_FILE,
    payload,
  };
}

export function getFilesAction(payload) {
  return {
    type: types.GET_FILES,
    payload,
  };
}

export function getDownloadUrlsAction(payload) {
  return {
    type: types.GET_DOWNLOADURLS,
    payload,
  };
}

export function getStudentsByTestEventAction(payload) {
  return {
    type: types.GET_STUDENTS_BY_TEST_EVENT,
    payload,
  };
}

export function getStudentsByTestFormatAction(payload) {
  return {
    type: types.GET_STUDENTS_BY_TEST_FORMAT,
    payload,
  };
}

export function getStudentsByGradesAction(payload) {
  return {
    type: types.GET_STUDENTS_BY_GRADES,
    payload,
  };
}

export function getStudentsByTestAction(payload) {
  return {
    type: types.GET_STUDENTS_BY_TEST,
    payload,
  };
}

export function getChildOrgsWithSummaryAction(payload) {
  return {
    type: types.GET_CHILD_ORGS_SUMMARY,
    payload,
  };
}

export function getChildOrgsAction(payload) {
  return {
    type: types.GET_CHILD_ORGS,
    payload,
  };
}

export function getEntityDefAction(payload) {
  return {
    type: types.GET_ENTITY_DEF,
    payload,
  };
}

export function getRegTypesAction(payload) {
  return {
    type: types.GET_REG_TYPES,
    payload,
  };
}

export function updateRegistrationOptionsAction(payload) {
  return {
    type: types.UPDATE_REGISTRATION_OPTIONS,
    payload,
  };
}

export function updateStudentAction(payload) {
  return {
    type: types.UPDATE_STUDENT_DETAILS,
    payload,
  };
}

export function addStudentAction(payload) {
  return {
    type: types.ADD_STUDENT,
    payload,
  };
}

export function searchStudentAction(payload) {
  return {
    type: types.SEARCH_STUDENT,
    payload,
  };
}

export function requestStudentTransferAction(payload) {
  return {
    type: types.REQEST_STUDENT_TRANSFER,
    payload,
  };
}

export function unenrollStudentAction(payload) {
  return {
    type: types.UNENROLL_STUDENT,
    payload,
  };
}

export function getSentTransferRequestsAction(payload) {
  return {
    type: types.GET_SENT_TRANSFER_REQUESTS,
    payload,
  };
}

export function getReceivedTransferRequestsAction(payload) {
  return {
    type: types.GET_RECEIVED_TRANSFER_REQUESTS,
    payload,
  };
}

export function cancelStudentTransferRequestAction(payload) {
  return {
    type: types.CANCEL_STUDENT_TRANSFER_REQUEST,
    payload,
  };
}

export function updateStudentTransferRequestAction(payload) {
  return {
    type: types.UPDATE_STUDENT_TRANSFER_REQUEST,
    payload,
  };
}

export function getTimingsActions(payload) {
  return {
    type: types.GET_TIMINGS,
    payload,
  };
}

export function getAccommodationOptionsAction(payload) {
  return {
    type: types.GET_ACCOMMODATION_OPTIONS,
    payload,
  };
}

export function getRegAccomsAction(payload) {
  return {
    type: types.GET_REG_ACCOMS,
    payload,
  };
}

function getStudents(params) {
  return RosterServices.getRecords(params);
}

function getNcrMaterialKit(params) {
  return RosterServices.getNcrMaterialKitOptions(params);
}

function getTestEvents(params) {
  return RosterServices.getTestingDates(params);
}

function regStudents(data) {
  return RosterServices.registerStudents(data);
}

function getFiles(orgPartId, adminId, params, jobTypeCode) {
  return RosterServices.getUploadedFiles(orgPartId, adminId, params, jobTypeCode);
}

function regWillNotTestStudents(data) {
  return RosterServices.regWillNotTestStudents(data);
}

function getUrls(orgPartId, jobId) {
  return RosterServices.getDownloadUrls(orgPartId, jobId);
}

export function bulkUpdateOptions(body) {
  return createDispatchFunction(
    _.partial(RosterServices.bulkUpdateOptions)([body]),
    bulkUpdateOptionsAction
  );
}

export function getStudentsRecords(params) {
  return createDispatchFunction(_.partial(getStudents)([params]), getStudentsRecordsAction);
}

export function getNcrMaterialKitOptions(params) {
  return createDispatchFunction(_.partial(getNcrMaterialKit)([params]), getNcrMaterialKitAction);
}

export function registerStudents(data) {
  return createDispatchFunction(_.partial(regStudents)([data]), registerStudentsAction);
}

export function registerWillNotTestStudents(data) {
  return createDispatchFunction(_.partial(regWillNotTestStudents)([data]), registerStudentsAction);
}

export function getTestingDates(params) {
  return createDispatchFunction(_.partial(getTestEvents)([params]), getTestingDatesAction);
}

export function uploadFile(data, orgId) {
  return createDispatchFunction(_.partial(RosterServices.uploadFile)([data, orgId]), uploadAction);
}

export function getUploadedFiles(orgPartId, adminId, params, jobTypeCode) {
  return createDispatchFunction(
    _.partial(getFiles)([orgPartId, adminId, params, jobTypeCode]),
    getFilesAction
  );
}

export function getDownloadUrls(orgPartId, jobId) {
  return createDispatchFunction(_.partial(getUrls)([orgPartId, jobId]), getDownloadUrlsAction);
}

export function getStudentsByTestEvent(orgPartId) {
  return createDispatchFunction(
    _.partial(RosterServices.getStudentsByTestEvent)([orgPartId]),
    getStudentsByTestEventAction
  );
}

export function getStudentsByTestFormat(orgPartId) {
  return createDispatchFunction(
    _.partial(RosterServices.getStudentsByTestFormat)([orgPartId]),
    getStudentsByTestFormatAction
  );
}

export function getStudentsByGrades(orgPartId) {
  return createDispatchFunction(
    _.partial(RosterServices.getStudentsByGrades)([orgPartId]),
    getStudentsByGradesAction
  );
}

export function getStudentsByTest(orgPartId) {
  return createDispatchFunction(
    _.partial(RosterServices.getStudentsByTest)([orgPartId]),
    getStudentsByTestAction
  );
}

export function getChildOrgsWithSummary(orgPartId) {
  return createDispatchFunction(
    _.partial(RosterServices.getChildOrgsWithSummary)([orgPartId]),
    getChildOrgsWithSummaryAction
  );
}

export function getChildOrgs(params) {
  return createDispatchFunction(
    _.partial(RosterServices.getChildOrgs)([params]),
    getChildOrgsAction
  );
}

export function getEntityDef(orgPartId) {
  return createDispatchFunction(
    _.partial(RosterServices.getEntityDef)([orgPartId]),
    getEntityDefAction
  );
}

export function getRegTypes(params) {
  return createDispatchFunction(_.partial(RosterServices.getRegTypes)([params]), getRegTypesAction);
}

export function updateRegistrationOptions(adminId, body) {
  return createDispatchFunction(
    _.partial(RosterServices.updateRegistrationOptions)([adminId, body]),
    updateRegistrationOptionsAction
  );
}

export function updateStudent(body) {
  return createDispatchFunction(
    _.partial(RosterServices.updateStudent)([body]),
    updateStudentAction
  );
}

export function addStudent(body) {
  return createDispatchFunction(_.partial(RosterServices.addStudent)([body]), addStudentAction);
}

export function searchStudent(orgPartId, lastName, externalStudentId, dateOfBirth) {
  return createDispatchFunction(
    _.partial(RosterServices.searchStudent)([orgPartId, lastName, externalStudentId, dateOfBirth]),
    searchStudentAction
  );
}

export function requestStudentTransfer(body) {
  return createDispatchFunction(
    _.partial(RosterServices.requestStudentTransfer)([body]),
    requestStudentTransferAction
  );
}

export function unenrollStudent(body) {
  return createDispatchFunction(
    _.partial(RosterServices.unenrollStudent)([body]),
    unenrollStudentAction
  );
}
export function getSentTransferRequests(body) {
  return createDispatchFunction(
    _.partial(RosterServices.getSentTransferRequests)([body]),
    getSentTransferRequestsAction
  );
}

export function getReceivedTransferRequests(body) {
  return createDispatchFunction(
    _.partial(RosterServices.getReceivedTransferRequests)([body]),
    getReceivedTransferRequestsAction
  );
}

export function cancelStudentTransferRequest(body) {
  return createDispatchFunction(
    _.partial(RosterServices.cancelStudentTransferRequest)([body]),
    cancelStudentTransferRequestAction
  );
}

export function updateStudentTransferRequest(body) {
  return createDispatchFunction(
    _.partial(RosterServices.updateStudentTransferRequest)([body]),
    updateStudentTransferRequestAction
  );
}

export function getTimings(params) {
  return createDispatchFunction(_.partial(RosterServices.getTimings)([params]), getTimingsActions);
}

export function getAccommodationOptions(params) {
  return createDispatchFunction(
    _.partial(RosterServices.getAccommodationOptions)([params]),
    getAccommodationOptionsAction
  );
}

export function getRegAccoms(regId) {
  return createDispatchFunction(
    _.partial(RosterServices.getRegAccoms)([regId]),
    getRegAccomsAction
  );
}

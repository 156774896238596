const feat = 'orgManagement';

const UPLOAD_FILE = `${feat}/UPLOAD_FILE`;
const GET_FILES = `${feat}/GET_FILES`;
const GET_DOWNLOAD_URLS = `${feat}/GET_DOWNLOAD_URLS`;
const GET_INVITE_STATUS = `${feat}/GET_INVITE_STATUS`;
const RESPOND_TO_INVITE = `${feat}/RESPOND_TO_INVITE`;
const GET_PARTICIPATION_STATUS = `${feat}/GET_PARTICIPATION_STATUS`;
const GET_STAFF = `${feat}/GET_STAFF`;
const GET_USER_PART_DETAILS = `${feat}/GET_USER_PART_DETAILS`;
const GET_STAFF_DOWNLOAD_URLS = `${feat}/GET_STAFF_DOWNLOAD_URLS`;
const GET_STAFF_FILES = `${feat}/GET_STAFF_FILES`;
const VERIFY_USER_BY_EMAIL = `${feat}/VERIFY_USER_BY_EMAIL`;
const GET_POSSIBLE_ROLES = `${feat}/GET_POSSIBLE_ROLES`;
const GET_USER_ROLES = `${feat}/GET_USER_ROLES`;
const GET_ALL_USER_ROLES = `${feat}/GET_ALL_USER_ROLES`;
const INVITE_USER_WITH_ROLES = `${feat}/INVITE_USER_WITH_ROLES`;
const AUTHORIZE_USER_WITH_ROLES = `${feat}/AUTHORIZE_USER_WITH_ROLES`;
const GET_AVAILABLE_EVENTS = `${feat}/GET_AVAILABLE_EVENTS`;
const SAVE_INACTIVE_USER_ROLES = `${feat}/SAVE_INACTIVE_USER_ROLES`;
const SAVE_ACTIVE_USER_ROLES = `${feat}/SAVE_ACTIVE_USER_ROLES`;
const GET_ROLES = `${feat}/GET_ROLES`;
const GET_ORGPART_DESCENDANTS = `${feat}/GET_ORGPART_DESCENDANTS`;
const INVITE_ORG = `${feat}/INVITE_ORG`;
const GET_DESCENDANT_ORGS = `${feat}/GET_DESCENDANT_ORGS`;
const GET_DESCENDANT_ORG_TYPES = `${feat}/GET_DESCENDANT_ORG_TYPES`;

export {
  UPLOAD_FILE,
  GET_FILES,
  GET_DOWNLOAD_URLS,
  GET_INVITE_STATUS,
  RESPOND_TO_INVITE,
  GET_PARTICIPATION_STATUS,
  GET_STAFF,
  GET_USER_PART_DETAILS,
  GET_STAFF_DOWNLOAD_URLS,
  GET_STAFF_FILES,
  VERIFY_USER_BY_EMAIL,
  GET_POSSIBLE_ROLES,
  GET_USER_ROLES,
  INVITE_USER_WITH_ROLES,
  AUTHORIZE_USER_WITH_ROLES,
  GET_AVAILABLE_EVENTS,
  SAVE_INACTIVE_USER_ROLES,
  SAVE_ACTIVE_USER_ROLES,
  GET_ROLES,
  GET_ORGPART_DESCENDANTS,
  INVITE_ORG,
  GET_ALL_USER_ROLES,
  GET_DESCENDANT_ORGS,
  GET_DESCENDANT_ORG_TYPES,
};

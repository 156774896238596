import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions as testManagementActions } from '../duck';
import { actions as orgManagementActions } from '../../administration/duck';
import { actions as coreActions } from '../../../core/duck';
import * as text from '../../../core/i18n/text';
import SubNavigation from '../../../components/navigation/SubNavigation';
import InfoBox from '../../../components/box/InfoBox';
import SimpleBox from '../../../components/box/SimpleBox';
import { getPermission } from '../../../core/duck/selectors';
import './TestManagementTestEvents.scss';
import { Helmet } from 'react-helmet';
import Alert from '../../../components/alerts/Alert';
import * as Text from '../../../core/i18n/text';

let items = [];

class TestManagementTestEvents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeNavItem: '',
      tiles: [],
    };
  }

  componentDidMount() {
    this.props.actions.getTestEvents(this.props.match.params.orgPartId);
    this.props.actions.getInvitationStatus({ orgPartId: this.props.match.params.orgPartId });
    if (!this.props.appUrl) {
      this.props.actions.getAppUrl('tcm');
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.testEvents &&
      this.props.testEvents.data?.length &&
      prevProps.testEvents !== this.props.testEvents
    ) {
      items = this.props.testEvents.data;
      this.setState({ activeNavItem: items[0].testingEventId });
      this.setTilesContent(items[0].testingEventId);
    }
  }
  setTilesContent = id => {
    if (id) {
      const event = items.filter(x => x.testingEventId === Number(id))[0];
      this.setState({
        tiles: [
          {
            title: text.features.testManagement.testEvents.tiles.studCount,
            items: [
              {
                key: text.features.testManagement.testEvents.tiles.assignedEvent,
                value: event.assignedRegCount,
              },
              {
                key: text.features.testManagement.testEvents.tiles.standardPaper,
                value: event.paperRegCount,
              },
              {
                key: text.features.testManagement.testEvents.tiles.standardOnline,
                value: event.onlineRegCount,
              },
            ],
          },
          {
            title: text.features.testManagement.testEvents.tiles.roomsAndStaff,
            items: [
              {
                key: text.features.testManagement.testEvents.tiles.roomsCreated,
                value: event.roomCount,
              },
              {
                key: text.features.testManagement.testEvents.tiles.roomsStaffed,
                value: event.roomSupervisorsCount,
              },
            ],
          },
        ],
      });
    }
  };

  handleNavChange = e => {
    this.setState({ activeNavItem: e.target.id });
    this.setTilesContent(e.target.id);
  };

  render() {
    const school =
      this.props.organizations?.data?.length && !this.props.organizations.loading
        ? this.props.organizations?.data.find(
            item => item.orgPartId === this.props.currentOrg.orgPartId
          )
        : this.props.parentStatus?.data;
    const notResponded = school?.status === 'pending';
    const declined = school?.status === 'inactive';
    if (notResponded) {
      return (
        <Alert type="info" message={Text.features.testManagement.testEvents.messages.notAccepted} />
      );
    }

    if (declined) {
      return (
        <Alert type="info" message={Text.features.testManagement.testEvents.messages.declined} />
      );
    }
    return (
      <>
        <Helmet>
          <title>Test Events - ACT-Now</title>
        </Helmet>
        <SubNavigation
          items={items}
          activeItem={this.state.activeNavItem}
          handleClick={this.handleNavChange}
          id={'testingEventId'}
          propToDisplay={'testDate'}
          formatTextToDisplay={data => data.testingEvent}
        />
        <div className="TestEvents__Container">
          <div className="TestEvents__TilesContainer">
            {this.state.tiles.map((tile, index) => {
              return <InfoBox key={index} title={tile.title} items={tile.items} />;
            })}
          </div>
          {this.props.hasTCMLaunchPermission && (
            <SimpleBox
              title={text.features.testManagement.testEvents.TCMLauncher.title}
              description={
                <>
                  <b>{text.features.testManagement.testEvents.TCMLauncher.description.bold}</b>
                  {text.features.testManagement.testEvents.TCMLauncher.description.normal}
                </>
              }
              buttons={[
                {
                  text: text.features.testManagement.testEvents.TCMLauncher.button,
                  action: () => {
                    window.location = `${this.props.appUrl.data}/dashboard`;
                  },
                },
              ]}
            />
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  const { testManagementReducer, userInfoReducer, rosterReducer, orgManagementReducer } = state;
  return {
    testEvents: testManagementReducer.testEvents,
    appUrl: userInfoReducer.appUrl,
    hasTCMLaunchPermission: getPermission(state, 'now_tcm_launch'),
    organizations: rosterReducer.childOrganizations,
    parentStatus: orgManagementReducer.inviteStatus,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      { ...testManagementActions, ...coreActions, ...orgManagementActions },
      dispatch
    ),
  };
};

export { TestManagementTestEvents };
export default connect(mapStateToProps, mapDispatchToProps)(TestManagementTestEvents);

import React, { useCallback, useEffect, useState } from 'react';
import propTypes from 'prop-types';

function InfiniteScroll(props) {
  const [page, setPage] = useState(0);
  const [pageHeight, setPageHeight] = useState(0);

  const onScroll = useCallback(() => {
    if (
      (document.documentElement.scrollTop || document.body.scrollTop) + window.innerHeight >=
        document.documentElement.scrollHeight - 1 &&
      props.hasMore &&
      document.documentElement.scrollHeight !== pageHeight
    ) {
      let newPage = page + 1;
      setPage(newPage);
      setPageHeight(document.documentElement.scrollHeight);
      props.onLoadData(page);
    }
  }, [page, pageHeight, props]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll, false);
    return () => {
      window.removeEventListener('scroll', onScroll, false);
    };
  }, [onScroll]);

  return <div>{props.children}</div>;
}

InfiniteScroll.displayName = 'InfiniteScroll';

InfiniteScroll.propTypes = {
  hasMore: propTypes.bool,
  onLoadData: propTypes.func.isRequired,
};

InfiniteScroll.defaultProps = {};

export default InfiniteScroll;

import React, { useCallback, useEffect, useState } from 'react';
import { countArray } from '../../core/constants';
import { useParams } from 'react-router-dom';
import propTypes from 'prop-types';
import _ from 'lodash/fp';
import './OrganizationsTab.scss';
import DataPanelContainer from '../data-panel/DataPanelContainer';

const hardcodedInputList = [
  {
    placeholder: 'Search by org name or code',
    maxLength: '30',
    type: 'text',
    name: 'nameOrCode',
  },
];

const SEARCH_PARAMS = {
  firstName: 'firstNameStartWith',
  lastName: 'lastNameStartWith',
  studentId: 'studentId',
};

const OrganizationTab = ({
  actions,
  addNewItemIcon,
  addNewItemLabel,
  callParameters = {},
  currentOrg,
  currentOrgPartId,
  dataToReload,
  emptyStateComponent,
  emptyStateMessage = '',
  filterFirst = false,
  filters,
  filtersOnlyOnDistrict = true,
  handleAddNewItem = () => {},
  initialColumns,
  initialSearch,
  inputList,
  inviteButton,
  linkButtons,
  nonStikyColumns,
  organizations,
  rootOrg,
  rootOrgPartId,
  setColumns,
  setTableColumns,
  useRootOrgPartId = false,
  usingOrgPartId = true,
}) => {
  /**
   * react-router-dom hooks
   */
  const { orgPartId } = useParams();

  /**
   * useState()
   */
  const [currentOrgPartIdValue, setCurrentOrgPartIdValue] = useState(orgPartId);
  const [org, setOrg] = useState(useRootOrgPartId ? rootOrg : currentOrg);
  const [orgsData, setOrgsData] = useState([]);

  /**
   * useCallback()
   */
  const getOrgRecords = useCallback(
    ({ filtersParams, limit, offset, searchedValues }) => {
      if (_.isEmpty(org)) return;
      const id =
        usingOrgPartId === true
          ? { orgPartId: currentOrgPartIdValue || currentOrg.orgPartId }
          : { orgId: org.orgId };
      const filterValue = (searchedValues?.nameOrCode || searchedValues?.filter || '')
        .replace('#', '%23')
        .replace('&', '%26')
        .replace('/', '%2F')
        .replace('\\', '%5C');

      actions.getData({
        ...id,
        adminId: org.adminId,
        filter: filterValue,
        limit: limit ?? countArray[0],
        offset: offset ?? 0,
        ...(filters && filtersParams),
        ...(!_.isEmpty(callParameters) && callParameters.params),
      });
    },
    [
      actions,
      callParameters,
      currentOrg.orgPartId,
      currentOrgPartIdValue,
      filters,
      org,
      usingOrgPartId,
    ]
  );

  /**
   * useEffect()
   */
  useEffect(() => {
    setCurrentOrgPartIdValue(useRootOrgPartId ? rootOrgPartId : parseInt(orgPartId));
    setOrg(useRootOrgPartId ? rootOrg : currentOrg);
  }, [currentOrg, orgPartId, rootOrg, rootOrgPartId, useRootOrgPartId]);

  useEffect(() => {
    if (!organizations) return;
    setOrgsData(organizations.data || []);
  }, [organizations]);

  /**
   * render
   */
  return (
    <DataPanelContainer
      addNewItemIcon={addNewItemIcon}
      addNewItemLabel={addNewItemLabel}
      currentOrgPartId={currentOrgPartId}
      dataToReload={dataToReload}
      emptyStateComponent={emptyStateComponent}
      emptyStateMessage={emptyStateMessage}
      filterFirst={filterFirst}
      filters={filters}
      filtersOnlyOnDistrict={filtersOnlyOnDistrict}
      getRecords={getOrgRecords}
      handleAddNewItem={handleAddNewItem}
      hasFocus
      initialColumns={initialColumns}
      initialSearch={initialSearch}
      inputList={inputList || hardcodedInputList}
      inviteButton={inviteButton}
      linkButtons={linkButtons}
      loadingData={organizations?.loading}
      nonStikyColumns={nonStikyColumns}
      org={org}
      searchParams={SEARCH_PARAMS}
      setColumns={setColumns}
      setTableColumns={setTableColumns}
      stickyColumnsLength={1}
      tableData={orgsData}
      title={emptyStateMessage}
      totalCount={organizations && !organizations.loading ? organizations.meta?.totalCount : 0}
      usingOrgPartId={usingOrgPartId}
    />
  );
};

OrganizationTab.displayName = 'OrganizationTab';
OrganizationTab.propTypes = {
  actions: propTypes.shape({
    getData: propTypes.func,
  }),
  addNewItemIcon: propTypes.elementType,
  addNewItemLabel: propTypes.string,
  callParameters: propTypes.shape({
    level: propTypes.string,
    params: propTypes.object,
  }),
  emptyStateMessage: propTypes.string,
  filterFirst: propTypes.bool,
  filters: propTypes.arrayOf(propTypes.object),
  filtersOnlyOnDistrict: propTypes.bool,
  handleAddNewItem: propTypes.func,
  organizations: propTypes.object,
  useRootOrgPartId: propTypes.bool,
  usingOrgPartId: propTypes.bool,
};

export default OrganizationTab;

import React, { useEffect, useState } from 'react';
import AlertModal from '../../../../components/modal/AlertModal';
import * as T from '../../../../core/i18n/text';
import GeneralDetailsComponent from './GeneralDetailsComponent.jsx';
import TrackingComponent from './TrackingComponent.jsx';
import MaterialsComponent from './MaterialsComponent.jsx';
import './OrderDetailsModal.scss';
import { TestManagementServices } from '../../../../services';

const tabs = [
  {
    title: 'General Details',
    id: 'generalDetails',
    component: GeneralDetailsComponent,
  },
  {
    title: 'Tracking',
    id: 'tracking',
    component: TrackingComponent,
  },
  {
    title: 'Materials',
    id: 'materials',
    component: MaterialsComponent,
  },
];

const OrderDetailsModal = ({
  open,
  orderId,
  status,
  organizationName,
  closeModalHandle,
  orgPartId,
}) => {
  const [errorGeneralDetailsNotification, setErrorGeneralDetailsNotification] = useState(false);
  const [errorTrackingNotification, setErrorTrackingNotification] = useState(false);
  const [errorMaterialsNotification, setErrorMaterialsNotification] = useState(false);
  const [tabValue, setTabValue] = React.useState(tabs[0].id);
  const [orderDetails, setOrderDetails] = useState([]);
  const [orderTracking, setOrderTracking] = useState([]);
  const [orderMaterials, setOrderMaterials] = useState([]);

  const getOrderDetails = async () => {
    try {
      const response = await TestManagementServices.getOrderDetails(orgPartId, orderId);
      if (response.data) {
        setOrderDetails(response.data);
      }
    } catch (e) {
      setErrorGeneralDetailsNotification(true);
    }
  };
  const getOrderTracking = async () => {
    try {
      const response = await TestManagementServices.getOrderTracking(orgPartId, orderId);
      if (response.data) {
        setOrderTracking(response.data);
      }
    } catch (e) {
      setErrorTrackingNotification(true);
    }
  };
  const getOrderMaterials = async () => {
    try {
      const response = await TestManagementServices.getOrderMaterials(orgPartId, orderId);
      if (response.data) {
        setOrderMaterials(response.data);
      }
    } catch (e) {
      setErrorMaterialsNotification(true);
    }
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (open) {
      getOrderDetails();
      getOrderTracking();
      getOrderMaterials();
    } else {
      setTabValue(tabs[0].id);
      setOrderDetails([]);
      setOrderTracking([]);
      setOrderMaterials([]);
      setErrorGeneralDetailsNotification(false);
      setErrorTrackingNotification(false);
      setErrorMaterialsNotification(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      <AlertModal
        className="order-details-modal"
        customStyles={{ overlay: { zIndex: '1000' } }}
        show={open}
        title={T.features.testManagement.testEvents.shipmentsModals.title}
        body={[
          <>
            <div className="order-details-title">
              <h4 className="order-details-number">
                {T.features.testManagement.testEvents.labels.order} {orderId}
              </h4>
              {status}
            </div>
            <p>
              <strong>{T.features.testManagement.testEvents.labels.organization}</strong>{' '}
              {organizationName}
            </p>

            <div className="tabs__Container" id="requestUpdateTab" role="tablist">
              {tabs.map(item => {
                return (
                  <div
                    role="tab"
                    key={item.id}
                    id={item.id}
                    className={`tabs__Single tab__Border ${item.id === tabValue ? 'active' : ''}`}
                    onClick={e => handleChange(e, item.id)}>
                    <span className={`tabs__Single text ${tabValue === item.id ? 'active' : ''} `}>
                      {item.title}
                    </span>
                    <div className="borderBottom"></div>
                    {item.id === tabValue && <div className="noBorder"></div>}
                  </div>
                );
              })}
            </div>
            <hr className="tabs__Divider" />
            <div className="tab-content" id="requestUpdateTabContent">
              {tabs.map(item => {
                return (
                  <div
                    role="tabpanel"
                    key={`${item.id}_content`}
                    id={`${item.id}_content`}
                    className={`tab-panel fade ${item.id === tabValue ? 'active' : ''}`}>
                    <item.component
                      orderDetails={orderDetails}
                      orderTracking={orderTracking}
                      orderMaterials={orderMaterials}
                      errorGeneralDetailsNotification={errorGeneralDetailsNotification}
                      errorTrackingNotification={errorTrackingNotification}
                      errorMaterialsNotification={errorMaterialsNotification}
                    />
                  </div>
                );
              })}
            </div>
          </>,
        ]}
        close={closeModalHandle}
        showButton={true}
      />
    </>
  );
};

export default OrderDetailsModal;

import { createContext, useContext, useReducer } from 'react';
import * as React from 'react';

export const CPCAContext = createContext();

export const CPCADispatchContext = createContext();

let setCPCA = () => {
  console.log('fake setProgram called!');
};

export function CPCAProvider(props) {
  const [cpca, dispatch] = useReducer(cpcaReducer, null);
  setCPCA = props.setCPCA;

  return (
    <CPCAContext.Provider value={cpca}>
      <CPCADispatchContext.Provider value={dispatch}>{props.children}</CPCADispatchContext.Provider>
    </CPCAContext.Provider>
  );
}

export function useCPCA() {
  return useContext(CPCAContext);
}

export function useCPCADispatch() {
  return useContext(CPCADispatchContext);
}

export function cpcaReducer(cpca, actions) {
  switch (actions.type) {
    case 'get':
      return cpca;
    case 'setProgram':
      setCPCA({ ...cpca, program: actions.payload });
      break;
    case 'set':
      console.log('CPCAContext.js cpcaReducer actions.payload', actions.payload);
      setCPCA({ ...cpca, mainSelector: actions.payload });
      break;
    default:
      console.err('Unknown action type: ', actions.type);
      break;
  }
}

import React, { useContext, useMemo } from 'react';
import propTypes from 'prop-types';
import QuestionMark from '../../../assets/svgs/questionMarkBlue.svg';
import { Tooltip } from '../../index';

import TextContext from '../../../core/providers/TextProvider';

const Input = ({
  className,
  disabled,
  error,
  inputDetails,
  label,
  maxLength,
  name,
  onChange,
  onFocus,
  onKeyDown,
  optionalLabel,
  placeholder,
  tooltip,
  type,
  value,
}) => {
  /**
   * useContext()
   */
  const Text = useContext(TextContext);

  /**
   * useMemo()
   */
  const labelClassName = useMemo(
    () => `field__Label${disabled ? ' field__Label-disabled' : error ? ' field__Label-error' : ''}`,
    [disabled, error]
  );

  return (
    <div className={`field__Container ${className}`}>
      {label && (
        <label className={labelClassName} htmlFor={name}>
          {label}
        </label>
      )}
      <div className={`field__InputRow ${inputDetails ? 'flex-row' : ''}`}>
        <div className={tooltip ? 'tooltip' : ''}>
          <input
            aria-label={placeholder}
            className={`field__Input${error ? ' field__Input-error' : ''} `}
            disabled={disabled}
            id={name}
            maxLength={maxLength}
            name={name}
            onChange={onChange}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
            placeholder={placeholder}
            type={type}
            value={value}
          />
          {disabled && tooltip && <span className="tooltiptext tooltip__top">{tooltip}</span>}
          {optionalLabel && <span className="optional__label">{Text.common.labels.optional}</span>}
        </div>
        {inputDetails && (
          <Tooltip content={inputDetails} className="field__TooltipDetails">
            <img src={QuestionMark} alt="upload" className="" />
          </Tooltip>
        )}
      </div>

      {error && <div className="field__ErrorMessage">{error}</div>}
    </div>
  );
};

Input.displayName = 'Input';
Input.defaultProps = {
  type: 'text',
  placeholder: '',
  disabled: false,
  error: '',
  name: '',
  onChange: null,
  maxLength: '99999',
  tooltip: '',
  className: '',
  inputDetails: '',
};
Input.propTypes = {
  disabled: propTypes.bool,
  error: propTypes.string,
  label: propTypes.string,
  name: propTypes.string,
  type: propTypes.string,
  placeholder: propTypes.string,
  onChange: propTypes.func,
  value: propTypes.any,
  maxLength: propTypes.oneOfType([propTypes.string, propTypes.number]).isRequired,
  tooltip: propTypes.string,
  className: propTypes.string,
  inputDetails: propTypes.string,
};

export default Input;

import React, { useCallback, useContext, useEffect, useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';

import { ReactComponent as Download } from '../../assets/svgs/downloadWhite.svg';
import { ReactComponent as InfoIcon } from '../../assets/svgs/info-blue.svg';
import { ReactComponent as SpinningArrow } from '../../assets/svgs/spinningArrow.svg';

import { OrgManagementServices } from '../../services';

import Loader from '../loader/Loader';
import Notification from '../alerts/Notification';

import TextContext from '../../core/providers/TextProvider';

import './Reports.scss';

const LightTooltip = withStyles({
  tooltip: {
    color: 'black',
    backgroundColor: 'white',
    fontSize: '14px',
    border: '1px solid #e8e8e8',
    borderRadius: '8px',
    padding: '6px',
  },
  arrow: {
    '&:before': {
      border: '1px solid #E6E8ED',
    },
    color: 'white',
  },
})(Tooltip);

const Reports = ({ category, currentOrg }) => {
  /**
   * useContext()
   */
  const Text = useContext(TextContext);

  /**
   * useState()
   */
  const [data, setData] = useState();
  const [timer, setTimer] = useState();
  const [displayNotification, setDisplayNotification] = useState(false);

  const { orgPartId } = currentOrg;

  const getStatus = data => data.some(item => item.status === 'processing');

  const download = async (reportId, format) => {
    const response = await OrgManagementServices.downloadOperationalReports(orgPartId, reportId);
    if (response.data.presignedUrl) {
      const link = document.createElement('a');
      link.href = response.data.presignedUrl;
      link.id = format;
      link.download = '';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleNotificationClose = () => {
    setDisplayNotification(false);
  };

  const updateData = data => {
    setData(data);
  };

  const getInterval = useCallback(
    (time, reportId) => {
      clearInterval(timer);
      const interval = setInterval(async () => {
        if (window.location.href.indexOf('reports') === -1) {
          clearInterval(interval);
        }
        const multipleResponse = await OrgManagementServices.getOperationalReports(
          orgPartId,
          category
        );
        const currentDate = moment();
        const generated = multipleResponse.data.find(item => item.reportId === reportId);
        const requestDate = moment(generated.requestDt);
        const difference = requestDate ? Math.abs(requestDate.diff(currentDate, 'seconds')) : 0;

        setData(data => multipleResponse.data);
        const isProcessing = getStatus(multipleResponse.data);
        setTimer(interval);
        if (reportId && (generated.status === 'ready' || generated.status === 'unavailable')) {
          updateData(multipleResponse.data);
        }
        if (!isProcessing) {
          updateData(multipleResponse.data);
          clearInterval(interval);
        }
        if (difference > 60 && difference < 300) {
          clearInterval(interval);
          getInterval(15000, reportId);
        }
        if (difference > 300) {
          clearInterval(interval);
          getInterval(60000, reportId);
        }
      }, time);
    },
    [category, orgPartId, timer]
  );

  const generateReport = async reportId => {
    const body = {
      orgPartId,
      reportId,
    };
    setDisplayNotification(false);
    try {
      const generated = await OrgManagementServices.generateOperationalReports(body);
      if (generated.data.status === 'processing') {
        const newData = data;
        const reports = newData.map(obj =>
          obj.reportId === generated.data.reportId ? generated.data : obj
        );
        setData(reports);
        const response = await OrgManagementServices.getOperationalReports(orgPartId, category);
        const isProcessing = getStatus(response.data);
        setData(response.data);
        if (isProcessing) {
          getInterval(3000, reportId);
        }
      }
    } catch (e) {
      setDisplayNotification(true);
      setTimeout(() => {
        setDisplayNotification(false);
      }, 3000);
    }
  };

  const getMultipleButtons = (staticFlag, format, reportId) => {
    return (
      <div className="multiple-buttons-container">
        {!staticFlag && (
          <button className="openButton" onClick={() => generateReport(reportId)}>
            Regenerate
          </button>
        )}
        {(format === 'pdf' || format === 'csv') && (
          <button className="bigButton download" onClick={() => download(reportId, format)}>
            <Download /> Download {format.toUpperCase()}
          </button>
        )}
      </div>
    );
  };

  const buttons = field => {
    switch (field.status) {
      case 'processing':
        return (
          <div className="generating">
            <img src={SpinningArrow} alt="generating" />
            <p>Generating</p>
            <div className="reports-info">
              <LightTooltip
                title={`Requested ${moment(field.requestDt).format('MM/DD/YYYY')} at ${moment(
                  field.requestDt
                ).format('h:mm A')} ${field.requestLastName}, ${field.requestFirstName}`}
                placement="top"
                arrow>
                <InfoIcon />
              </LightTooltip>
            </div>
          </div>
        );
      case 'ready':
        return getMultipleButtons(field.staticFlag, field.format, field.reportId);
      case 'unavailable':
        return (
          <button className="openButton" onClick={() => generateReport(field.reportId)}>
            Generate
          </button>
        );
      default:
        return <></>;
    }
  };

  useEffect(() => {
    if (!orgPartId) return;
    OrgManagementServices.getOperationalReports(orgPartId, category).then(({ data }) => {
      const isProcessing = getStatus(data);
      if (isProcessing) {
        const report = data.find(item => item.status === 'processing');
        getInterval(3000, report.reportId);
      }
      setData(data);
    });
  }, [category, getInterval, orgPartId]);

  return (
    <div className="reports-container">
      {displayNotification && (
        <Notification
          text={Text.messages.error.generic}
          handleClose={handleNotificationClose}
          type="Error"
        />
      )}
      <div className="reports-table-header">
        <div>Report Name</div>
        <div>Current version date</div>
      </div>
      {!data ? (
        <Loader />
      ) : (
        data.map((field, i) => (
          <div className="reports-row" key={i}>
            <div className="report-name-info">
              <div className="report-name">{field.name}</div>
              <div className="reports-info">
                <LightTooltip title={field.description} placement="top" arrow>
                  <InfoIcon />
                </LightTooltip>
              </div>
            </div>
            <div>
              {field.fileDt ? (
                <div className="date-label-container">
                  <span className="date-label">{moment(field.fileDt).format('MM/DD/YYYY')}</span> at{' '}
                  <span className="date-label">{moment(field.fileDt).format('h:mm A')}</span>
                </div>
              ) : (
                '-'
              )}
            </div>
            <div className="reports-buttons-container">{buttons(field)}</div>
          </div>
        ))
      )}
    </div>
  );
};

export default Reports;

import React from 'react';
import Modal from 'react-modal';
import propTypes from 'prop-types';
import { common } from '../../core/i18n/text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as BinIcon } from '../../assets/svgs/Bin.svg';

const Text = { common };

const AlertModal = props => {
  const {
    className,
    show,
    title,
    bodyTitle,
    body,
    note,
    close,
    showButton,
    buttonText,
    buttonAction,
    buttonDisabled,
    buttonLoading,
    validationMessage,
    secondaryButton,
    customStyles,
  } = props;

  return (
    <Modal
      isOpen={show}
      className={`Modal__Container ${className ? className : ''}`}
      style={customStyles}
      ariaHideApp={false}
      aria={{
        labelledby: 'Alert Modal',
        modal: 'true',
      }}
      onRequestClose={close}
      closeTimeoutMS={200}
      shouldCloseOnOverlayClick={false}>
      {title && (
        <div className="Modal__Title">
          <span>{title}</span>
          <button aria-label={common.labels.close} className="Modal__Close" onClick={close}>
            <FontAwesomeIcon id="close-upload-modal" icon={faTimes} />
          </button>
          <hr className="Modal__Delimiter" />
        </div>
      )}
      {bodyTitle && <div className="Modal__BodyTitle">{bodyTitle}</div>}
      {note && <div className="Modal__Note">{note}</div>}
      {validationMessage && <div className="Modal__ValidationMessage">{validationMessage}</div>}
      <div className="Modal__Body" tabIndex="-1">
        {body && body.map((entry, index) => <div key={index}>{entry}</div>)}
        {props.children}
      </div>
      <div className="Modal__ButtonContainer">
        {secondaryButton && (
          <>
            {secondaryButton.redButton && <BinIcon />}
            <button
              type="button"
              className={secondaryButton.redButton ? 'redButton' : 'openButton'}
              onClick={secondaryButton.action ? secondaryButton.action : close}>
              {secondaryButton.text ? secondaryButton.text : Text.common.labels.close}
            </button>
          </>
        )}
        {showButton && (
          <button
            type="button"
            className="Modal__Button"
            disabled={buttonDisabled || buttonLoading}
            onClick={buttonAction ? buttonAction : close}>
            <hr className="Modal__Delimiter bottom" />
            {buttonLoading ? (
              <FontAwesomeIcon id="spinner-upload-modal" icon={faSpinner} spin />
            ) : buttonText ? (
              buttonText
            ) : (
              Text.common.labels.close
            )}
          </button>
        )}
      </div>
    </Modal>
  );
};

AlertModal.displayName = 'AlertModal';
AlertModal.propTypes = {
  show: propTypes.bool.isRequired,
  title: propTypes.string.isRequired,
  note: propTypes.string,
  close: propTypes.func.isRequired,
  buttonDisabled: propTypes.bool,
  buttonLoading: propTypes.bool,
  validationMessage: propTypes.string,
  customStyles: propTypes.object,
};

AlertModal.defaultProp = {
  buttonDisabled: false,
  buttonLoading: false,
  customStyles: {},
};

export default AlertModal;

import * as types from './types';

const initialState = {
  userInfo: null,
  organization: null,
  orgHierarchy: null,
  allAdminsByProgram: null,
  programs: null,
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.GET_USERINFO:
      return {
        ...state,
        userInfo: action.userInfo,
      };
    case types.GET_USER_ORGANIZATION:
      return {
        ...state,
        organization: action.organization,
      };
    case types.GET_USER_PROGRAMS:
      return {
        ...state,
        programs: action.programs,
      };
    case types.GET_USER_ADMINS:
      return {
        ...state,
        admins: action.admins,
      };
    case types.GET_ALL_ADMINS_BY_PROGRAM:
      return {
        ...state,
        allAdminsByProgram: action.payload,
      };
    case types.GET_USER_ORGS:
      return {
        ...state,
        orgs: action.orgs,
      };
    case types.GET_ORG_HIERARCHY:
      return {
        ...state,
        orgHierarchy: action.orgHierarchy,
      };
    case types.GET_USER_PERMISSIONS:
      return {
        ...state,
        userPermissions: action.userPermissions,
      };
    case types.GET_CHILD_ORGS:
      return {
        ...state,
        childOrganizations: action.payload,
      };
    case types.GET_APP_URL:
      return {
        ...state,
        appUrl: action.payload,
      };
    case types.GET_FILE_TEMPLATE:
      return {
        ...state,
        fileTemplate: action.payload,
      };
    case types.GET_SERVER_DATE_TIME:
      return {
        ...state,
        serverDateTime: action.payload,
      };
    case types.GET_TERMS:
      return {
        ...state,
        terms: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import _ from 'lodash/fp';
import moment from 'moment';

import { actions as coreActions } from '../../../core/duck';

import { OrgManagementServices } from '../../../services';

import { users } from '../../../core/constants';
import { ReactComponent as Invite } from '../../../assets/svgs/invite.svg';
import EmptyState from './EmptyState';
import AssignUserRolesModal from './AssignUserRolesModal';
import DataPanelContainer from '../../../components/data-panel/DataPanelContainer';
import LeftButtons from '../../../components/data-panel/profileButtons/ProfileButtons';
import AssingnStaffInTCMButton from './AssingnStaffInTCMButton';
import { countArray } from '../../../core/constants';
import { getPermission } from '../../../core/duck/selectors';

import { useStore } from '../../../store/hooks';

import TextContext from '../../../core/providers/TextProvider';

import './AdministrationUsers.scss';

const initialColumns = [
  {
    accessor: 'emailAddress',
    fields: ['emailAddress'],
    Header: 'Email',
    minWidth: 260,
    name: 'Email',
    type: '',
    width: 260,
  },
  {
    accessor: 'orgName',
    fields: ['orgName'],
    Header: 'Org name',
    minWidth: 406,
    name: 'Org name',
    type: '',
    width: 406,
  },
  {
    accessor: 'roles',
    fields: ['roles'],
    Header: 'Roles',
    minWidth: 210,
    name: 'Roles',
    type: '',
    width: 210,
  },
  {
    accessor: 'status',
    fields: ['status'],
    Header: 'Status',
    minWidth: 105,
    name: 'Status',
    type: 'status',
    width: 105,
  },
];

const initialSearch = {
  filter: '',
};

const inputList = [
  {
    maxLength: '30',
    name: 'filter',
    placeholder: 'Name or email',
    type: 'text',
  },
];

const SEARCH_PARAMS = {
  personnelName: 'personnelName',
};

const AdministrationUsers = ({ currentOrg, rootOrg }) => {
  /**
   *  react-router-dom hooks
   */
  const match = useRouteMatch();

  /**
   * useContext()
   */
  const Text = useContext(TextContext);

  /**
   * useDispatch()
   */
  const dispatch = useDispatch();

  /**
   * useStore()
   */
  const [childOrganizationsResponse, childOrganizationsStore] = useStore('childOrganizations');
  const [rolesResponse, rolesStore] = useStore('roles');
  const [testingEventsResponse, testingEventsStore] = useStore('testingEvents');

  /**
   * useState()
   */
  const [getStaffRecordsParams, setGetStaffRecordsParams] = useState({});
  const [getStaffResponse, setGetStaffResponse] = useState({});
  const [modalState, setModalState] = useState({ show: false, user: null });
  const [nonStickyColumns, setNonStickyColumns] = useState(initialColumns);

  /**
   * useSelector()
   */
  const appUrlResponse = useSelector(state => state.userInfoReducer.appUrl);
  const hasUsersManagePermissions = useSelector(state => getPermission(state, 'now_user_manage'));
  const hasUsersViewPermissions = useSelector(state => getPermission(state, 'now_user_view'));
  const serverDateTime = useSelector(state => state.userInfoReducer.serverDateTime);
  const userInfo = useSelector(state => state.userInfoReducer.userInfo);

  /**
   * useMemo()
   */
  const availableTestingEvents = useMemo(() => {
    if (!testingEventsResponse?.data || !serverDateTime) return [];
    const currentDate = moment(serverDateTime).format('YYYY-MM-DD');
    return testingEventsResponse.data.filter(({ endTestDate, testDate }) =>
      moment(
        !!endTestDate && moment(endTestDate).isAfter(moment(testDate), 'day')
          ? endTestDate
          : testDate
      )
        .add(7, 'd')
        .isSameOrAfter(currentDate)
    );
  }, [serverDateTime, testingEventsResponse]);

  const filterSections = useMemo(() => {
    return [
      {
        callParameter: 'orgId',
        label: 'orgname',
        name: 'organization',
        options:
          childOrganizationsResponse?.data?.filter(({ status }) => status !== 'notInvited') || [],
        placeholder: 'Select organization',
        setCustomLabel: option => `${option.orgname} (${option.orgcode})`,
        title: 'Organization',
        type: 'dropdown',
        value: 'orgId',
      },
      {
        callParameter: 'roles',
        isClearable: true,
        label: 'name',
        name: 'roles',
        options: rolesResponse?.data || [],
        placeholder: 'Select role',
        singleSelect: true,
        title: 'Roles',
        type: 'dropdown',
        value: 'role',
      },
      {
        callParameter: 'status',
        name: 'status',
        options: [
          {
            label: 'Active',
            value: 'active',
          },
          {
            label: 'Invited',
            value: 'inactive',
          },
        ],
        title: 'Status',
        type: 'checkbox',
      },
    ].filter(
      ({ name }) =>
        name !== 'organization' || currentOrg.allowChildOrgsFlag || currentOrg.type !== 'school'
    );
  }, [childOrganizationsResponse, currentOrg, rolesResponse]);

  /**
   * useCallback()
   */
  const getStaffRecords = useCallback(
    ({ filtersParams, limit = countArray[0], offset = 0, searchedValues }) => {
      if (!currentOrg?.orgPartId) return;
      setGetStaffRecordsParams({ filtersParams, limit, offset, searchedValues });
      setGetStaffResponse({ data: null, loading: true, meta: null });
      const { adminId, orgPartId } = currentOrg;
      const params = {
        adminId,
        filter: searchedValues?.filter,
        limit,
        offset,
        orgId: filtersParams?.orgId,
        orgPartId,
        roles: filtersParams?.roles,
        status: filtersParams?.status,
      };
      OrgManagementServices.getStaff(params).then(response =>
        setGetStaffResponse({ ...response.data, loading: false })
      );
    },
    [currentOrg]
  );

  const openInviteModal = useCallback(user => setModalState({ show: true, user }), []);

  const closeInviteModal = useCallback(() => {
    getStaffRecords(getStaffRecordsParams);
    setModalState({ show: false, user: null });
  }, [getStaffRecords, getStaffRecordsParams]);

  const onKeyDown = useCallback(
    (e, user) => {
      if (e.keyCode !== 13) return;
      openInviteModal(user);
    },
    [openInviteModal]
  );

  const setTableColumns = useCallback(
    () => [
      {
        columns: [
          {
            accessor: 'personnelName',
            Cell: ({ cell }) => {
              if (cell.row.original.personnelId === userInfo?.data?.id?.toString())
                return <div className="OrgManagementUsers__Name">{cell.value}</div>;
              return (
                <>
                  <div
                    className="link"
                    onClick={() =>
                      hasUsersViewPermissions ? openInviteModal(cell.row.original) : {}
                    }
                    onKeyDown={e =>
                      hasUsersViewPermissions ? onKeyDown(e, cell.row.original) : {}
                    }
                    tabIndex="0">
                    {cell.value}
                  </div>
                  <LeftButtons
                    hasUsersViewPermissions={hasUsersViewPermissions}
                    item={cell.row.original}
                    openInviteModal={openInviteModal}
                  />
                </>
              );
            },
            Header: 'Name',
            length: 1 + nonStickyColumns.length,
            minWidth: 293,
            type: 'actionCell',
            width: 293,
          },
        ],
        id: 'leftColumns',
        sticky: 'left',
      },
      {
        columns: nonStickyColumns,
        id: 'rightColumns',
      },
    ],
    [hasUsersViewPermissions, nonStickyColumns, openInviteModal, onKeyDown, userInfo]
  );

  /**
   * useEffect()
   */
  useEffect(() => {
    dispatch(coreActions.getAppUrl('tcm'));
    dispatch(coreActions.getServerDateTime());
  }, [dispatch]);

  useEffect(() => {
    if (!currentOrg || _.isEmpty(currentOrg)) return;
    const { adminId, orgPartId } = currentOrg;
    childOrganizationsStore.fetch({ adminId, orgPartId });
  }, [childOrganizationsStore, currentOrg]);

  useEffect(() => {
    if ((!currentOrg || _.isEmpty(currentOrg)) && (!rootOrg || _.isEmpty(rootOrg))) return;
    const { adminId, orgPartId } = rootOrg || currentOrg;
    rolesStore.fetch({ adminId, orgPartId });
  }, [currentOrg, rolesStore, rootOrg]);

  useEffect(() => {
    if (!currentOrg || _.isEmpty(currentOrg)) return;
    const { orgPartId } = currentOrg;
    testingEventsStore.fetch({ orgPartId });
  }, [currentOrg, testingEventsStore]);

  /**
   * render
   */
  return (
    <>
      {!!testingEventsResponse?.data?.length && (
        <AssingnStaffInTCMButton
          availableEvents={availableTestingEvents}
          appUrl={appUrlResponse?.data}
        />
      )}
      {filterSections &&
      filterSections[1].options.length > 0 &&
      (!currentOrg.allowChildOrgsFlag || filterSections[0].options.length > 0) ? (
        <DataPanelContainer
          addNewItemIcon={Invite}
          addNewItemLabel={Text.common.labels.invite}
          clickAction={openInviteModal}
          currentOrg={currentOrg}
          emptyStateComponent={
            <EmptyState
              handleOpenInviteModal={() => openInviteModal()}
              hasPermissions={hasUsersManagePermissions}
              organizations={getStaffResponse}
            />
          }
          emptyStateMessage={Text.features.orgManagement.emptyMessage}
          filters={filterSections}
          filtersOnlyOnDistrict={false}
          getRecords={getStaffRecords}
          handleAddNewItem={() => openInviteModal()}
          hasFocus={true}
          idKey="orgId"
          initialColumns={initialColumns}
          initialSearch={initialSearch}
          inputList={inputList}
          loadingData={getStaffResponse?.loading}
          manageList={users.manageList}
          nonStikyColumns={nonStickyColumns}
          searchParams={SEARCH_PARAMS}
          setColumns={setNonStickyColumns}
          setTableColumns={setTableColumns}
          stickyColumnsLength={0}
          tableData={getStaffResponse?.data}
          title="Participation Users - ACT-Now"
          totalCount={getStaffResponse?.meta?.totalCount}
          usingOrgPartId={true}
        />
      ) : (
        <DataPanelContainer
          addNewItemIcon={Invite}
          addNewItemLabel={Text.common.labels.invite}
          clickAction={openInviteModal}
          currentOrgPartId={currentOrg.orgPartId}
          emptyStateComponent={
            <EmptyState
              organizations={getStaffResponse}
              hasPermissions={hasUsersManagePermissions}
              handleOpenInviteModal={() => openInviteModal()}
            />
          }
          emptyStateMessage={Text.features.orgManagement.emptyMessage}
          filters={filterSections}
          filtersOnlyOnDistrict={false}
          getRecords={getStaffRecords}
          handleAddNewItem={() => openInviteModal()}
          hasFocus={true}
          hasUsersManagePermissions={hasUsersManagePermissions}
          idKey="orgId"
          initialColumns={initialColumns}
          initialSearch={initialSearch}
          inputList={inputList}
          loadingData={getStaffResponse?.loading}
          manageList={users.manageList}
          nonStikyColumns={nonStickyColumns}
          setColumns={setNonStickyColumns}
          setTableColumns={setTableColumns}
          stickyColumnsLength={0}
          tableData={getStaffResponse?.data}
          title="Participation Users - ACT-Now"
          totalCount={getStaffResponse?.meta?.totalCount}
          usingOrgPartId={true}
        />
      )}
      {modalState.show && (
        <AssignUserRolesModal
          close={closeInviteModal}
          currentOrg={currentOrg}
          match={match}
          showModal={true}
          userEntry={modalState.user}
        />
      )}
    </>
  );
};

export default AdministrationUsers;

import http from '../core/services/Http';
import Config from '../core/config';

const tcmQuery = '/tcm/query';

export default class ContractServices {
  static getContractAdminInfo(params) {
    return http.get(
      `${Config.apiUrl}${tcmQuery}/contractAdminDetail?adminId=${params.adminId}&contractId=${params.contractId}`
    );
  }

  static getContractInfo(params) {
    return http.get(`${Config.apiUrl}${tcmQuery}/contract?contractId=${params.contractId}`);
  }

  static getProgramAdminStaticInfo(params) {
    return http.get(
      `${Config.apiUrl}${tcmQuery}/programAdminStaticInfo?contractId=${params.contractId}`
    );
  }

  static getAdminContent(adminId) {
    return http.get(`${Config.apiUrl}${tcmQuery}/admin?adminId=${adminId}`);
  }
}

import React from 'react';
import * as Text from '../../../../core/i18n/text';
import './OrderDetailsModal.scss';
import CustomReactTable from '../../../../components/table/Table';
import Alert from '../../../../components/alerts/Alert';
import moment from 'moment';

const TrackingComponent = ({ orderTracking, errorTrackingNotification }) => {
  const tableData = orderTracking.boxes;
  const columns = [
    {
      Header: 'Box #',
      accessor: 'boxNumber',
      width: 62,
      type: 'number',
    },
    {
      Header: 'Status',
      accessor: 'status',
      width: 110,
      Cell: ({ cell }) =>
        cell.row.original.deliveredOn
          ? Text.features.testManagement.testEvents.labels.delivered
          : Text.features.testManagement.testEvents.labels.inTransit,
    },
    {
      Header: 'Expected arrival',
      accessor: 'expectedArrival',
      width: 140,
      Cell: ({ cell }) =>
        cell.row.original.expectedArrival
          ? moment(cell.row.original.expectedArrival).format('MM/DD/YYYY')
          : '-',
    },
    {
      Header: 'Delivered on',
      accessor: 'deliveredOn',
      Cell: ({ cell }) =>
        cell.row.original.deliveredOn
          ? moment(cell.row.original.deliveredOn).format('MM/DD/YYYY')
          : '-',
      width: 140,
    },
    {
      Header: 'Tracking #',
      accessor: 'trackingNumber',
      width: 124,
      Cell: ({ cell }) => (
        <a
          className="link"
          target="blank"
          href={`https://www.fedex.com/fedextrack/?trknbr=${cell.row.original.trackingNumber}`}>
          {cell.row.original.trackingNumber}
        </a>
      ),
    },
  ];
  return tableData?.length ? (
    <>
      <p className="info-text">
        <strong>{Text.features.testManagement.testEvents.labels.carrier}</strong>
        {orderTracking.carrier}
      </p>
      <CustomReactTable data={tableData} columns={columns} fullWidth />
    </>
  ) : errorTrackingNotification ? (
    <Alert type="error" message={Text.messages.error.generic} />
  ) : (
    <Alert type="info" message={Text.features.testManagement.testEvents.messages.noDetails} />
  );
};

export default TrackingComponent;

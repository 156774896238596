import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import _ from 'lodash/fp';
import moment from 'moment';

import BulkNotificationsModal from '../bulk-notifications-modal/BulkNotificationsModal';
import Header from '../../../components/header/Header';
import Notification from '../../../components/alerts/Notification';
import { OrgCrumbs, OrgSelect, Tabs } from '../../../components/index';
import OrgManagementFiles from '../administration-files/AdministrationFiles';
import OrgManagementOrganization from '../administration-organization/AdministrationOrganization';
import OrgManagementOverview from '../administration-overview/AdministrationOverview';
import OrgManagementParticipation from '../administration-participation/AdministrationParticipation';
import OrgManagementUsers from '../administration-users/AdministrationUsers';
import Reports from '../../../components/reports/Reports';
import SidebarNav from '../../../components/sidebar-nav/SidebarNav';

import { getPermission } from '../../../core/duck/selectors';
import { useStore } from '../../../store/hooks';

import TextContext from '../../../core/providers/TextProvider';

import { ReactComponent as Notifications } from '../../../assets/svgs/notifications.svg';
import { ReactComponent as Calendar } from '../../../assets/svgs/calendarIcon.svg';

import './AdministrationRouter.scss';

let closeUpdateSuccessNotificationTimer;
let tabDefinitions = [
  {
    component: <OrgManagementOrganization />,
    onlyForParentOrgs: true,
    title: 'Organization',
    url: 'organization',
  },
  {
    component: <OrgManagementParticipation />,
    hideForAutoConfirmDistricts: true,
    hideForRootParentOrg: true,
    onlyForParentOrgs: false,
    title: 'Org Participation',
    url: 'participation',
  },
  {
    avail: true,
    component: <OrgManagementUsers />,
    permissions: ['now_user_view', 'now_user_manage'],
    title: 'Users',
    url: 'users',
  },
  {
    avail: true,
    component: <Reports />,
    permissions: ['now_org_part_reports'],
    title: 'Reports',
    url: 'reports',
  },
  {
    component: <OrgManagementOverview />,
    onlyForParentOrgs: true,
    title: 'Overview',
    url: 'overview',
  },
  {
    component: <OrgManagementFiles />,
    onlyForParentOrgs: true,
    permissions: ['now_org_part_files', 'now_user_files'],
    subtabs: ['participate', 'staffinvite'],
    title: 'Files',
    url: 'files',
  },
];

const AdministrationRouter = () => {
  /**
   *  react-router-dom hooks
   */
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const params = useParams();

  /**
   * useContext()
   */
  const Text = useContext(TextContext);

  /**
   * useStore()
   */
  const [contractAdminInfoResponse, contractAdminInfoStore] = useStore('contractAdminInfo');
  const [invitationStatusResponse, invitationStatusStore] = useStore('invitationStatus');
  const [orgHierarchyResponse] = useStore('orgHierarchy');

  /**
   * useState()
   */
  const [activeSubTab, setActiveSubTab] = useState();
  const [activeTab, setActiveTab] = useState();
  const [notificationProps, setNotificationProps] = useState({ show: false });
  const [notificationModal, setNotificationModal] = useState({ display: false });
  const [TabComponent, setTabComponent] = useState(null);

  /**
   * useSelector()
   */
  const hasBulkNotificationPermissions = useSelector(state =>
    getPermission(state, 'now_bulk_notification_send')
  );
  const hasFilePermissions = useSelector(state => getPermission(state, 'now_org_part_files'));
  const hasUserFilePermissions = useSelector(state => getPermission(state, 'now_user_files'));
  const permissions = useSelector(state =>
    state.userInfoReducer.userPermissions?.data?.map(({ code }) => code)
  );

  /**
   * useMemo()
   */
  const currentOrg = useMemo(() => {
    if (!orgHierarchyResponse?.data) return {};
    return orgHierarchyResponse.data[orgHierarchyResponse.data.length - 1];
  }, [orgHierarchyResponse]);

  const endDate = useMemo(() => {
    if (!invitationStatusResponse?.data) return null;
    return moment(invitationStatusResponse.data.orgPartAddEndDate).format('MM.DD.YYYY');
  }, [invitationStatusResponse]);

  const parentOrg = useMemo(() => {
    if (!orgHierarchyResponse?.data) return {};
    return orgHierarchyResponse.data?.length === 1
      ? orgHierarchyResponse.data[0]
      : orgHierarchyResponse.data[orgHierarchyResponse.data.length - 2];
  }, [orgHierarchyResponse]);

  const rootOrg = useMemo(() => {
    if (!orgHierarchyResponse?.data) return {};
    return orgHierarchyResponse.data[0];
  }, [orgHierarchyResponse]);

  const tabs = useMemo(() => {
    if (
      _.isEmpty(currentOrg) ||
      !contractAdminInfoResponse?.data ||
      !invitationStatusResponse?.data ||
      !permissions?.length
    )
      return [];
    const { allowChildOrgsFlag, type } = currentOrg;
    const hasParent = Boolean(invitationStatusResponse.data.parentname);
    return tabDefinitions.map(tab => {
      if (tab.permissions?.length)
        return {
          ...tab,
          avail: tab.permissions.some(permission => permissions?.includes(permission)),
        };
      return {
        ...tab,
        avail:
          (!tab.hideForAutoConfirmDistricts ||
            type !== 'district' ||
            !contractAdminInfoResponse.data.length ||
            !contractAdminInfoResponse.data[0].districtAutoConfirm) &&
          ((allowChildOrgsFlag && tab.onlyForParentOrgs) ||
            (hasParent && tab.hideForRootParentOrg) ||
            !!tab.avail),
      };
    });
  }, [contractAdminInfoResponse, currentOrg, invitationStatusResponse, permissions]);

  /**
   * useCallback()
   */
  const closeNotificationModal = useCallback(() => {
    setNotificationModal({ display: false });
  }, []);

  const openNotificationModal = useCallback(() => {
    setNotificationModal({ display: true });
  }, []);

  /**
   * useEffect()
   */
  useEffect(() => {
    if (_.isEmpty(currentOrg)) return;
    const { adminId, contractId } = currentOrg;
    contractAdminInfoStore.fetch({ adminId, contractId });
  }, [contractAdminInfoStore, currentOrg]);

  useEffect(() => {
    if (activeSubTab || !_.isEmpty(currentOrg)) return;
    if (hasFilePermissions && currentOrg.allowChildOrgsFlag) {
      setActiveSubTab('participate');
    } else if (hasUserFilePermissions) {
      setActiveSubTab('staffinvite');
    } else {
      setActiveSubTab(null);
    }
  }, [activeSubTab, currentOrg, hasFilePermissions, hasUserFilePermissions]);

  useEffect(() => {
    if (_.isEmpty(currentOrg)) return;
    const { orgPartId } = currentOrg;
    invitationStatusStore.fetch({ orgPartId });
  }, [currentOrg, invitationStatusStore, rootOrg]);

  useEffect(() => {
    if (!tabs.length) return;
    const [url, path] = match.url.split('/').slice(2);
    const currentTab = tabs.find(tab => tab.url === url);
    if (!currentTab) return;

    setActiveTab(url);
    setActiveSubTab(currentTab?.subtabs?.includes(path) ? path : null);
    setTabComponent(currentTab?.component);
  }, [match.url, tabs]);

  useEffect(() => {
    if (_.isEmpty(currentOrg) || _.isEmpty(rootOrg)) return;
    const { allowChildOrgsFlag } = currentOrg;
    if (allowChildOrgsFlag) {
      if (
        location.pathname.includes('participation') &&
        orgHierarchyResponse.data?.length > 1 &&
        parseInt(rootOrg.orgPartId) === parseInt(currentOrg.orgPartId)
      )
        history.replace(`/administration/organization/orgPartId/${currentOrg.orgPartId}`);
    } else {
      if (location.pathname.includes('organization'))
        history.replace(`/administration/participation/orgPartId/${params.orgPartId}`);
    }
  }, [currentOrg, history, location.pathname, orgHierarchyResponse, params.orgPartId, rootOrg]);

  // Close notifications after 5 seconds
  useEffect(() => {
    if (!notificationProps.show) return;

    closeUpdateSuccessNotificationTimer = setTimeout(() => {
      setNotificationProps({ show: false });
      clearTimeout(closeUpdateSuccessNotificationTimer);
    }, 5000);
  }, [history, notificationProps.show]);

  return (
    <div className="roster__Page">
      <Header />
      <div className="_outter_">
        <SidebarNav active="orgManagement" />
        <div className="sidebar__Separator sidebar_fit_table">
          <OrgCrumbs
            crumbs={orgHierarchyResponse?.data || []}
            rootName={Text.features.orgManagement.breadcrumbsBase}
          />
          <div className={hasBulkNotificationPermissions ? 'bulk-notifications-container' : ''}>
            <OrgSelect currentOrg={currentOrg} parentOrg={parentOrg} />
            <div className="deadline-reminder-container">
              {endDate && (
                <div className="deadline-label">
                  <span>Deadline to Add/Confirm </span>
                  <Calendar />
                  {endDate}
                </div>
              )}
              {hasBulkNotificationPermissions && (
                <button className="bigButton" onClick={openNotificationModal}>
                  <Notifications />
                  {Text.features.orgManagement.bulkNotification.sendReminder}
                </button>
              )}
            </div>
          </div>

          <Tabs
            activeTab={activeTab}
            activeSubTab={activeSubTab}
            basePath="/administration"
            currentOrgPartId={match.params.orgPartId}
            handleTabChange={setActiveTab}
            tabs={tabs}
          />
          {TabComponent &&
            React.cloneElement(TabComponent, {
              activeSubTab,
              category: 'now_admin',
              currentOrg,
              inviteStatus: invitationStatusResponse,
              match,
              rootOrg,
            })}
        </div>
      </div>
      {notificationModal.display && (
        <BulkNotificationsModal
          closeModal={closeNotificationModal}
          handleNotificationShow={() => setNotificationProps({ show: true })}
          orgPartId={match.params.orgPartId}
        />
      )}
      {notificationProps.show && (
        <Notification
          handleClose={() => setNotificationProps({ show: false })}
          text={Text.features.orgManagement.bulkNotification.success}
          type="Success"
        />
      )}
    </div>
  );
};

export default AdministrationRouter;

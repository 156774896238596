import _ from 'lodash/fp';
import { createDispatchFunction } from '../../../services/helpers/stateHelper';

import { UserServices } from '../../../services';

import * as types from './types';

export function loginAction(payload) {
  return {
    type: types.LOGIN,
    payload,
  };
}

export function logoutAction(payload) {
  return {
    type: types.LOGOUT,
    payload,
  };
}

export function accVerificationAction(payload) {
  return {
    type: types.ACC_VERIFICATION,
    payload,
  };
}

export function forgotPasswordAction(payload) {
  return {
    type: types.FORGOT_PASSWORD,
    payload,
  };
}

export function resetPasswordAction(payload) {
  return {
    type: types.RESET_PASSWORD,
    payload,
  };
}

export function getTncAction(payload) {
  return {
    type: types.GET_TNC,
    payload,
  };
}

export function login(email, password, tnc) {
  return dispatch => {
    dispatch(loginAction({ loading: true, data: null, error: null }));

    UserServices.loginUser({ email, password, tnc })
      .then(success => {
        UserServices.setLoginToken(success.data);
        dispatch(loginAction({ loading: false, data: true, error: null }));
      })
      .catch(err => {
        let msg = err.response.data.Error.message || err.response.data.Error;
        if (msg === 'User is not confirmed.') {
          msg =
            'User account not verified, click resend verification email and follow the instructions.';
        }
        dispatch(loginAction({ loading: false, data: null, error: msg }));
      });
  };
}

export function logout() {
  return createDispatchFunction(_.partial(UserServices.logoutUser)([]), logoutAction);
}

export function resendVerificationCode(email) {
  return dispatch => {
    dispatch(accVerificationAction({ loading: true, data: null, error: null }));
    UserServices.resendVerificationCode(email)
      .then(success => {
        dispatch(accVerificationAction({ loading: false, data: true, error: null }));
      })
      .catch(error => {
        dispatch(
          accVerificationAction({ loading: false, data: null, error: error.response.data.Error })
        );
      });
  };
}

export function forgotPassword(email) {
  return createDispatchFunction(
    _.partial(UserServices.forgotPassword)([email]),
    forgotPasswordAction
  );
}

export function clearForgotPasswordFlow() {
  return dispatch => {
    dispatch(forgotPasswordAction({ loading: false, data: null, error: null }));
  };
}

export function resetPassword(data) {
  return createDispatchFunction(_.partial(UserServices.resetPassword)([data]), resetPasswordAction);
}

export function getTnc() {
  return dispatch => {
    dispatch(getTncAction({ loading: true, data: null, error: null }));
    UserServices.getTnc()
      .then(success => {
        dispatch(getTncAction({ loading: false, data: success.data, error: null }));
      })
      .catch(err => {
        const tncError = {
          UUID: null,
          Terms:
            '<p class="text-center">ACT ACCOUNT TERMS AND CONDITIONS OF USE</p>\r\n<p class="text-center" style="color: red">WE APOLOGIZE THAT THE TERMS COULD NOT BE LOADED AT THIS TIME. PLEASE TRY REFRESHING THE PAGE.</p>\r\n',
        };
        dispatch(getTncAction({ loading: false, data: tncError, error: null }));
      });
  };
}

import React from 'react';
import propTypes from 'prop-types';
import { messages, common } from '../../../core/i18n/text';
import './Password.scss';

const Text = { messages, common };

class Password extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      text: 'Show',
    };
  }

  showHidePassword = e => {
    e.preventDefault();
    const showPassword = this.state.showPassword ? false : true;
    const text = showPassword ? 'Hide' : 'Show';
    this.setState(() => ({
      showPassword,
      text,
    }));
  };

  onChange = e => {
    this.props.onChange(e.target.value);
  };

  onFocus = e => {
    if (this.props.onFocus) {
      this.props.onFocus(e.target.value);
    }
  };

  onBlur = e => {
    if (this.props.onBlur) {
      this.props.onBlur(e.target.value);
    }
  };

  render() {
    const { displayEmpty, displayError, displayHint } = this.props;
    return (
      <div className="field__Container">
        <label
          className={`field__Label${displayError || displayEmpty ? ' field__Label-error' : ''}`}
          htmlFor="password">
          {Text.common.fields.password}
        </label>
        <div className="field_wrapper">
          <input
            className={`field__Input${
              this.props.displayError || this.props.displayEmpty ? ' field__Input-error' : ''
            }`}
            name="password"
            id="password"
            type={this.state.showPassword ? 'text' : 'password'}
            onChange={this.onChange}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
          />
          <button className="showHideButton" onClick={this.showHidePassword} tabindex="-1">
            {this.state.text}
          </button>
        </div>
        {this.props.displayError && !this.props.displayEmpty && (
          <div className="field__ErrorMessage">{Text.messages.error.passwordInvalid}</div>
        )}
        {displayEmpty && (
          <div className="field__ErrorMessage">{Text.messages.error.passwordRequired}</div>
        )}
        {displayHint && !displayEmpty && !displayError && (
          <div className="field__HintMessage">{Text.messages.warning.passwordHint}</div>
        )}
      </div>
    );
  }
}

Password.displayName = 'Password';
Password.defaultProps = {
  displayEmpty: false,
  displayError: false,
  displayHint: false,
};
Password.propTypes = {
  onChange: propTypes.func.isRequired,
  onBlur: propTypes.func,
  onFocus: propTypes.func,
  displayEmpty: propTypes.bool,
  displayError: propTypes.bool,
  displayHint: propTypes.bool,
};

export default Password;

import React from 'react';
import * as Text from '../../../../core/i18n/text';
import './OrderDetailsModal.scss';
import CustomReactTable from '../../../../components/table/Table';
import Alert from '../../../../components/alerts/Alert';

const MaterialsComponent = ({ orderMaterials, errorMaterialsNotification }) => {
  const tableData = orderMaterials.materials;
  const columns = [
    {
      Header: 'Qty',
      accessor: 'quantity',
      width: 44,
      type: 'number',
    },
    {
      Header: 'Item name',
      accessor: 'itemName',
      width: 420,
    },
    {
      Header: 'Item code',
      accessor: 'itemCode',
      width: 115,
      minWidth: 115,
    },
  ];
  return tableData?.length ? (
    <>
      <CustomReactTable data={tableData} columns={columns} fullWidth />
    </>
  ) : errorMaterialsNotification ? (
    <Alert type="error" message={Text.messages.error.generic} />
  ) : (
    <Alert type="info" message={Text.features.testManagement.testEvents.messages.noDetails} />
  );
};

export default MaterialsComponent;
